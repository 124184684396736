import React from 'react';
import { useUnit } from 'effector-react';
import { get, isEmpty } from 'src/lib/lodash';
import { getDateReverse } from 'src/lib/date';
import { getValueFromMask, maskPhone } from 'src/lib/mask';
import { REG_STEP1_FIELDS, REG_STEP3_FIELDS } from 'src/dict/fields';
import { $user, $userCombineData } from 'src/models/User';

const { PHONE_HOME } = REG_STEP3_FIELDS;
const {
  FIRST_NAME, LAST_NAME, MIDDLE_NAME,
  YEAR_OF_BIRTH, PHONE, EMAIL,
} = REG_STEP1_FIELDS;

export const View = React.memo(() => {
  const [userCombineData, user] = useUnit([$userCombineData, $user]);

  const yearOfBirth = get(userCombineData, YEAR_OF_BIRTH);
  if (isEmpty(yearOfBirth)) return <div>Загрузка..</div>;

  let userName = `${get(userCombineData, LAST_NAME)} ${get(userCombineData, FIRST_NAME)}`;
  const middleName = get(userCombineData, MIDDLE_NAME);
  if (!isEmpty(middleName)) {
    userName = `${userName} ${middleName}`;
  }

  const email = get(userCombineData, EMAIL);
  const phone = get(userCombineData, PHONE);

  const userPhone = isEmpty(phone) ? '' : getValueFromMask(phone, maskPhone);
  const homePhone = get(user, 'contacts', []).reduce((acc, item) => {
    if (item.type === PHONE_HOME) {
      return item.contact;
    }
    return acc;
  }, '');
  const userHomePhone = isEmpty(homePhone) ? '' : getValueFromMask(homePhone, maskPhone);

  return (
    <>
      <div className="block-items">
        <div className="block-item">
          <div className="block-item-info">
            <p className="block-label">ФИО</p>
            <p className="block-text">{userName}</p>
          </div>
        </div>
        <div className="block-item">
          <div className="block-item-info">
            <p className="block-label">Дата рождения</p>
            <p className="block-text">
              {getDateReverse(yearOfBirth)}
            </p>
          </div>
        </div>
      </div>

      <div className="block-items">
        <div className="block-item">
          <div className="block-item-info">
            <p className="block-label">Телефон</p>
            <p className="block-text">{userPhone}</p>
          </div>
        </div>
        <div className="block-item">
          <div className="block-item-info">
            <p className="block-label">Электронная почта</p>
            <p className="block-text">{email}</p>
          </div>
        </div>
      </div>

      <div className="block-items">
        <div className="block-item">
          <div className="block-item-info">
            <p className="block-label">Домашний телефон</p>
            <p className="block-text">{userHomePhone}</p>
          </div>
        </div>
      </div>
    </>
  );
});
