import React from 'react';
import { useUnit } from 'effector-react';
import { get } from 'src/lib/lodash';
import { REG_PATH } from 'src/dict/path';
import {
  toggleDataEditFn,
  $dataIsEdit,
} from 'src/models/User/Personal';
import { View } from './children/View';
import { Form } from './children/Form';

const { PERSONAL } = REG_PATH;
export const Personal = React.memo(() => {
  const isEdit = get(useUnit($dataIsEdit), PERSONAL, true);
  const classEdit = isEdit ? 'edit' : '';
  return (
    <>
      <div className={`block ${classEdit}`}>
        <div className="edit">
          {!isEdit
            ? (
              <div
                className="edit-info"
                onClick={toggleDataEditFn.prepend(
                  () => ({ form: PERSONAL, value: true }),
                )}
              >
                <span className="text">Редактировать</span>
                <span className="icon">
                  <img src="/img/profile/edit.svg" alt="" />
                </span>
              </div>
            )
            : (
              <div
                className="edit-cancel"
                onClick={toggleDataEditFn.prepend(
                  () => ({ form: PERSONAL, value: false }),
                )}
              >
                Отменить
              </div>
            )}
        </div>

        <h2 className="block-title">Личные данные</h2>
        {isEdit
          ? <Form />
          : <View />}
      </div>
    </>
  );
});
