import React from 'react';
import { useStore } from 'effector-react';
import { isEmpty } from 'src/lib/lodash';
import { ButtonWithLoader as Button } from 'src/ui/components/Form';
import { $lockButton } from 'src/models/Registration';
import { sendYMGoalFx } from 'src/models/Statistic';
import {
  stopTimerActivationCodeFn,
  $valueTimer, $timerActivationCode,
} from 'src/models/Loan/Application';
import { Timer } from 'src/ui/components/Сonfirmation/Timer';

const handlerClick = (sendCodeFn, ym) => (e) => {
  e.preventDefault();
  sendCodeFn();
  if (!isEmpty(ym)) {
    sendYMGoalFx(ym);
  }
};

export const CodeRepeat = ({ sendCodeFn, ym }) => {
  const lockButton = useStore($lockButton);
  const valueTimer = useStore($valueTimer);
  const timerActive = useStore($timerActivationCode);
  const textButton = lockButton ? 'Отправка ...' : 'Оправить SMS код повторно';
  return (
    <>
      {!timerActive ? (
        <Button
          default={false}
          className="border newCodeBtn"
          onClick={handlerClick(sendCodeFn, ym)}
          disabled={lockButton}
        >
          {textButton}
        </Button>
      )
        : (
          <Timer
            stopTimer={stopTimerActivationCodeFn}
            timerActive={timerActive}
            value={valueTimer}
          />
        )}
    </>
  );
};
