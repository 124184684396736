import {
  sample, attach, combine, // combine,
} from 'effector';
import { debounce } from 'patronum/debounce';
import persist from 'effector-localstorage';
import { get, isEmpty, zipObject } from 'src/lib/lodash';
import { REG_PATH } from 'src/dict/path';
import {
  REG_STEP3_FIELDS, REG_STEP3_DEV_FIELDS, USER_FORM,
} from 'src/dict/fields';
import { pushHistoryFn } from 'src/models/Helper/History';
import { storageRemoveFn, storageSetFn } from 'src/models/Helper/Storage';
import { notifyErrorFn } from 'src/models/Helper/Notification';
import { hideLoaderButtonFn, showLoaderButtonFn } from 'src/models/Helper/Loader';
import { jumpFocusFieldFn } from 'src/models/Helper/Ref';
import { sendYMGoalFx } from 'src/models/Statistic';
import {
  $addressActualId,
  $addressPermanentId, $user, // $itemAddressData,
  getItemIdentitiesDataFx, updateHomePhoneUserFx,
} from 'src/models/User';
import {
  $registrationPage,
  loadDataToFormOperator, setFormFieldsOperator,
} from 'src/models/Registration';
import {
  getParentData, getOptions, getChildrenData, getObjAddress,
} from './helper';
import {
  // эффекты
  addAddressDataFx,
  // события
  addAddressDataFn, addHomePhoneFn,
  postChangeAddressFn, prepareChangeAddressFn,
  clearChildrenAddressFn,
  choiceAddressFn, notChoiceAddressFn,
  // хранилища
  $matchesPermanentActual,
  // формы
  step3Form,
  loadAddressOperator, getAddressDataByIdFx,
} from './index';

const { ADDRESS, EXPERIENCE, REGISTRATION } = REG_PATH;
const {
  HANDLER,
  REGION_CITY_STREET_PER, REGION_CITY_STREET_ACT,
  REGISTRATION_DATE,
  REGION_PER, REGION_ID_PER, AREA_ID_PER, REGION_ACT, REGION_ID_ACT, AREA_ID_ACT,
  CITY_PER, CITY_ID_PER, SETTLEMENT_ID_PER, CITY_ACT, CITY_ID_ACT, SETTLEMENT_ID_ACT,
  STREET_PER, STREET_ID_PER, STREET_ACT, STREET_ID_ACT,
  HOUSE_PER, HOUSE_ID_PER, HOUSE_ACT, HOUSE_ID_ACT,
  HOUSING_PER, HOUSING_ACT, APARTMENT_PER, APARTMENT_ACT, PHONE_HOME, IS_NOT_PHONE_HOME,
  HOUSE_ALL_DATA_PER, HOUSE_ALL_DATA_ACT, MATCHES_PER_ACT,
} = REG_STEP3_FIELDS;

const {
  REGION, REGION_ID, AREA_ID, CITY, CITY_ID, SETTLEMENT_ID,
  STREET, STREET_ID, HOUSE, HOUSE_ID, HOUSING, APARTMENT,
  HOUSE_ALL_DATA,
} = REG_STEP3_DEV_FIELDS;

const getArrPerItems = () => [
  REGION_PER, REGION_ID_PER, AREA_ID_PER, CITY_PER, CITY_ID_PER, SETTLEMENT_ID_PER,
  STREET_PER, STREET_ID_PER, HOUSE_PER, HOUSE_ID_PER, HOUSING_PER, APARTMENT_PER,
  HOUSE_ALL_DATA_PER,
];

const getArrActItems = () => [
  REGION_ACT, REGION_ID_ACT, AREA_ID_ACT, CITY_ACT, CITY_ID_ACT, SETTLEMENT_ID_ACT,
  STREET_ACT, STREET_ID_ACT, HOUSE_ACT, HOUSE_ID_ACT, HOUSING_ACT, APARTMENT_ACT,
  HOUSE_ALL_DATA_ACT,
];

const fieldPerItems = [...getArrPerItems()];
// const fieldPerItemsForClear = [REGION_PER, ...arrPerItems];

const fieldActItems = [...getArrActItems()];
// const fieldActItemsForClear = [REGION_ACT, ...arrActItems];

const fieldItems = [
  REGION, REGION_ID, AREA_ID, CITY, CITY_ID, SETTLEMENT_ID,
  STREET, STREET_ID, HOUSE, HOUSE_ID, HOUSING, APARTMENT,
  HOUSE_ALL_DATA,
];

/// ///////////////////////////////////////////////////////////
// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ===================== БЛОК ОПЕРАТОРОВ =================== //
/// ///////////////////////////////////////////////////////////

loadAddressOperator({ type: USER_FORM.ADDRESS_REG });

loadAddressOperator({ type: USER_FORM.ADDRESS_ACT });

sample({
  clock: getAddressDataByIdFx.done,
  fn: ({ params, result }) => ({
    ...get(result, 'data.suggestions.0.data', {}),
    unrestricted: get(result, 'data.suggestions.0.unrestricted_value', {}),
    fieldName: (params.formId === USER_FORM.ADDRESS_REG ? HOUSE_PER : HOUSE_ACT),
    formId: params.formId,
  }),
  target: choiceAddressFn,
});

// $itemAddressData.watch((itemAddressData) => console.log('itemAddressData:', itemAddressData));

// заполняем поля данными с ЕСИА (если таковые есть)
setFormFieldsOperator({
  form: step3Form,
  fields: [MATCHES_PER_ACT],
  key: ADDRESS,
  combineValues: combine($user, (user) => ({
    [MATCHES_PER_ACT]: (
      get(user, 'esia_data.addresses.0.fias', '') === get(user, 'esia_data.addresses.1.fias', '')
    ),
  })),
});

// адрес регистрации
sample({
  clock: sample({
    clock: $registrationPage,
    filter: (registrationPage) => registrationPage === ADDRESS,
  }),
  source: $addressPermanentId,
  filter: (addressPermanentId) => !isEmpty(addressPermanentId),
  target: getItemIdentitiesDataFx.prepend((id) => id),
});

// адрес проживания
sample({
  clock: sample({
    clock: $registrationPage,
    filter: (registrationPage) => registrationPage === ADDRESS,
  }),
  source: $addressActualId,
  filter: (addressActualId) => !isEmpty(addressActualId),
  target: getItemIdentitiesDataFx.prepend((id) => id),
});

// загрузка из LS
loadDataToFormOperator({
  form: step3Form,
  key: ADDRESS,
});

// сохранение в LS
persist({
  store: step3Form.$values,
  key: ADDRESS,
});

// Валидация при onBlur полей
sample({
  clock: [debounce({
    source: notChoiceAddressFn,
    timeout: 300,
  })],
  source: [step3Form.$values, step3Form.fields],
  fn: ([formValues, form], eventParam) => {
    const { fieldName } = eventParam;
    let inValidField = false;
    let errorText = '';
    let field = '';
    switch (fieldName) {
      // смотрим введено что-то в поле
      case REGION_CITY_STREET_PER:
        const inValidField0 = isEmpty(formValues[REGION_CITY_STREET_PER]);
        const inValidField1 = isEmpty(formValues[REGION_ID_PER]) && isEmpty(formValues[AREA_ID_PER]);
        const inValidField2 = isEmpty(formValues[SETTLEMENT_ID_PER]) && isEmpty(formValues[CITY_ID_PER]);
        const inValidField3 = !isEmpty(formValues[HOUSE_PER]) && formValues[HOUSE_PER].includes(', ');

        inValidField = inValidField0 || inValidField1 || inValidField2 || inValidField3;
        if (inValidField1) {
          errorText = 'Необходимо указать регион или район';
        }
        if (inValidField2) {
          errorText = 'Необходимо указать город или населенный пункт';
        }
        if (inValidField3) {
          errorText = 'Вы не указали улицу';
        }
        break;

      case REGION_CITY_STREET_ACT:
        const inValidField00 = isEmpty(formValues[REGION_CITY_STREET_ACT]);
        const inValidField01 = isEmpty(formValues[REGION_ID_ACT]) && isEmpty(formValues[AREA_ID_ACT]);
        const inValidField02 = isEmpty(formValues[SETTLEMENT_ID_ACT]) && isEmpty(formValues[CITY_ID_ACT]);
        const inValidField03 = !isEmpty(formValues[HOUSE_ACT]) && formValues[HOUSE_ACT].includes(', ');
        inValidField = inValidField00 || inValidField01 || inValidField02 || inValidField03;
        if (inValidField01) {
          errorText = 'Необходимо указать регион или район; выберите вариант из выпадающего списка';
        }
        if (inValidField02) {
          errorText = 'Необходимо указать город или населенный пункт; выберите вариант из выпадающего списка';
        }
        if (inValidField03) {
          errorText = 'Вы не указали улицу';
        }
        break;

      case HOUSE_PER:
        const inValidField10 = !isEmpty(formValues[HOUSE_PER]) && isEmpty(formValues[HOUSE_ID_PER]);
        const inValidField11 = !isEmpty(formValues[HOUSE_PER]) && formValues[HOUSE_PER].includes(', ');
        if (inValidField11) {
          errorText = 'Вы не указали улицу';
          field = REGION_CITY_STREET_PER;
        }

        inValidField = inValidField10 || inValidField11;
        break;

      case HOUSE_ACT:
        const inValidField20 = !isEmpty(formValues[HOUSE_ACT]) && isEmpty(formValues[HOUSE_ID_ACT]);
        const inValidField21 = !isEmpty(formValues[HOUSE_ACT]) && formValues[HOUSE_ACT].includes(', ');
        if (inValidField21) {
          errorText = 'Вы не указали улицу';
          field = REGION_CITY_STREET_ACT;
        }
        inValidField = inValidField20 || inValidField21;
        break;
      default:
    }
    return {
      inValidField, fieldName: (isEmpty(field) ? fieldName : field), form, errorText, formValues,
    };
  },
  target: [
    attach({
      effect: (_, {
        inValidField, fieldName, form, errorText,
      }) => {
        if (inValidField) {
          if (isEmpty(errorText)) {
            // form[fieldName].reset();
            form[fieldName].addError({
              rules: 'required',
              errorText: 'Пожалуйста, выберите вариант из выпадающего списка',
            });
          } else {
            // form[fieldName].reset();
            form[fieldName].addError({
              rules: 'required', errorText,
            });
          }
        } else {
          form[fieldName].validate();
        }

        /* if (isHandlerClick) {
          form.setForm({ [HANDLER]: '' });
        } */
      },
    }),
  ],
});

// при наборе в поле ввода адреса, достаем id-ки из родительского
// и посылка запроса на получение списка
sample({
  clock: prepareChangeAddressFn,
  source: step3Form.$values,
  fn: (formValues, eventParam) => {
    const { fieldName } = eventParam;
    let fiasObj = {};
    switch (fieldName) {
      case HOUSE_PER:
        if (!isEmpty(formValues[STREET_ID_PER])) {
          fiasObj = { street_fias_id: formValues[STREET_ID_PER] };
        } else {
          fiasObj = getOptions(
            formValues, STREET_ID_PER, STREET_ID_PER, 'street_fias_id', 'street_fias_id',
          );
          if (isEmpty(fiasObj)) {
            fiasObj = getOptions(
              formValues, CITY_ID_PER, SETTLEMENT_ID_PER, 'city_fias_id', 'settlement_fias_id',
            );
          }
          // console.log('fiasObj:', fiasObj, formValues);
        }
        break;
      case HOUSE_ACT:
        if (!isEmpty(formValues[STREET_ID_ACT])) {
          fiasObj = { street_fias_id: formValues[STREET_ID_ACT] };
        } else {
          fiasObj = getOptions(
            formValues, CITY_ID_ACT, SETTLEMENT_ID_ACT, 'city_fias_id', 'settlement_fias_id',
          );
          if (isEmpty(fiasObj)) {
            fiasObj = getOptions(formValues,
              REGION_ID_ACT, AREA_ID_ACT, 'region_fias_id', 'area_fias_Id');
          }
        }
        break;
      default:
    }

    return ({ ...eventParam, ...fiasObj });
  },
  // служит тригером внутри модели компонента адреса
  target: [
    // посылаем запрос на сервер dadata
    postChangeAddressFn,
  ],
});

// очистка нижних полей при изменении верхних
sample({
  clock: clearChildrenAddressFn,
  source: [step3Form.$values, $matchesPermanentActual],
  fn: ([formValues, matchesPermanentActual], eventParam) => {
    const { fieldName } = eventParam;
    let obj = {};

    const fieldsPer = getArrPerItems();
    const fieldsAct = getArrActItems();

    switch (fieldName) {
      case REGION_CITY_STREET_PER:
        obj = getChildrenData(obj, fieldsPer, REGION_PER);
        if (!isEmpty(matchesPermanentActual)) {
          obj = getChildrenData(obj, fieldsAct, REGION_ACT);
        }
        break;
      case REGION_CITY_STREET_ACT:
        obj = getChildrenData(obj, fieldsAct, REGION_ACT);
        break;

      case HOUSE_PER:
        obj = getChildrenData(obj, fieldsPer, fieldName);
        if (!isEmpty(matchesPermanentActual)) {
          obj = getChildrenData(obj, fieldsAct, fieldName);
        }
        break;
      case HOUSE_ACT:
        obj = getChildrenData(obj, fieldsAct, fieldName);
        break;

      case HOUSING_PER:
        obj = getChildrenData(obj, fieldsPer, fieldName);
        if (!isEmpty(matchesPermanentActual)) {
          obj = getChildrenData(obj, fieldsAct, fieldName);
        }
        break;

      case HOUSING_ACT:
        obj = getChildrenData(obj, fieldsAct, fieldName);
        break;
      default:
    }
    return { ...formValues, ...obj };
  },
  target: [
    step3Form.setForm,
  ],
  // target: postClearChildrenAddressFn,
});

// при выборе из выпадающего списка - изменение значений в полях
sample({
  clock: choiceAddressFn,
  source: step3Form.$values,
  fn: (formValues, eventParam) => {
    const { fieldName, unrestricted } = eventParam;
    const obj = {};

    const regionAgr = get(eventParam, 'region_with_type', '');
    let str = regionAgr;
    const areaAgr = get(eventParam, 'area_with_type', '');
    if (!isEmpty(areaAgr)) {
      str += `, ${areaAgr}`;
    }

    const cityAgr = get(eventParam, 'city_with_type', '');
    if (!isEmpty(cityAgr) && cityAgr !== regionAgr && cityAgr !== areaAgr) {
      str += `, ${cityAgr}`;
    }

    const settlementAgr = get(eventParam, 'settlement_with_type', '');
    if (!isEmpty(settlementAgr) && settlementAgr !== regionAgr && cityAgr !== areaAgr) {
      str += `, ${settlementAgr}`;
    }

    const streetAgr = get(eventParam, 'street_with_type', '');
    if (!isEmpty(streetAgr)) {
      str += `, ${streetAgr}`;
    }

    // const postalCode = get(eventParam, 'postal_code', '');
    // const regionCityStreet = get(eventParam, 'unrestricted', '').replace(`${postalCode},`, '');
    const regionCityStreet = `${str} `;

    const regionCityStreetPer = get(eventParam, 'regionCityStreet', regionCityStreet);
    const regionCityStreetAct = get(eventParam, 'regionCityStreet', regionCityStreet);

    const region = getParentData(eventParam, 'region_with_type', 'area_with_type');
    const regId = get(eventParam, 'region_fias_id', '');
    const areaId = get(eventParam, 'area_fias_Id', '');

    const city = getParentData(eventParam, 'city_with_type', 'settlement_with_type');
    const cityId = get(eventParam, 'city_fias_id', '');
    const settlementId = get(eventParam, 'settlement_fias_id', '');

    const street = get(eventParam, 'street_with_type', '');
    const streetId = get(eventParam, 'street_fias_id', '');

    const house = get(eventParam, 'house', '');
    const houseId = get(eventParam, 'house_fias_id', '');

    switch (fieldName) {
      case REGION_CITY_STREET_PER:
        obj[REGION_CITY_STREET_PER] = regionCityStreetPer;
        obj[REGION_PER] = region;
        obj[REGION_ID_PER] = regId;
        obj[AREA_ID_PER] = areaId;
        obj[CITY_PER] = city;
        obj[CITY_ID_PER] = cityId;
        obj[SETTLEMENT_ID_PER] = settlementId;
        obj[STREET_PER] = street;
        obj[STREET_ID_PER] = streetId;
        break;
      case REGION_CITY_STREET_ACT:
        obj[REGION_CITY_STREET_ACT] = regionCityStreetAct;
        obj[REGION_ACT] = region;
        obj[REGION_ID_ACT] = regId;
        obj[AREA_ID_ACT] = areaId;
        obj[CITY_ACT] = city;
        obj[CITY_ID_ACT] = cityId;
        obj[SETTLEMENT_ID_ACT] = settlementId;
        obj[STREET_ACT] = street;
        obj[STREET_ID_ACT] = streetId;
        break;

      case HOUSE_PER:
        obj[REGION_CITY_STREET_PER] = regionCityStreetPer;
        obj[REGION_PER] = region;
        obj[REGION_ID_PER] = regId;
        obj[AREA_ID_PER] = areaId;
        obj[CITY_PER] = city;
        obj[CITY_ID_PER] = cityId;
        obj[SETTLEMENT_ID_PER] = settlementId;
        obj[STREET_PER] = street;
        obj[STREET_ID_PER] = streetId;
        obj[HOUSE_PER] = house;
        obj[HOUSE_ID_PER] = houseId;
        if (isEmpty(obj[HOUSE_ID_PER])) {
          obj[HOUSE_ID_PER] = get(eventParam, 'fias_id', '');
        }
        obj[HOUSING_PER] = get(eventParam, 'block', '');
        obj[HOUSE_ALL_DATA_PER] = {
          data: { ...eventParam },
          unrestricted_value: unrestricted,
          value: unrestricted,
        };
        break;
      case HOUSE_ACT:
        obj[REGION_CITY_STREET_ACT] = regionCityStreetAct;
        obj[REGION_ACT] = region;
        obj[REGION_ID_ACT] = regId;
        obj[AREA_ID_ACT] = areaId;
        obj[CITY_ACT] = city;
        obj[CITY_ID_ACT] = cityId;
        obj[SETTLEMENT_ID_ACT] = settlementId;
        obj[STREET_ACT] = street;
        obj[STREET_ID_ACT] = streetId;
        obj[HOUSE_ACT] = house;
        obj[HOUSE_ID_ACT] = houseId;
        if (isEmpty(obj[HOUSE_ID_ACT])) {
          obj[HOUSE_ID_ACT] = get(eventParam, 'fias_id', '');
        }
        obj[HOUSING_ACT] = get(eventParam, 'block', '');
        obj[HOUSE_ALL_DATA_ACT] = {
          data: { ...eventParam },
          unrestricted_value: unrestricted,
          value: unrestricted,
        };
        break;
      default:
    }
    step3Form.resetErrors();
    return {
      ...formValues,
      ...obj,
      [HANDLER]: get(eventParam, HANDLER, ''),
      fieldName,
    };
  },
  target: [
    step3Form.setForm,
    jumpFocusFieldFn.prepend(({ fieldName }) => ([
      REGION_CITY_STREET_PER, REGION_CITY_STREET_ACT,
    ].includes(fieldName) ? fieldName : '')),
  ],
});

sample({
  clock: step3Form.formValidated,
  target: [
    showLoaderButtonFn,
    addAddressDataFn,
    addHomePhoneFn,
  ],
});

sample({
  clock: addHomePhoneFn,
  source: step3Form.$values,
  filter: (data) => isEmpty(data[IS_NOT_PHONE_HOME]),
  target: updateHomePhoneUserFx.prepend((data) => ({
    type: PHONE_HOME,
    contact: `${Number(data[PHONE_HOME].replace(/\D+/g, ''))}`,
  })),
});
//

// вначале добавляем адрес регистрации
sample({
  clock: addAddressDataFn,
  source: step3Form.$values,
  fn: (formValues) => {
    const arrDate = formValues[REGISTRATION_DATE].split('.');
    const registeredAt = `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}`;
    const objTest1 = {
      ...getObjAddress(
        formValues,
        zipObject(fieldItems, fieldPerItems),

      ),
      // registered_at: getCurrentDate(),
      [REGISTRATION_DATE]: registeredAt,
      type: 'registration',
    };
    return objTest1;
  },
  target: addAddressDataFx,
});

sample({
  clock: addAddressDataFx.fail,
  target: [
    // снимаем блок с кнопки
    hideLoaderButtonFn,
    notifyErrorFn.prepend(
      (data) => get(
        data,
        'error.response.data.errors.street.0',
        get(data, 'error.response.street.message', ''),
      ),
    ),
  ],
});

sample({
  clock: sample({
    clock: addAddressDataFx.done,
    filter: ({ params }) => {
      const { type } = params;
      return type === USER_FORM.ADDRESS_REG;
    },
  }),
  source: [step3Form.$values, $matchesPermanentActual],
  fn: ([formValues, matchesPermanentActual]) => {
    const data = matchesPermanentActual ? fieldPerItems : fieldActItems;
    const objTest = ({
      ...getObjAddress(
        formValues,
        zipObject(fieldItems, data),
      ),
      type: USER_FORM.ADDRESS_ACT,
    });
    return objTest;
  },
  // тут посылаем запрос на добавлении адреса проживания
  target: addAddressDataFx,
});

const triggerAddAddressDataFn = sample({
  clock: addAddressDataFx.done,
  filter: ({ params }) => {
    const { type } = params;
    return type === USER_FORM.ADDRESS_ACT;
  },
});

sample({
  clock: triggerAddAddressDataFn,
  target: [
    // и записываем в локалсторадж что у нас новый шаг
    storageSetFn.prepend(({ params }) => ({
      step: EXPERIENCE,
      city: get(params, 'city', get(params, 'settlement', '')).replace('г', ''),
    })),
    sendYMGoalFx.prepend(() => 'REG_ADDR_DATA_SENT'),
  ],
});

sample({
  clock: debounce({
    source: triggerAddAddressDataFn,
    timeout: 100,
  }),
  target: [
    // очищаем форму от данных
    // step3Form.reset,
    storageRemoveFn.prepend(() => ADDRESS),
    // очищаем форму от данных
    // step3Form.reset,
    // редиректим юзера на след шаг
    pushHistoryFn.prepend(() => `/${REGISTRATION}/${EXPERIENCE}`),
    // снимаем блок с кнопки
    hideLoaderButtonFn,
  ],
});

// step3Form.$values.watch((state) => console.log('step3Form.$values:', state));
