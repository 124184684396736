import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { useForm } from 'effector-forms';
import { get, isEmpty } from 'src/lib/lodash';
import { getEventsField, onSubmit } from 'src/lib/form';
import { REG_STEP_SUCCESS_CONFIRM_FIELDS } from 'src/dict/fields';
import {
  $agreementDocs, changeAgreementDocsFn, getAgreementDocsFn,
  stepAgreementForm,
} from 'src/models/Loan/Application/Success';
import { Checkbox, ButtonWithLoader as Button } from 'src/ui/components/Form';
import 'src/ui/styles/AgreementLoan.css';

const { AGREEMENT } = REG_STEP_SUCCESS_CONFIRM_FIELDS;

export const AgreementLoan = React.memo(() => {
  const agreementDocs = useStore($agreementDocs);
  const { submit, ...restProps } = useForm(stepAgreementForm);

  useEffect(() => {
    getAgreementDocsFn();
  }, []);

  const agreementErrorText = get(restProps, 'errorText', () => '')(AGREEMENT);
  const agreementHasError = get(restProps, 'hasError', () => '')(AGREEMENT);
  const classCheckValid = !isEmpty(agreementHasError) ? ' invalid' : '';

  return (
    <>
      <h3 className="title">
        Для получения займа вам необходимо согласиться с условиями договора и ввести проверочный
        код из SMS
      </h3>
      <form onSubmit={onSubmit(submit)}>
        <div className="changeAmountAndTerm-checks block-checks">
          {agreementDocs.map(
            ({
              link, label, value, id,
            }) => (
              <div className="modal modal-checkbox default-checkbox" key={id}>
                <input
                  type="checkbox"
                  id={`ex${id}confirm`}
                  checked={value}
                  onChange={changeAgreementDocsFn.prepend(() => ({ id, value: !value }))}
                />
                <label htmlFor={`ex${id}confirm`} className="default-checkbox__label">
                  <div className="default-checkbox__block" />
                  <a
                    className="default-checkbox__text"
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {label}
                  </a>
                </label>
              </div>
            ),
          )}
        </div>

        <div className="block-checkbox default-checkbox green agreeWithEverything">
          <Checkbox
            {...getEventsField(get(restProps.fields, AGREEMENT))}
            name={AGREEMENT}
            id={AGREEMENT}
          />
          <label htmlFor={AGREEMENT} className="default-checkbox__label ">
            <div className={`default-checkbox__block ${classCheckValid}`} />
            <p className="default-checkbox__text">Согласен со всем</p>
          </label>

          {agreementHasError
        && !isEmpty(agreementErrorText)
        && <p className="invalid-text">{agreementErrorText}</p>}
        </div>

        <br />
        <div className="block-checks__button">
          <Button
            type="submit"
            className="nextStep"
          >
            Получить СМС
          </Button>
        </div>
      </form>
    </>
  );
});
