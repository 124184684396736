import { createForm } from 'effector-forms';
import { rules } from 'src/lib/rules';
import {
  REG_STEP_SUCCESS_CONFIRM_FIELDS, REG_STEP_SUCCESS_FINAL_FIELDS,
} from 'src/dict/fields';
import { applicationDomain } from 'src/models/Loan/Application';
import {
  getAgreementDocSign,
} from 'src/api/signatures/Agreement';

// STEP SUCCESS / CONFIRM / FINAL - последний шаг

/// ///////////////////////////////////////////////////////////
// ================== СЛОВАРИ / КОНСТАНТЫ ================== //
/// ///////////////////////////////////////////////////////////

const { AGREEMENT } = REG_STEP_SUCCESS_CONFIRM_FIELDS;
const {
  ONE, TWO, THREE, FOUR, FIVE, SIX,
} = REG_STEP_SUCCESS_FINAL_FIELDS;

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ СОБЫТИЙ ===================== //
/// ///////////////////////////////////////////////////////////

// изменение массива согласий, которые должны быть одобрены
export const changeAgreementDocsFn = applicationDomain.createEvent('changeAgreementDocsFn');

export const getAgreementDocsFn = applicationDomain.createEvent('getAgreementDocsFn');
export const getAgreementDocsForLoanFn = applicationDomain.createEvent('getAgreementDocsFn');

export const getConfirmSuccessPageFn = applicationDomain.createEvent('getConfirmSuccessPageFn');

export const getAfterConfirmSuccessPageFn = applicationDomain.createEvent('getAfterConfirmSuccessPageFn');

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ХРАНИЛИЦ ==================== //
/// ///////////////////////////////////////////////////////////

// массив согласий, которые должны быть одобрены
export const $agreementDocs = applicationDomain.createStore([], { name: 'agreementDocs' });

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ЭФФЕКТОВ ==================== //
/// ///////////////////////////////////////////////////////////

export const getAgreementDocsFx = applicationDomain.createEffect(getAgreementDocSign);
export const getAgreementDocsForLoanFx = applicationDomain.createEffect(getAgreementDocSign);

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ЭФФЕКТОВ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

// тут создаются эффекты через attach

/// ///////////////////////////////////////////////////////////
// ====================== ЮНИТЫ ФОРМ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем формы с помощью effector-forms

export const stepAgreementForm = createForm({
  fields: {
    [AGREEMENT]: { rules: [rules.requiredAgreementCheckbox()], init: false },
  },
  validateOn: ['submit'],
  domain: applicationDomain,
});

export const stepConfirmForm = createForm({
  fields: {
    [ONE]: { rules: [rules.required(), rules.number(), rules.maxLength(1)], init: '' },
    [TWO]: { rules: [rules.required(), rules.number(), rules.maxLength(1)], init: '' },
    [THREE]: { rules: [rules.required(), rules.number(), rules.maxLength(1)], init: '' },
    [FOUR]: { rules: [rules.required(), rules.number(), rules.maxLength(1)], init: '' },
    [FIVE]: { rules: [rules.required(), rules.number(), rules.maxLength(1)], init: '' },
    [SIX]: { rules: [rules.required(), rules.number(), rules.maxLength(1)], init: '' },
  },
  validateOn: ['blur', 'submit'],
  domain: applicationDomain,
});

/// ///////////////////////////////////////////////////////////
//  ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ФОРМ + (ХРАНИЛИЩ, ЭФФЕКТОВ) //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ========================= ФАБРИКИ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем различные фабрики и хранилища с использованием фабрик
