import React, { useRef } from 'react';
import { useStore } from 'effector-react';
import { get, isEmpty } from 'src/lib/lodash';
import { useOutsideAlerter } from 'src/lib/hooks';
import '../shared/InputDaData.css';

const handlerChange = ({ onChange, onChangeInputFn }) => (e) => {
  onChange(e);
  onChangeInputFn(get(e, 'target.value', ''));
};

const checkPhoneKey = (onCloseOptionsFn) => (e) => {
  const keyCode = get(e, 'keyCode', '');
  if (!isEmpty(keyCode) && keyCode === 9) {
    onCloseOptionsFn();
  }
};

const handlerBlur = ({
  formId, name, notChoiceAddressFn, onBlur,
}) => (e) => {
  onBlur(e);
  notChoiceAddressFn({ fieldName: name, formId });
};

const handlerClick = ({
  valueOption, onChange, onCloseOptionsFn, jumpField,
  choiceAddressFn, options, name, formId,
}) => () => {
  onChange(valueOption);
  choiceAddressFn({
    ...get(options.find(({ value }) => value === valueOption), 'data', {}),
    unrestricted: get(options.find(({ value }) => value === valueOption), 'unrestricted_value', {}),
    fieldName: name,
    handler: 'click',
    formId,
  });
  if (!isEmpty(jumpField) && typeof jumpField === 'function') {
    jumpField();
  }
  onCloseOptionsFn();
};

export const InputDaDataAddress = React.forwardRef(({
  // пришло из модели DaData
  modelData: {
    $selectData, onChangeInputFn, onFocusInputFn, onCloseOptionsFn,
  },
  propsData,
}, ref) => {
  const {
    visibleOptions, placeholder, options, isOpen, minLength,
  } = useStore($selectData);
  const {
    choiceAddressFn, // событие эффектора из вне, которое будет срабатывать при выборе из списка подсказок
    notChoiceAddressFn, // событие эффектора из вне, которое будет срабатывать при потере фокуса
    typeAddress,
    formId, value, label, disabled, name, type, tabIndex, jumpField, required,
    errorText, hasError, onChange, onBlur, // приходят из getPropsField - формы эффектора
    ...restProps
  } = propsData;

  const val = (isEmpty(value) ? '' : value);
  const isMinValue = (minLength <= val.length);
  const isOpenOptions = isMinValue && isOpen && !isEmpty(options);

  const hasFocused = name === get(document, 'activeElement.id', false);

  const fieldLabel = `${label}${(required ? '*' : '')}`;
  let validState = '';
  if (required && (hasError || (!isEmpty(`${val}`.trim()) && !hasFocused))) {
    validState = hasError ? 'invalid' : 'valid';
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onCloseOptionsFn);

  const offsetWidth = get(document.getElementById(name), 'parentElement.parentElement.offsetWidth', '');
  const styleContainer = {
    position: 'absolute',
    width: isEmpty(offsetWidth) ? 0 : `${offsetWidth}px`,
  };

  return (
    <>
      <div
        className={`default-input ${validState} ${isOpenOptions ? ' select open' : ''}`}
      >
        <input
          {...restProps}
          tabIndex={tabIndex}
          type={type}
          placeholder={placeholder}
          required={required}
          value={val}
          onChange={handlerChange({ onChange, onChangeInputFn })}
          onBlur={handlerBlur({
            formId, name, notChoiceAddressFn, onBlur,
          })}
          onFocus={onFocusInputFn}
          disabled={disabled}
          id={name}
          ref={ref}
          onKeyDown={checkPhoneKey(onCloseOptionsFn)}
        />
        <label>{fieldLabel}</label>
      </div>
      {required && hasError && !isEmpty(errorText) && <p className="invalid-text">{errorText}</p>}

      {isOpenOptions && (
        <div
          className="default-select container"
          style={styleContainer}
        >
          <div
            className="box"
          >
            <ul ref={wrapperRef}>
              <>
                {visibleOptions.map(({ label: labelOption, value: valueOption }, index) => (
                  <li
                    key={`${valueOption}_${index}`}
                    aria-selected={valueOption === val}
                    role="option"
                    onClick={
                      handlerClick({
                        valueOption,
                        onChange,
                        onCloseOptionsFn,
                        jumpField,
                        choiceAddressFn,
                        options,
                        name,
                        formId,
                      })
                    }
                  >
                    {labelOption}
                  </li>
                ))}
              </>
            </ul>
          </div>

        </div>
      )}
    </>
  );
});

InputDaDataAddress.defaultProps = {
  formId: '',
  label: '',
  value: '',
  options: [],
  onChange: () => '',
  onBlur: () => '',
  required: false,
  jumpField: () => '',
};
