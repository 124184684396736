import React from 'react';
import { useStore } from 'effector-react';
import { $phoneSite } from 'src/models/App';
import { SignIn } from 'src/ui/blocks/Header/children/SignIn';

const Menu = React.memo(({ isOpen, closeMenuMobileFn }) => {
  const phoneSite = useStore($phoneSite);
  return (
    <div className="header-mobile__menu">

      {isOpen && (
        <div className="header-button__menu" onClick={closeMenuMobileFn}>
          <div className="header-line header-line1" />
          <div className="header-line header-line2" />
          <div className="header-line header-line3" />
        </div>
      )}

      <nav className="header-navigation">
        <ul className="header-navigation__list">
          <li className="header-navigation__item ">
            <a href="/#how" onClick={closeMenuMobileFn}>О нас</a>
          </li>
          <li className="header-navigation__item">
            <a href="/#why" onClick={closeMenuMobileFn}>Почему мы</a>
          </li>
          <li className="header-navigation__item">
            <a href="/#reviews" onClick={closeMenuMobileFn}>Отзывы</a>
          </li>
        </ul>
      </nav>

      <SignIn />

      <div className="header-info">
        <div className="header-info__label">Круглосуточно</div>
        <a href={`tel:${phoneSite};`} className="header-info__tel">{phoneSite}</a>
      </div>
    </div>
  );
});

export { Menu };
