import React from 'react';
import { useStore } from 'effector-react';
import { getFormatMoney } from 'src/lib/money';
import { $calcCombineData } from 'src/models/Calculator';

export const Limit = React.memo(() => {
  const { amountLimit } = useStore($calcCombineData);
  return (
    <div className="profileTop-item profileTop-item-limit">
      <div className="profileTop-item-icon">
        <img src="/img/profile/coinIcon.png" alt="" />
      </div>
      <div className="profileTop-item-info">
        <p className="profileTop-item-description">Ваш лимит</p>
        <p className="profileTop-item-title">
          {getFormatMoney(amountLimit)}
          {' '}
          ₽
        </p>
        {/* <a href="#" className="profileTop-item-btn">увеличить</a> */}
      </div>
    </div>
  );
});
