import { CODE_FIELDS } from 'src/dict/config';

export const GENDER_FIELD_VALUES = {
  MAN: 'male',
  WOMAN: 'female',
};

export const GENDER_VALUES = {
  [GENDER_FIELD_VALUES.MAN]: 'Мужской',
  [GENDER_FIELD_VALUES.WOMAN]: 'Женский',
};

export const REG_STEP1_FIELDS = {
  PHONE: 'phone',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',

  IS_NOT_MIDDLE_NAME: 'isNotMiddleName',
  MIDDLE_NAME: 'middle_name',

  YEAR_OF_BIRTH: 'birth_date',
  GENDER: 'gender',

  IS_NOT_EMAIL: 'isNotEmail',
  EMAIL: 'email',

  PURPOSE: 'purpose',
  AGREEMENT: 'agreement',
  UUID: 'uuid',
};

export const AGREEMENT_DOCS = [{
  id: 'ef75a8b1-65fa-4901-96bd-3f73b178e12b',
  label: 'Правила предоставления займов',
  value: false,
  link: '#',
}, /* , {
  id: 2,
  label: 'Соглашение об использовании АСП',
  link: '#',
  value: false,
}, {
  id: 3,
  label: 'Согласие на обработку персональных данных',
  link: '#',
  value: false,
}, {
  id: 4,
  label: 'Согласие на обработку персональных данных (ПАО МТС)',
  link: '#',
  value: false,
}, {
  id: 5,
  label: 'Общие условия договора потребительского займа',
  link: '#',
  value: false,
}, {
  id: 6,
  label: 'Уведомление о расчете ПДН',
  link: '#',
  value: false,
} */];

export const REG_STEP2_FIELDS = {
  PASSPORT_ID: 'number',
  SNILS: 'snils',
  PASSPORT_SERIES: 'series',
  PASSPORT_DATE_ISSUE: 'issued_at',
  CODE_DEPARTMENT: 'subdivision',
  PASSPORT_ISSUE_BY: 'issued_by',
  PLACE_BIRTH: 'birth_place',
  UUID: 'uuid',
};

export const REG_STEP3_FIELDS = {
  HANDLER: 'handler',
  REGION_CITY_STREET_PER: 'regionCityStreetPer',
  REGION_CITY_STREET_ACT: 'regionCityStreetAct',

  RAW_DATA: 'raw_data',
  PHONE_HOME: 'home_phone',
  REGISTRATION_DATE: 'registered_at',
  IS_NOT_PHONE_HOME: 'isNotPhoneHome',
  REGION_PER: 'regionPer',
  CITY_PER: 'cityPer',
  STREET_PER: 'streetPer',
  HOUSE_PER: 'housePer',
  HOUSING_PER: 'housingPer',
  APARTMENT_PER: 'apartmentPer',
  MATCHES_PER_ACT: 'matchesPerAct',
  REGION_ACT: 'regionAct',
  CITY_ACT: 'cityAct',
  STREET_ACT: 'streetAct',
  HOUSE_ACT: 'houseAct',
  HOUSING_ACT: 'housingAct',
  APARTMENT_ACT: 'apartmentAct',
  RAW_DATA_ACT: 'raw_data',

  REGION_FIAS_ID_PER: 'regionFiasIdPer',
  AREA_FIAS_ID_PER: 'areaFiasIdPer',
  SETTLEMENT_FIAS_ID_PER: 'settlementFiasIdPer',
  CITY_FIAS_ID_PER: 'cityFiasIdPer',
  STREET_FIAS_ID_PER: 'streetFiasIdPer',
  HOUSE_FIAS_ID_PER: 'houseFiasIdPer',
  // все данные по последнему запросу - по сути по запросу дома
  HOUSE_ALL_DATA_PER: 'houseAllDataPer',

  REGION_FIAS_ID_ACT: 'regionFiasIdAct',
  AREA_FIAS_ID_ACT: 'areaFiasIdAct',
  SETTLEMENT_FIAS_ID_ACT: 'settlementFiasIdAct',
  CITY_FIAS_ID_ACT: 'cityFiasIdAct',
  STREET_FIAS_ID_ACT: 'streetFiasIdAct',
  HOUSE_FIAS_ID_ACT: 'houseFiasIdAct',
  // все данные по последнему запросу - по сути по запросу дома
  HOUSE_ALL_DATA_ACT: 'houseAllDataAct',

  REGION_ID_PER: 'regionIdPer',
  AREA_ID_PER: 'areaIdPer',
  REGION_ID_ACT: 'regionIdAct',
  AREA_ID_ACT: 'areaIdAct',
  CITY_ID_PER: 'cityIdPer',
  SETTLEMENT_ID_PER: 'settlementIdPer',
  CITY_ID_ACT: 'cityIdAct',
  SETTLEMENT_ID_ACT: 'settlementIdAct',
  STREET_ID_PER: 'streetIdPer',
  STREET_ID_ACT: 'streetIdAct',
  HOUSE_ID_PER: 'houseIdPer',
  HOUSE_ID_ACT: 'houseIdAct',
};

export const REG_STEP3_DEV_FIELDS = {
  ADDRESS_TYPE: 'addressType',
  REGION_CITY_STREET: 'regionCityStreet',
  REGISTRATION_DATE: 'registered_at',

  REGION: 'region',
  REGION_ID: 'regionId',
  AREA_ID: 'areaId',
  CITY: 'city',
  CITY_ID: 'cityId',
  SETTLEMENT_ID: 'settlementId',
  STREET: 'street',
  STREET_ID: 'streetId',
  HOUSE: 'house',
  HOUSE_ID: 'houseId',
  HOUSING: 'block',
  APARTMENT: 'flat',
  HOUSE_ALL_DATA: 'houseAllData',

  KLADR: 'kladr',
  FIAS: 'fias',
  ZIP: 'zip',
  RAW_DATA: 'raw_data',
  SETTLEMENT: 'settlement',
  AREA: 'area',
};

// current/info
// current/works
export const REG_STEP4_FIELDS = {
  SCOPE_EMPLOYMENT: 'scope_id', // works
  POSITION: 'position', // works
  PLACE_WORK: 'name', // works
  PHONE_WORK: 'work_phone', // works

  EDUCATION: 'education_id', // info
  FAMILY_STATUS: 'marital_status_id', // info
  ACTIVITY_TYPE: 'employment_id', // info
  INCOME_PER_MONTH: 'income_per_month', // info
  COST_LOANS_PER_MONTH: 'amount_pay_monthly_credit', // info
  EXISTENCE_BANKRUPTCY_CASE: 'was_bankrupt', // info
};

export const REG_STEP5_PHONE = {
  PHONE: 'phone',
  PHONE_MOBILE: 'mobile_phone',
};

export const REG_STEP5_CODE = {
  ONE: 'one',
  TWO: 'two',
  THREE: 'three',
  FOUR: 'four',
  FIVE: 'five',
  SIX: 'six',
};

export const REG_STEP_SUCCESS_CONFIRM_FIELDS = {
  AGREEMENT: 'agreement',
};

export const CONFIRM_DOCS = [{
  id: 1,
  label: 'Я соглашаюсь с условиями договора',
  link: '#',
  value: false,
}, {
  id: 2,
  label: 'Соглашением на обработку персональных данных;',
  link: '#',
  value: false,
}, {
  id: 3,
  label: 'Соглашением на взаимодействие с третьими лицами;',
  link: '#',
  value: false,
}, {
  id: 4,
  label: 'Соглашением на передачу данных третьим лицам;',
  link: '#',
  value: false,
}, {
  id: 5,
  label: 'Соглашением на акцептное списание;',
  link: '#',
  value: false,
}];

export const REG_STEP_SUCCESS_FINAL_FIELDS = {
  AGREEMENT: 'agreement',
  ...CODE_FIELDS,
};
