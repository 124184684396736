import React from 'react';
import { useUnit } from 'effector-react';
import { useForm } from 'effector-forms';
import { get, isEmpty } from 'src/lib/lodash';
import {
  getEventsField,
  getPropsField, getRefField, jumpFocusField, onSubmit,
} from 'src/lib/form';
import { maskNumHouses } from 'src/lib/mask';
import { maskDate, maskHomePhone } from 'src/lib/maskPlaceholder';
import { REG_STEP3_FIELDS } from 'src/dict/fields';
import {
  jumpFocusFieldFn, setRefFieldsFn,
} from 'src/models/Helper/Ref';
import {
  prepareChangeAddressFn,
  postChangeAddressFn,
  choiceAddressFn, notChoiceAddressFn, clearChildrenAddressFn,
  step3Form,
} from 'src/models/Registration/Address';
import {
  Input, Checkbox, InputAddress, ButtonWithLoader as Button, InputDate,
} from 'src/ui/components/Form';

const {
  REGION_CITY_STREET_PER, HOUSE_PER, HOUSING_PER, APARTMENT_PER,
  REGISTRATION_DATE,
  PHONE_HOME, IS_NOT_PHONE_HOME, MATCHES_PER_ACT,
  REGION_CITY_STREET_ACT, HOUSE_ACT, HOUSING_ACT, APARTMENT_ACT,
} = REG_STEP3_FIELDS;

const methodsAddress = {
  prepareChangeAddressFn,
  postChangeAddressFn,
  choiceAddressFn,
  clearChildrenAddressFn,
  notChoiceAddressFn,
};

export const AddressForm = React.memo(() => {
  const {
    submit, eachValid, ...restProps
  } = useForm(step3Form);
  const [isMatchesPermanentActual, isNotPhoneHome] = useUnit([
    step3Form.fields[MATCHES_PER_ACT].$value, step3Form.fields[IS_NOT_PHONE_HOME].$value,
  ]);

  return (
    <form autoComplete="off" onSubmit={onSubmit(submit)}>

      <h2 className="block-subtitle">Адрес постоянной регистрации</h2>

      <div className="block-item">
        <InputAddress
          from_bound="region"
          to_bound="street"
          label="Регион / Населенный пункт / Улица"
          name={REGION_CITY_STREET_PER}
          {...methodsAddress}
          {...getPropsField({
            fieldName: REGION_CITY_STREET_PER, props: restProps, // mask: maskLetterAddress,
          })}
          ref={getRefField({
            fieldName: REGION_CITY_STREET_PER, callBack: setRefFieldsFn,
          })}
          /* jumpField={jumpFocusField({
            fieldName: HOUSE_PER,
            callBack: jumpFocusFieldFn,
          })} */
          required
        />
      </div>

      <div className="block-items three">
        <div className="block-item">
          <InputAddress
            from_bound="house"
            to_bound="house"
            label="Дом"
            name={HOUSE_PER}
            {...methodsAddress}
            {...getPropsField({
              fieldName: HOUSE_PER, props: restProps,
            })}
            ref={getRefField({
              fieldName: HOUSE_PER, callBack: setRefFieldsFn,
            })}
            jumpField={jumpFocusField({
              fieldName: APARTMENT_PER,
              callBack: jumpFocusFieldFn,
            })}
            minLength={1}
            required
          />
        </div>
        <div className="block-item">
          <Input
            label="Корпус"
            name={HOUSING_PER}
            {...getPropsField({
              fieldName: HOUSING_PER, props: restProps,
            })}
            ref={getRefField({
              fieldName: HOUSING_PER, callBack: setRefFieldsFn,
            })}
          />
        </div>
        <div className="block-item">
          <Input
            label="Квартира"
            name={APARTMENT_PER}
            {...getPropsField({
              fieldName: APARTMENT_PER, props: restProps, mask: maskNumHouses,
            })}
            ref={getRefField({
              fieldName: APARTMENT_PER, callBack: setRefFieldsFn,
            })}
          />
        </div>
      </div>

      <div className="block-item">
        <InputDate
          label="Дата регистрации (в паспорте)"
          type="text"
          inputMode="numeric"
          name={REGISTRATION_DATE}
          {...maskDate}
          {...getPropsField({
            fieldName: REGISTRATION_DATE, props: restProps,
          })}
          ref={getRefField({
            fieldName: REGISTRATION_DATE, callBack: setRefFieldsFn,
          })}
          required
        />
      </div>

      <div className="block-item">
        <Input
          label="Домашний номер телефона"
          type="text"
          inputMode="tel"
          name={PHONE_HOME}
          {...maskHomePhone}
          {...getPropsField({
            fieldName: PHONE_HOME, props: restProps,
          })}
          ref={getRefField({
            fieldName: PHONE_HOME, callBack: setRefFieldsFn,
          })}
          jumpField={jumpFocusField({
            fieldName: REGION_CITY_STREET_ACT,
            callBack: jumpFocusFieldFn,
            trigger: { name: 'maxLength', data: { value: 17 } },
          })}
          required={isEmpty(isNotPhoneHome)}
          disabled={isNotPhoneHome}
        />
      </div>

      <div className="block-item default-checkbox">
        <Checkbox
          label="Нет домашнего телефона"
          {...getEventsField(get(restProps.fields, IS_NOT_PHONE_HOME))}
          name={IS_NOT_PHONE_HOME}
        />
      </div>

      <div className="block-line" />
      <div className="block-adress" data-input="adress">
        <h2 className="block-subtitle">Адрес фактического проживания</h2>

        {isEmpty(isMatchesPermanentActual) && (
        <>
          <div className="block-item">
            <InputAddress
              from_bound="region"
              to_bound="street"
              label="Регион / Населенный пункт / Улица"
              name={REGION_CITY_STREET_ACT}
              {...methodsAddress}
              {...getPropsField({
                fieldName: REGION_CITY_STREET_ACT, props: restProps,
              })}
              ref={getRefField({
                fieldName: REGION_CITY_STREET_ACT, callBack: setRefFieldsFn,
              })}
              /* jumpField={jumpFocusField({
                fieldName: HOUSE_ACT,
                callBack: jumpFocusFieldFn,
              })} */
              required
            />
          </div>

          <div className="block-items three">
            <div className="block-item">
              <InputAddress
                from_bound="house"
                to_bound="house"
                label="Дом"
                name={HOUSE_ACT}
                {...methodsAddress}
                {...getPropsField({
                  fieldName: HOUSE_ACT, props: restProps,
                })}
                ref={getRefField({
                  fieldName: HOUSE_ACT, callBack: setRefFieldsFn,
                })}
                jumpField={jumpFocusField({
                  fieldName: APARTMENT_ACT,
                  callBack: jumpFocusFieldFn,
                })}
                minLength={1}
                required
              />
            </div>
            <div className="block-item">
              <Input
                label="Корпус"
                name={HOUSING_ACT}
                {...getPropsField({
                  fieldName: HOUSING_ACT, props: restProps,
                })}
                ref={getRefField({
                  fieldName: HOUSING_ACT, callBack: setRefFieldsFn,
                })}
              />
            </div>
            <div className="block-item">
              <Input
                label="Квартира"
                name={APARTMENT_ACT}
                {...getPropsField({
                  fieldName: APARTMENT_ACT, props: restProps, mask: maskNumHouses,
                })}
                ref={getRefField({
                  fieldName: APARTMENT_ACT, callBack: setRefFieldsFn,
                })}
              />
            </div>
          </div>
        </>
        )}
      </div>

      <div className="block-item block-checkbox default-checkbox">
        <Checkbox
          name={MATCHES_PER_ACT}
          {...getEventsField(get(restProps.fields, MATCHES_PER_ACT))}
        />
        <label htmlFor={MATCHES_PER_ACT} className="default-checkbox__label">
          <div className="default-checkbox__block" />
          <p className="default-checkbox__text">
            Совпадает с адресом постоянной регистрации
          </p>
        </label>
      </div>

      <div className="block-button block-button__form">
        <Button
          type="submit"
          className="nextStep"
        >
          Далее
        </Button>
      </div>

    </form>
  );
});
