import React from 'react';
import { useForm } from 'effector-forms';
import {
  getPropsField, getRefField, jumpFocusField, onSubmit,
} from 'src/lib/form';
import { validateSnils } from 'src/lib/legal';
import {
  maskPassportSeries, maskPassportId, maskPassportCodeDepartment, maskDate, maskSnils,
} from 'src/lib/maskPlaceholder';
import { REG_STEP2_FIELDS } from 'src/dict/fields';
import {
  jumpFocusFieldFn, setRefFieldsFn,
} from 'src/models/Helper/Ref';
import {
  choiceCodeFMSFn, clearCodeFMSFn, notChoiceCodeFMSFn, submitFormFn,
  step2Form,
} from 'src/models/Registration/Passport';
import {
  Input, InputDate, InputFMS, ButtonWithLoader as Button,
} from 'src/ui/components/Form';

const {
  PASSPORT_SERIES, PASSPORT_ID, PASSPORT_DATE_ISSUE,
  CODE_DEPARTMENT, PASSPORT_ISSUE_BY, PLACE_BIRTH, SNILS,
} = REG_STEP2_FIELDS;

// const { SURNAME, NAME, PATRONYMIC } = daData.NAME.FILTER_PARTS;

const methodsPassport = {
  choiceCodeFMSFn,
  clearCodeFMSFn,
  notChoiceCodeFMSFn,
};

export const PassportForm = React.memo(() => {
  const {
    submit, eachValid, ...restProps
  } = useForm(step2Form);

  return (
    <form autoComplete="off" onSubmit={onSubmit(submit)}>
      <div className="block-items">
        <div className="block-item">
          <Input
            label="Серия"
            type="text"
            inputMode="numeric"
            name={PASSPORT_SERIES}
            {...maskPassportSeries}
            {...getPropsField({
              fieldName: PASSPORT_SERIES, props: restProps, // maskPassportSeries
            })}
            ref={getRefField({
              fieldName: PASSPORT_SERIES, callBack: setRefFieldsFn,
            })}
            jumpField={jumpFocusField({
              fieldName: PASSPORT_ID,
              callBack: jumpFocusFieldFn,
              trigger: { name: 'maxLength', data: { value: 5 } },
            })}
            required
          />
        </div>
        <div className="block-item">
          <Input
            label="Номер"
            type="text"
            inputMode="numeric"
            name={PASSPORT_ID}
            {...maskPassportId}
            {...getPropsField({
              fieldName: PASSPORT_ID, props: restProps, // mask: maskPassportId,
            })}
            ref={getRefField({
              fieldName: PASSPORT_ID, callBack: setRefFieldsFn,
            })}
            jumpField={jumpFocusField({
              fieldName: PASSPORT_DATE_ISSUE,
              callBack: jumpFocusFieldFn,
              trigger: { name: 'maxLength', data: { value: 7 } },
            })}
            required
          />
        </div>
      </div>

      <div className="block-item">
        <InputDate
          label="Дата выдачи"
          type="text"
          inputMode="numeric"
          name={PASSPORT_DATE_ISSUE}
          {...maskDate}
          {...getPropsField({
            fieldName: PASSPORT_DATE_ISSUE, props: restProps,
          })}
          ref={getRefField({
            fieldName: PASSPORT_DATE_ISSUE, callBack: setRefFieldsFn,
          })}
          required
        />
      </div>

      <div className="block-item">
        <InputFMS
          label="Код подразделения"
          type="text"
          inputMode="numeric"
          name={CODE_DEPARTMENT}
          {...maskPassportCodeDepartment}
          {...methodsPassport}
          {...getPropsField({
            fieldName: CODE_DEPARTMENT, props: restProps,
          })}
          ref={getRefField({
            fieldName: CODE_DEPARTMENT, callBack: setRefFieldsFn,
          })}
          jumpField={jumpFocusField({
            fieldName: SNILS,
            callBack: jumpFocusFieldFn,
          })}
          required
          // positionAbsolute={false}
        />
      </div>

      <div className="block-item">
        <Input
          label="Кем выдан"
          type="text"
          inputMode="numeric"
          name={PASSPORT_ISSUE_BY}
          {...getPropsField({
            fieldName: PASSPORT_ISSUE_BY, props: restProps,
          })}
          ref={getRefField({
            fieldName: PASSPORT_ISSUE_BY, callBack: setRefFieldsFn,
          })}
          disabled
          required
        />
      </div>

      <div className="block-item">
        <Input
          label="СНИЛС"
          type="text"
          inputMode="numeric"
          name={SNILS}
          {...maskSnils}
          {...getPropsField({
            fieldName: SNILS, props: restProps,
          })}
          ref={getRefField({
            fieldName: SNILS, callBack: setRefFieldsFn,
          })}
          jumpField={jumpFocusField({
            fieldName: PLACE_BIRTH,
            callBack: jumpFocusFieldFn,
            trigger: { name: 'maxLength', data: { value: 14 } },
            validateFunc: validateSnils,
          })}
          required
        />
      </div>

      <div className="block-item">
        <Input
          label="Место рождения"
          type="text"
          name={PLACE_BIRTH}
          {...getPropsField({
            fieldName: PLACE_BIRTH, props: restProps,
          })}
          ref={getRefField({
            fieldName: PLACE_BIRTH, callBack: setRefFieldsFn,
          })}
          required
        />
      </div>

      <div className="block-button block-button__form">
        <Button
          type="submit"
          className="nextStep"
          onClick={submitFormFn}
        >
          Далее
        </Button>
      </div>

      {/* <div className="fms_code_empty" /> */}
    </form>

  );
});
