import React, { useEffect } from 'react';
import { useUnit } from 'effector-react';
import { useForm } from 'effector-forms';
import { get, isEmpty } from 'src/lib/lodash';
import { getDate } from 'src/lib/date';
import { getEventsField, onSubmit } from 'src/lib/form';
import { LOAN_FAILURE_FIELDS } from 'src/dict/fields';
import { openModalFn } from 'src/models/components/Modal';
import { goAgreementPageFn } from 'src/models/Loan/Agreement';
import { $applicationDetail, $isRepeatNewLoan } from 'src/models/Loan/Application';
import { declineLandingUrlFn } from 'src/models/Profile';
import { failureForm } from 'src/models/Loan/Application/Failure';
import { Checkbox, ButtonWithLoader as Button } from 'src/ui/components/Form';
import { Timer } from './children/Timer';
import { Agreement } from './children/Agreement';

const { AGREEMENT } = LOAN_FAILURE_FIELDS;

const styleSubTitle = {
  margin: '30px',
  textAlign: 'left',
};

export const FailureForm = React.memo(() => {
  const [applicationDetail, isRepeatNewLoan] = useUnit([$applicationDetail, $isRepeatNewLoan]);
  const nextAttempt = get(applicationDetail, 'next_attempt_at', '');

  useEffect(() => {
    if (!isEmpty(isRepeatNewLoan)) {
      goAgreementPageFn();
    }
  }, [isRepeatNewLoan]);

  if (isEmpty(nextAttempt)) return <div />;

  return (
    <>
      <p className="text">
        {`Не огорчайтесь! Попробуйте подать заявку после ${getDate(nextAttempt)}, мы будем вас очень ждать!`}
      </p>
      <div style={styleSubTitle}>
        <div className="button">
          <Button
            type="button"
            className="nextStep"
            onClick={declineLandingUrlFn}
          >
            Получить деньги
          </Button>
        </div>
      </div>
    </>
  );
});

export const FailureLoanOld = React.memo(() => {
  const {
    submit, eachValid, ...restProps
  } = useForm(failureForm);

  const agreementErrorText = get(restProps, 'errorText', () => '')(AGREEMENT);
  const agreementHasError = get(restProps, 'hasError', () => '')(AGREEMENT);
  const classCheckValid = !isEmpty(agreementHasError) ? ' invalid' : '';

  return (
    <>
      <p className="text">
        К сожалению, мы отклонили вашу заявку на займ.
        Вы можете отправить повторную заявку после дата.
        <br />
        Однако мы можем помочь вам получить его у наших партнеров!
      </p>
      <br />

      <Timer />

      <form onSubmit={onSubmit(submit)}>
        <div className="block-item default-checkbox">
          <Checkbox
            name={AGREEMENT}
            {...getEventsField(get(restProps.fields, AGREEMENT))}
          />
          <label htmlFor={AGREEMENT} className="default-checkbox__label">
            <div className={`default-checkbox__block ${classCheckValid}`} />
            <p className="default-checkbox__text">
              Согласен с
              {' '}
              <button
                type="button"
                className="button-agreement"
                onClick={openModalFn.prepend(() => ({
                  importantOperation: true,
                  className: 'modal-agreement',
                  content: Agreement,
                }))}
              >
                документами
              </button>
            </p>
          </label>
        </div>

        {agreementHasError
          && !isEmpty(agreementErrorText)
        && (
          <>
            <p className="invalid-text text">{agreementErrorText}</p>
            <br />
          </>
        )}

        <div className="button">
          <Button
            type="submit"
            className="nextStep"
          >
            Получить деньги
          </Button>
        </div>
      </form>
      {/* <div className="list">
        <div className="list-item">
          <span className="list-item__icon">
            <img src="/img/other/failure-icon1.svg" alt="" />
          </span>
          <p className="list-item__text">
            <span className="bold">Не нужно</span>
            заново заполнять
            {' '}
            <span
              className="bold"
            >
              анкету
            </span>
            , вы уже все заполнили. В случае одобрения,
            <span className="bold">останется только получить деньги.</span>
          </p>
        </div>
        <div className="list-item">
          <span className="list-item__icon">
            <img src="/img/other/failure-icon2.svg" alt="" />
          </span>
          <p>
            <span className="bold">Бесплатно проверим вашу анкету</span>
            и подберем компании, которые с наибольшей
            вероятностью дадут вам займ.
          </p>
        </div>
        <div className="list-item">
          <span className="list-item__icon">
            <img src="/img/other/failure-icon3.svg" alt="" />
          </span>
          <p>
            Дополнительно
            <span className="bold" />
            во все доступные
            {' '}
            <span className="bold">мфо</span>
            , если
            партнеры не одобрят займ.
          </p>
        </div>
        <div className="list-item">
          <span className="list-item__icon">
            <img src="/img/other/failure-icon4.svg" alt="" />
          </span>
          <p>
            Будем
            <span className="bold">обновлять</span>
            доступные предложения
            <span className="bold">раз в неделю.</span>
          </p>
        </div>
      </div>

      <div className="attention">
        <p>ВНИМАНИЕ: Услуга недоступна клиентам, успешно оформившим займ в КексКредит</p>
      </div>
      <div className="all">
        <div className="all-icon">
          <img src="/img/other/coin.png" alt="" />
        </div>
        <p>Все это за 349 рублей!</p>
      </div>
      <p className="description">Полная цена услуги: 1396 рублей в рассрочку на 4 недели. </p>
      */}
    </>
  );
});
