import React, { useState } from 'react';
import { useUnit } from 'effector-react';
import { NavLink } from 'react-router-dom';
import { isEmpty } from 'src/lib/lodash';
import { config } from 'src/dict/config';
import { PAGES_PATH } from 'src/dict/path';
import { $appContactCombineData } from 'src/models/App';
import { $isRegistrationPage } from 'src/models/Registration';
import { pushHistoryFn } from 'src/models/Helper/History';
import { GetMoneyButton, Button } from 'src/ui/components/Form';

const { SHOW_CONFIG_ENV } = config;
const { DOCUMENTS, FEEDBACK } = PAGES_PATH;
const toggleHandler = (setVisible, val) => () => setVisible(val);

// eslint-disable-next-line max-len
const urlRules = 'https://static.kekscredit.ru/public-docs/%D0%9F%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0%20%D0%BF%D1%80%D0%B5%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F%20%D0%B7%D0%B0%D0%B8%CC%86%D0%BC%D0%BE%D0%B2.pdf';

const Footer = React.memo(() => {
  const [isOpen, setVisible] = useState(false);
  const [
    isRegistrationPage,
    { phoneSite, emailSite },
  ] = useUnit([$isRegistrationPage, $appContactCombineData]);
  const colorVal = { color: 'white' };
  const styleVal = { cursor: 'pointer' };
  return (
    <footer className="footer">
      <div className="wrapper">
        <div className="blocks ">
          <div className="block block-bg">
            <a href="#" className="logo"><img src="/img/footer/logo.png" alt="" /></a>
            <div className="footer-btn">
              {!isRegistrationPage && <GetMoneyButton ym="GET_MONEY_LANDING_BOT_BT" />}
            </div>
          </div>
          <div className="block block-sm">
            {/* <a href="#" className="block-link">Вопрос ответ</a> */}
            {/* <a href="#" className="block-link">Информация для заемщиков</a> */}
            <a
              href={urlRules}
              target="_blank"
              className="block-link"
              rel="noreferrer"
            >
              Правила предоставления займов
            </a>
            {/* <a href="#" className="block-link">Базовый стандарт МФО</a> */}
          </div>

          <div className="block block-sm">
            <NavLink
              className="block-link"
              to={`/${DOCUMENTS}`}
            >
              Юридическая информация
            </NavLink>
            {/* <a href="#" className="block-link">Карта сайта</a>
            <a href="#" className="block-link">Оформить займ - лендинг</a>
            <a href="/documents" className="block-link" > Документы - регистрационные документы мфо
            </a> */}
          </div>
          <div className="block block-bg">
            <div className="block-item">
              <p className="block-label">Телефон</p>
              <a href={`tel:${phoneSite};`}>{phoneSite}</a>
            </div>
            <div className="block-item">
              <p className="block-label">Почта</p>
              <a href="mailto:support@kekscredit.ru">{emailSite}</a>
            </div>
            <div className="footer-btn">
              <Button
                className="white"
                onClick={pushHistoryFn.prepend(() => `/${FEEDBACK}`)}
              >
                Связаться с нами
              </Button>
            </div>
            {/* <div className="footer-btn">
              <Button className="white">Заказать звонок</Button>
            </div> */}
          </div>
        </div>

        <div className="bottom">
          <p>
            © 2021 kekscredit.ru. Все права защищены. Общество с ограниченной ответственностью
            «Микрокредитная компания «Варенье Кредит»
          </p>
          <p>ОГРН: 1217800099263</p>
          <p>ИНН: 7806588260</p>

          <p>
            Общество с ограниченной ответственностью «Микрокредитная компания
            «Варенье Кредит» зарегистрировано в Государственном реестре микрофинансовых
            организаций 16 августа 2021 года.
          </p>

          <p>
            Номер свидетельства:
            {' '}
            <a
              href="https://www.cbr.ru/registries/microfinance/?utm_source=w&utm_content=page#a_14199"
              target="_blank"
              rel="noreferrer"
            >
              №2103140009725
            </a>
            {' '}
            —
            {' '}
            <a href="https://cbr.ru" target="_blank" rel="noreferrer">cbr.ru</a>
          </p>

          <p>
            <a
              href="https://www.cbr.ru/reception/"
              target="_blank"
              rel="noreferrer"
            >
              Интернет-приёмная Банка России
            </a>
          </p>

          <p>
            Потребитель вправе направить
            {' '}
            <a
              href="https://finombudsman.ru/"
              target="_blank"
              rel="noreferrer"
            >
              обращение Финансовому уполномоченному
            </a>
            {' '}
            (119017, г. Москва, Старомонетный пер., д. 3. Тел.: 8 (800) 200-00-10).
          </p>

          <p>
            ООО МКК «Варенье Кредит» является членом
            {' '}
            <a href="https://npmir.ru/" target="_blank" rel="noreferrer">СРО «МиР»</a>
            {' '}
            (107078, г. Москва Орликов переулок, д.5, стр.1, этаж 2, пом.11).
          </p>

          <p>
            ООО МКК «Варенье Кредит» использует cookie (файлы с данными о прошлых
            посещениях сайта) для персонализации сервисов и удобства пользователей.
            Оставаясь на сайте вы даете согласие на обработку персональных данных.
          </p>
        </div>
        {!isEmpty(SHOW_CONFIG_ENV) && SHOW_CONFIG_ENV.toLowerCase() === 'true' && (
          <>
            <br />
            <p style={colorVal}>
              {isOpen
                ? (
                  <div>
                    <div style={styleVal} onClick={toggleHandler(setVisible, false)}>Скрыть</div>
                    <br />
                    {Object.entries(config).map(([field, value]) => (
                      <div>
                        {`${field} => ${value}`}
                      </div>
                    ))}
                  </div>
                )
                : (<span style={styleVal} onClick={toggleHandler(setVisible, true)}>Показать ENV params</span>)}
            </p>
          </>
        )}
      </div>
    </footer>
  );
});

export { Footer };
