import React from 'react';
import { YellowBlock, MainBlock } from 'src/ui/components';
import { EsiaButtonReg } from 'src/ui/components/Block/EsiaButtonReg';
import { AddressForm } from './children/AddressForm';

const KeksBlock = () => (
  <div className="keks keks4">
    <img src="/img/form/keks-4.png" alt="" />
  </div>
);

export const Step3 = () => (
  <div className="step step4 active">
    <YellowBlock
      title="Уже почти все!"
      text="Заполните, пожалуйста, информацию о вашем месте жительства"
    />
    <MainBlock keksBlock={KeksBlock}>
      <div className="block-main__content">
        <AddressForm />
        <EsiaButtonReg />
      </div>
    </MainBlock>
  </div>
);
