import { createDomain, combine } from 'effector';
import { createGate } from 'effector-react';
// import { attachLogger } from 'effector-logger/attach';

export const AppGate = createGate('App');
export const RouteGate = createGate('Route');

export const allDomain = createDomain('App');
/* attachLogger(allDomain, {
  reduxDevtools: 'enabled',
  console: 'enabled',
  inspector: 'enabled',
}); */

// общий домен  для все доменов моделей приложения
export const appDomain = allDomain.createDomain('App');

// домен используется для справочников и данных, которые не очищаем после logout-a / reseta
export const dictionariesDomain = allDomain.createDomain('Dictionaries');

// домен используется для служебных целей
export const settingsDomain = allDomain.createDomain('Settings');

/// ///////////////////////////////////////////////////////////
// ================== СЛОВАРИ / КОНСТАНТЫ ================== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ СОБЫТИЙ ===================== //
/// ///////////////////////////////////////////////////////////

// на событие resetStates очищаем все хранилища
export const resetStatesFn = settingsDomain.createEvent();

export const mountAppFn = settingsDomain.createEvent();

export const scrollTopFn = settingsDomain.createEvent();

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ХРАНИЛИЦ ==================== //
/// ///////////////////////////////////////////////////////////

export const $phoneSite = settingsDomain.createStore('8 (499) 444-33-07');
export const $emailSite = settingsDomain.createStore('support@kekscredit.ru');

// Хранилище для текущего path из url
export const $pathnameUrl = settingsDomain.createStore('');
export const $pathParams = settingsDomain.createStore('');
export const $navigator = settingsDomain.createStore('');

export const $initApp = settingsDomain.createStore(true);

export const $firstPage = settingsDomain.createStore('');

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ЭФФЕКТОВ ==================== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

export const $isHome = $pathnameUrl.map((value) => value === '/');
export const $webpClass = $navigator.map(
  ({ vendor }) => (vendor === 'Apple Computer, Inc.' ? 'not_webp' : 'webp'),
);

export const $wow = combine(
  $firstPage, $pathnameUrl, $initApp,
  (firstPage, pathnameUrl, initAp) => (firstPage === pathnameUrl && initAp ? 'wow' : ''),
);

export const $appContactCombineData = combine(
  $phoneSite, $emailSite, (phoneSite, emailSite) => ({ phoneSite, emailSite }),
);

/*
$wow.watch((state) => console.log('watch $wow:', state));
$initApp.watch((state) => console.log('watch $initApp:', state));
$firstPage.watch((state) => console.log('watch $firstPage:', state));
$pathnameUrl.watch((state) => console.log('watch $pathnameUrl:', state));
*/

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ЭФФЕКТОВ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

// тут создаются эффекты через attach

/// ///////////////////////////////////////////////////////////
// ====================== ЮНИТЫ ФОРМ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем формы с помощью effector-forms

/// ///////////////////////////////////////////////////////////
//  ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ФОРМ + (ХРАНИЛИЩ, ЭФФЕКТОВ) //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ========================= ФАБРИКИ ======================= //
/// ///////////////////////////////////////////////////////////
