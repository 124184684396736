import React from 'react';
import { useStore } from 'effector-react';
import {
  toggleMakeLoanFn,
  $isOpenMakeLoan,
} from 'src/models/Loan/Application';

export const NewLoan = React.memo(() => {
  const isOpenMakeLoan = useStore($isOpenMakeLoan);

  return (
    <>
      {isOpenMakeLoan
        ? (
          <button
            type="button"
            className="default-btn border button-cancel"
            onClick={toggleMakeLoanFn.prepend(() => false)}
          >
            Отмена
          </button>
        )
        : (
          <button
            type="button"
            className="default-btn button-open"
            onClick={toggleMakeLoanFn.prepend(() => true)}
            // Новый займ
          >
            Получить деньги
          </button>
        )}
    </>
  );
});
