import React from 'react';
import { useStore } from 'effector-react';
import { getDateInterval } from 'src/lib/date';
import { getFormatMoney } from 'src/lib/money';
import { $calcCombineData } from 'src/models/Calculator';

export const InfoCalc = React.memo(() => {
  const {
    // amountMin, amountMax, periodMin, periodMax,
    amount,
    period,
  } = useStore($calcCombineData);

  return (
    <div className="profileTop-noLoan-info">
      <div className="profileTop-noLoan-info__item">
        <p className="profileTop-noLoan-info__title">
          Проценты
          <span className="icon">
            <img
              src="/img/profile/percent-gray.png"
              alt=""
            />
          </span>
        </p>
        <p className="profileTop-noLoan-info__info">
          <span className="sum">0</span>
          <span className="cur">₽</span>
        </p>
      </div>
      <div className="profileTop-noLoan-info__item">
        <p className="profileTop-noLoan-info__title">Сумма займа</p>
        <p className="profileTop-noLoan-info__info">
          <span className="sum">{getFormatMoney(amount)}</span>
          <span className="cur">₽</span>
        </p>
      </div>
      <div className="profileTop-noLoan-info__item">
        <p className="profileTop-noLoan-info__title">Сумма возврата</p>
        <p className="profileTop-noLoan-info__info">
          <span className="sum">{getFormatMoney(amount)}</span>
          <span className="cur">₽</span>
        </p>
      </div>
      <div className="profileTop-noLoan-info__item">
        <p className="profileTop-noLoan-info__title">Дата возврата1</p>
        <p className="profileTop-noLoan-info__info">{getDateInterval(period)}</p>
      </div>
    </div>
  );
});
