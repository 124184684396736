import { isEmpty } from './lodash';

export const getFormatMoney = (val) => {
  if (isEmpty(val)) {
    val = 0;
  }
  const valFloat = Number.parseFloat(val, 10);
  const value = Number.parseInt((valFloat * 100), 10) / 100;
  return Intl.NumberFormat('ru-RU').format(value);
};

export const getFormatIntMoney = (val) => {
  if (isEmpty(val)) {
    val = 0;
  }
  const valFloat = Number.parseFloat(val, 10);
  const value = Math.ceil((Number.parseInt((valFloat * 100), 10) / 100));
  return Intl.NumberFormat('ru-RU').format(value);
};
