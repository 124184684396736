import React from 'react';

export const GreenBlock = React.memo(({ title, text }) => (
  <div className="top">
    <div className="block-yellow block-green">
      <div className="block-yellow__top desktop">
        <img src="/img/other/greenBlockTop.svg" alt="" />
      </div>
      <div className="block-yellow__top mobile">
        <img src="/img/other/greenBlockTop-mobile.svg" alt="" />
      </div>
      <div className="block-yellow__main">
        <h3 className="block-yellow__title">{title}</h3>
        {text && <p className="block-yellow__text">{text}</p>}
      </div>
      <div className="block-yellow__bottom desktop">
        <img src="/img/other/greenBlockBottom.svg" alt="" />
      </div>
      <div className="block-yellow__bottom mobile">
        <img src="/img/other/greenBlockBottom-mobile.svg" alt="" />
      </div>
    </div>
  </div>
));
