import {
  dateYearPattern,
  phonePattern,
  passportSeriesPattern,
  passportIdPattern,
  passportCodeDepartmentPattern,
  numberPattern,
  numberCardPattern,
  dateCardPattern, cvvPattern, snilsPattern,
} from 'src/lib/rules';

export const maskPhone = {
  // mask: '+7 (\\999) 999-99-99',
  mask: '+7 (999) 999-99-99',
  maskPattern: phonePattern,
};

export const maskNumberCard = {
  mask: '9999 9999 9999 9999',
  maskPattern: numberCardPattern,
};

export const maskNumberCard19 = {
  mask: '9999 9999 9999 9999 999',
  maskPattern: numberCardPattern,
  maskPlaceholder: null,
};

export const maskDateCard = {
  mask: '99/99',
  maskPattern: dateCardPattern,
};

export const maskCvvCard = {
  mask: '999',
  maskPattern: cvvPattern,
};

export const maskHomePhone = {
  mask: '8 (999) 999-99-99',
  maskPattern: phonePattern,
};

export const maskNum = {
  mask: '9',
  maskPattern: numberPattern,
};

export const maskWorkPhone = {
  mask: '8 (999) 999-99-99',
  maskPattern: phonePattern,
};

export const maskDate = {
  mask: '99.99.9999',
  maskPattern: dateYearPattern,
};

export const maskSnils = {
  mask: '999-999-999 99',
  maskPattern: snilsPattern,
};

export const maskPassportSeries = {
  mask: '99 99',
  maskPattern: passportSeriesPattern,
};

export const maskPassportId = {
  mask: '999 999',
  maskPattern: passportIdPattern,
};

export const maskPassportCodeDepartment = {
  mask: '999-999',
  maskPattern: passportCodeDepartmentPattern,
};
