import React from 'react';
import { ChangePassword } from './children/ChangePassword';
import { ChangePhone } from './children/ChangePhone';
// import { History } from './children/ChangePhone';

export const Security = React.memo(() => (
  <>
    <h1 className="title">Безопасность</h1>
    <div className="blocks">
      <ChangePassword />
      <ChangePhone />
      {/* <History /> */}
    </div>
  </>
));
