import React from 'react';
import { useForm } from 'effector-forms';
import { SECURITY_FIELDS } from 'src/dict/fields';
import { getPropsField, getRefField, onSubmit } from 'src/lib/form';
import { changePasswordForm } from 'src/models/User';
import { setRefFieldsFn } from 'src/models/Helper/Ref';
import { ButtonWithLoader as Button, Input } from 'src/ui/components/Form';

const { PASSWORD, PASSWORD_CONFIRM } = SECURITY_FIELDS;

export const ChanePassFom = React.memo(() => {
  const {
    submit, ...restProps
  } = useForm(changePasswordForm);
  return (
    <>
      <form onSubmit={onSubmit(submit)}>
        <div className="block-item">
          <Input
            label="Новый пароль"
            type="password"
            name={PASSWORD}
            {...getPropsField({
              fieldName: PASSWORD, props: restProps,
            })}
            ref={getRefField({
              fieldName: PASSWORD, callBack: setRefFieldsFn,
            })}
            required
          />
        </div>
        <div className="block-item">
          <Input
            label="Подтверждение пароля"
            type="password"
            name={PASSWORD_CONFIRM}
            {...getPropsField({
              fieldName: PASSWORD_CONFIRM, props: restProps,
            })}
            ref={getRefField({
              fieldName: PASSWORD_CONFIRM, callBack: setRefFieldsFn,
            })}
            required
          />
        </div>

        <div className="buttons">
          <div className="button">
            <Button
              type="submit"
              className="nextStep"
            >
              Сохранить новый пароль
            </Button>
          </div>

        </div>
      </form>
    </>
  );
});
