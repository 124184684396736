import React from 'react';
import { LoginForm } from 'src/ui/components/LoginForm';
import 'src/ui/styles/Login.css';

export const Login = React.memo(() => (
  <>
    <main className="loginPage authorization">
      <section className="section">
        <div className="wrapper">

          <div className="block">
            <div className="block-content">

              <div className="block-topBg desktop">
                <img src="/img/other/topbgblock.svg" alt="" />
              </div>
              <div className="block-topBg mobile">
                <img src="/img/other/topbgblock2-mobile.svg" alt="" />
              </div>

              <div className="block-main">
                <div className="block-wrapper">

                  <LoginForm />

                </div>

              </div>
            </div>
          </div>

        </div>
      </section>
    </main>
  </>
));
