import {
  attach, restore, sample, split,
} from 'effector';
import { get, isEmpty } from 'src/lib/lodash';
import { getErrorFromDict } from 'src/lib/errors';
import {
  REG_STEP1_FIELDS, REG_STEP3_DEV_FIELDS, REG_STEP3_FIELDS, USER_FORM,
} from 'src/dict/fields';
import { $messagesDict } from 'src/models/Dictionaries';
import { step2Form } from 'src/models/Registration/Passport';
import { hideLoaderButtonFn, showLoaderButtonFn } from 'src/models/Helper/Loader';
import { notifyErrorFn, notifySuccessFn } from 'src/models/Helper/Notification';
import {
  getAddressDataFx,
  getCurrentUserOnlyQueryFx, updateAdditionalInfoUserFx,
  updateHomePhoneUserFx,
} from 'src/models/User';
import {
  getChildrenData, getDataAddress, getOptions, getParentData,
} from 'src/models/Registration/Address/helper';
import { jumpFocusFieldFn } from 'src/models/Helper/Ref';
import { $isProfilePersonalPage } from 'src/models/Profile';
import { debounce } from 'patronum/debounce';
import {
  // события
  toggleDataEditFn,
  choiceAddressFn, clearChildrenAddressFn, notChoiceAddressFn, postChangeAddressFn, prepareChangeAddressFn,
  addAddressFn,
  // хранилища
  $dataIsEdit,
  // эффекты
  updatePersonalDataFx, addAddressFx, getDataByIdFx,
  // формы
  personaForm,
  addressRegForm, addressActualForm,
} from './index';

const {
  FIRST_NAME, LAST_NAME, MIDDLE_NAME, IS_NOT_MIDDLE_NAME,
  YEAR_OF_BIRTH, // GENDER, // UUID: UUID_STEP1,
} = REG_STEP1_FIELDS;
const {
  HANDLER, REGISTRATION_DATE, IS_NOT_PHONE_HOME, PHONE_HOME,
} = REG_STEP3_FIELDS;
const {
  ADDRESS_TYPE, REGION_CITY_STREET,
  REGION, REGION_ID, AREA_ID,
  CITY, CITY_ID, SETTLEMENT_ID, STREET, STREET_ID,
  HOUSE, HOUSE_ID, HOUSING, HOUSE_ALL_DATA,
  APARTMENT, // SETTLEMENT, AREA,
} = REG_STEP3_DEV_FIELDS;

const fieldItems = [
  REGION, REGION_ID, AREA_ID, CITY, CITY_ID, SETTLEMENT_ID,
  STREET, STREET_ID, HOUSE, HOUSE_ID, HOUSING, APARTMENT,
  HOUSE_ALL_DATA,
];

export const getFieldItems = () => [...fieldItems];

export const checkValidData = (formValues) => {
  const obj = {
    inValid: true,
    fieldName: '',
  };

  obj.inValid = isEmpty(formValues[SETTLEMENT_ID]) && isEmpty(formValues[CITY_ID]);
  if (!isEmpty(obj.inValid)) {
    obj.fieldName = REGION_CITY_STREET;
  }

  // проверка на ввод улицы
  if (isEmpty(obj.inValid) && !isEmpty(formValues[HOUSE]) && formValues[HOUSE].includes(', ')) {
    obj.inValid = true;
    obj.fieldName = HOUSE;
  }

  return obj;
};

/// //////////////////////////////////////////////////////////
// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
/// ///////////////////////////////////////////////////////////

$dataIsEdit
  .on(toggleDataEditFn, (state, { form, value }) => ({ ...state, [form]: value }));

/// ///////////////////////////////////////////////////////////
// ===================== БЛОК ОПЕРАТОРОВ =================== //
/// ///////////////////////////////////////////////////////////

split({
  source: sample(restore(toggleDataEditFn, {}), toggleDataEditFn),
  match: {
    [USER_FORM.PERSONAL]: ({ form }) => form === USER_FORM.PERSONAL,
    [USER_FORM.PASSPORT]: ({ form }) => form === USER_FORM.PASSPORT,
    [USER_FORM.ADDRESS_REG]: ({ form }) => form === USER_FORM.ADDRESS_REG,
    [USER_FORM.ADDRESS_ACT]: ({ form }) => form === USER_FORM.ADDRESS_ACT,
  },
  cases: {
    [USER_FORM.PERSONAL]: personaForm.resetErrors,
    // [USER_FORM.PASSPORT]: passportForm.resetErrors,
    [USER_FORM.PASSPORT]: step2Form.resetErrors,
    [USER_FORM.ADDRESS_REG]: [
      addressRegForm.resetErrors,
    ],
    [USER_FORM.ADDRESS_ACT]: [
      addressActualForm.resetErrors,
    ],
  },
});

// ================== Персональные данные ============== //

// ФИО + дата рождения + дом телефон

sample({
  clock: personaForm.formValidated,
  source: personaForm.$values,
  filter: (data) => isEmpty(data[IS_NOT_PHONE_HOME]),
  target: updateHomePhoneUserFx.prepend((data) => ({
    type: PHONE_HOME,
    contact: `${Number(data[PHONE_HOME].replace(/\D+/g, ''))}`,
    // contact: data[PHONE_HOME],
  })),
});

sample({
  clock: debounce({
    source: personaForm.formValidated,
    timeout: 100,
  }),
  fn: (data) => {
    const arrBirthDate = data[YEAR_OF_BIRTH].split('.');
    const birthDate = `${arrBirthDate[2]}-${arrBirthDate[1]}-${arrBirthDate[0]}`;
    const middleName = isEmpty(data[IS_NOT_MIDDLE_NAME]) ? data[MIDDLE_NAME] : null;
    return ({
      [FIRST_NAME]: data[FIRST_NAME],
      [LAST_NAME]: data[LAST_NAME],
      [MIDDLE_NAME]: middleName,
      [YEAR_OF_BIRTH]: birthDate,
    });
  },
  target: [
    showLoaderButtonFn,
    updatePersonalDataFx,
  ],
});

sample({
  clock: updatePersonalDataFx.done,
  target: [
    hideLoaderButtonFn,
    getCurrentUserOnlyQueryFx,
    toggleDataEditFn.prepend(() => ({ form: USER_FORM.PERSONAL, value: false })),
    notifySuccessFn.prepend(() => 'Персональные данные успешно обновлены'),
  ],
});

sample({
  clock: updatePersonalDataFx.fail,
  source: $messagesDict,
  fn: (dict, data) => ({ dict, data }),
  target: [
    hideLoaderButtonFn,
    notifyErrorFn.prepend(getErrorFromDict('При сохранении данных произошла ошибка')),
  ],
});

sample({
  clock: updateHomePhoneUserFx.fail,
  source: $messagesDict,
  fn: (dict, data) => ({ dict, data }),
  target: [
    hideLoaderButtonFn,
    notifyErrorFn.prepend(getErrorFromDict('При сохранении домашнего телефона произошла ошибка')),
  ],
});

// ================== Паспортные данные ============== //

sample({
  // при завершении 2-ого шага:
  clock: updateAdditionalInfoUserFx.done,
  filter: $isProfilePersonalPage,
  target: [
    hideLoaderButtonFn,
    getCurrentUserOnlyQueryFx,
    toggleDataEditFn.prepend(() => ({ form: USER_FORM.PASSPORT, value: false })),
    notifySuccessFn.prepend(() => 'Паспортные данные успешно обновлены'),
  ],
});

// ================== Адреса ============== //

sample({
  clock: getDataByIdFx.done,
  fn: ({ params, result }) => ({
    ...get(result, 'data.suggestions.0.data', {}),
    unrestricted: get(result, 'data.suggestions.0.unrestricted_value', {}),
    fieldName: HOUSE,
    formId: params.formId,
  }),
  target: choiceAddressFn,
});

// ! Валидация при onBlur полей
sample({
  clock: [debounce({
    source: notChoiceAddressFn,
    timeout: 300,
  })],
  source: [
    addressRegForm.$values, addressRegForm.fields,
    addressActualForm.$values, addressActualForm.fields,
  ],
  fn: ([regFormValues, regForm, actFormValues, actForm], eventParam) => {
    const { fieldName, formId } = eventParam;
    const isReg = formId === USER_FORM.ADDRESS_REG;
    const formValues = isReg ? regFormValues : actFormValues;
    const form = isReg ? regForm : actForm;

    let inValidField = false;
    let errorText = '';
    let field = '';
    switch (fieldName) {
      // смотрим введено что-то в поле
      case REGION_CITY_STREET:
        const inValidField0 = isEmpty(formValues[REGION_CITY_STREET]);
        const inValidField1 = isEmpty(formValues[REGION_ID]) && isEmpty(formValues[AREA_ID]);
        const inValidField2 = isEmpty(formValues[SETTLEMENT_ID]) && isEmpty(formValues[CITY_ID]);
        const inValidField3 = !isEmpty(formValues[HOUSE]) && formValues[HOUSE].includes(', ');

        inValidField = inValidField0 || inValidField1 || inValidField2 || inValidField3;
        if (inValidField1) {
          errorText = 'Необходимо указать регион или район';
        }
        if (inValidField2) {
          errorText = 'Необходимо указать город или населенный пункт';
        }
        if (inValidField3) {
          errorText = 'Вы не указали улицу';
        }
        break;

      case HOUSE:
        const inValidField10 = !isEmpty(formValues[HOUSE]) && isEmpty(formValues[HOUSE_ID]);
        const inValidField11 = !isEmpty(formValues[HOUSE]) && formValues[HOUSE].includes(', ');
        if (inValidField11) {
          errorText = 'Вы не указали улицу';
          field = REGION_CITY_STREET;
        }

        inValidField = inValidField10 || inValidField11;
        break;
      default:
    }
    return {
      inValidField, fieldName: (isEmpty(field) ? fieldName : field), form, errorText, formValues,
    };
  },
  target: [
    attach({
      effect: (_, {
        inValidField, fieldName, form, errorText,
      }) => {
        if (inValidField) {
          if (isEmpty(errorText)) {
            // form[fieldName].reset();
            form[fieldName].addError({
              rules: 'required',
              errorText: 'Пожалуйста, выберите вариант из выпадающего списка',
            });
          } else {
            // form[fieldName].reset();
            form[fieldName].addError({
              rules: 'required', errorText,
            });
          }
        } else {
          form[fieldName].validate();
        }

        /* if (isHandlerClick) {
          form.setForm({ [HANDLER]: '' });
        } */
      },
    }),
  ],
});

// при наборе в поле ввода адреса, достаем id-ки из родительского
// и посылка запроса на получение списка

sample({
  clock: prepareChangeAddressFn,
  source: [addressRegForm.$values, addressRegForm.$values],
  fn: ([regFormValues, actFormValues], eventParam) => {
    const { fieldName, formId } = eventParam;
    const isReg = formId === USER_FORM.ADDRESS_REG;
    const formValues = isReg ? regFormValues : actFormValues;

    let fiasObj = {};
    switch (fieldName) {
      case HOUSE:
        if (!isEmpty(formValues[STREET_ID])) {
          fiasObj = { street_fias_id: formValues[STREET_ID] };
        } else {
          fiasObj = getOptions(
            formValues, STREET_ID, STREET_ID, 'street_fias_id', 'street_fias_id',
          );
          if (isEmpty(fiasObj)) {
            fiasObj = getOptions(
              formValues, CITY_ID, SETTLEMENT_ID, 'city_fias_id', 'settlement_fias_id',
            );
          }
          // console.log('fiasObj:', fiasObj, formValues);
        }
        break;
      default:
    }

    return ({ ...eventParam, ...fiasObj });
  },
  // служит тригером внутри модели компонента адреса
  target: [
    // посылаем запрос на сервер dadata
    postChangeAddressFn,
  ],
});

// очистка нижних полей при изменении верхних
sample({
  clock: clearChildrenAddressFn,
  source: [
    addressRegForm.$values, addressRegForm,
    addressActualForm.$values, addressActualForm,
  ],
  fn: ([regFormValues, regForm, actFormValues, actForm], eventParam) => {
    const { fieldName, formId } = eventParam;
    const isReg = formId === USER_FORM.ADDRESS_REG;
    const formValues = isReg ? regFormValues : actFormValues;
    const form = isReg ? regForm : actForm;
    let obj = {};
    switch (fieldName) {
      case REGION_CITY_STREET:
        obj = getChildrenData(obj, getFieldItems(), REGION);
        break;

      case HOUSE:
        obj = getChildrenData(obj, getFieldItems(), fieldName);
        break;

      case HOUSING:
        obj = getChildrenData(obj, getFieldItems(), fieldName);
        break;
      default:
    }
    return ({ data: { ...formValues, ...obj }, form });
  },
  target: attach({
    effect: (_, { data, form }) => {
      form.setForm(data);
    },
  }),
});

// V // при выборе из выпадающего списка - изменение значений в полях
sample({
  clock: choiceAddressFn,
  source: [
    addressRegForm.$values, addressRegForm,
    addressActualForm.$values, addressActualForm,
  ],
  fn: ([regFormValues, regForm, actFormValues, actForm], eventParam) => {
    const { fieldName, unrestricted, formId } = eventParam;
    const isReg = formId === USER_FORM.ADDRESS_REG;
    const formValues = isReg ? regFormValues : actFormValues;
    const form = isReg ? regForm : actForm;
    const obj = {};
    const regionAgr = get(eventParam, 'region_with_type', '');
    let str = regionAgr;
    const areaAgr = get(eventParam, 'area_with_type', '');
    if (!isEmpty(areaAgr)) {
      str += `, ${areaAgr}`;
    }

    const cityAgr = get(eventParam, 'city_with_type', '');
    if (!isEmpty(cityAgr) && cityAgr !== regionAgr && cityAgr !== areaAgr) {
      str += `, ${cityAgr}`;
    }

    const settlementAgr = get(eventParam, 'settlement_with_type', '');
    if (!isEmpty(settlementAgr) && settlementAgr !== regionAgr && cityAgr !== areaAgr) {
      str += `, ${settlementAgr}`;
    }

    const streetAgr = get(eventParam, 'street_with_type', '');
    if (!isEmpty(streetAgr)) {
      str += `, ${streetAgr}`;
    }

    // const postalCode = get(eventParam, 'postal_code', '');
    // const regionCityStreet = get(eventParam, 'unrestricted', '').replace(`${postalCode},`, '');

    const regionCityStreet = get(eventParam, 'regionCityStreet', `${str} `);

    const region = getParentData(eventParam, 'region_with_type', 'area_with_type');
    const regId = get(eventParam, 'region_fias_id', '');
    const areaId = get(eventParam, 'area_fias_Id', '');

    const city = getParentData(eventParam, 'city_with_type', 'settlement_with_type');
    const cityId = get(eventParam, 'city_fias_id', '');
    const settlementId = get(eventParam, 'settlement_fias_id', '');

    const street = get(eventParam, 'street_with_type', '');
    const streetId = get(eventParam, 'street_fias_id', '');

    const house = get(eventParam, 'house', '');
    const houseId = get(eventParam, 'house_fias_id', '');

    switch (fieldName) {
      case REGION_CITY_STREET:
        obj[REGION_CITY_STREET] = regionCityStreet;
        obj[REGION] = region;
        obj[REGION_ID] = regId;
        obj[AREA_ID] = areaId;
        obj[CITY] = city;
        obj[CITY_ID] = cityId;
        obj[SETTLEMENT_ID] = settlementId;
        obj[STREET] = street;
        obj[STREET_ID] = streetId;
        break;

      case HOUSE:
        obj[REGION_CITY_STREET] = regionCityStreet;
        obj[REGION] = region;
        obj[REGION_ID] = regId;
        obj[AREA_ID] = areaId;
        obj[CITY] = city;
        obj[CITY_ID] = cityId;
        obj[SETTLEMENT_ID] = settlementId;
        obj[STREET] = street;
        obj[STREET_ID] = streetId;
        obj[HOUSE] = house;
        obj[HOUSE_ID] = houseId;
        if (isEmpty(obj[HOUSE_ID])) {
          obj[HOUSE_ID] = get(eventParam, 'fias_id', '');
        }
        // obj[HOUSING] = get(eventParam, 'block', '');
        obj[HOUSE_ALL_DATA] = {
          data: { ...eventParam },
          unrestricted_value: unrestricted,
          value: unrestricted,
        };
        break;
      default:
    }
    return {
      data: {
        ...formValues,
        ...obj,
        [HANDLER]: get(eventParam, HANDLER, ''),
        fieldName,
      },
      fieldName,
      form,
    };
  },
  target: [
    attach({
      effect: (_, { data, form }) => {
        form.setForm(data);
        form.resetErrors();
      },
    }),
    jumpFocusFieldFn.prepend(({ fieldName }) => ([
      REGION_CITY_STREET,
    ].includes(fieldName) ? fieldName : '')),
  ],
});

// Submit-им форму
sample({
  clock: addressRegForm.formValidated,
  target: [showLoaderButtonFn, addAddressFn],
});

sample({
  clock: addressActualForm.formValidated,
  target: [showLoaderButtonFn, addAddressFn],
});

// Cохранение (данные передаем через событие)
sample({
  clock: addAddressFn,
  fn: (formValues) => {
    let registeredAt = null;
    if (!isEmpty(formValues[REGISTRATION_DATE])) {
      const arrDate = formValues[REGISTRATION_DATE].split('.');
      registeredAt = `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}`;
    }
    const values = {
      ...getDataAddress(formValues),
      [REGISTRATION_DATE]: registeredAt,
      type: formValues[ADDRESS_TYPE],
    };
    return values;
  },
  target: [
    addAddressFx,
  ],
});

sample({
  clock: addAddressFx.doneData,
  fn: (data) => ({
    id: get(data, 'data.id', ''),
    type: get(data, 'data.type', ''),
  }),
  target: [
    hideLoaderButtonFn,
    notifySuccessFn.prepend(() => 'Адрес успешно обновлен'),
    toggleDataEditFn.prepend(({ type }) => ({ form: type, value: false })),
    getAddressDataFx.prepend(({ id }) => id),
  ],
});

sample({
  clock: addAddressFx.fail,
  target: [
    // снимаем блок с кнопки
    hideLoaderButtonFn,
    notifyErrorFn.prepend(
      (data) => get(
        data,
        'error.response.data.errors.street.0',
        get(data, 'error.response.street.message', ''),
      ),
    ),
  ],
});
