import { combine } from 'effector';
import { dictionariesDomain } from 'src/models/App';
import {
  getMessagesDictSign,
  getPurposesDictSign,
  getEducationsDictSign,
  getMaritalStatusesDictSign,
  getWorkScopesDictSign,
  getEmploymentsDictSign,
  getConfigDictSign,
} from 'src/api/signatures/Dictionaries';

/// ///////////////////////////////////////////////////////////
// ================== СЛОВАРИ / КОНСТАНТЫ ================== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ СОБЫТИЙ ===================== //
/// ///////////////////////////////////////////////////////////

export const getMessagesDictFn = dictionariesDomain.createEvent();

export const getPurposesDictFn = dictionariesDomain.createEvent();

export const getEducationsDictFn = dictionariesDomain.createEvent();
export const getMaritalStatusesDictFn = dictionariesDomain.createEvent();
export const getWorkScopesDictFn = dictionariesDomain.createEvent();
export const getEmploymentsDictFn = dictionariesDomain.createEvent();
export const getConfigDictFn = dictionariesDomain.createEvent();

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ХРАНИЛИЦ ==================== //
/// ///////////////////////////////////////////////////////////

// хранилище для тексов сообщений / ошибок
export const $messagesDict = dictionariesDomain.createStore({});

// хранилище для целей займа
export const $purposesDict = dictionariesDomain.createStore([]);

// хранилище для образования
export const $educationsDict = dictionariesDomain.createStore([]);
// хранилище для семейного положения
export const $maritalStatusesDict = dictionariesDomain.createStore([]);
// хранилище для сфер трудоустройства
export const $workScopesDict = dictionariesDomain.createStore([]);
// хранилище для типов деятельности
export const $employmentsDict = dictionariesDomain.createStore([]);
export const $configDict = dictionariesDomain.createStore([]);

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ЭФФЕКТОВ ==================== //
/// ///////////////////////////////////////////////////////////

export const getMessagesDictFx = dictionariesDomain.createEffect(getMessagesDictSign);
export const getPurposesDictFx = dictionariesDomain.createEffect(getPurposesDictSign);

export const getEducationsDictFx = dictionariesDomain.createEffect(getEducationsDictSign);
export const getMaritalStatusesDictFx = dictionariesDomain.createEffect(getMaritalStatusesDictSign);
export const getWorkScopesDictFx = dictionariesDomain.createEffect(getWorkScopesDictSign);
export const getEmploymentsDictFx = dictionariesDomain.createEffect(getEmploymentsDictSign);
export const getConfigDictFx = dictionariesDomain.createEffect(getConfigDictSign);

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

export const $dictCombineData = combine(
  $messagesDict, $purposesDict,
  $educationsDict, $maritalStatusesDict, $workScopesDict, $employmentsDict,
  (messagesDict, purposesDict,
    educationsDict, maritalStatusesDict, workScopesDict, employmentsDict) => ({
    messagesDict,
    purposesDict: purposesDict.map(({ id, name }) => ({ value: id, label: name })),
    educationsDict: educationsDict.map(({ id, name }) => ({ value: id, label: name })),
    maritalStatusesDict: maritalStatusesDict.map(({ id, name }) => ({ value: id, label: name })),
    workScopesDict: workScopesDict.map(({ id, name }) => ({ value: id, label: name })),
    employmentsDict: employmentsDict.map(({ id, name }) => ({ value: id, label: name })),
  }),
);

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ЭФФЕКТОВ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

// тут создаются эффекты через attach

/// ///////////////////////////////////////////////////////////
// ====================== ЮНИТЫ ФОРМ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем формы с помощью effector-forms

/// ///////////////////////////////////////////////////////////
//  ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ФОРМ + (ХРАНИЛИЩ, ЭФФЕКТОВ) //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ========================= ФАБРИКИ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем различные фабрики и хранилища с использованием фабрик
