import React from 'react';
import { sendYMGoalFx } from 'src/models/Statistic';
import { openModalFn } from 'src/models/components/Modal';
import { Form } from './children/Form';

const handlerClickChangePhone = () => {
  openModalFn({
    className: 'modal-agreement',
    content: Form,
  });
  sendYMGoalFx.prepend(() => 'REG_PHONE_VER_CHANGE_PHONE_FORM_BT');
};

export const ChanePhone = React.memo(() => (
  <p className="description">
    Если вы неверно указали номер телефона, то вы можете
    {' '}
    <button
      type="button"
      className="block-btn__change button-agreement"
      onClick={handlerClickChangePhone}
    >
      изменить номер
    </button>
  </p>
));
