import { sample } from 'effector';
import { createForm } from 'effector-forms';
import { debounce } from 'patronum/debounce';
import { get, isEmpty } from 'src/lib/lodash';
import { isCurrentPath } from 'src/lib/url';
import { storage } from 'src/lib/storage';
import { phonePattern, rules } from 'src/lib/rules';
import { REG_PATH } from 'src/dict/path';
import { REG_STEP3_DEV_FIELDS, REG_STEP3_FIELDS, USER_FORM } from 'src/dict/fields';
import { $pathnameUrl } from 'src/models/App';
import { $user } from 'src/models/User';
import { addAddressDataSign } from 'src/api/signatures/User';
import { getDaDataByIdSign } from 'src/api/signatures/DaData';
import { registrationDomain } from '../index';

/// ///////////////////////////////////////////////////////////
// ================== СЛОВАРИ / КОНСТАНТЫ ================== //
/// ///////////////////////////////////////////////////////////
const { ADDRESS } = REG_PATH;

const {
  HANDLER,
  REGION_CITY_STREET_PER, REGION_CITY_STREET_ACT,
  HOUSE_PER, HOUSE_ACT,
  MATCHES_PER_ACT, PHONE_HOME, IS_NOT_PHONE_HOME, REGISTRATION_DATE,
  HOUSE_ID_ACT, HOUSE_ID_PER,
} = REG_STEP3_FIELDS;

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ СОБЫТИЙ ===================== //
/// ///////////////////////////////////////////////////////////

/* событие набора в поле ввода адреса (запускает формирование фильтрации)
 триггерится в модели компонента адреса */
export const prepareChangeAddressFn = registrationDomain.createEvent('prepareChangeAddressFn');
/* событие вызывающееся после окончания prepareChangeAddressFn (тригеррит запрос на получение данных)
триггерится в модели шага адреса */
export const postChangeAddressFn = registrationDomain.createEvent('postChangeAddressFn');
/* событие вызывающееся на набора в поле ввода адреса (запускает формирование полей которые очистятся)
 триггерится в модели компонента адреса */
export const clearChildrenAddressFn = registrationDomain.createEvent('clearChildrenAddressFn');
/* событие вызывающееся после окончания clearChildrenAddressFn (тригеррит split где происходит очистка)
триггерится в модели шага адреса */
export const postClearChildrenAddressFn = registrationDomain.createEvent('postClearChildrenAddressFn');
/* событие выбора option (запускает данных которые будут записаны в поля)
 триггерится в компоненте адреса */
export const choiceAddressFn = registrationDomain.createEvent('choiceAddressFn');
/* событие вызывающееся после окончания choiceAddressFn (тригеррит split где происходит запись)
 триггерится в модели шага адреса */
export const postChoiceAddressFn = registrationDomain.createEvent('postChoiceAddressFn');
/* событие вызывающееся при потере фокуса с адреса (служит для валидирования что данные выбраны из списка)
 триггерится в компоненте адреса */
export const notChoiceAddressFn = registrationDomain.createEvent('notChoiceAddressFn');

export const addAddressDataFn = registrationDomain.createEvent('addAddressDataFn');
export const addHomePhoneFn = registrationDomain.createEvent('addHomePhoneFn');

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ХРАНИЛИЦ ==================== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ЭФФЕКТОВ ==================== //
/// ///////////////////////////////////////////////////////////

export const addAddressDataFx = registrationDomain.createEffect(addAddressDataSign);
export const getAddressDataByIdFx = registrationDomain.createEffect(getDaDataByIdSign);

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

export const $isAddressPage = $pathnameUrl.map((path) => isCurrentPath(path, ADDRESS));

export const $isNotPhoneHome = registrationDomain.createStore(false, { name: 'isNotPhoneHome' });
export const $matchesPermanentActual = registrationDomain.createStore(true, { name: 'matchesPermanentActual' });

// $addressMetaData - значения
// addressMetaData - хранилища

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ЭФФЕКТОВ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

// тут создаются эффекты через attach

/// ///////////////////////////////////////////////////////////
// ====================== ЮНИТЫ ФОРМ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем формы с помощью effector-forms
const fields = {
  [HANDLER]: { init: '' },
  [REGION_CITY_STREET_PER]: { rules: [rules.required()], init: '' },
  // [REGION_PER]: { rules: [rules.required()], init: '' },
  // [CITY_PER]: { rules: [rules.required()], init: '' },
  [HOUSE_PER]: { rules: [rules.required()], init: '' },
  [REGISTRATION_DATE]: {
    rules: [rules.required(), rules.dateYear(), rules.isValidRegDate()],
    init: '',
    validateOn: ['blur'],
  },
  [PHONE_HOME]: {
    rules: [
      {
        source: $isNotPhoneHome,
        validator: (value, form, isNotPhoneHome) => !isEmpty(value) || isNotPhoneHome,
        errorText: 'Данное поле обязательно для заполнения',
      },
      {
        source: $isNotPhoneHome,
        validator: (value, form, isNotPhoneHome) => phonePattern.test(value) || isNotPhoneHome,
        errorText: 'Телефонный номер введен в неверном формате',
      },
    ],
    init: '',
    validateOn: ['blur'],
  },
  [IS_NOT_PHONE_HOME]: { init: false, units: { $value: $isNotPhoneHome } },
  [MATCHES_PER_ACT]: {
    init: true,
    units: { $value: $matchesPermanentActual },
    validateOn: ['blur'],
  }, //
  [REGION_CITY_STREET_ACT]: {
    rules: [
      {
        source: $matchesPermanentActual,
        validator: (value, form, isMatches) => !isEmpty(value) || isMatches,
        errorText: 'Данное поле обязательно для заполнения',
      },
    ],
    init: '',
  },
  [HOUSE_ACT]: {
    rules: [
      {
        source: $matchesPermanentActual,
        validator: (value, form, isMatches) => !isEmpty(value) || isMatches,
        errorText: 'Данное поле обязательно для заполнения',
      },
    ],
    init: '',
  },
};

Object.values(REG_STEP3_FIELDS).forEach((item) => {
  if (isEmpty(fields[item])) {
    fields[item] = { init: '' };
  }
});

Object.values(REG_STEP3_DEV_FIELDS).forEach((item) => {
  if (isEmpty(fields[item])) {
    fields[item] = { init: '' };
  }
});

export const step3Form = createForm({
  fields,
  validateOn: ['submit'],
  domain: registrationDomain,
});

/// ///////////////////////////////////////////////////////////
//  ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ФОРМ + (ХРАНИЛИЩ, ЭФФЕКТОВ) //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ========================= ФАБРИКИ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем различные фабрики и хранилища с использованием фабрик

export const loadAddressOperator = ({ type }) => {
  sample({
    clock: debounce({
      source: sample({
        clock: [$pathnameUrl, $user],
        source: [$pathnameUrl, $user, getAddressDataByIdFx.pending],
        filter: ([path, user, pending]) => {
          if (isEmpty(user)) {
            return false;
          }
          const esiaDataSAddr = get(user, 'esia_data.addresses', {});
          if (isEmpty(esiaDataSAddr)) {
            return false;
          }
          const data = Object.values(esiaDataSAddr).find((item) => item.type === type);
          if (isEmpty(data)) {
            return false;
          }
          return path.split('/').pop() === ADDRESS && isEmpty(pending) && !isEmpty(get(data, 'fias', ''));
        },
      }),
      timeout: 10,
    }),
    source: $user,
    filter: () => {
      const data = storage.get(ADDRESS);
      const houseId = type === USER_FORM.ADDRESS_REG ? get(data, HOUSE_ID_PER) : get(data, HOUSE_ID_ACT);
      // если houseId не пустой, то значит мы уже загружали данные по адресу
      return isEmpty(houseId);
    },
    fn: (user) => {
      const data = Object.values(get(user, 'esia_data.addresses', {})).find((item) => item.type === type);
      return ({
        query: get(data, 'fias', ''),
        formId: type,
      });
    },
    target: getAddressDataByIdFx,
  });
};
