import React, { Suspense } from 'react';
import { CalculatorHome as Calculator } from 'src/ui/components';

import { TopSection } from './children/TopSection';
import { How } from './children/How';
import { Way } from './children/Way';
import { Trust } from './children/Trust';
import { Why } from './children/Why';
import { Work } from './children/Work';
import { Reviews } from './children/Reviews';
import { Information } from './children/Information';

// const { Reviews } = React.lazy(() => import('./children/Reviews'));

export const Home = React.memo(() => (
  <main className="main">
    <TopSection>
      <Calculator />
    </TopSection>
    <How />
    <Way />
    <Why />
    <Trust />
    <Work />
    <Suspense fallback={<div>Загрузка...</div>}>
      <Reviews />
    </Suspense>
    <Information />
  </main>
));
