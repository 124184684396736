import React from 'react';
import { useStore } from 'effector-react';
import Modal from 'react-modal';
import { get, isEmpty } from 'src/lib/lodash';
import {
  closeModalFn as closeModal,
  $contentModal,
} from 'src/models/components/Modal';
import './ModalWindow.css';

const customStyles = {
  overlay: {
    overflow: 'auto',
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    // webkitBackfaceVisibility: 'hidden',
    height: '100%',
    left: 0,
    // webkitTapHighlightColor: 'transparent',
    top: 0,
    msTouchAction: 'manipulation',
    touchAction: 'manipulation',
    transform: 'translateZ(0)',
    width: '100%',
    zIndex: '999',
  },
  content: {
    right: 'auto',
    borderRadius: '35px',
    padding: '4px 0 0',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '570px',
  },
};

// <div className="modal-main>

Modal.setAppElement('#root');

export const ModalWindow = () => {
  const contentModal = useStore($contentModal);
  // основной компонент
  const content = get(contentModal, 'content', '');
  const nameClass = get(contentModal, 'className', '');
  // колбек на закрытие
  const closeCallback = get(contentModal, 'closeCallback', closeModal);
  const successCallback = get(contentModal, 'successCallback', () => '');
  // условия невозможности закрытия по клику вне
  const importantOperation = get(contentModal, 'importantOperation', false);
  // попадает пропсами в компонент
  const props = get(contentModal, 'props', {});
  // наличие футера
  const isBottom = get(contentModal, 'isBottom', false);
  // при важных операциях закрытие только по крестику
  const objProps = !importantOperation ? { onRequestClose: closeCallback } : {};
  // переопределение стандартных стилей
  const style = get(contentModal, 'style', {});
  const objStyle = {
    overlay: { ...customStyles.overlay, ...get(style, 'overlay', {}) },
    content: { ...customStyles.content, ...get(style, 'content', {}) },
  };
  return (
    <>
      {!isEmpty(contentModal) && (
        <Modal
          className="disableDefault"
          style={objStyle}
          isOpen
          {...objProps}
          // onAfterOpen={closeModal}
        >
          <div className={`modal ${nameClass}`}>
            <div className="modal-content">
              <div className="modal-top">
                <img src="/img/modal/top.svg" alt="" />
              </div>
              {React.createElement(content, { ...props, closeCallback, successCallback })}
            </div>
            {isBottom && <div className="modal-bottom" />}
          </div>
        </Modal>
      )}
    </>
  );
};
