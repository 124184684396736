import React from 'react';

export const Loans = React.memo(() => (
  <>
    <div className="loans">
      <h3 className="block-title">Мы подобрали вам займы</h3>
      <p className="block-text">
        Одновременно подайте заявку, как минимум, в 3 компании для гарантированного получения
        займа.
      </p>
      <div className="loans-items">
        <div className="loans-item">
          <div className="loans-item-info">
            <p>
              Вероятность
              <span className="bold">83%</span>
            </p>
          </div>
          <div className="loans-item-logo">
            <img src="/img/profile/logo4.png" alt="" />
          </div>
          <div className="block-line" />
          <div className="loans-item-bottom">
            <p className="loans-item-sum">22 000 ₽</p>
            <p className="loans-item-text">
              от
              <span className="bold">0%</span>
              {' '}
              в день
            </p>
            <div className="loans-item-button">
              <a href="#" className="default-btn">Оформить заявку</a>
            </div>
          </div>
        </div>
        <div className="loans-item">
          <div className="loans-item-info">
            <p>
              Вероятность
              <span className="bold">83%</span>
            </p>
          </div>
          <div className="loans-item-logo">
            <img src="/img/profile/logo4.png" alt="" />
          </div>
          <div className="block-line" />
          <div className="loans-item-bottom">
            <p className="loans-item-sum">22 000 ₽</p>
            <p className="loans-item-text">
              от
              <span className="bold">0%</span>
              {' '}
              в день
            </p>
            <div className="loans-item-button">
              <a href="#" className="default-btn">Оформить заявку</a>
            </div>
          </div>
        </div>
        <div className="loans-item">
          <div className="loans-item-info">
            <p>
              Вероятность
              <span className="bold">83%</span>
            </p>
          </div>
          <div className="loans-item-logo">
            <img src="/img/profile/logo4.png" alt="" />
          </div>
          <div className="block-line" />
          <div className="loans-item-bottom">
            <p className="loans-item-sum">22 000 ₽</p>
            <p className="loans-item-text">
              от
              <span className="bold">0%</span>
              {' '}
              в день
            </p>
            <div className="loans-item-button">
              <a href="#" className="default-btn">Оформить заявку</a>
            </div>
          </div>
        </div>
        <div className="loans-item">
          <div className="loans-item-info">
            <p>
              Вероятность
              <span className="bold">83%</span>
            </p>
          </div>
          <div className="loans-item-logo">
            <img src="/img/profile/logo4.png" alt="" />
          </div>
          <div className="block-line" />
          <div className="loans-item-bottom">
            <p className="loans-item-sum">22 000 ₽</p>
            <p className="loans-item-text">
              от
              <span className="bold">0%</span>
              {' '}
              в день
            </p>
            <div className="loans-item-button">
              <a href="#" className="default-btn">Оформить заявку</a>
            </div>
          </div>
        </div>
        <div className="loans-item">
          <div className="loans-item-info">
            <p>
              Вероятность
              <span className="bold">83%</span>
            </p>
          </div>
          <div className="loans-item-logo">
            <img src="/img/profile/logo4.png" alt="" />
          </div>
          <div className="block-line" />
          <div className="loans-item-bottom">
            <p className="loans-item-sum">22 000 ₽</p>
            <p className="loans-item-text">
              от
              <span className="bold">0%</span>
              {' '}
              в день
            </p>
            <div className="loans-item-button">
              <a href="#" className="default-btn">Оформить заявку</a>
            </div>
          </div>
        </div>
        <div className="loans-item">
          <div className="loans-item-info">
            <p>
              Вероятность
              <span className="bold">83%</span>
            </p>
          </div>
          <div className="loans-item-logo">
            <img src="/img/profile/logo4.png" alt="" />
          </div>
          <div className="block-line" />
          <div className="loans-item-bottom">
            <p className="loans-item-sum">22 000 ₽</p>
            <p className="loans-item-text">
              от
              <span className="bold">0%</span>
              {' '}
              в день
            </p>
            <div className="loans-item-button">
              <a href="#" className="default-btn">Оформить заявку</a>
            </div>
          </div>
        </div>
      </div>
      <div className="loans-button">
        <a href="#" className="default-btn border">Еще варианты</a>
      </div>
    </div>
  </>
));
