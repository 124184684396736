import {
  sample, split, restore, attach,
} from 'effector';
import { debounce } from 'patronum/debounce';
import { combineEvents } from 'patronum/combine-events';
import { spread } from 'patronum/spread';
import { get, isEmpty } from 'src/lib/lodash';
import { getDate } from 'src/lib/date';
import { isCurrentPath } from 'src/lib/url';
import {
  AUTH_TO_PROFILE, PAGES_PATH, PROFILE_PATH,
} from 'src/dict/path';
import { config } from 'src/dict/config';
import {
  $pathnameUrl,
  mountAppFn,
  resetStatesFn, scrollTopFn,
} from 'src/models/App';
import { pushHistoryFn } from 'src/models/Helper/History';
import { hideLoaderPageFn, showLoaderPageFn } from 'src/models/Helper/Loader';
import { closeModalFn, openModalFn } from 'src/models/components/Modal';
import {
  authLoginAfterFn, goLoginPageFn, clearAllStorageFn,
  $tokenData,
  authLoginFx,
} from 'src/models/Auth';
import { authESIALoginFx } from 'src/models/Esia';
import {
  $isProfileNewLoanPage, $isProcessingPage, $isPaymentNewLoanPage, $isCardsNewLoanPage,
  $isAgreementNewLoanPage, $isConfirmNewLoanPage, $isFailureNewLoanPage, $isSuccessNewLoanPage,
  $applicationDetail, $applicationStatus,
  STATUS_LOAN,
} from 'src/models/Loan/Application';
import {
  $isCardsToRepayPage,
  $isPaymentToRepayPage,
  $isProfileLoansPage, $isProfileToRepayPage,
} from 'src/models/Loan/Agreement';
import {
  $userId, $firstName,
  getCurrentUserFromAuthFx,
} from 'src/models/User';
import { recoveryPassAfterFn } from 'src/models/Recovery';
import {
  getPersonalProductsCalcFn, getProductsCalcFn,
} from 'src/models/Calculator';
import { Refusal } from 'src/ui/components/Modals/Refusal';
import {
  // события
  toProfileFn, checkVerifiedFn, logoutForProfileFn, infoRefusalFn,
  resetPathToBackFn, declineLandingUrlFn,
  goCardsPageFn,
  // хранилдища
  $pathToBack,
  $isProfilePage,
  $isProfilePersonalPage, $isProfileFeedbackPage, $isProfileCardsPage,
  $isProfileSecurityPage, $isProfileServicesPage, $isProfileEventsPage,
  $isProfileReportsPage, $isProfilePartnersPage,
  profileClassOperator,
} from './index';

const { PROFILE } = PAGES_PATH;
const { CARDS } = PROFILE_PATH;

/// ///////////////////////////////////////////////////////////
// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
/// ///////////////////////////////////////////////////////////

$pathToBack.reset(resetPathToBackFn);

/// ///////////////////////////////////////////////////////////
// ===================== БЛОК ОПЕРАТОРОВ =================== //
/// ///////////////////////////////////////////////////////////

split({
  source: sample($tokenData, [
    toProfileFn,
    sample({
      clock: mountAppFn,
      source: $isProfilePage,
      filter: (isProfilePage) => isProfilePage,
    }),
    authLoginFx.done,
    authESIALoginFx.done,
  ]),
  match: {
    isNotToken: ({ accessToken }) => isEmpty(accessToken),
  },
  cases: {
    isNotToken: [
      // аккаунт не  не верифицирован
      goLoginPageFn,
      // запрашиваем общие продукты
      getProductsCalcFn,
    ],
    __: [
      // если это после получения заема - то закрываем модалку
      closeModalFn,
      // запрашиваем данные о юере чтобы быть уверенными что ему можно в ЛК
      getCurrentUserFromAuthFx,
    ],
  },
});

sample({
  clock: getCurrentUserFromAuthFx.fail,
  target: [
    goLoginPageFn,
    clearAllStorageFn,
  ],
});

sample({
  clock: [
    getCurrentUserFromAuthFx.doneData,
    // authLoginFx.doneData,
  ],
  target: checkVerifiedFn,
});

split({
  source: sample(
    [
      restore(checkVerifiedFn, {}),
      $isProfilePage,
      $pathToBack,
    ], checkVerifiedFn,
  ),
  match: {
    toLogin: ([{ data }]) => isEmpty(get(data, 'is_verified', '')),
    toProfile: ([{ data }, isProfilePage, pathToBack]) => (
      isEmpty(isProfilePage)
      && isEmpty(pathToBack)
      && !isEmpty(get(data, 'is_verified', ''))
    ),
    /* toProfileLoan: ([{ data }, isProfilePage, pathToBack]) => (
      isEmpty(isProfilePage)
      && isEmpty(pathToBack)
      && !isEmpty(get(data, 'is_verified', ''))
    ), */
    // $applicationStatus
  },
  cases: {
    toLogin: [
      // аккаунт не  не верифицирован
      goLoginPageFn,
    ],
    toProfile: [
      pushHistoryFn.prepend(() => `/${AUTH_TO_PROFILE}`),
      // запрашиваем персональные продукты
      getPersonalProductsCalcFn,
    ],
    __: [
      // запрашиваем персональные продукты
      getPersonalProductsCalcFn,
      // eslint-disable-next-line unicorn/no-unreadable-array-destructuring
      pushHistoryFn.prepend(
        // eslint-disable-next-line unicorn/no-unreadable-array-destructuring
        ([,, pathToBack]) => (!isEmpty(pathToBack) ? pathToBack : `/${AUTH_TO_PROFILE}`),
      ),
    ],
  },
});

// если разлогинивание проиходит на странице профайла
sample({
  clock: sample({
    clock: logoutForProfileFn,
    source: $isProfilePage,
    filter: (isProfilePage) => isProfilePage,
  }),
  target: [
    // очищаем через домен вообще все сторы
    resetStatesFn,
    // очищаем LS
    clearAllStorageFn,
    // запрашиваем общие продукты
    getProductsCalcFn,
    // шлем на логин
    goLoginPageFn,
  ],
});

// Редирект после авторизации на нужную страницу
sample({
  clock: sample({
    clock: debounce({
      source: mountAppFn,
      timeout: 100,
    }),
    source: $pathnameUrl,
    filter: (path) => (
      // ссылка ведет в ЛК
      isCurrentPath(path, PROFILE)
        // и эта ссылка в ЛК не дефолтная (куда редиректим после авторизации)
        && path !== `/${AUTH_TO_PROFILE}`),
  }),
  source: [$pathnameUrl, $isProfilePage],
  fn: ([path]) => ({ path }),
  target: [
    spread({
      targets: {
        path: $pathToBack,
      },
    }),
  ],
});

/* Если клиент логинится в личный кабинет если у него есть заявка с отклонением -
 необходимо ему отобразить попап с отказом */
sample({
  clock: [
    combineEvents({
      events: {
        authLoginAfterFn,
        $userId,
      },
    }),
    debounce({
      source: recoveryPassAfterFn,
      timeout: 1000,
    }),
  ],
  source: $applicationStatus,
  filter: (status) => status === STATUS_LOAN.REJECTED,
  target: infoRefusalFn,
});

sample({
  clock: [
    combineEvents({
      events: {
        authLoginAfterFn,
        $userId,
      },
    }),
  ],
  target: [
    scrollTopFn.prepend(
      () => window.scrollTo({ top: 0, behavior: 'smooth' }),
    ),
  ],
});

sample({
  clock: infoRefusalFn,
  source: [$firstName, $applicationDetail],
  target: openModalFn.prepend(([firstName, applicationDetail]) => ({
    importantOperation: true,
    className: 'modal-agreement',
    content: Refusal,
    props: {
      name: firstName,
      date: getDate(get(applicationDetail, 'next_attempt_at')),
      callBack: declineLandingUrlFn,
    },
    // указываем свое событие на закрытие модального окна
    closeCallback: closeModalFn,
  })),
});

sample({
  clock: declineLandingUrlFn,
  target: attach({
    async effect() {
      window.location.href = config.DECLINE_LANDING_URL;
    },
  }),
});

// запускаем loader - профиль новая заявка
sample({
  clock: [
    sample({
      clock: $isProfileNewLoanPage,
      filter: (isProfileNewLoanPage) => isProfileNewLoanPage,
    }),
    sample({
      clock: mountAppFn,
      source: $isProfilePage,
      filter: (isProfilePage) => isProfilePage,
    }),
  ],
  target: showLoaderPageFn,
});

// скрываем loader - профиль
sample({
  clock: debounce(({
    source: scrollTopFn,
    timeout: 1500,
  })),
  source: [$isCardsNewLoanPage, $pathnameUrl],
  filter: ([isCardsNewLoanPage, pathnameUrl]) => (
    isEmpty(isCardsNewLoanPage) && isCurrentPath(pathnameUrl, PROFILE_PATH.PROFILE)
  ),
  target: hideLoaderPageFn,
});

sample({
  clock: goCardsPageFn,
  target: pushHistoryFn.prepend(() => `/${PROFILE}/${CARDS}`),
});

// установка динамически стилей для ЛК
[
  // Различные страницы
  { isPage: $isProfilePersonalPage, pageClass: 'profilePage-personal' },
  { isPage: $isProfileCardsPage, pageClass: 'profilePage-cards profilePage-loans' },
  { isPage: $isProfileFeedbackPage, pageClass: 'profilePage-feedback' },
  { isPage: $isProfileSecurityPage, pageClass: 'profilePage-security' },
  { isPage: $isProfileServicesPage, pageClass: 'profilePage-paidServices' },
  { isPage: $isProfileEventsPage, pageClass: 'profilePage-events' },
  { isPage: $isProfileReportsPage, pageClass: 'profilePage-reports' },
  { isPage: $isProfilePartnersPage, pageClass: 'profilePage-showcase' },
  // Заявка
  { isPage: $isProfileNewLoanPage, pageClass: 'profilePage-cards profilePage-loans' },
  { isPage: $isSuccessNewLoanPage, pageClass: 'profilePage-successfullLoan successfullLoanPage afterAddCard' },
  { isPage: $isFailureNewLoanPage, pageClass: 'profilePage-failure failurePage' },
  { isPage: $isProcessingPage, pageClass: 'profilePage-loans' },
  // eslint-disable-next-line max-len
  { isPage: $isPaymentNewLoanPage, pageClass: 'profilePage-cardsLoans profilePage-successfullLoan successfullLoanPage' },
  { isPage: $isCardsNewLoanPage, pageClass: 'profilePage-cards' },
  { isPage: $isAgreementNewLoanPage, pageClass: 'profilePage-agreement agreementPage' },
  { isPage: $isConfirmNewLoanPage, pageClass: 'profilePage-agreement agreementPage' },
  // Договор
  { isPage: $isProfileLoansPage, pageClass: 'profilePage-loans' },
  { isPage: $isProfileToRepayPage, pageClass: 'profilePage-cards profilePage-loans' },
  { isPage: $isPaymentToRepayPage, pageClass: 'profilePage-paymentToRepay successfullLoanPage profilePage-cardsLoans' },
  { isPage: $isCardsToRepayPage, pageClass: 'profilePage-cards' },
].forEach(({ isPage, pageClass }) => {
  profileClassOperator({ isPage, pageClass });
});

// обработка форма персональной
// patch /clients/current/profile
/*
ФИО
Дата Рождения
 */

// /clients/current/info
/*
СНИЛС
 */
