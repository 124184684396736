import {
  openMenuMobileFn, closeMenuMobileFn,
  // хранилища
  $openMenuMobile,
  // эффекты
} from './index';

/// ///////////////////////////////////////////////////////////
// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
/// ///////////////////////////////////////////////////////////

$openMenuMobile
  .on(closeMenuMobileFn, () => '')
  .on(openMenuMobileFn, () => 'openMenu');

/// ///////////////////////////////////////////////////////////
// ===================== БЛОК ОПЕРАТОРОВ =================== //
/// ///////////////////////////////////////////////////////////
