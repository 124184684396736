import React from 'react';
import { Services } from './children/Services';

export const PaidServices = React.memo(() => (
  <>
    <h1 className="title">Платные услуги</h1>
    <div className="container">

      <Services />

      <div className="info">
        <h2 className="info-title">Как вернуть деньги?</h2>
        <p className="info-text">
          Сайт рыбатекст поможет дизайнеру, верстальщику, вебмастеру сгенерировать несколько
          абзацев более менее осмысленного текста рыбы на русском языке, а начинающему оратору отточить навык
          публичных выступлений в домашних условиях.
        </p>
        <p className="info-text">При создании генератора мы использовали небезизвестный универсальный код речей.</p>
      </div>
    </div>
  </>
));
