import React from 'react';
import { Loans } from './children/Loans';
import { Offers } from './children/Offers';

export const OffersPartners = React.memo(() => (
  <>
    <Offers />
    <Loans />
  </>
));
