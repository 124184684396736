import React from 'react';
import { useUnit } from 'effector-react';
import {
  PROFILE_PATH, PROFILE_NEW_LOAN_PATH, PROFILE_NEW_LOAN_CARD_PATH, PROFILE_LOAN_PATH,
} from 'src/dict/path';
import { $firstName } from 'src/models/User';
import { GreenBlock, MainBlock } from 'src/ui/components';
import {
  Card, Contact, BankTransfer,
} from 'src/pages/children/Profile/children/shared/PaymentList';

const { PROFILE, LOAN } = PROFILE_PATH;
const { NEW_LOAN } = PROFILE_LOAN_PATH;
const { PAYMENT } = PROFILE_NEW_LOAN_PATH;
const { CARDS } = PROFILE_NEW_LOAN_CARD_PATH;

const KeksBlock = () => (
  <>
    <div className="keks keks1">
      <img src="/img/other/successfull-keks.png" alt="" />
    </div>
    <div className="coin">
      <img src="/img/other/successfull-coin.png" alt="" />
    </div>
  </>
);
/*
const KeksBlock = () => (
  <>
    <div className="keks keks6">
      <img src="/img/form/keks-6.png" alt="" />
    </div>
    <span className="keks6-hand">
      <img src="/img/form/keks-6-hand.png" alt="" />
    </span>
  </>
); */

export const ListChoice = () => {
  const userName = useUnit($firstName);
  return (
    <div className="step">
      <GreenBlock
        title={`${userName}, поздравляем! Займ одобрен для выдачи!`}
        text="Пожалуйста, выберите способ получения займа"
      />
      <MainBlock keksBlock={KeksBlock}>
        <div className="block-main__content">
          <Card url={`/${PROFILE}/${LOAN}/${NEW_LOAN}/${PAYMENT}/${CARDS}`} />
          <div className="block-line" />

          <Contact />
          <div className="block-line" />

          <BankTransfer />
          <div className="block-line" />
        </div>
      </MainBlock>
    </div>
  );
};
/*
export const ListChoice = React.memo(() => (
  <div className="step step6 active">
    <GreenBlock
      title="Выберите, как хотите получить деньги?"
      text="Выберите удобный для вас способ получения денег"
    />
    <MainBlock keksBlock={KeksBlock}>
      <div className="block-main__content">
        <Card />
        <div className="block-line" />

        <Contact />
        <div className="block-line" />

        <BankTransfer />
        <div className="block-line" />
      </div>
    </MainBlock>
  </div>
));
*/
