import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { get, isEmpty } from 'src/lib/lodash';
import { daData } from 'src/dict/config';
import {
  getInputDaDataAddressModelApiFn,
  $objInputDaDataAddressModels,
} from 'src/models/components/InputDaData/Address';
import { InputDaDataAddress } from './InputDaDataAddress';

const {
  TIMEOUT_DEBOUNCE, MIN_LENGTH, PATH, LIMIT,
} = daData.ADDRESS;

export const InputAddress = React.forwardRef(({
  prepareChangeAddressFn,
  postChangeAddressFn,
  clearChildrenAddressFn,
  ...props
}, ref) => {
  const name = get(props, 'name', '');
  const formId = get(props, 'formId', '');
  const key = `${formId}_${name}`;

  const dataInputDaDataApi = get(useStore($objInputDaDataAddressModels), key, {});
  useEffect(() => {
    getInputDaDataAddressModelApiFn({
      limit: LIMIT,
      typeAction: PATH,
      timeoutDebounce: TIMEOUT_DEBOUNCE,
      minLength: MIN_LENGTH,
      prepareChangeAddressFn,
      postChangeAddressFn,
      clearChildrenAddressFn,
      ...props,
    });
  }, []);

  if (isEmpty(dataInputDaDataApi)) return <div />;

  return (
    <InputDaDataAddress
      modelData={{ ...dataInputDaDataApi }}
      propsData={{ ...props }}
      className="address"
      ref={ref}
    />
  );
});
