import React, { useEffect } from 'react';
import { useUnit } from 'effector-react';
import { useForm } from 'effector-forms';
import {
  getEventsField, getPropsField, getRefField, jumpFocusField, onSubmit,
} from 'src/lib/form';
import { getDateReverse } from 'src/lib/date';
import { maskLetter } from 'src/lib/mask';
import { get, isEmpty } from 'src/lib/lodash';
import {
  REG_STEP1_FIELDS, REG_STEP3_FIELDS,
} from 'src/dict/fields';
import { daData } from 'src/dict/config';
import { maskDate, maskHomePhone, maskPhone } from 'src/lib/maskPlaceholder';
import { getKeyboardLayoutFn } from 'src/models/Helper/Keyboard';
import { jumpFocusFieldFn, setRefFieldsFn } from 'src/models/Helper/Ref';
import { $user } from 'src/models/User';
import { personaForm } from 'src/models/User/Personal';
import {
  Input, InputDate, InputName, InputEmail, Checkbox, ButtonWithLoader as Button,
} from 'src/ui/components';

const {
  FIRST_NAME, LAST_NAME, IS_NOT_MIDDLE_NAME, MIDDLE_NAME,
  YEAR_OF_BIRTH, PHONE, EMAIL,
} = REG_STEP1_FIELDS;
const {
  PHONE_HOME, IS_NOT_PHONE_HOME,
} = REG_STEP3_FIELDS;

const { SURNAME, NAME, PATRONYMIC } = daData.NAME.FILTER_PARTS;

export const Form = React.memo(() => {
  const {
    submit, eachValid, ...restProps
  } = useForm(personaForm);

  const [user, isNotMiddleName, isNotPhoneHome] = useUnit([
    $user, personaForm.fields[IS_NOT_MIDDLE_NAME].$value, personaForm.fields[IS_NOT_PHONE_HOME].$value,
  ]);

  if (isEmpty(restProps.fields[MIDDLE_NAME].value)) {
    restProps.fields[MIDDLE_NAME].value = '';
  }

  useEffect(() => {
    personaForm.setInitialForm({
      ...user,
      ...user.profile,
      [YEAR_OF_BIRTH]: getDateReverse(get(user, `profile.${YEAR_OF_BIRTH}`, '')),
      [PHONE_HOME]: get(user, 'contacts', []).reduce((acc, item) => {
        if (item.type === PHONE_HOME) {
          return item.contact;
        }
        return acc;
      }, ''),
      [EMAIL]: get(user, 'contacts', []).reduce((acc, item) => {
        if (item.type === EMAIL) {
          return item.contact;
        }
        return acc;
      }, ''),
    });
  }, [user]);

  return (
    <>
      <form autoComplete="off" onSubmit={onSubmit(submit)}>
        <div className="block-items">
          <div className="block-item">
            <InputName
              filterParts={SURNAME}
              label="Фамилия"
              name={LAST_NAME}
              {...getPropsField({
                fieldName: LAST_NAME, props: restProps, mask: maskLetter,
              })}
              ref={getRefField({
                fieldName: LAST_NAME, callBack: setRefFieldsFn,
              })}
              jumpField={jumpFocusField({
                fieldName: YEAR_OF_BIRTH,
                callBack: jumpFocusFieldFn,
              })}
              onInput={getKeyboardLayoutFn.prepend((e) => ({
                field: get(restProps, `fields.${LAST_NAME}`),
                value: e.target.value,
              }))}
              required
            />
          </div>
          <div className="block-item">
            <InputName
              filterParts={NAME}
              label="Имя"
              name={FIRST_NAME}
              {...getPropsField({
                fieldName: FIRST_NAME, props: restProps, mask: maskLetter,
              })}
              ref={getRefField({
                fieldName: FIRST_NAME, callBack: setRefFieldsFn,
              })}
              jumpField={jumpFocusField({
                fieldName: (!isNotMiddleName ? MIDDLE_NAME : YEAR_OF_BIRTH),
                callBack: jumpFocusFieldFn,
              })}
              onInput={getKeyboardLayoutFn.prepend((e) => ({
                field: get(restProps, `fields.${FIRST_NAME}`),
                value: e.target.value,
              }))}
              required
            />
          </div>
        </div>

        <div className="block-items">

          <div className="block-item">
            <InputName
              filterParts={PATRONYMIC}
              label="Отчество"
              name={MIDDLE_NAME}
              {...getPropsField({
                fieldName: MIDDLE_NAME, props: restProps, mask: maskLetter,
              })}
              ref={getRefField({
                fieldName: MIDDLE_NAME, callBack: setRefFieldsFn,
              })}
              jumpField={jumpFocusField({
                fieldName: YEAR_OF_BIRTH,
                callBack: jumpFocusFieldFn,
              })}
              onInput={getKeyboardLayoutFn.prepend((e) => ({
                field: get(restProps, `fields.${MIDDLE_NAME}`),
                value: e.target.value,
              }))}
              required={isEmpty(isNotMiddleName)}
              disabled={isNotMiddleName}
            />
          </div>

          <div className="block-item default-checkbox">
            <Checkbox
              label="Нет отчества"
              name={IS_NOT_MIDDLE_NAME}
              {...getEventsField(get(restProps.fields, IS_NOT_MIDDLE_NAME))}
            />
          </div>

        </div>

        <div className="block-items">
          <div className="block-item">
            <Input
              label="Домашний номер телефона"
              type="text"
              inputMode="tel"
              name={PHONE_HOME}
              {...maskHomePhone}
              {...getPropsField({
                fieldName: PHONE_HOME, props: restProps,
              })}
              ref={getRefField({
                fieldName: PHONE_HOME, callBack: setRefFieldsFn,
              })}
              jumpField={jumpFocusField({
                fieldName: YEAR_OF_BIRTH,
                callBack: jumpFocusFieldFn,
                trigger: { name: 'maxLength', data: { value: 17 } },
              })}
              required={isEmpty(isNotPhoneHome)}
              disabled={isNotPhoneHome}
            />
          </div>

          <div className="block-item default-checkbox">
            <Checkbox
              label="Нет домашнего телефона"
              {...getEventsField(get(restProps.fields, IS_NOT_PHONE_HOME))}
              name={IS_NOT_PHONE_HOME}
            />
          </div>
        </div>

        <div className="block-items three">
          <div className="block-item">
            <InputDate
              label="Дата рождения"
              type="text  "
              inputMode="numeric"
              name={YEAR_OF_BIRTH}
              {...maskDate}
              {...getPropsField({
                fieldName: YEAR_OF_BIRTH, props: restProps,
              })}
              required
            />
          </div>

          <div className="block-item">
            <Input
              label="Телефон"
              type="text"
              inputMode="tel"
              name={PHONE}
              {...maskPhone}
              {...getPropsField({
                fieldName: PHONE, props: restProps,
              })}
              disabled
            />
          </div>

          <div className="block-item">
            <InputEmail
              label="Электронная почта"
              type="text"
              inputMode="email"
              name={EMAIL}
              {...getPropsField({
                fieldName: EMAIL, props: restProps,
              })}
              disabled
            />
          </div>
        </div>
        <Button
          type="submit"
          className="default-btn block-button-save"
        >
          Сохранить
        </Button>
      </form>
    </>
  );
});
