import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { isEmpty } from 'src/lib/lodash';
import { getValueFromMask, maskPhone } from 'src/lib/mask';
import { $phone } from 'src/models/User';
import {
  sendCodeSigningApplicationFn,
} from 'src/models/Loan/Application';
import { sendYMGoalFx } from 'src/models/Statistic';
import { $isRegistrationPage } from 'src/models/Registration';
import { stepConfirmForm } from 'src/models/Loan/Application/Success';
import { CodeForm } from 'src/ui/components/Form/CodeForm';
import { CodeRepeat } from './children/CodeRepeat';
import 'src/ui/styles/ConfirmForm.css';

export const ConfirmForm = () => {
  const valuePhone = useStore($phone);
  const isRegistrationPage = useStore($isRegistrationPage);

  useEffect(() => {
    sendCodeSigningApplicationFn();
    if (isRegistrationPage) {
      sendYMGoalFx('REG_OFFER_GET_SMS_AGAIN');
    }
  }, []);

  if (isEmpty(valuePhone)) return <div />;

  const phone = getValueFromMask(valuePhone, maskPhone);
  return (
    <>
      <h3 className="title">
        Для получения займа введите проверочный код из SMS
      </h3>

      <div className="info">
        <p>{`На ваш телефон ${phone} был отправлен проверочный код, введите его в поле ниже`}</p>
      </div>

      <CodeForm form={stepConfirmForm} />

      <CodeRepeat
        sendCodeFn={sendCodeSigningApplicationFn}
        ym={(isRegistrationPage ? 'REG_OFFER_GET_SMS_AGAIN' : '')}
      />
    </>
  );
};
