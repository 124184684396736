import React from 'react';
import { useStore } from 'effector-react';
import { isEmpty, has, get } from 'src/lib/lodash';
import { $loaderButton } from 'src/models/Helper/Loader';
import { Button } from './Button/Button';

export const ButtonWithLoader = React.memo(({
  children, ...rest
}) => {
  const loaderButton = useStore($loaderButton);
  const nameClasses = [];

  if (!isEmpty(loaderButton)) {
    nameClasses.push('default-btn-load');
  }

  if (!isEmpty(rest) && has(rest, 'className')) {
    nameClasses.push(get(rest, 'className', ''));
  }

  if (!isEmpty(rest) && has(rest, 'size')) {
    nameClasses.push(get(rest, 'size', ''));
  }

  const props = { ...rest, className: nameClasses.join(' ') };
  const content = !isEmpty(loaderButton)
    ? (
      <div className="default-btn-loadBlock">
        <span className="circle-line circle-line1" />
        <span className="circle circle1" />
        <span className="circle circle2" />
        <span className="circle circle3" />
      </div>
    ) : children;

  return (
    <>
      <Button {...props}>
        {content}
      </Button>
    </>
  );
});
