import { createForm } from 'effector-forms';
import { get } from 'src/lib/lodash';
import { isCurrentPath } from 'src/lib/url';
import { rules } from 'src/lib/rules';
import { REG_PATH } from 'src/dict/path';
import { REG_STEP4_FIELDS } from 'src/dict/fields';
import { $pathnameUrl } from 'src/models/App';
import { registrationDomain } from '../index';

// STEP 4 - образование

/// ///////////////////////////////////////////////////////////
// ================== СЛОВАРИ / КОНСТАНТЫ ================== //
/// ///////////////////////////////////////////////////////////

const { EXPERIENCE } = REG_PATH;

const {
  EDUCATION, FAMILY_STATUS, ACTIVITY_TYPE, SCOPE_EMPLOYMENT, POSITION,
  PLACE_WORK, PHONE_WORK, INCOME_PER_MONTH, COST_LOANS_PER_MONTH, EXISTENCE_BANKRUPTCY_CASE,
} = REG_STEP4_FIELDS;

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ СОБЫТИЙ ===================== //
/// ///////////////////////////////////////////////////////////

export const openPageExperienceFn = registrationDomain.createEvent('openPageExperienceFn');

export const addExperienceDataFn = registrationDomain.createEvent('addExperienceDataFn');

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ХРАНИЛИЦ ==================== //
/// ///////////////////////////////////////////////////////////

export const $activityType = registrationDomain.createStore('', { name: 'activityType' });

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ЭФФЕКТОВ ==================== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

export const $isExperiencePage = $pathnameUrl.map((path) => isCurrentPath(path, EXPERIENCE));

export const $isNotWorkActivityType = $activityType.map((obj) => [
  'студент', 'пенсионер', 'безработный',
].includes(get(obj, 'label', '').toLowerCase()));

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ЭФФЕКТОВ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

// тут создаются эффекты через attach

/// ///////////////////////////////////////////////////////////
// ====================== ЮНИТЫ ФОРМ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем формы с помощью effector-forms

export const step4Form = createForm({
  fields: {
    // Образование
    [EDUCATION]: { rules: [rules.required()], init: '' },
    // Семейное положение
    [FAMILY_STATUS]: { rules: [rules.required()], init: '' },
    // Тип деятельности ->  Тип трудоустройства
    [ACTIVITY_TYPE]: { rules: [rules.required()], init: '', units: { $value: $activityType } },

    // Сфера трудоустройства
    [SCOPE_EMPLOYMENT]: {
      rules: [
        rules.requiredIsEmptySource($isNotWorkActivityType),
      ],
      init: '',
    },
    // Должность
    [POSITION]: {
      rules: [
        rules.requiredIsEmptySource($isNotWorkActivityType),
        rules.positionIsEmptySource($isNotWorkActivityType),
      ],
      init: '',
    },
    // Место работы
    [PLACE_WORK]: {
      rules: [
        rules.requiredIsEmptySource($isNotWorkActivityType),
        // rules.positionIsEmptySource($isNotWorkActivityType),
      ],
      init: '',
    },
    // Рабочий телефон
    [PHONE_WORK]: {
      rules: [
        rules.requiredIsEmptySource($isNotWorkActivityType),
        rules.phoneIsEmptySource($isNotWorkActivityType),
      ],
      init: '',
    },

    // Доходы в месяц
    [INCOME_PER_MONTH]: { rules: [rules.required(), rules.number()], init: '' },
    // Расходы на кредиты в месяц
    [COST_LOANS_PER_MONTH]: { rules: [rules.required(), rules.number()], init: '' },
    // Наличие дела о банкротстве
    [EXISTENCE_BANKRUPTCY_CASE]: { init: false },
  },
  validateOn: ['blur', 'submit'],
  domain: registrationDomain,
});

/// ///////////////////////////////////////////////////////////
//  ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ФОРМ + (ХРАНИЛИЩ, ЭФФЕКТОВ) //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ========================= ФАБРИКИ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем различные фабрики и хранилища с использованием фабрик
