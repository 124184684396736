import { isEmpty } from './lodash';

export const getLastYear = () => {
  const date = new Date();
  return date.getFullYear();
};

const unitDay = {
  zero: 'дней',
  one: 'день',
  two: 'дня',
};

export const getUnitDay = (val) => {
  if (isEmpty(val)) return '';
  const lastNum = Number.parseInt(`${val}`.slice(-1), 10);
  let unit = 'one';
  if ([0, 5, 6, 7, 8, 9].includes(lastNum) || (val > 9 && val < 21)) {
    unit = 'zero';
  } else if ([2, 3, 4].includes(lastNum)) {
    unit = 'two';
  }
  return unitDay[unit];
};

export const getDateInterval = (val) => {
  const date = new Date();
  date.setDate(date.getDate() + val);
  return date.toLocaleDateString('ru-RU');
};

export const getDateReverse = (date) => {
  if (isEmpty(date)) return '';
  const arrDate = date.split('-');
  return `${arrDate[2]}.${arrDate[1]}.${arrDate[0]}`;
};

export const getDate = (val) => {
  if (isEmpty(val)) return val;
  const date = new Date(val);
  const arrDate = date.toISOString().split('T');
  return getDateReverse(arrDate[0]);
};

export const getDateCard = (val) => {
  if (isEmpty(val)) return val;
  const date = new Date(val);
  const arrDate = date.toISOString().split('T');
  const arrVal = arrDate[0].split('-');
  return arrVal.length === 3 ? `ДО ${arrVal[1]}/${arrVal[0].slice(-2)}` : val;
};

export const getCurrentDate = () => getDate(new Date());

export const handlerDelayRun = (fn, time) => setTimeout(fn, time);

export const isValidPassportDate = (date, birthDate) => {
  const arrDate = date.split('.');
  const passportDate = `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}`;
  const now = new Date();
  // дата рождения
  const objBirthDate = new Date(birthDate);
  // кол-во лет
  const diff = new Date(now.getTime() - objBirthDate.getTime());
  const age = diff.getUTCFullYear() - 1970;
  // дата выдачи
  const objPassportDate = new Date(passportDate);
  // если меньше 14 нах
  if (age < 14) {
    return false;
  }

  now.setFullYear(now.getFullYear());
  // если дата выдачи больше завтрашнего дня
  if (objPassportDate.getTime() >= now.getTime()) {
    return false;
  }
  objBirthDate.setFullYear((objBirthDate.getFullYear() + 14));
  // если < 20 лет и дата выдачи больше или равна даты рождения + 14 лет
  if (age < 20 && objPassportDate.getTime() >= objBirthDate.getTime()) {
    return true;
  }
  objBirthDate.setFullYear((objBirthDate.getFullYear() + 6));
  // если < 45 лет и дата выдачи больше или равна даты рождения + 20 лет
  if (age < 45 && objPassportDate.getTime() >= objBirthDate.getTime()) {
    return true;
  }
  objBirthDate.setFullYear((objBirthDate.getFullYear() + 25));
  // если Ю= 45 лет и дата выдачи больше или равна даты рождения + 45 лет
  if (age >= 45 && objPassportDate.getTime() >= objBirthDate.getTime()) {
    return true;
  }
  return false;
};

export const isValidBirthDate = (date) => {
  // дата рождения
  const arrDate = date.split('.');
  const birthDate = `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}`;
  const objBirthDate = new Date(birthDate);

  const min = new Date(1920, 0, 1);
  // const max = new Date((getLastYear() - 18), 0, 1);
  const max = new Date();
  max.setFullYear((max.getFullYear() - 18));

  // если дата выдачи больше завтрашнего дня
  if (objBirthDate.getTime() < min.getTime()) {
    return false;
  }

  // если < 20 лет и дата выдачи больше или равна даты рождения + 14 лет
  if (objBirthDate.getTime() > max.getTime()) {
    return false;
  }

  return true;
};

export const isValidRegDate = (date) => {
  // дата рождения
  const arrDate = date.split('.');
  const birthDate = `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}`;
  const objBirthDate = new Date(birthDate);

  const min = new Date(1905, 0, 1);
  // const max = new Date((getLastYear() - 18), 0, 1);
  const max = new Date();

  if (objBirthDate.getTime() < min.getTime()) {
    return false;
  }

  if (objBirthDate.getTime() > max.getTime()) {
    return false;
  }

  return true;
};

export const isValidDateCard = (value) => {
  const [month, year] = value.split('/');
  const yearCard = Number.parseInt(`20${year}`, 10);
  const monthCard = Number.parseInt(month.replace(/^0+/, ''), 10);

  const max = new Date();
  const currentYear = max.getFullYear();
  const currentMonth = max.getMonth() + 1;

  return (currentYear < yearCard)
    || (currentYear === yearCard && currentMonth < monthCard);
};

export const getCurrentTime = () => {
  const date = new Date();
  // return date.toLocaleDateString('ru-RU');
  return date.toLocaleTimeString('ru-RU');
};

export const formatISODate = (dateString) => {
  if (isEmpty(dateString)) return '';
  const date = new Date(dateString);

  const val = Intl.DateTimeFormat('ru-Ru', {
    hour: 'numeric',
    minute: 'numeric',
    weekday: 'short',
    // year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(date);
  const arr = val.split(',');
  return arr.reverse().join(', ');
};

export const formatISODateLoan = (dateString) => {
  if (isEmpty(dateString)) return '';
  const date = new Date(dateString);

  const val = Intl.DateTimeFormat('ru-Ru', {
    hour: 'numeric',
    minute: 'numeric',
    // weekday: 'short',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(date);
  const arr = val.split(',');
  return arr.reverse().join(', ');
};

export const diffDate = (date1, date2) => {
  const x = new Date(date1);
  const y = new Date(date2);

  // seconds = milliseconds/1000
  // minutes = seconds/60
  // hours = minutes/60
  // Days = hours/24

  return Math.floor((x - y) / (1000 * 60 * 60 * 24));
};

export const formatOtherDate = (dateString) => {
  const d = new Date(dateString);
  const date = d.toLocaleString('ru', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return date.replace(' г.', '');
};
