export const USER_FIELDS = {
  ID: 'id',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  MIDDLE_NAME: 'middle_name',
  YEAR_OF_BIRTH: 'birth_date',
  GENDER: 'gender',
  EMAIL: 'email',
  PHONE: 'phone',
};

export const USER_FORM = {
  PERSONAL: 'personal',
  PASSPORT: 'passport',
  ADDRESS_REG: 'registration',
  ADDRESS_ACT: 'residence',
};
