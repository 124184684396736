import React from 'react';

export const Trust = React.memo(() => (
  <section className="trustSection">
    <div className="wrapper">
      <div className="content">
        <div className="bgTop">
          <img src="/img/main/trust/bgTop.svg" alt="" />
        </div>
        <div className="bgTop mobile">
          <img src="/img/main/trust/bgTopMobile.svg" alt="" />
        </div>
        <div className="mainBlock">
          <div className="info">
            <h2 className="title">
              Мы вам доверяем
            </h2>
            <div className="items">
              <div className="left">
                <div className="item">
                  <div className="item-num">01</div>
                  <div className="item-text">
                    Мы не беспокоим вас звонками, система работает в автоматическом режиме
                  </div>
                </div>
                <div className="item">
                  <div className="item-num">02</div>
                  <div className="item-text">
                    У нас простые и прозрачные условия сотрудничества
                  </div>
                </div>
              </div>
              <div className="image">
                <img src="/img/main/trust/image.png" alt="" />
              </div>
              <div className="right">
                <div className="item">
                  <div className="item-num">03</div>
                  <div className="item-text">
                    Удобный личный кабинет, в котором всегда можно посмотреть график платежей
                  </div>
                </div>
                <div className="item">
                  <div className="item-num">04</div>
                  <div className="item-text">
                    Максимально быстрая выдача денег
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bgBottom">
          <img src="/img/main/trust/bgBottom.svg" alt="" />
        </div>
        <div className="bgBottom mobile">
          <img src="/img/main/trust/bgBottomMobile.svg" alt="" />
        </div>
      </div>
    </div>
  </section>
));
