import {
  attach, sample, split, restore,
} from 'effector';
import { debounce } from 'patronum/debounce';
import { combineEvents } from 'patronum/combine-events';
import { spread } from 'patronum/spread';
import { get, isEmpty } from 'src/lib/lodash';
import { isCurrentPath } from 'src/lib/url';
import { storage } from 'src/lib/storage';
import { PAGES_PATH } from 'src/dict/path';
// import { storage } from 'src/lib/storage';
import { LS } from 'src/dict/config';
import { PAYMENT_CONTEXT } from 'src/dict/payment';
import { $pathnameUrl, mountAppFn } from 'src/models/App';
import {
  goPaymentPageFn, sendStatisticUtmFn,
  $applicationId, $isCardsNewLoanPage,
} from 'src/models/Loan/Application';
import { $userId } from 'src/models/User';
import { goLoginPageFn } from 'src/models/Auth';
import {
  // goAgreementPaymentCardsPageFn,
  $isCardsToRepayPage, $agreementToRepayId, $repaymentIntentItem, $amountToRepay, sendToRepayFn,

} from 'src/models/Loan/Agreement';
import {
  showLoaderNotTimePageFn, hideLoaderNotTimePageFn,
} from 'src/models/Helper/Loader';
import { closeModalFn, openModalFn } from 'src/models/components/Modal';
import { notifyErrorFn, notifySuccessFn } from 'src/models/Helper/Notification';
import { storageRemoveFn, storageSetFn } from 'src/models/Helper/Storage';
import {
  goCardsPageFn,
  $isProfileCardsPage,
} from 'src/models/Profile';
import { Iframe } from 'src/ui/components';
import {
  // события
  startBindCardBankFn, addCardBankFn, removeBindCardFn,
  bindPaymentToAppFn,
  getFailBindCardBankFn,
  openIframeAddCardBankFn, removeBindCardBankFn,
  savePaymentContextBindCardFn, afterSuccessBindCardFn,
  // хранилище
  $processId, $itemsPayment, $isCardsLoanPage,
  // эффекты
  bindPaymentToAppFx,
  startBindCardBankFx,
  getItemsPaymentFx,
  getBindCardBankFx,
  timerGetBindCardBankFx, removeBindCardBankFx, getItemsPaymentFn, goBindCardsIfFailFn,
} from './index';

/* const { APP } = LS;
const {
  AMOUNT, PERIOD,
  PURPOSE_ID,
} = APPLICATION_FIELDS; */

/// ///////////////////////////////////////////////////////////
// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
/// ///////////////////////////////////////////////////////////

// Процесс привязки карты
$processId
  .on(startBindCardBankFx.doneData, (_, result) => get(result, 'data.id', ''));

$itemsPayment
  .on(getItemsPaymentFx.doneData, (_, result) => get(result, 'data', ''));

/// ///////////////////////////////////////////////////////////
// ===================== БЛОК ОПЕРАТОРОВ =================== //
/// ///////////////////////////////////////////////////////////

// Запуск привязк карты осуществляется только если карт нет, но не на странице
sample({
  clock: getItemsPaymentFx.doneData,
  source: $isProfileCardsPage,
  // если карт нет - вызываем сразу добавление
  filter: (isProfileCardsPage, result) => isEmpty(isProfileCardsPage) && isEmpty(get(result, 'data', '')),
  target: [
    startBindCardBankFn,
  ],
});

// стартуем событие привязки карты
sample({
  clock: [
    debounce({ source: startBindCardBankFn, timeout: 500 }),
    debounce({ source: addCardBankFn, timeout: 500 }),
  ],
  // ставим ограничение - добавление карт допустимо только на страницах: нового займа и погашения
  filter: $isCardsLoanPage,
  target: [
    // посылаем utm метки
    sendStatisticUtmFn,
    // послылаем запрос на получения id привязки
    startBindCardBankFx,
    // показываем лоадер
    showLoaderNotTimePageFn,
  ],
});

/* после инициализации процесса привязки карты
сохраняем данные в LS чтобы понимать контекст происходящего */
sample({
  clock: startBindCardBankFx.doneData,
  source: [
    $applicationId,
    $agreementToRepayId,
    $repaymentIntentItem,
    $amountToRepay,
  ],
  fn: ([appId, agreementId, repaymentIntentItem, amountToRepay], data) => ({
    processId: get(data, 'data.id', ''),
    applicationId: appId,
    agreementToRepayId: agreementId,
    amountToRepay,
    repaymentIntentItem,
  }),
  target: [
    // тригерим сохранение контекста привязки котекста в LS
    savePaymentContextBindCardFn,
    // запускаем таймер для пулинга
    timerGetBindCardBankFx.prepend(() => 2000),
  ],
});

// Сохраняем в LS информацию о том что мы сейчас привязываем карту в контексте полученния/погашения кредита
sample({
  clock: savePaymentContextBindCardFn,
  source: [$isCardsToRepayPage, $isCardsNewLoanPage],
  fn: ([isCardsToRepayPage, isCardsNewLoanPage],
    {
      processId, applicationId, agreementToRepayId, repaymentIntentItem, amountToRepay,
    }) => {
    const context = isCardsNewLoanPage ? PAYMENT_CONTEXT.ISSUANCE_CREDIT
      : (isCardsToRepayPage ? PAYMENT_CONTEXT.TO_REPAY : '');
    return ({
      [LS.PAYMENT_CONTEXT]: {
        context,
        params: {
          processId,
          applicationId,
          agreementToRepayId,
          amountToRepay,
          repaymentIntentItem,
        },
      },
    });
  },
  target: storageSetFn,
});

// После того таймер отработает проверяем запускаем проверку статуса привязки
sample({
  clock: timerGetBindCardBankFx.done,
  source: $processId,
  fn: (processId) => processId,
  target: [
    getBindCardBankFx,
  ],
});

// Смотрим полученный статус привязки и в зависимости от значения выполняем соответствующие события / эффекты
split({
  source: sample(
    [restore(getBindCardBankFx.doneData, {}), $pathnameUrl],
    getBindCardBankFx.doneData,
  ),
  match: {
    // если статус ждать - вновь запускаем таймер
    wait: ([{ data: { status } }]) => status === 'waiting_url',
    // если статус ошибка - вызываем ошибку
    error: ([{ data: { status } }]) => status === 'error',
    // если статус норм - запускаем алгоритм дальше
    openIframe: ([{ data: { status, card_binding_url: cardBindingUrl } }, pathnameUrl]) => (
      !isEmpty(status) && status === 'url_received'
        && !isEmpty(cardBindingUrl)
        && !isCurrentPath(pathnameUrl, PAGES_PATH.BIND)
    ),
    // если статус "гуд" и мы на странице куда нас редиректнул сервер
    success: (dataValue) => {
      const [{ data: { status } }] = dataValue;
      return (
        status === 'success' // && isCurrentPath(pathnameUrl, PAGES_PATH.BIND)
      );
    },
    // если статус "гуд" и мы на странице куда нас редиректнул сервер
    synonymReceived: ([{ data: { status } }]) => status === 'synonym_received',
  },
  cases: {
    // запускаем таймер еще раз
    wait: [
      timerGetBindCardBankFx.prepend(() => 2000),
    ],
    // тригеррим событие ошибки привязки карты
    error: getFailBindCardBankFn,
    // открываем iframe
    openIframe: openIframeAddCardBankFn.prepend(([data]) => data),
    // связываем card_id с завкой + редирект
    success: [
      afterSuccessBindCardFn.prepend(([{ data }]) => ({
        paymentId: get(data, 'card_id', ''),
        paymentType: 'bank_card',
      })),
      // выдаем сообщение об успешности
      notifySuccessFn.prepend(() => 'Банковская карта успешно была привязана'),
    ],
    synonymReceived: [
      timerGetBindCardBankFx.prepend(() => 2000),
    ],
    __: [
      timerGetBindCardBankFx.prepend(() => 2000),
    ],
  },
});

split({
  source: sample(restore(afterSuccessBindCardFn, {}), afterSuccessBindCardFn),
  match: {
    newLoan: () => {
      const obj = storage.get(LS.PAYMENT_CONTEXT);
      // eslint-disable-next-line max-len
      return !isEmpty(obj) && get(obj, 'context') === PAYMENT_CONTEXT.ISSUANCE_CREDIT;
    },
    toRepay: () => {
      const obj = storage.get(LS.PAYMENT_CONTEXT);
      return !isEmpty(obj) && get(obj, 'context') === PAYMENT_CONTEXT.TO_REPAY;
    },
  },
  cases: {
    newLoan: [
      // тригеррим событие для привязки данных проццесса с текущей заявкой
      bindPaymentToAppFn.prepend((data) => data),
      // удаляем контекст процесса
      removeBindCardFn,
      //
    ],
    toRepay: [
      // редиректим на страницу выбора карт для погашения
      /* goAgreementPaymentCardsPageFn.prepend(() => {
        const obj = storage.get(LS.PAYMENT_CONTEXT);
        const agreementToRepayId = get(obj, 'params.agreementToRepayId', '');
        if (!isEmpty(agreementToRepayId)) {
          storageSetFn({
            [LS.PAYMENT_CONTEXT]: {
              params: { ...get(storage.get(LS.PAYMENT_CONTEXT), 'params', {}) },
              context: '',
            },
          });
        }
        return agreementToRepayId;
      }), */

      notifySuccessFn.prepend(() => 'Проводим погашение по займу с привязанной банковской карты'),
      // списываем деньги с только что привязанной карты
      /* В аргументах передаются через afterSuccessBindCardFn объкт с paymentId и paymentType */
      sendToRepayFn,
    ],
    __: [
      goCardsPageFn,
      hideLoaderNotTimePageFn,
    ],
  },
});

// Открываем фрейм где будем вводить данные карты для привязки
sample({
  clock: openIframeAddCardBankFn,
  /* clock: sample({
    clock: openIframeAddCardBankFn,
    filter: $isCardsLoanPage,
  }), */
  fn: ({ data }) => data,
  target: [
    hideLoaderNotTimePageFn,
    openModalFn.prepend(({
      card_binding_url: cardBindingUrl, // client_id: clientId, id,
    }) => ({
      className: 'card-bank-iframe',
      content: Iframe,
      importantOperation: true,
      isBottom: true,
      style: { content: { maxWidth: '100%' } },
      props: {
        link: cardBindingUrl,
        id: 'cardBankIframe',
        title: 'Card Bank',
        classNameNotContent: 'notIframe',
      },
      // указываем свое событие на закрытие модального окна
      closeCallback: closeModalFn,
    })),
  ],
});

sample({
  clock: getBindCardBankFx.fail,
  target: getFailBindCardBankFn,
});

sample({
  clock: getFailBindCardBankFn,
  target: [
    // удаляем данные о платежном контексте
    // storageRemoveFn.prepend(() => [LS.PAYMENT_CONTEXT]),
    // редиректим куда надо
    goBindCardsIfFailFn,
    // редиректим на способы получения займа
    // goPaymentPageFn,

    /* notifyErrorFn.prepend(
      (error) => get(error, '0.data.error_reason',
        get(error, '1.data.error_reason',
          'При попытке привязки банковской карты возникла ошибка, пожалуйста, попробуйте еще раз')),
    ), */
    notifyErrorFn.prepend(
      () => 'Ошибка при привязке банковской карты! Пожалуйста, проверьте реквизиты карты или попробуйте другую',
    ),
    hideLoaderNotTimePageFn,
  ],
});

split({
  source: sample(restore(goBindCardsIfFailFn, {}), goBindCardsIfFailFn),
  match: {
    loan: () => {
      const obj = storage.get(LS.PAYMENT_CONTEXT);
      return !isEmpty(obj) && (
        get(obj, 'context') === PAYMENT_CONTEXT.ISSUANCE_CREDIT
        || get(obj, 'context') === PAYMENT_CONTEXT.TO_REPAY);
    },
  },
  cases: {
    loan: [
      // редиректим на способы получения займа
      goPaymentPageFn,
      // удаляем данные о платежном контексте
      removeBindCardFn,
    ],
    __: [
      goCardsPageFn,
      // удаляем данные о платежном контексте
      removeBindCardFn,
    ],
  },
});

// Удаляем данные о платежном контексте
sample({
  clock: debounce({
    source: removeBindCardFn,
    timeout: 700,
  }),
  target: [
    // удаляем данные о платежном контексте
    storageRemoveFn.prepend(() => [LS.PAYMENT_CONTEXT]),
  ],
});

sample({
  clock: sample({
    clock: mountAppFn,
    source: $pathnameUrl,
    filter: (pathnameUrl) => isCurrentPath(pathnameUrl, PAGES_PATH.BIND),
  }),
  target: showLoaderNotTimePageFn,
});

split({
  source: sample(
    [$userId, $pathnameUrl],
    combineEvents({
      events: {
        $pathnameUrl,
        $userId,
      },
    }),
  ),
  match: {
    cardFail: ([userId, pathnameUrl]) => {
      const processId = get(storage.get(LS.PAYMENT_CONTEXT), 'params.processId', '');
      return isCurrentPath(pathnameUrl, PAGES_PATH.BIND) && isEmpty(processId) && !isEmpty(userId);
    },
    toHome: ([userId, pathnameUrl]) => isCurrentPath(pathnameUrl, PAGES_PATH.BIND) && isEmpty(userId),
  },
  cases: {
    cardFail: goBindCardsIfFailFn,
    toHome: goLoginPageFn,
  },
});

// при редиректе с бека после ввода данных в форму добавления карты
sample({
  clock: combineEvents({
    events: {
      $pathnameUrl,
      $userId,
    },
  }),
  source: [$userId, $pathnameUrl],
  filter: ([userId, pathnameUrl]) => {
    const processId = get(storage.get(LS.PAYMENT_CONTEXT), 'params.processId', '');
    // eslint-disable-next-line max-len
    // const arrPath = pathnameUrl.split('/');
    return isCurrentPath(pathnameUrl, PAGES_PATH.BIND)
      && !isEmpty(processId)
      && !isEmpty(userId);
  },
  fn: () => ({
    processId: get(storage.get(LS.PAYMENT_CONTEXT), 'params.processId', ''),
    applicationId: get(storage.get(LS.PAYMENT_CONTEXT), 'params.applicationId', ''),
    repaymentIntentItem: get(storage.get(LS.PAYMENT_CONTEXT), 'params.repaymentIntentItem', {}),
    amountToRepay: get(storage.get(LS.PAYMENT_CONTEXT), 'params.amountToRepay', ''),
  }),
  target: [
    spread({
      targets: {
        processId: $processId,
        applicationId: $applicationId,
        repaymentIntentItem: $repaymentIntentItem,
        amountToRepay: $amountToRepay,
      },
    }),
    timerGetBindCardBankFx.prepend(() => 1000),
  ],
});

/* при перезагрузке страницы когда происходит погашение - достаем из LS данные для погашения
  если вообще это надо */
sample({
  clock: mountAppFn,
  source: $repaymentIntentItem,
  filter: (repaymentIntentItem) => (
    isEmpty(repaymentIntentItem)
        && !isEmpty(get(storage.get(LS.PAYMENT_CONTEXT), 'params.repaymentIntentItem', {}))
  ),
  fn: () => ({
    repaymentIntentItem: get(storage.get(LS.PAYMENT_CONTEXT), 'params.repaymentIntentItem', {}),
    amountToRepay: get(storage.get(LS.PAYMENT_CONTEXT), 'params.amountToRepay', ''),
  }),
  target: [
    spread({
      targets: {
        repaymentIntentItem: $repaymentIntentItem,
        amountToRepay: $amountToRepay,
      },
    }),
  ],
});

// Cобытие для привязки данных проццесса с текущей заявкой
// при bindPaymentToAppFx.done -> /models/Loan/Application - непонятно нужно ли этот момент вообще обрабатывать
sample({
  clock: bindPaymentToAppFn,
  target: [
    attach({
      effect: bindPaymentToAppFx,
      source: $applicationId,
      mapParams: ({ paymentId, paymentType }, applicationId) => ({
        applicationId,
        data: {
          paymentable_type: paymentType,
          paymentable_id: paymentId,
        },
      }),
    }),
  ],
});

// Ошибка привязки данных проццесса с текущей заявкой
sample({
  clock: bindPaymentToAppFx.fail,
  target: [
    notifyErrorFn.prepend((data) => {
      const message = get(data, 'error.response.data.message', {});
      if (isEmpty(message)) {
        return 'Cтатус заявки не позволяет привязать карту';
      }
      return message;
    }),
  ],
});

sample({
  clock: removeBindCardBankFn,
  target: removeBindCardBankFx,
});

// НЕТ обновления спсика карт - проверить нужно ли перезапрашивать список карт
sample({
  clock: removeBindCardBankFx.done,
  target: [
    notifySuccessFn.prepend(() => 'Банковская карта удалена'),
    getItemsPaymentFn,
  ],
});

sample({
  clock: removeBindCardBankFx.fail,
  target: notifyErrorFn.prepend(() => 'При удалении карты произошла ошибка'),
});
