import React from 'react';
import { Button } from 'src/ui/components/Form/Buttons/Button/Button';

export const RepayPaymentError = React.memo(({ closeCallback, successCallback }) => (
  <div className="modal-main to-loan">

    <button
      type="button"
      className="close-modal__btn close-modal"
      onClick={closeCallback}
    >
      <img src="/img/modal/close.svg" alt="" />
    </button>
    <div className="modal-wrapper">
      <h2 className="modal-title">Ошибка при оплате</h2>

      <div className="sub-title">
        При попытке оплаты возникла ошибка.
        Пожалуйста, проверьте баланс на вашем счете, проверьте указанные реквизиты и попробуйте еще раз!
      </div>

      <Button
        type="button"
        className="nextStep"
        onClick={successCallback}
      >
        Хорошо
      </Button>
    </div>
  </div>
));
