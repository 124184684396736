import React, { useEffect } from 'react';
import { useUnit } from 'effector-react';
import { isEmpty } from 'src/lib/lodash';
import { getFormatIntMoney, getFormatMoney } from 'src/lib/money';
import {
  bindCalcAmountFn, closeCalculatorPageFn,
  $calcCombineData,
} from 'src/models/Calculator';
import { GetMoneyButton } from 'src/ui/components/Form';
import { EsiaBloockHome } from 'src/ui/components/Block/EsiaBloockHome';
import 'nouislider/dist/nouislider.css';

export const CalculatorHome = React.memo(() => {
  const {
    amountMin, amountMax, amount, amountRefund, amountGraceRefund,
  } = useUnit($calcCombineData);

  useEffect(() => {
    bindCalcAmountFn(document.querySelector('.calculator-slider'));
    return closeCalculatorPageFn;
  }, []);

  // console.log('amountGraceRefund:', amountGraceRefund);
  return (
    <div className="calculator">
      <div className="calculator-wrapper">
        <div className="calculator-title">
          Первый займ
          {' '}
          <span className="darkred">бесплатно</span>
        </div>
        <p className="calculator-subtitle">
          При условии возврата займа в срок
          {/* <span className="term">срок</span> */}
        </p>
        <div className="calculator-top">
          <p className="calculator-text">Сумма займа</p>
          <div className="calculator-sum">
            <span className="sum">{getFormatMoney(amount)}</span>
            {' '}
            <span className="cur">₽</span>
          </div>
        </div>

        <div className="calculator-slider__block">
          <div
            className="calculator-slider"
            data-min={amountMin}
            data-max={amountMax}
            data-start={amount}
          />
        </div>
        <div className="calculator-description">
          <p>Вы вернете</p>
          <div className="calculator-sums">
            {!isEmpty(amountRefund) && (
            <p className="calculator-oldSum">
              <span className="sum">{getFormatIntMoney(amountRefund)}</span>
              {' '}
              <span className="cur">₽</span>
            </p>
            )}
            <p className="calculator-totalSum">
              <span className="sum">{getFormatIntMoney(amountGraceRefund)}</span>
              <span className="cur"> ₽</span>
            </p>

          </div>
        </div>
        <div className="calculator-btn">
          <GetMoneyButton size="big" ym="GET_MONEY_LANDING_TOP_BT">Получить бесплатно</GetMoneyButton>
        </div>

        <EsiaBloockHome />
      </div>
    </div>
  );
});
