import React from 'react';

const Nav = React.memo(() => (
  <nav className="header-navigation">
    <ul className="header-navigation__list">
      <li className="header-navigation__item"><a href="#how">О нас</a></li>
      <li className="header-navigation__item"><a href="#why">Почему мы</a></li>
      <li className="header-navigation__item"><a href="#reviews">Отзывы</a></li>
    </ul>
  </nav>
));

export { Nav };
