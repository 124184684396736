import React, { useEffect } from 'react';
import { useUnit } from 'effector-react';
import { useForm } from 'effector-forms';
import { get, isEmpty } from 'src/lib/lodash';
import {
  getPropsField, getEventsField, getRefField, jumpFocusField, onSubmit,
} from 'src/lib/form';
import { maskLetter } from 'src/lib/mask';
import { maskDate, maskPhone } from 'src/lib/maskPlaceholder';
import { REG_STEP1_FIELDS } from 'src/dict/fields';
import { daData } from 'src/dict/config';
import {
  setRefFieldsFn, jumpFocusFieldFn, jumpFocusFn,
} from 'src/models/Helper/Ref';
import { getKeyboardLayoutFn } from 'src/models/Helper/Keyboard';
import { step1Form } from 'src/models/Registration/Personal';

import { $dictCombineData } from 'src/models/Dictionaries';
import { getDocsFn } from 'src/models/Documents';
import { openModalFn } from 'src/models/components/Modal';
import { EsiaBloockReg } from 'src/ui/components/Block/EsiaBloockReg';
import {
  Input, InputDate, InputName, InputEmail, Checkbox, Gender,
  // Select,
  SelectPosAbsolute as Select,
  ButtonWithLoader as Button,
} from 'src/ui/components/Form';
import { Agreement } from './children/Agreement/Agreement';

const {
  PHONE, FIRST_NAME, LAST_NAME, MIDDLE_NAME, IS_NOT_MIDDLE_NAME,
  YEAR_OF_BIRTH, PURPOSE, EMAIL, IS_NOT_EMAIL, GENDER, AGREEMENT,
} = REG_STEP1_FIELDS;

const { SURNAME, NAME, PATRONYMIC } = daData.NAME.FILTER_PARTS;

export const PersonalForm = React.memo(() => {
  // Загружаем док-ты согдашения
  useEffect(() => {
    getDocsFn();
  }, []);

  const {
    submit, eachValid, ...restProps
  } = useForm(step1Form);

  const [{ purposesDict }, isNotMiddleName, isNotEmail] = useUnit([
    $dictCombineData, step1Form.fields[IS_NOT_MIDDLE_NAME].$value, step1Form.fields[IS_NOT_EMAIL].$value,
  ]);
  // console.log('purposesDict:', purposesDict);
  const agreementErrorText = get(restProps, 'errorText', () => '')(AGREEMENT);
  const agreementHasError = get(restProps, 'hasError', () => '')(AGREEMENT);
  const classCheckValid = !isEmpty(agreementHasError) ? ' invalid' : '';

  return (
    <>
      <EsiaBloockReg />

      <form autoComplete="off" onSubmit={onSubmit(submit)}>

        <div className="block-item">
          <Input
            label="Телефон"
            type="text"
            inputMode="tel"
            name={PHONE}
            {...maskPhone}
            {...getPropsField({
              fieldName: PHONE, props: restProps,
            })}
            ref={getRefField({
              fieldName: PHONE, callBack: setRefFieldsFn,
            })}
            jumpField={jumpFocusField({
              fieldName: LAST_NAME,
              callBack: jumpFocusFieldFn,
              trigger: { name: 'maxLength', data: { value: 18 } },
            })}
            required
          />
        </div>

        <div className="block-item">
          <InputName
            filterParts={SURNAME}
            label="Фамилия"
            name={LAST_NAME}
            {...getPropsField({
              fieldName: LAST_NAME, props: restProps, mask: maskLetter,
            })}
            ref={getRefField({
              fieldName: LAST_NAME, callBack: setRefFieldsFn,
            })}
            jumpField={jumpFocusField({
              fieldName: FIRST_NAME,
              callBack: jumpFocusFieldFn,
            })}
            onInput={getKeyboardLayoutFn.prepend((e) => ({
              field: get(restProps, `fields.${LAST_NAME}`),
              value: e.target.value,
            }))}
            required
          />
        </div>

        <div className="block-item">
          <InputName
            filterParts={NAME}
            label="Имя"
            name={FIRST_NAME}
            {...getPropsField({
              fieldName: FIRST_NAME, props: restProps, mask: maskLetter,
            })}
            ref={getRefField({
              fieldName: FIRST_NAME, callBack: setRefFieldsFn,
            })}
            jumpField={jumpFocusField({
              fieldName: (!isNotMiddleName ? MIDDLE_NAME : YEAR_OF_BIRTH),
              callBack: jumpFocusFieldFn,
            })}
            onInput={getKeyboardLayoutFn.prepend((e) => ({
              field: get(restProps, `fields.${FIRST_NAME}`),
              value: e.target.value,
            }))}
            required
          />
        </div>

        <div className="block-item">
          <InputName
            filterParts={PATRONYMIC}
            label="Отчество"
            name={MIDDLE_NAME}
            {...getPropsField({
              fieldName: MIDDLE_NAME, props: restProps, mask: maskLetter,
            })}
            ref={getRefField({
              fieldName: MIDDLE_NAME, callBack: setRefFieldsFn,
            })}
            jumpField={jumpFocusField({
              fieldName: YEAR_OF_BIRTH,
              callBack: jumpFocusFieldFn,
            })}
            onInput={getKeyboardLayoutFn.prepend((e) => ({
              field: get(restProps, `fields.${MIDDLE_NAME}`),
              value: e.target.value,
            }))}
            required={isEmpty(isNotMiddleName)}
            disabled={isNotMiddleName}
          />
        </div>

        <div className="block-item default-checkbox">
          <Checkbox
            label="Нет отчества"
            name={IS_NOT_MIDDLE_NAME}
            {...getEventsField(get(restProps.fields, IS_NOT_MIDDLE_NAME))}
          />
        </div>

        <div className="block-item">
          <InputDate
            label="Дата рождения"
            type="text  "
            inputMode="numeric"
            name={YEAR_OF_BIRTH}
            {...maskDate}
            {...getPropsField({
              fieldName: YEAR_OF_BIRTH, props: restProps,
            })}
            ref={getRefField({
              fieldName: YEAR_OF_BIRTH, callBack: setRefFieldsFn,
            })}
            required
          />
        </div>

        <div className="block-item block-gender">
          <Gender
            {...getPropsField({
              fieldName: GENDER, props: restProps,
            })}
          />
        </div>

        <div className="block-item">
          <InputEmail
            label="Email"
            type="text"
            inputMode="email"
            name={EMAIL}
            {...getPropsField({
              fieldName: EMAIL, props: restProps,
            })}
            ref={getRefField({
              fieldName: EMAIL, callBack: setRefFieldsFn,
            })}
            jumpField={jumpFocusField({
              fieldName: PURPOSE,
              callBack: jumpFocusFn,
            })}
            required={isEmpty(isNotEmail)}
            disabled={isNotEmail}
          />
        </div>

        <div className="block-item default-checkbox">
          <Checkbox
            label="Нет email"
            {...getEventsField(get(restProps.fields, IS_NOT_EMAIL))}
            name={IS_NOT_EMAIL}
          />
        </div>

        <div className="block-item">
          <Select
            label="Цель займа"
            placeholder=""
            options={purposesDict}
            name={PURPOSE}
            {...getPropsField({
              fieldName: PURPOSE, props: restProps,
            })}
            ref={getRefField({
              fieldName: PURPOSE, callBack: setRefFieldsFn,
            })}
            positionAbsolute={false}
            required
            disable
          />
        </div>

        <div className="block-item block-checkbox default-checkbox">
          <Checkbox
            name={AGREEMENT}
            {...getEventsField(get(restProps.fields, AGREEMENT))}
          />
          <label htmlFor={AGREEMENT} className="default-checkbox__label">
            <div className={`default-checkbox__block ${classCheckValid}`} />
            <p className="default-checkbox__text">
              Согласен с
              {' '}
              <button
                type="button"
                className="button-agreement"
                onClick={openModalFn.prepend(() => ({
                  importantOperation: true,
                  className: 'modal-agreement',
                  content: Agreement,
                }))}
              >
                условиями сервиса
              </button>
            </p>
          </label>

          {agreementHasError
          && !isEmpty(agreementErrorText)
          && <p className="invalid-text">{agreementErrorText}</p>}
        </div>

        <div className="block-button block-button__form">
          <Button
            type="submit"
            className="nextStep"
          >
            Далее
          </Button>
        </div>

      </form>
    </>
  );
});
