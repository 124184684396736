import React, { useEffect } from 'react';
import { useUnit } from 'effector-react';
import Lottie from 'react-lottie-player';
import { isEmpty } from 'src/lib/lodash';
import {
  sendApplicationProcessFn,
  $applicationDetail,
} from 'src/models/Loan/Application';
import lottieJson from 'src/dict/data-lottie.json';

export const Processing = () => {
  const applicationDetail = useUnit($applicationDetail);
  const isNotAppData = isEmpty(applicationDetail);
  useEffect(() => {
    if (applicationDetail !== '' && isNotAppData) {
      sendApplicationProcessFn();
    }
  }, [isNotAppData]);

  return (
    <div className="process">
      <h2 className="process-title">Мы обрабатываем вашу заявку</h2>
      <p className="process-text">
        Наши алгоритмы обрабатывают введеную вами информацию и проверяют данные. Это займет
        менее 10 секунд. Пожалуйста, не закрывайте страницу.
      </p>
      <div className="process-load active">
        <div className="process-keks">
          <Lottie
            animationData={lottieJson}
            play
            loop
          />
        </div>
        <div className="process-block">
          <div className="process-block-line active" />
        </div>
      </div>
    </div>
  );
};
