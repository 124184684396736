import { helperDomain } from 'src/models/Helper';

/// ///////////////////////////////////////////////////////////
// ================== СЛОВАРИ / КОНСТАНТЫ ================== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ СОБЫТИЙ ===================== //
/// ///////////////////////////////////////////////////////////

export const getKeyboardLayoutFn = helperDomain.createEvent('getKeyboardLayoutFn');

export const onKeyDownFn = helperDomain.createEvent('keyDownFn');
export const backSpaceFn = helperDomain.createEvent('backSpaceFn');
export const digitFn = helperDomain.createEvent('backSpaceFn');
export const removeValFieldFn = helperDomain.createEvent('removeValFieldFn');

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ХРАНИЛИЦ ==================== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ЭФФЕКТОВ ==================== //
/// ///////////////////////////////////////////////////////////

// Очистка поля
export const resetFieldFx = helperDomain.createEffect(
  ({ fieldName, form }) => form.fields[fieldName].reset(),
);

export const onChangeFieldFx = helperDomain.createEffect(
  ({ fieldName, value, form }) => form.fields[fieldName].onChange(value),
);

export const resetErrorFieldFx = helperDomain.createEffect(
  ({ fieldName, form }) => form.fields[fieldName].resetErrors(),
);

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ЭФФЕКТОВ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

// тут создаются эффекты через attach

/// ///////////////////////////////////////////////////////////
// ====================== ЮНИТЫ ФОРМ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем формы с помощью effector-forms

/// ///////////////////////////////////////////////////////////
//  ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ФОРМ + (ХРАНИЛИЩ, ЭФФЕКТОВ) //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ========================= ФАБРИКИ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем различные фабрики и хранилища с использованием фабрик
