import React from 'react';
// import { NavLink } from 'react-router-dom';
import { useStore } from 'effector-react';
import { isEmpty } from 'src/lib/lodash';
import { $isHome } from 'src/models/App';
// import { $loaderPage } from 'src/models/Helper/Loader';
import {
  openMenuMobileFn, closeMenuMobileFn,
  $openMenuMobile,
} from 'src/models/Helper/MenuMobile';
/* import {
  $isAuthenticated,
  // logoutFn,
} from 'src/models/Auth';
import { $name } from 'src/models/User'; */
import {
  Logo, Nav, Phone, SignIn, /* SignUp, */ MenuMobile,
} from './children';

export const Header = React.memo(() => {
  const isHome = useStore($isHome);
  const isOpen = !isEmpty(useStore($openMenuMobile));

  return (
    <header className="header">
      <div className="wrapper">
        <div className="header-content">
          <Logo />
          {isHome && <Nav />}
          <div className="header-block">
            <Phone />
            <SignIn />
            {/* <SignUp /> */}
          </div>

          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          {!isOpen && (
          <div className="header-button__menu" onClick={openMenuMobileFn}>
            <div className="header-line header-line1" />
            <div className="header-line header-line2" />
            <div className="header-line header-line3" />
          </div>
          )}
        </div>
        <MenuMobile isOpen={isOpen} closeMenuMobileFn={closeMenuMobileFn} />
      </div>
    </header>
  );
});
/*
  <div>
        <NavLink to="/">Главная</NavLink>
        {' | '}
        {isAuthenticated
          ? (
            <>
              <NavLink to="/profile">Профайл</NavLink>
              {' '}
              <span>
                | Вы авторизовались под пользователем
                {' '}
                <strong>{name}</strong>
                {' '}
                |
              </span>
              {' '}
              <NavLink to="/login" onClick={logoutFn}>Выйти</NavLink>
            </>

          )
          : <NavLink to="/login">Вход</NavLink>}
      </div>
 */
