import React from 'react';
import { useUnit } from 'effector-react';
import { getFormatMoney } from 'src/lib/money';
import { getUnitDay } from 'src/lib/date';
import {
  closeChangeCalcFn,
  $calcCombineData,
} from 'src/models/Calculator';
import { openModalFn } from 'src/models/components/Modal';
import { Calculator } from 'src/ui/components/Modals/Calculator';

export const InfoParams = React.memo(() => {
  const { amount, period } = useUnit($calcCombineData);
  return (
    <>
      <div className="block-top">
        <div className="block-top__info">
          <span className="icon">
            <img src="/img/form/coin.svg" alt="" />
          </span>
          {getFormatMoney(amount)}
          ₽
        </div>
        <div className="block-top__info">
          <span className="icon">
            <img src="/img/form/calendar.svg" alt="" />
          </span>
          {period}
          {' '}
          {getUnitDay(period)}
        </div>
      </div>

      <button
        type="button"
        className="block-btn__change"
        onClick={openModalFn.prepend(() => ({
          className: 'modal-changeAmountAndTerm',
          content: Calculator,
          importantOperation: true,
          // указываем свое событие на закрытие модального окна
          closeCallback: closeChangeCalcFn,
        }))}
      >
        Изменить сумму и срок
      </button>
    </>
  );
});
