import { appDomain } from 'src/models/App';
import { get, isEmpty } from 'src/lib/lodash';

export const ModalDomain = appDomain.createDomain('Modal');

// ===================== ЮНИТЫ СОБЫТИЙ ===================== //
const openModalFn = ModalDomain.createEvent('openModal');
const successModalFn = ModalDomain.createEvent('successModal');
const closeModalFn = ModalDomain.createEvent('cancelModal');

// ===================== ЮНИТЫ ХРАНИЛИЦ ==================== //
const $contentModal = ModalDomain.createStore({}, { name: 'contentModal' });

// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
// отменяем действие кнопки по умолчанию
closeModalFn.watch((event) => {
  try {
    event.preventDefault();
    // eslint-disable-next-line no-empty
  } catch {}
});
// openModalFn.watch((event) => event.preventDefault());
successModalFn.watch((event) => {
  try {
    event.preventDefault();
    // eslint-disable-next-line no-empty
  } catch {}
});

$contentModal
  // при открытии окна записываем обработчик на successModal
  .on(openModalFn, (state, obj) => ({ ...obj }))
  // при отмене - закрытие окна и очистка store
  .reset(closeModalFn)
  // триггере successModal вызываем сохраненный обработчик
  .watch(successModalFn, (state) => {
    const callback = get(state, 'callbackAgree', '');
    return (!isEmpty(callback)) && callback();
  });

export {
  openModalFn,
  successModalFn,
  closeModalFn,
  $contentModal,
};
/*
export const createModalApi = () => {
  // ===================== ЮНИТЫ СОБЫТИЙ ===================== //
  const openModalFn = ModalDomain.createEvent('openModal');
  const successModalFn = ModalDomain.createEvent('successModal');
  const closeModalFn = ModalDomain.createEvent('cancelModal');

  // ===================== ЮНИТЫ ХРАНИЛИЦ ==================== //
  const $contentModal = ModalDomain.createStore({}, { name: 'contentModal' });

  // ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
  // отменяем действие кнопки по умолчанию
  successModalFn.watch((event) => event.preventDefault());

  $contentModal
    // при открытии окна записываем обработчик на successModal
    .on(openModalFn, (state, obj) => ({ ...obj }))
    // при отмене - закрытие окна и очистка store
    .reset(closeModalFn)
    // триггере successModal вызываем сохраненный обработчик
    .watch(successModalFn, (state) => {
      const callback = get(state, 'callbackAgree', '');
      return (!isEmpty(callback)) && callback();
    });

  return ({
    $contentModal,
    openModalFn,
    successModalFn,
    closeModalFn,
  });
};

export const ModalModelApi = createModalApi();
*/
