import React from 'react';
import { useStore } from 'effector-react';
import { useForm } from 'effector-forms';
import { get } from 'src/lib/lodash';
import { getEventsField } from 'src/lib/form';
import { LOAN_FAILURE_FIELDS } from 'src/dict/fields';
import {
  changeAgreementDocsFn,
  $agreementDocs,
  failureForm,
} from 'src/models/Loan/Application/Failure';
import { Checkbox, ButtonWithLoader as Button } from 'src/ui/components/Form';

const { AGREEMENT } = LOAN_FAILURE_FIELDS;

export const Agreement = React.memo(({ closeCallback }) => {
  const agreementDocs = useStore($agreementDocs);
  const { ...restProps } = useForm(failureForm);

  return (
    <div className="modal-main">
      <button
        type="button"
        className="close-modal__btn close-modal"
        onClick={closeCallback}
      >
        <img src="/img/modal/close.svg" alt="" />
      </button>
      <form>
        <div className="modal-wrapper">
          <h2 className="modal-title">Соглашение</h2>
          <div className="changeAmountAndTerm-checks">
            {agreementDocs.map(
              ({
                link, label, value, id,
              }) => (
                <div className="modal-checkbox default-checkbox" key={id}>
                  <input
                    type="checkbox"
                    id={`ex${id}s`}
                    checked={value}
                    onChange={changeAgreementDocsFn.prepend(() => ({ id, value: !value }))}
                  />
                  <label htmlFor={`ex${id}s`} className="default-checkbox__label">
                    <div className="default-checkbox__block" />
                    <a className="default-checkbox__text" href={link}>{label}</a>
                  </label>
                </div>
              ),
            )}
          </div>
          <p className="modal-description nocenter">
            Подтверждаю, что в отношениях с ООО МФК “КексКредит” действую
            сознательно, свободно, своей волей и в своих интересах, мои действия не контролируются иными третьими
            лицами, самостоятельно представляю свои интересы, также подтверждаю что я не являюсь
            {' '}
            <a href="#">
              публичным
              должностным лицом
            </a>
          </p>

          <div className="modal-checkbox default-checkbox green agreeWithEverything">
            <Checkbox
              {...getEventsField(get(restProps.fields, AGREEMENT))}
              name={AGREEMENT}
              id={AGREEMENT}
            />
            <label htmlFor={AGREEMENT} className="default-checkbox__label ">
              <div className="default-checkbox__block" />
              <p className="default-checkbox__text">Согласен со всем</p>
            </label>
          </div>

          <div className="modal-button">
            <Button
              className="close-modal"
              onClick={closeCallback}
            >
              Продолжить
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
});
