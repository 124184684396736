import {
  sample, attach, split, restore,
} from 'effector';
import { debounce } from 'patronum/debounce';
import noUiSlider from 'nouislider';
import { spread } from 'patronum/spread';
import { get, isEmpty } from 'src/lib/lodash';
import {
  PROFILE_LOAN_PATH, PROFILE_NEW_LOAN_CARD_PATH, PROFILE_NEW_LOAN_PATH, PROFILE_PATH,
} from 'src/dict/path';
import { INTENT_TO_REPAY, STATUS_AGREEMENT } from 'src/dict/fields/loan';
import { LOAN_SERVICE_FIELDS } from 'src/dict/fields';
import { pushHistoryFn } from 'src/models/Helper/History';
import { openModalFn, closeModalFn } from 'src/models/components/Modal';
import { storageSetFn, storageRemoveFn } from 'src/models/Helper/Storage';
import { notifyErrorFn, notifySuccessFn } from 'src/models/Helper/Notification';
import {
  hideLoaderNotTimePageFn, showLoaderNotTimePageFn,
} from 'src/models/Helper/Loader';
import {
  getCurrentUserFn,
  getCurrentUserAfterConfirmSuccessFx, getCurrentUserOnlyQueryFx,
} from 'src/models/User';
import {
  goPaymentPageFn,
  $applicationId, $isSuccessNewLoanPage,
} from 'src/models/Loan/Application';
import {
  $itemsPaymentForExLoan,
  getItemsPaymentForExLoanFx, removeBindCardFn,
} from 'src/models/Payment';
import {
  getAgreementDocsForLoanFn,
  getAgreementDocsForLoanFx,
} from 'src/models/Loan/Application/Success';
import { CalculatorToRepay } from 'src/ui/components';
import { RepayPaymentError } from 'src/ui/components/Modals/RepayPaymentError';
import {
  goAgreementPageFn,
  goAgreementPaymentPageFn,
  goAgreementPaymentCardsPageFn,
  getAgreementsApplicationFn,
  getAgreementsFn,
  getAgreementFn,
  getAgreementForLoanFn,
  removeAgreementInStorageFn,
  getAgreementsApplicationTempFn,
  toggleLoanExtendedFn,
  changeValueCalcAmountToRepayFn,
  bindCalcAmountToRepayFn,
  closeCalculatorToRepayFn,
  sendToRepayFn,
  // хранилища
  $agreementItems,
  $agreementAppItem,
  $agreementId,
  $amountBodyAgreements,
  $termAgreements,
  $dateAgreements, // $amountAgreements
  $isProfileLoansPage,
  $isActiveAgreement,
  $agreementActiveItem,
  $agreementStatus,
  $selectorCalcAmountToRepay,
  $amountToRepay,
  $repaymentIntentId, $repaymentIntentItem, // $isCardsToRepayPage,
  // эффекты
  getAgreementsFx,
  getAgreementFx,
  getAgreementForExtendedLoanFx,
  getAgreementsApplicationFx,
  getAgreementsApplicationTempFx,
  changeAmountToRepayFn,
  intentionToRepayFn,
  getAgreementForToRepayLoanFx,
  createIntentToRepayFx,
  timerToRepayStatusFx, getStatusToRepayFx, timerAgreementActiveStatusFx,
  sendToRepayFx, timerToRepayStatusFinalFx, getStatusToRepayFinalFx,
} from './index';

const { PROFILE, LOAN } = PROFILE_PATH;
const { LOANS } = PROFILE_LOAN_PATH;
const { PAYMENT, AGREEMENT } = PROFILE_NEW_LOAN_PATH;
const { CARDS } = PROFILE_NEW_LOAN_CARD_PATH;
const { AGREEMENT_ID } = LOAN_SERVICE_FIELDS;
const {
  WAITING_AGREE, ACTIVE, AGREE, EXPIRED, OVERDUE,
} = STATUS_AGREEMENT;
const {
  CREATED,
  WAITING_REFRESH_AGREEMENT,
  WAITING_REPAYMENT_AMOUNT,
  // WAITING_REPAYMENT,
  REPAYMENT_ERROR,
  REPAYMENT_SUCCESS,
} = INTENT_TO_REPAY;

/// ///////////////////////////////////////////////////////////
// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
/// ///////////////////////////////////////////////////////////

// карты для отображения в истории платежей
$itemsPaymentForExLoan
  .on(getItemsPaymentForExLoanFx.doneData, (_, result) => {
    const items = get(result, 'data', []);
    return items.reduce((obj, item) => {
      obj[item.paymentable_id] = item;
      return obj;
    }, {});
  });

// Список займов
$agreementItems
  .on(getAgreementsFx.doneData, (_, result) => {
    const items = get(result, 'data', {});
    return items.filter(({ status }) => status !== WAITING_AGREE).map((item) => ({
      ...item, isOpen: false, agreementDocs: [],
    }));
  })
  .on(getAgreementForExtendedLoanFx.doneData, (items, result) => {
    const obj = get(result, 'data', {});
    return items.map((item) => ((item.id === obj.id) ? ({ ...item, ...obj }) : item));
  })
  .on(getAgreementDocsForLoanFx.done, (items, { params: agreementId, result }) => {
    const agreementDocs = get(result, 'data', {});
    return items.map((item) => ((item.id === agreementId) ? ({ ...item, agreementDocs }) : item));
  })
  .on(toggleLoanExtendedFn, (items, obj) => items.reduce((arr, item) => {
    if (item.id === obj.id) {
      arr.push({ ...item, isOpen: obj.isOpen });
    } else {
      arr.push(item);
    }
    return arr;
  }, []));

$agreementActiveItem
  // eslint-disable-next-line consistent-return
  .on(getAgreementFx.doneData, (_, result) => {
    const data = get(result, 'data', '');
    if (!isEmpty(data)) {
      const status = get(data, 'status', '');
      if ([ACTIVE, AGREE, OVERDUE].includes(status)) {
        return data;
      }
    }
  });

// Для одобреной завки нас странице одобрения - после привязки карты
$agreementAppItem
  .on(getAgreementsApplicationFx.doneData, (_, result) => get(result, 'data.0', {}))
  .on(getAgreementsApplicationTempFx.doneData, (_, result) => get(result, 'data.0', {}));

$selectorCalcAmountToRepay.reset(closeCalculatorToRepayFn);

$amountToRepay
  .on(changeValueCalcAmountToRepayFn, (_, value) => Number.parseFloat(get(value, '0', 0), 10));

$repaymentIntentId
  .on(createIntentToRepayFx.doneData, (_, result) => get(result, 'data.repayment.id', ''));

$repaymentIntentItem
  .on([
    getStatusToRepayFx.doneData, createIntentToRepayFx.doneData,
  ], (_, result) => ({
    amountMin: get(result, 'data.amount_interval.min', ''),
    amountMax: get(result, 'data.amount_interval.max', ''),
    ...get(result, 'data.repayment', {}),
  }));

// $agreementActiveItem.watch((state) => console.log('$agreementActiveItem:', state));
// $repaymentIntentId.watch((state) => console.log('$repaymentIntentId:', state));
// $repaymentIntentItem.watch((state) => console.log('$repaymentIntentItem:', state));

/// ///////////////////////////////////////////////////////////
// ===================== БЛОК ОПЕРАТОРОВ =================== //
/// ///////////////////////////////////////////////////////////
/// ///////////////////////////////////////////////////////////

// когда таймер заканчивается проверяем статус agreement из данных юзера
sample({
  clock: timerAgreementActiveStatusFx.done,
  target: getCurrentUserAfterConfirmSuccessFx,
});

// если статус равен WAITING_REPAYMENT_AMOUNT - перестаем узнавать статус
sample({
  clock: getCurrentUserAfterConfirmSuccessFx.doneData,
  source: $agreementStatus,
  filter: (status) => status !== 'active',
  target: timerAgreementActiveStatusFx.prepend(() => 2000),
});

sample({
  clock: goPaymentPageFn,
  target: getCurrentUserFn,
});

sample({
  clock: removeAgreementInStorageFn,
  // удаляем agreementId, если он есть
  target: storageRemoveFn.prepend(() => [AGREEMENT_ID]),
});

// Записываем
sample({
  clock: $agreementAppItem,
  filter: (agreementAppItem) => !isEmpty(agreementAppItem),
  fn: ({ id }) => id,
  target: storageSetFn.prepend((id) => ({
    step: AGREEMENT,
    [AGREEMENT_ID]: id,
  })),
});

sample({
  clock: $agreementAppItem,
  target: spread({
    targets: {
      id: $agreementId,
      amount_body: $amountBodyAgreements,
      term: $termAgreements,
      date_end: $dateAgreements,
    },
  }),
});

// достаем активный займ (в основном нужен для шапки)
sample({
  clock: $isActiveAgreement,
  source: [$agreementId, $agreementActiveItem],
  // eslint-disable-next-line no-unused-vars
  filter: ([_, agreementActiveItem], isActiveAgreement) => (
    // есть активный займ + объект активного займа еще пустой
    !isEmpty(isActiveAgreement) && isEmpty(agreementActiveItem)
  ),
  fn: ([id]) => id,
  target: getAgreementFx,
});

// Дл вывода активной заявки над списокм займов на странице займов
sample({
  clock: sample({
    clock: [getAgreementsApplicationTempFn, $applicationId],
    source: [$applicationId, $isProfileLoansPage],
    filter: ([applicationId, isProfileLoansPage]) => (
      !isEmpty(applicationId) && isProfileLoansPage
    ),
  }),
  fn: ([applicationId]) => applicationId,
  target: getAgreementsApplicationTempFx,
});

// страница одобрения - достаем инфоормацию по заявке
sample({
  clock: sample({
    clock: [getAgreementsApplicationFn, $applicationId],
    source: [$applicationId, getAgreementsApplicationFx.pending, $isSuccessNewLoanPage],
    filter: ([applicationId, pending, isSuccessNewLoanPage]) => (
      !isEmpty(applicationId) && isEmpty(pending) && !isEmpty(isSuccessNewLoanPage)
    ),
  }),
  fn: ([applicationId]) => applicationId,
  target: getAgreementsApplicationFx,
});

// страница займов
sample({
  clock: getAgreementsFn,
  target: getAgreementsFx,
});

sample({
  clock: toggleLoanExtendedFn,
  source: $agreementItems,
  filter: (agreementItems, { id, isOpen }) => isOpen && agreementItems.some(
    (item) => ((id === item.id) ? get(item, 'repayment_intents', null) === null : false),
  ),
  fn: (_, { id }) => id,
  target: getAgreementForLoanFn,
});

// в шапке - запрос займа по agreementId из storage
sample({
  clock: getAgreementFn,
  target: getAgreementFx,
});

// запрос займа по agreementId в клоак
sample({
  clock: getAgreementForLoanFn,
  target: getAgreementForExtendedLoanFx,
});

sample({
  clock: getAgreementForExtendedLoanFx.doneData,
  fn: (data) => get(data, 'data.id', ''),
  target: getAgreementDocsForLoanFn,
});

// ===================== ПОГАШЕНИЕ =====================

// нажатие на кнопку погашения | когда появится $agreementId - убрать agreementIdFromClock и из препенда тоже
sample({
  clock: intentionToRepayFn,
  source: $agreementId,
  filter: (agreementId, agreementIdFromClock) => !isEmpty(agreementId) || !isEmpty(agreementIdFromClock),
  fn: (agreementId, agreementIdFromClock) => (!isEmpty(agreementIdFromClock) ? agreementIdFromClock : agreementId),
  target: [
    // начинаем показывать прелоадер
    showLoaderNotTimePageFn,
    getAgreementForToRepayLoanFx,
  ],
});

// убеждаемся что статус у договора активный и посылаем запрос на намерение погасить займ
sample({
  clock: getAgreementForToRepayLoanFx.doneData,
  filter: (data) => [ACTIVE, AGREE, EXPIRED, OVERDUE].includes(get(data, 'data.status', '')),
  fn: (data) => get(data, 'data.id', ''),
  target: createIntentToRepayFx,
});

// Ошибка создания намерения
sample({
  clock: createIntentToRepayFx.fail,
  target: hideLoaderNotTimePageFn,
});

// после создания -запускаем таймер для пулинга
/* sample({
  clock: createIntentToRepayFx.doneData,
  filter:
  target: timerToRepayStatusFx.prepend(() => 2000),
}); */

// когда таймер заканчивается проверяем статус намерение платежа | добавить проверку на agreementId
sample({
  clock: timerToRepayStatusFx.done,
  source: $repaymentIntentItem,
  fn: (repaymentIntentItem) => ({
    agreementId: get(repaymentIntentItem, 'agreement.id'),
    repaymentIntentId: get(repaymentIntentItem, 'id'),
  }),
  target: getStatusToRepayFx,
});

/* при получении данных о статусе, в зависимости от статуса, решаем
  ждать дальше или переходить к след шагу */
split({
  clock: [getStatusToRepayFx.doneData, createIntentToRepayFx.doneData],
  source: $repaymentIntentItem,
  match: {
    wait: (data) => [CREATED, WAITING_REFRESH_AGREEMENT].includes(get(data, 'status', '')),
    next: (data) => WAITING_REPAYMENT_AMOUNT === get(data, 'status', ''),
  },
  cases: {
    wait: timerToRepayStatusFx.prepend(() => 2000),
    next: [
      // заканчиваем показывать прелоадер
      hideLoaderNotTimePageFn,
      // открываем модальное окно с выбором суммы погашения
      openModalFn.prepend(() => ({
        className: 'modal-changeAmountAndTerm modal-repayLoan fancybox-content',
        content: CalculatorToRepay,
        // указываем строгое закрытие
        importantOperation: true,
        // указываем свое событие на закрытие модального окна
        closeCallback: closeModalFn,
      })),
    ],
    __: timerToRepayStatusFx.prepend(() => 2000), // [], // выдаем ошибку что что-то не атк
  },
});

// Сохраняем селектор калькулятора погашения в хранилище
sample({
  clock: bindCalcAmountToRepayFn,
  source: $selectorCalcAmountToRepay,
  filter: (selectorCalcAmount) => isEmpty(selectorCalcAmount),
  fn: (_, selector) => selector,
  target: $selectorCalcAmountToRepay,
});
// при изменении положения ползунка меняем значение в хранилище для калькулятора погашения
sample({
  clock: $selectorCalcAmountToRepay,
  filter: (selectorCalcAmount) => !isEmpty(selectorCalcAmount),
  fn: (selector) => selector,
  target: attach({
    source: [$repaymentIntentItem, $amountToRepay],
    effect: ([
      { amountMax, amountMin }, sum], selector) => {
      noUiSlider.create(selector, {
        start: sum,
        range: { min: amountMin, max: amountMax },
        step: 50,
        connect: 'lower',
      });
      selector.noUiSlider.on('update', changeValueCalcAmountToRepayFn);
    },
  }),
});

// при нажатии кнопки в модальном окне калькулятора погашения
sample({
  clock: changeAmountToRepayFn,
  fn: (agreementId) => agreementId,
  target: [
    // сохраняем в LS $agreementId и $repaymentIntentId + выбранную сумму
    // редирект на выбор платежных систем
    goAgreementPaymentPageFn,
    // закрытие модального окна
    closeModalFn,
  ],
});

// ?????? - узнать нужен ли это параметр для sendYMGoalFx - та это убрано из регистрации
// в зависимости от того где находимся (url)  перенаправляем куда нужно
sample({
  clock: sendToRepayFn,
  source: [$repaymentIntentItem, $amountToRepay],
  // filter: $isCardsToRepayPage,
  fn: ([repaymentIntentItem, amountToRepay], data) => ({
    agreementId: get(repaymentIntentItem, 'agreement.id'),
    repaymentIntentId: get(repaymentIntentItem, 'id'),
    data: {
      amount: (amountToRepay * 100),
      paymentable_id: get(data, 'paymentId', ''),
      paymentable_type: get(data, 'paymentType', ''),
    },
  }),
  target: [
    showLoaderNotTimePageFn,
    sendToRepayFx,
  ],
});

sample({
  clock: sendToRepayFx.done,
  target: timerToRepayStatusFinalFx.prepend(() => 2000),
});

//  запускаем таймер
sample({
  clock: sendToRepayFx.fail,
  target: [
    // заканчиваем по казывать прелоадер
    hideLoaderNotTimePageFn,
    notifyErrorFn.prepend(() => 'Ошибка отправки платежа'),
    goAgreementPageFn,
  ],
});

// по оканчании таймера смотрим статус
sample({
  clock: timerToRepayStatusFinalFx.done,
  source: $repaymentIntentItem,
  fn: (repaymentIntentItem) => ({
    agreementId: get(repaymentIntentItem, 'agreement.id'),
    repaymentIntentId: get(repaymentIntentItem, 'id'),
  }),
  target: getStatusToRepayFinalFx,
});

// смотрим результат - если не ок - запускаем таймер заново
split({
  source: sample(
    restore(getStatusToRepayFinalFx.doneData, {}),
    getStatusToRepayFinalFx.doneData,
  ),
  match: {
    next: (data) => [REPAYMENT_SUCCESS, 'success'].includes(get(data, 'data.repayment.status', '')),
    error: (data) => [REPAYMENT_ERROR, 'error'].includes(get(data, 'data.repayment.status', '')),
  },
  cases: {
    next: [
      getCurrentUserOnlyQueryFx,
      // обновляем данные по займу в шарке
      getAgreementFn.prepend((data) => get(data, 'data.repayment.agreement.id', '')),
      // заканчиваем показывать прелоадер
      hideLoaderNotTimePageFn,
      notifySuccessFn.prepend(() => 'Платеж успешно совершен'),
      goAgreementPageFn,
      // удаляем остальной контекст из LS
      removeBindCardFn,
    ],
    error: [
      // заканчиваем по казывать прелоадер
      hideLoaderNotTimePageFn,
      // notifyErrorFn.prepend(() => 'Ошибка платежа'),
      // goAgreementPageFn,
      openModalFn.prepend(() => ({
        importantOperation: true,
        className: 'modal-agreement',
        content: RepayPaymentError,
        successCallback: () => {
          goAgreementPageFn();
          closeModalFn();
        },
      })),
      // удаляем остальной контекст из LS
      removeBindCardFn,
    ],
    __: timerToRepayStatusFinalFx.prepend(() => 2000), // [], // выдаем ошибку что что-то не атк
  },
});

// ===================== ПЕРЕНАПРАВЛЕНИЯ =====================

sample({
  clock: debounce({
    source: goAgreementPageFn,
    timeout: 10,
  }),
  target: pushHistoryFn.prepend(() => `/${PROFILE}/${LOAN}/${LOANS}`),
});

sample({
  clock: goAgreementPaymentPageFn,
  target: pushHistoryFn.prepend((agreementId) => `/${PROFILE}/${LOAN}/${agreementId}/${PAYMENT}`),
});

sample({
  clock: goAgreementPaymentCardsPageFn,
  fn: (agreementId) => agreementId,
  target: pushHistoryFn.prepend((agreementId) => `/${PROFILE}/${LOAN}/${agreementId}/${PAYMENT}/${CARDS}`),
});
