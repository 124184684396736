import {
  split, sample, attach, combine,
} from 'effector';
import { spread } from 'patronum/spread';
import { debounce } from 'patronum/debounce';
import persist from 'effector-localstorage';
import { v4 as uuidv4 } from 'uuid';
import { get, isEmpty } from 'src/lib/lodash';
import { getDateReverse } from 'src/lib/date';
// import { storage } from 'src/lib/storage';
// import { isValidBirthDate } from 'src/lib/date';
import {
  APPLICATION_FIELDS, GENDER_FIELD_VALUES, REG_STEP1_FIELDS,
} from 'src/dict/fields';
import { REG_PATH } from 'src/dict/path';
import { config, validate, LS } from 'src/dict/config';
import {
  UserExists,
} from 'src/ui/components/Modals/UserExists';
import {
  loadDataToFormOperator, setFormFieldsOperator,
} from 'src/models/Registration';
import { sendYMGoalFx } from 'src/models/Statistic';
import { $messagesDict } from 'src/models/Dictionaries';
import { pushHistoryFn } from 'src/models/Helper/History';
import { notifyErrorFn } from 'src/models/Helper/Notification';
import { storageRemoveFn, storageSetFn } from 'src/models/Helper/Storage';
import { jumpFocusFn, jumpFocusFieldFn } from 'src/models/Helper/Ref';
import { hideLoaderButtonFn, showLoaderButtonFn } from 'src/models/Helper/Loader';
import { openModalFn, closeModalFn } from 'src/models/components/Modal';
import {
  $amount as $amountCalc, $period as $periodCalc, $productId,
} from 'src/models/Calculator';
import {
  updateContactsUserAfterRegFx, updateAdditionalInfoUserAfterRegFx,
  $userId, $user,
} from 'src/models/User';
import { createApplicationsAfterRegFx } from 'src/models/Loan/Application';
import { removeAgreementInStorageFn } from 'src/models/Loan/Agreement';
import { getDocsFx } from 'src/models/Documents';
import {
  // personalRegDomain,
  // эффекты
  addPersonalDataFx,
  // события
  step1FinishFn, addPersonalDataFn, changeAgreementDocsFn,
  // хранилища
  $agreementDocs,
  // формы
  step1Form,
} from './index';

// const { LOGIN } = PAGES_PATH;
const { DATE } = validate;
const {
  FIRST_NAME, LAST_NAME, MIDDLE_NAME, IS_NOT_EMAIL, IS_NOT_MIDDLE_NAME,
  YEAR_OF_BIRTH, PHONE, GENDER, EMAIL, AGREEMENT, PURPOSE, UUID: UUID_STEP1,
} = REG_STEP1_FIELDS;
const { PERSONAL, PASSPORT, REGISTRATION } = REG_PATH;
const {
  AMOUNT, PERIOD, PROJECT_ID, CLIENT_ID, PURPOSE_ID, PRODUCT_ID, UUID,
} = APPLICATION_FIELDS;

const { APP, STEP, USER } = LS;

/// ///////////////////////////////////////////////////////////
// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
/// ///////////////////////////////////////////////////////////

$agreementDocs
  .on(getDocsFx.doneData, (_, result) => {
    const items = get(result, 'data', result);
    return items.map(({ id, name, link }) => ({
      id, label: name, value: false, link,
    }));
  });

/// ///////////////////////////////////////////////////////////
// ===================== БЛОК ОПЕРАТОРОВ =================== //
/// ///////////////////////////////////////////////////////////

loadDataToFormOperator({
  form: step1Form,
  key: PERSONAL,
});

// сохранение в LS
persist({
  store: step1Form.$values,
  // store: $agreementDocs,
  key: PERSONAL,
});

// заполняем поля данными с ЕСИА (если таковые есть)
setFormFieldsOperator({
  form: step1Form,
  fields: [FIRST_NAME, LAST_NAME, MIDDLE_NAME, YEAR_OF_BIRTH, PHONE, GENDER, EMAIL],
  key: PERSONAL,
  combineValues: combine($user, (user) => ({
    ...get(user, 'esia_data', {}),
    [PHONE]: get(user, 'esia_data.phone', ''),
    [YEAR_OF_BIRTH]: !isEmpty(get(user, 'esia_data.birth_date', ''))
      ? getDateReverse(get(user, 'esia_data.birth_date')) : '',
    // user,
  })),
});

// $user.watch((user) => console.log('$user:', user));

// при ошибке не перескаиваем
sample({
  clock: sample({
    clock: step1Form.fields[YEAR_OF_BIRTH].onChange,
    source: step1Form.fields[YEAR_OF_BIRTH].$value,
    filter: (dateBirth) => {
      const len = dateBirth.replace(/[^\d.]/g, '').length;
      return len === DATE.LENGTH;
    },
  }),
  target: step1Form.fields[YEAR_OF_BIRTH].validate,
});

/* sample({
  clock: sample({
    clock: step1Form.fields[YEAR_OF_BIRTH].validate,
    source: step1Form.fields[YEAR_OF_BIRTH].$errors,
    filter: (errors) => isEmpty(errors),
  }),
  source: step1Form.fields[IS_NOT_EMAIL].$value,
  fn: (isNotEmail) => (!isNotEmail ? EMAIL : PURPOSE),
  target: jumpFocusFieldFn,
}); */

split({
  source: sample(
    step1Form.fields[IS_NOT_EMAIL].$value,
    sample({
      clock: step1Form.fields[YEAR_OF_BIRTH].validate,
      source: step1Form.fields[YEAR_OF_BIRTH].$errors,
      filter: (errors) => isEmpty(errors),
    }),
  ),
  match: {
    // есть данные для сохранения в контакты
    jumpToEmail: (isNotEmail) => isEmpty(isNotEmail),
    jumpToPurpose: (isNotEmail) => !isEmpty(isNotEmail),
  },
  cases: {
    // если есть что добавить в контакты, то добавляем
    jumpToEmail: jumpFocusFieldFn.prepend(() => EMAIL),
    jumpToPurpose: jumpFocusFn.prepend(() => PURPOSE),
  },
});

sample({
  clock: debounce({
    source: step1Form.fields[EMAIL].onBlur,
    timeout: 300,
  }),
  target: step1Form.fields[EMAIL].validate,
});

/* при получении общего согласия проставляем согласие везде,
и если согласия нет - снимаем галочки везде */
sample({
  clock: step1Form.fields[AGREEMENT].$value,
  source: $agreementDocs,
  fn: (agreementDocs, agreement) => ({
    agreement,
    agreementDocs: agreementDocs.map((item) => ({ ...item, value: !isEmpty(agreement) })),
  }),
  target: spread({
    targets: {
      agreement: step1Form.fields[AGREEMENT].$value,
      agreementDocs: $agreementDocs,
    },
  }),
});

sample({
  clock: changeAgreementDocsFn,
  source: $agreementDocs,
  fn: (agreementDocs,
    { id: agreementDocsId, value: agreementDocsValue, docName }) => {
    const items = agreementDocs.map(
      (itemDoc) => ((agreementDocsId !== itemDoc.id) ? { ...itemDoc }
        : { ...itemDoc, value: agreementDocsValue }),
    );
    return ({
      agreementDocs: items,
      agreement: !items.some(({ value }) => !value),
      docName,
    });
  },
  target: spread({
    targets: {
      agreement: step1Form.fields[AGREEMENT].$value,
      agreementDocs: $agreementDocs,
    },
  }),
});

sample({
  clock: step1Form.formValidated,
  target: [
    showLoaderButtonFn,
    addPersonalDataFn,
  ],
});

sample({
  clock: addPersonalDataFn,
  target: addPersonalDataFx.prepend((data) => {
    const arrBirthDate = data[YEAR_OF_BIRTH].split('.');
    const birthDate = `${arrBirthDate[2]}-${arrBirthDate[1]}-${arrBirthDate[0]}`;
    const phone = `${Number(data[PHONE].replace(/\D+/g, ''))}`;
    const middleName = isEmpty(data[IS_NOT_MIDDLE_NAME]) ? data[MIDDLE_NAME] : null;
    return ({
      [FIRST_NAME]: data[FIRST_NAME],
      [LAST_NAME]: data[LAST_NAME],
      [MIDDLE_NAME]: middleName,
      [UUID_STEP1]: data[UUID_STEP1],
      [YEAR_OF_BIRTH]: birthDate,
      [PHONE]: phone,
    });
  }),
});

// проверка на то что такой юзер существует
sample({
  // ошибка
  clock: sample({
    clock: addPersonalDataFx.fail,
    filter: (data) => {
      const status = get(data, 'error.response.status', {});
      return status === 409;
    },
  }),
  target: [
    hideLoaderButtonFn,
    openModalFn.prepend(() => ({
      importantOperation: true,
      className: 'modal-agreement',
      content: UserExists,
      // указываем свое событие на закрытие модального окна
      closeCallback: closeModalFn,
    })),
  ],
});

// проверка на то что такой юзер существует
sample({
  // ошибка
  clock: sample({
    clock: addPersonalDataFx.fail,
    filter: (data) => {
      const status = get(data, 'error.response.status', {});
      return status !== 409;
    },
  }),
  source: $messagesDict,
  fn: (dict, data) => ({ dict, data }),
  target: [
    hideLoaderButtonFn,
    notifyErrorFn.prepend(({ dict, data }) => {
      const key = get(data, 'error.response.data.message', '');
      const message = (!isEmpty(dict[key]))
        ? dict[key] : 'Упс, что-то пошло не так! Пожалуйста, обратитесь в службу поддержки.';
      return message;
    }),
  ],
});

sample({
  // после того как клиент создался
  clock: addPersonalDataFx.done,
  target: [
    // создаем заявку
    attach({
      effect: createApplicationsAfterRegFx,
      source: [$amountCalc, $periodCalc, $productId, step1Form.fields[PURPOSE].$value, $userId],
      mapParams: (_, [amount, period, productId, purpose, userId]) => ({
        [AMOUNT]: amount,
        [PERIOD]: period,
        [PRODUCT_ID]: productId,
        [PURPOSE_ID]: get(purpose, 'value', ''),
        [PROJECT_ID]: config.API_PROJECT_ID,
        [CLIENT_ID]: userId,
        [UUID]: uuidv4(),
      }),
    }),
    storageSetFn.prepend(({ result }) => ({
      [USER]: { ...get(result, 'data', {}) },
    })),
  ],
});

// после создания заявки, добавляем доп инф-цию о юзере
sample({
  clock: createApplicationsAfterRegFx.doneData,
  source: step1Form.formValidated,
  fn: (form, params) => ({
    appData: get(params, 'data', {}),
    userData: {
      [GENDER]: isEmpty(form[GENDER]) ? GENDER_FIELD_VALUES.MAN : form[GENDER],
      [EMAIL]: form[EMAIL],
    },
  }),
  target: [
    updateAdditionalInfoUserAfterRegFx.prepend(({ userData }) => ({
      [GENDER]: get(userData, GENDER, ''),
    })),
    // также записываем какой шаг прошел юзер в локалсторадж
    storageSetFn.prepend(({ appData }) => ({
      [APP]: { ...appData },
    })),
  ],
});

/* после добавления / обновления доп инф-ции о юзере (дабы не пересекались)
добавляем / обновляем инф-цию о контактах */
split({
  source: sample(step1Form.formValidated, updateAdditionalInfoUserAfterRegFx.done),
  match: {
    // есть данные для сохранения в контакты
    contactsUser: (form) => isEmpty(form[IS_NOT_EMAIL]) && !isEmpty(form[EMAIL]),
  },
  cases: {
    // если есть что добавить в контакты, то добавляем
    contactsUser: [
      updateContactsUserAfterRegFx.prepend(
        (form) => ({
          type: 'email',
          contact: form[EMAIL],
        }),
        storageSetFn.prepend(() => ({
          [STEP]: PASSPORT,
        })),
      ),
    ],
    // иначе, если в контакты нечего добавлять, сигнализируем об завершении 1-ого шага
    __: step1FinishFn,
  },
});

// после добавления контактов после регистрации
sample({
  clock: updateContactsUserAfterRegFx.done,
  // сигнализируем об завершении 1-ого шага
  target: step1FinishFn,
});

// тут ловим все остальные ошибки
// проверка на то что такой юзер существует
sample({
  // ошибка
  clock: [
    createApplicationsAfterRegFx.fail,
    updateAdditionalInfoUserAfterRegFx.fail,
    updateContactsUserAfterRegFx.fail,
  ],
  source: $messagesDict,
  fn: (dict, data) => ({ dict, data }),
  target: [
    hideLoaderButtonFn,
    // eslint-disable-next-line consistent-return
    notifyErrorFn.prepend(({ dict, data }) => {
      const key = get(data, 'error.response.data.message', '');
      if (!isEmpty(dict[key])) {
        return dict[key];
      }
      const errors = get(data, 'error.response.data.errors', []);

      // eslint-disable-next-line consistent-return
      const arrErrors = Object.values(errors).reduce((arr, arrVal) => {
        if (!isEmpty(arrVal[0]) && !isEmpty(dict[arrVal[0]])) {
          arr.push(dict[arrVal[0]]);
        }
        return arr;
      }, []);

      if (!isEmpty(arrErrors)) {
        return arrErrors[0];
      }
    }),
  ],
});

sample({
  // при завершении 1-ого шага:
  clock: step1FinishFn,
  target: [
    // очищаем форму от данных
    step1Form.reset,
    // и записываем в локалсторадж что у нас ноdый шаг
    storageSetFn.prepend(() => ({
      [STEP]: PASSPORT,
    })),
    removeAgreementInStorageFn,
    sendYMGoalFx.prepend(() => 'REG_PERS_DATA_SENT'),
  ],
});//

sample({
  clock: debounce({
    source: step1FinishFn,
    timeout: 200,
  }),
  target: [
    // снимаем блок с кнопки
    hideLoaderButtonFn,
    storageRemoveFn.prepend(() => [PERSONAL]),
    // редиректим юзера на след шаг
    pushHistoryFn.prepend(() => `/${REGISTRATION}/${PASSPORT}`),
  ],
});
