import { combine } from 'effector';
import { isCurrentPath } from 'src/lib/url';
import { PAGES_PATH, RECOVERY_PATH } from 'src/dict/path';
import {
  appDomain,
  $pathnameUrl,
} from 'src/models/App';
import { $isAuthenticated } from 'src/models/Auth';

export const recoveryDomain = appDomain.createDomain('Recovery');

/// ///////////////////////////////////////////////////////////
// ================== СЛОВАРИ / КОНСТАНТЫ ================== //
/// ///////////////////////////////////////////////////////////

const { RECOVERY } = PAGES_PATH;
const { CONFIRM, CHANGE } = RECOVERY_PATH;

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ СОБЫТИЙ ===================== //
/// ///////////////////////////////////////////////////////////

export const resetCodePageFn = recoveryDomain.createEvent();

export const recoveryPassAfterFn = recoveryDomain.createEvent();

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ХРАНИЛИЦ ==================== //
/// ///////////////////////////////////////////////////////////

export const $isCodePage = recoveryDomain.createStore(false);

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ЭФФЕКТОВ ==================== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

export const $isRecoveryPage = $pathnameUrl.map((path) => isCurrentPath(path, RECOVERY));

// export const $classNamesMap = $pathnameUrl.map((path) => {
export const $classNamesMap = combine($pathnameUrl, $isCodePage,
  (path, isCodePage) => {
    const arrPath = path.split('/');
    const page = arrPath[arrPath.length - 1];

    let sectionClass = 'section';
    let pageClass = 'formPage';

    switch (page) {
      case CONFIRM:
        pageClass = isCodePage ? 'loginPage main validationPage ' : 'loginPage authorization';
        sectionClass = isCodePage ? 'validation' : sectionClass;
        break;
      case CHANGE:
        pageClass = 'loginPage authorization';
        break;
      default:
    }
    return { pageClass, sectionClass };
  });

export const $routesStepsRecovery = combine(
  $isAuthenticated, () => [
    {
      path: CONFIRM, // обработка заявки
      visible: true,
    },
    {
      path: CHANGE, // обработка заявки
      visible: true,
    },

  ].filter(({ visible }) => visible)
  ,
);

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ЭФФЕКТОВ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

// тут создаются эффекты через attach

/// ///////////////////////////////////////////////////////////
// ====================== ЮНИТЫ ФОРМ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем формы с помощью effector-forms

/// ///////////////////////////////////////////////////////////
//  ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ФОРМ + (ХРАНИЛИЩ, ЭФФЕКТОВ) //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ========================= ФАБРИКИ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем различные фабрики и хранилища с использованием фабрик
