import React, { useState } from 'react';
import Select from 'react-select';
import {
  get, isEmpty,
} from 'src/lib/lodash';
import './Select.css';

const customStyles = {
  container: () => ({
    width: '100%',
    border: 0,
    lineHeight: '27px',
    padding: '7px 4px 6px 7px',
    fontSize: '15px',
    borderRadius: '15px',
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? '#61c1d6' : '#fff',
    color: state.isSelected ? '#fff' : '#00090b',
    fontFamily: 'Rubik, sans-serif',
    fontSize: '15px',
    lineHeight: '25px',
    padding: '7px 20px 8px 10px',
    position: 'relative',
    cursor: 'pointer',
    borderBottom: '1px solid #eee',
    borderLeft: 0,
    width: '100%',
  }),
  menuList: () => ({
    background: '#61c1d6',
    borderTop: '2px solid #61c1d6',
    margin: 0,
    padding: 0,
  }),
  indicatorSeparator: () => ({
    width: 0,
  }),
  menu: () => ({
    padding: 0,
    margin: 0,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return {
      ...provided,
      opacity,
      transition,
    };
  },
};

const handlerChange = ({ onChange, jumpField }) => (obj) => {
  onChange(obj);
  jumpField();
};

const handlerInputChange = (callback) => (val) => callback(val);

const getLabel = (obj) => (isEmpty(obj) ? obj : get(obj, 'label', ''));

export const getValue = (obj) => (isEmpty(obj) ? obj : get(obj, 'value', ''));

export const filterOption = (value) => (option, inputValue) => (
  (isEmpty(value) || value !== inputValue)
    ? (option.label.toString().toLowerCase().match(inputValue.toLowerCase()) || []).length > 0
    : option.label.toString().toLowerCase());

export const SelectCustom = React.forwardRef(({
  value, onChange, onBlur, // приходят из getPropsField - формы эффектора
  options, placeholder, name, label, jumpField,
  positionAbsolute,
  required, errorText, hasError, disabled, ...restProps
}, ref) => {
  const [inputText, setInputText] = useState('');
  const fieldLabel = `${label}${(required ? '*' : '')}`;
  let validState = '';
  if (required && (hasError && (isEmpty(`${inputText}`.trim())))) {
    validState = 'invalid';
  } else if (value) {
    validState = 'valid';
  }

  if (!isEmpty(value) && isEmpty(inputText)) {
    setInputText(Date.now);
  }

  return (
    <>
      <div className={`default-input ${validState} simple`}>
        <Select
          // используем для обноления компонента при подгрузке данных из LS
          key={inputText}
          onInputChange={handlerInputChange(setInputText)}
          // className="react-select-container"
          classNamePrefix="react-select"
          {...restProps}
          defaultValue={value}
          filterOption={filterOption(getLabel(value))}
          placeholder={placeholder}
          styles={{
            ...customStyles,
            // ...styleMenuList,
          }}
          isSearchable={false}
          options={options}
          onChange={handlerChange({ onChange, jumpField })}
          required={required}
          onBlur={onBlur}
          disabled={disabled}
          id={name}
          ref={ref}
          openMenuOnFocus
        />
        {isEmpty(value) && isEmpty(inputText) && <label>{fieldLabel}</label>}
      </div>
      {required
      && hasError
      && !isEmpty(errorText)
      && isEmpty(inputText) && <p className="invalid-text">{errorText}</p>}
    </>
  );
});

SelectCustom.defaultProps = {
  positionAbsolute: true,
  jumpField: () => '',
};
