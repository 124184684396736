import { appDomain } from 'src/models/App';
import { statistic } from 'src/dict/config';
import { storage } from 'src/lib/storage';
import {
  transformParamsSign,
  sendYMGoalSign,
  sendYMHitSign,
} from 'src/signatures/Statistic';

export const statisticDomain = appDomain.createDomain('Statistic');

/// ///////////////////////////////////////////////////////////
// ================== СЛОВАРИ / КОНСТАНТЫ ================== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ СОБЫТИЙ ===================== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ХРАНИЛИЦ ==================== //
/// ///////////////////////////////////////////////////////////

// тип трафика (многие ошибочно пишут utm_media, но это неверно)
/* export const $utmMedium = statisticDomain.createStore('', { name: 'utm_medium' });
// источник трафика
export const $utmSource = statisticDomain.createStore('', { name: 'utm_source' });
// рекламная кампания
export const $utmCampaign = statisticDomain.createStore('', { name: 'utm_campaign' });
// ключевое слово
export const $utmTerm = statisticDomain.createStore('', { name: 'utm_term' });
// содержание
export const $utmContent = statisticDomain.createStore('', { name: 'utm_content' });
*/
export const $statInfo = statisticDomain.createStore(storage.get(statistic.INFO), { name: 'statInfo' });

/*
export const $fromSource = statisticDomain.createStore('', { name: 'fromSource' });
export const $toSource = statisticDomain.createStore('', { name: 'toSource' });

export const $browser = statisticDomain.createStore({}, { name: 'browser' });
export const $device = statisticDomain.createStore({}, { name: 'device' });
export const $os = statisticDomain.createStore({}, { name: 'os' });
*/

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ЭФФЕКТОВ ==================== //
/// ///////////////////////////////////////////////////////////

export const transformParamsFx = statisticDomain.createEffect(transformParamsSign);

export const sendYMGoalFx = statisticDomain.createEffect(sendYMGoalSign);
export const sendYMHitFx = statisticDomain.createEffect(sendYMHitSign);

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ЭФФЕКТОВ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

// тут создаются эффекты через attach

/// ///////////////////////////////////////////////////////////
// ====================== ЮНИТЫ ФОРМ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем формы с помощью effector-forms

/// ///////////////////////////////////////////////////////////
//  ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ФОРМ + (ХРАНИЛИЩ, ЭФФЕКТОВ) //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ========================= ФАБРИКИ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем различные фабрики и хранилища с использованием фабрик
