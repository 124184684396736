import React from 'react';
import { YellowBlock, MainBlock } from 'src/ui/components';
import { InfoParams } from './children/InfoParams';
import { PersonalForm } from './children/PersonalForm/PersonalForm';

const KeksBlock = () => (
  <>
    <div className="keks keks2">
      <img src="/img/form/keks-2.png" alt="" />
    </div>
    <span className="keks2-hand">
      <img src="/img/form/keks-2-hand.png" alt="" />
    </span>
  </>
);

export const Step1 = () => (
  <div className="step step1 active">
    <YellowBlock
      title="Давайте знакомиться!"
      text="Ответьте, пожалуйста, на несколько простых вопросов"
    />
    <MainBlock keksBlock={KeksBlock}>
      <InfoParams />
      <div className="block-line" />

      <div className="block-main__content">
        <PersonalForm />
      </div>
    </MainBlock>
  </div>
);
