import { axios } from 'src/api/axios';
import ReactRecaptcha3 from 'react-google-recaptcha3';
import { config } from 'src/dict/config';

const { RECAPTCHA_KEY } = config;

export const initReCaptchaSign = () => ReactRecaptcha3.init(RECAPTCHA_KEY);

export const getTokenReCaptchaSign = () => ReactRecaptcha3.getToken();

export const sendFeedbackSign = (data) => axios.post('/feedback/send', data);
