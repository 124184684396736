import { sample } from 'effector';
import { spread } from 'patronum/spread';
import { isEmpty } from 'src/lib/lodash';
import { $history } from 'src/models/Helper/History';
import { $enqueueSnackbar } from 'src/models/Helper/Notification';
import { closeMenuMobileFn } from 'src/models/Helper/MenuMobile';
import { toggleMakeLoanFn } from 'src/models/Loan/Application';
import {
  AppGate, RouteGate, appDomain,
  // события
  mountAppFn, resetStatesFn,
  // хранилища
  $initApp,
  $pathnameUrl, $firstPage, $navigator, $pathParams,
  // эффекты
} from './index';

// при создании любого стора крепим обработчик очистки на событие resetStates
appDomain.onCreateStore((store) => {
  store.reset(resetStatesFn);
});

/// ///////////////////////////////////////////////////////////
// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ===================== БЛОК ОПЕРАТОРОВ =================== //
/// ///////////////////////////////////////////////////////////

sample({
  clock: AppGate.state,
  filter: ({ enqueueSnackbar }) => !isEmpty(enqueueSnackbar),
  target:
    [
      // распределяем данные, полученные через Gate по хранилищам
      spread({
        targets: {
          navigator: $navigator,
          pathname: $pathnameUrl,
          pathParams: $pathParams,
          firstPage: $firstPage,
          // уведомления
          enqueueSnackbar: $enqueueSnackbar,
        },
      }),
      mountAppFn,
    ],
});

// При первом заходе на шаблон (определяется через Gate на самом верхнем уровне layout-a)
sample({
  clock: RouteGate.state,
  filter: ({ pathname }) => !isEmpty(pathname),
  target:
    [
      // распределяем данные, полученные через Gate по хранилищам
      spread({
        targets: {
          history: $history,
          pathname: $pathnameUrl,
          pathParams: $pathParams,
        },
      }),
    ],
});

// тут мы определяем на сайт только сейчас зашли или прошли по внутренней ссылке
sample({
  clock: $pathnameUrl,
  source: [$pathnameUrl, $firstPage],
  filter: ([pathnameUrl, firstPage]) => !isEmpty(firstPage) && !isEmpty(pathnameUrl) && firstPage !== pathnameUrl,
  fn: () => false,
  target: [
    // записываем false, чтобы понимать что юзер на сайт уже был на сайте
    $initApp,
  ],
});

sample({
  clock: $pathnameUrl,
  target: [
    closeMenuMobileFn,
    toggleMakeLoanFn.prepend(() => false),
  ],
});
