import {
  sample, attach,
} from 'effector';
import {
  // события
  pushHistoryFn,
  // хранилища,
  $history,
  // эффекты
  pushHistoryFx,
} from './index';

/// ///////////////////////////////////////////////////////////
// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ===================== БЛОК ОПЕРАТОРОВ =================== //
/// ///////////////////////////////////////////////////////////

// редирект через history.push
sample({
  clock: pushHistoryFn,
  target: attach({
    effect: pushHistoryFx,
    source: $history,
    mapParams: (path, history) => ({ history, path }),
  }),
});
