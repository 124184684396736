import { sample, attach } from 'effector';
import { isEmpty } from 'src/lib/lodash';
import { $applicationId } from 'src/models/Loan/Application';

import {
  createDocsFn, getDocsFn, getDocFn,
  createDocsFx, getDocsFx, getDocFx,
  openDocFx,
} from './index';

/// ///////////////////////////////////////////////////////////
// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ===================== БЛОК ОПЕРАТОРОВ =================== //
/// ///////////////////////////////////////////////////////////

sample({
  clock: sample({
    clock: createDocsFn,
    source: createDocsFx.pending,
    filter: (pending) => isEmpty(pending),
  }),
  target: attach({
    effect: createDocsFx,
    source: $applicationId,
    mapParams: (_, applicationId) => applicationId,
  }),
});

sample({
  clock: getDocsFn,
  target: getDocsFx,
});

sample({
  clock: getDocFn,
  target: getDocFx,
});

sample({
  clock: getDocFx.done,
  target: openDocFx.prepend(({ params: { docName }, result }) => ({
    data: result,
    fileName: docName,
    type: 'pdf',
    typeMeme: 'application/pdf',
  })),
});
