import {
  attach, sample, split, restore, combine,
} from 'effector';
import persist from 'effector-localstorage';
import { debounce } from 'patronum/debounce';
import { spread } from 'patronum/spread';
import { get, isEmpty } from 'src/lib/lodash';
import { getDateReverse } from 'src/lib/date';
import { getErrorFromDict } from 'src/lib/errors';
import {
  getValueFromMask,
  maskPassportId, maskPassportSeries, maskPassportCodeDepartment, maskDate, maskSnils,
} from 'src/lib/mask';
import { REG_PATH } from 'src/dict/path';
import { validate } from 'src/dict/config';
import { REG_STEP2_FIELDS } from 'src/dict/fields';
// import { isValidPassportDate } from 'src/lib/date';
import { pushHistoryFn } from 'src/models/Helper/History';
import { jumpFocusFieldFn } from 'src/models/Helper/Ref';
import { storageRemoveFn, storageSetFn } from 'src/models/Helper/Storage';
import { hideLoaderButtonFn, showLoaderButtonFn } from 'src/models/Helper/Loader';
import { notifyErrorFn } from 'src/models/Helper/Notification';
import {
  loadDataToFormOperator, setFormFieldsOperator,
} from 'src/models/Registration';
import { sendYMGoalFx } from 'src/models/Statistic';
import {
  $user,
  updateAdditionalInfoUserFx,
} from 'src/models/User';
import { $messagesDict } from 'src/models/Dictionaries';
import {
  addPassportDataFx,
  addPassportDataFn, choiceCodeFMSFn, clearCodeFMSFn, notChoiceCodeFMSFn,
  step2Form, submitFormFn, $isPassportPage,
} from './index';

const { DATE } = validate;
const { PASSPORT, ADDRESS, REGISTRATION } = REG_PATH;
const {
  PASSPORT_DATE_ISSUE, CODE_DEPARTMENT, SNILS,
  PASSPORT_ISSUE_BY, PASSPORT_SERIES, PASSPORT_ID, PLACE_BIRTH,
} = REG_STEP2_FIELDS;

/// ///////////////////////////////////////////////////////////
// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ===================== БЛОК ОПЕРАТОРОВ =================== //
/// ///////////////////////////////////////////////////////////

// загрузка из LS
loadDataToFormOperator({
  form: step2Form,
  key: PASSPORT,
});

// сохранение в LS
persist({
  store: step2Form.$values,
  key: PASSPORT,
});

// заполняем поля данными с ЕСИА (если таковые есть)
setFormFieldsOperator({
  form: step2Form,
  fields: [PASSPORT_SERIES, PASSPORT_ID, PLACE_BIRTH, PASSPORT_DATE_ISSUE, CODE_DEPARTMENT, PASSPORT_ISSUE_BY, SNILS],
  key: PASSPORT,
  combineValues: combine($user, (user) => ({
    ...get(user, 'esia_data.identity', {}),
    [SNILS]: getValueFromMask(get(user, `info.${SNILS}`, ''), maskSnils),
    [PASSPORT_SERIES]: getValueFromMask(get(user, `esia_data.identity.${PASSPORT_SERIES}`, ''), maskPassportSeries),
    [PASSPORT_ID]: getValueFromMask(get(user, `esia_data.identity.${PASSPORT_ID}`, ''), maskPassportId),
    [CODE_DEPARTMENT]: getValueFromMask(
      get(user, `esia_data.identity.${CODE_DEPARTMENT}`, ''), maskPassportCodeDepartment,
    ),
    [PLACE_BIRTH]: get(user, 'esia_data.birth_place', null),
    [PASSPORT_DATE_ISSUE]: !isEmpty(get(user, `esia_data.identity.${PASSPORT_DATE_ISSUE}`, ''))
      ? getValueFromMask(getDateReverse(get(user, `esia_data.identity.${PASSPORT_DATE_ISSUE}`)), maskDate) : '',
    // user,
  })),
});

// step2Form.$values.watch((state) => console.log('step2Form state:', state));

// при ошибке не перескаиваем
sample({
  clock: sample({
    clock: step2Form.fields[PASSPORT_DATE_ISSUE].onChange,
    source: step2Form.fields[PASSPORT_DATE_ISSUE].$value,
    filter: (dateBirth) => {
      const len = dateBirth.replace(/[^\d.]/g, '').length;
      return len === DATE.LENGTH;
    },
  }),
  target: step2Form.fields[PASSPORT_DATE_ISSUE].validate,
});

sample({
  clock: sample({
    clock: step2Form.fields[PASSPORT_DATE_ISSUE].validate,
    source: step2Form.fields[PASSPORT_DATE_ISSUE].$errors,
    filter: (errors) => isEmpty(errors),
  }),
  fn: () => CODE_DEPARTMENT,
  target: jumpFocusFieldFn,
});

sample({
  clock: debounce({
    source: submitFormFn,
    timeout: 10,
  }),
  source: step2Form.fields[SNILS].$value,
  filter: (value) => {
    const val = value.replace(/[\s-]/g, '').replace('___________', '');
    return isEmpty(val);
  },
  // target: step2Form.fields[SNILS].validate,
  target:
    attach({
      effect: () => {
        step2Form.fields[SNILS].addError({
          rules: 'required',
          errorText: 'Данное поле обязательно для заполнения  ',
        });
      },
    }),
});

sample({
  clock: step2Form.formValidated,
  target: [
    showLoaderButtonFn,
    addPassportDataFn,
  ],
});

sample({
  clock: choiceCodeFMSFn,
  fn: ({ code, name }) => ({ code, name }),
  target: spread({
    targets: {
      code: step2Form.fields[CODE_DEPARTMENT].$value,
      name: step2Form.fields[PASSPORT_ISSUE_BY].$value,
    },
  }),
});

sample({
  clock: clearCodeFMSFn,
  fn: () => '',
  target: step2Form.fields[PASSPORT_ISSUE_BY].$value,
});

sample({
  clock: debounce({
    source: notChoiceCodeFMSFn,
    timeout: 300,
  }),
  source: [
    step2Form.fields[CODE_DEPARTMENT].$value,
    step2Form.fields[PASSPORT_ISSUE_BY].$value,
  ],
  fn: ([code, name]) => ({ inValidField: !isEmpty(code) && isEmpty(name) }),
  target: [
    attach({
      effect: (_, { inValidField }) => {
        if (inValidField) {
          step2Form.fields[CODE_DEPARTMENT].addError({
            rules: 'required',
            errorText: 'Пожалуйста, выберите вариант из выпадающего списка',
          });
        } else {
          step2Form.fields[CODE_DEPARTMENT].validate();
        }
      },
    }),
  ],
});

sample({
  clock: addPassportDataFn,
  fn: (data) => {
    const arrDate = data[PASSPORT_DATE_ISSUE].split('.');
    const issuedAt = `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}`;
    return ({
      ...data,
      [PASSPORT_ID]: data[PASSPORT_ID].replace(' ', ''),
      [PASSPORT_SERIES]: data[PASSPORT_SERIES].replace(' ', ''),
      [PASSPORT_DATE_ISSUE]: issuedAt,
      type: 'passport',
    });
  },
  target: addPassportDataFx,
});

// обрабатываем ошибку Паспорта
split({
  clock: addPassportDataFx.failData,
  source: restore(addPassportDataFx.fail, {}),
  match: {
    error422: (error) => (get(error, 'error.response.status') === 422),
    error500: (error) => (get(error, 'error.response.status') === 500),
  },
  cases: {
    error422: [
      hideLoaderButtonFn,
      attach({
        source: $messagesDict,
        effect: (messagesDict, value) => {
          const keySeries = get(value, `response.data.errors.${PASSPORT_SERIES}.0`);
          const keyNumber = get(value, `response.data.errors.${PASSPORT_ID}.0`);
          step2Form.fields[PASSPORT_ID].addError({
            rules: 'required',
            errorText: messagesDict[keyNumber],
          });
          step2Form.fields[PASSPORT_SERIES].addError({
            rules: 'required',
            errorText: messagesDict[keySeries],
          });
          notifyErrorFn(getErrorFromDict('Ошибка пасортных данных')({ dict: messagesDict, data: value }));
        },
      }),
    ],
    error500: [
      hideLoaderButtonFn,
      notifyErrorFn.prepend(() => 'Упс, что-то пошло не так! Обновите страницу и попробуйте еще раз. '
        + 'Если ошибка повторится, пожалуйста, обратитесь в службу поддержки.'),
    ],
    __: [
      hideLoaderButtonFn,
      notifyErrorFn.prepend((error) => get(error, 'error.response.data.message')),
    ],
  },
});

sample({
  // при успешной записи данных - обновляем снилс
  clock: addPassportDataFx.done,
  source: step2Form.fields[SNILS].$value,
  fn: (value) => ({ [SNILS]: `${value.replace(/[\s-]/g, '')}` }),
  target: [
    updateAdditionalInfoUserFx,
  ],
});

// обрабатываем ошибку СНИЛС
split({
  clock: updateAdditionalInfoUserFx.failData,
  source: restore(updateAdditionalInfoUserFx.fail, {}),
  match: {
    error422: (error) => (get(error, 'error.response.status') === 422),
    error500: (error) => (get(error, 'error.response.status') === 500),
  },
  cases: {
    error422: [
      hideLoaderButtonFn,
      attach({
        source: $messagesDict,
        effect: (messagesDict, value) => {
          const key = get(value, 'error.response.data.message');
          step2Form.fields[SNILS].addError({
            rules: 'required',
            errorText: messagesDict[key],
          });
        },
      }),
    ],
    error500: [
      hideLoaderButtonFn,
      notifyErrorFn.prepend(() => 'Упс, что-то пошло не так! Обновите страницу и попробуйте еще раз. '
        + 'Если ошибка повторится, пожалуйста, обратитесь в службу поддержки.'),
    ],
    __: [
      hideLoaderButtonFn,
      notifyErrorFn.prepend((error) => get(error, 'error.response.data.message')),
    ],
  },
});

sample({
  // при завершении 2-ого шага:
  clock: updateAdditionalInfoUserFx.done,
  filter: $isPassportPage,
  target: [
    storageSetFn.prepend(() => ({
      step: ADDRESS,
    })),
    sendYMGoalFx.prepend(() => 'REG_PASSP_DATA_SENT'),
  ],
});

sample({
  // при завершении 2-ого шага:
  clock: debounce({
    source: updateAdditionalInfoUserFx.done,
    timeout: 100,
  }),
  filter: $isPassportPage,
  target: [
    // очищаем форму от данных
    // step2Form.reset,
    // редиректим юзера на след шаг
    pushHistoryFn.prepend(() => `/${REGISTRATION}/${ADDRESS}`),
    // снимаем блок с кнопки
    hideLoaderButtonFn,
    storageRemoveFn.prepend(() => [PASSPORT]),
  ],
});
