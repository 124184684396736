import { sample } from 'effector';
import { debounce } from 'patronum/debounce';
import { get } from 'src/lib/lodash';
import { FEEDBACK_FIELDS } from 'src/dict/fields';
import { $email, $user } from 'src/models/User';
// import { $isProfilePage } from 'src/models/Profile';
import { notifyErrorFn, notifySuccessFn } from 'src/models/Helper/Notification';
import { hideLoaderButtonFn, showLoaderButtonFn } from 'src/models/Helper/Loader';
import {
  $isFeedbackPage, $tokenReCaptcha,
  initReCaptchaFx, getTokenReCaptchaFx, sendFeedbackFx,
  feedbackForm,
} from './index';

const {
  EMAIL, PHONE, FIRST_NAME, LAST_NAME, TOKEN,
} = FEEDBACK_FIELDS;

/// ///////////////////////////////////////////////////////////
// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
/// ///////////////////////////////////////////////////////////

$tokenReCaptcha.on(
  getTokenReCaptchaFx.doneData, (_, result) => result,
);

/// ///////////////////////////////////////////////////////////
// ===================== БЛОК ОПЕРАТОРОВ =================== //
/// ///////////////////////////////////////////////////////////

sample({
  clock: $isFeedbackPage,
  target: feedbackForm.resetErrors,
});

sample({
  clock: $isFeedbackPage,
  // source: $isProfilePage,
  filter: (isProfilePage, isFeedbackPage) => isFeedbackPage, //  && !isProfilePage,
  target: initReCaptchaFx,
});

sample({
  clock: debounce({
    source: initReCaptchaFx.done,
    timeout: 2000,
  }),
  target: getTokenReCaptchaFx,
});

sample({
  clock: getTokenReCaptchaFx.fail,
  target: notifyErrorFn.prepend(
    (data) => get(data, 'error.message', 'ООшибка получения токена'),
  ),
});

sample({
  clock: debounce({
    source: feedbackForm.fields[EMAIL].onBlur,
    timeout: 300,
  }),
  target: feedbackForm.fields[EMAIL].validate,
});

sample({
  clock: [$user, $email],
  source: [$user, $email],
  // заполняем данные только в ЛК
  // так как поля для юзера и для обратной связи совпадают то:
  fn: ([user, email]) => ({
    [EMAIL]: email,
    [PHONE]: get(user, PHONE, ''),
    [FIRST_NAME]: get(user, `profile.${FIRST_NAME}`, ''),
    [LAST_NAME]: get(user, `profile.${LAST_NAME}`, ''),
  }),
  target: feedbackForm.setForm,
});

sample({
  clock: feedbackForm.formValidated,
  source: $tokenReCaptcha,
  fn: (token, form) => ({
    ...form,
    [PHONE]: `${Number(form[PHONE].replace(/\D+/g, ''))}`,
    [TOKEN]: token,
  }),
  target: [
    showLoaderButtonFn,
    sendFeedbackFx,
  ],
});

sample({
  clock: sendFeedbackFx.done,
  target: [
    feedbackForm.reset,
    notifySuccessFn.prepend(() => 'Сообщение отправлено'),
  ],
});

sample({
  clock: sendFeedbackFx.fail,
  target: notifyErrorFn.prepend(
    (data) => get(data, 'error.response.data.message', ''),
  ),
});

sample({
  clock: sendFeedbackFx.finally,
  target: hideLoaderButtonFn,
});
