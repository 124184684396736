import React from 'react';
import { GreenBlock, MainBlock } from 'src/ui/components';
import { SuccessLoan } from './children/SuccessLoan';

const KeksBlock = () => (
  <>
    <div className="keks keks1">
      <img src="/img/other/successfull-keks.png" alt="" />
    </div>
    <div className="coin">
      <img src="/img/other/successfull-coin.png" alt="" />
    </div>
  </>
);

export const Success = () => (
  <div className="step">
    <GreenBlock
      title="Займ одобрен для выдачи!"
      text="Осталось подтвердить сумму и срок"
    />
    <MainBlock keksBlock={KeksBlock}>
      <SuccessLoan />
    </MainBlock>
  </div>
);
