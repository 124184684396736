import React, { useEffect } from 'react';
import { useUnit } from 'effector-react';
import { isEmpty } from 'src/lib/lodash';
import { getDateCard } from 'src/lib/date';
import { getPaymentType } from 'src/lib/payment';
// import { PAYMENT } from 'src/dict/payment';
import {
  getItemsPaymentFn, removeBindCardBankFn, addCardBankFn,
  $itemsPayment,
  getItemsPaymentFx, // startBindCardBankFn,
} from 'src/models/Payment';
import { closeModalFn, openModalFn } from 'src/models/components/Modal';
import { Button } from 'src/ui/components';
import { RemoveCard } from './children/RemoveCard';
// const { VISA, MASTERCARD } = PAYMENT;

export const CardsNot = React.memo(() => (
  <div className="noYet">
    <div className="noYet-img">
      <img src="/img/profile/bank-card-credit.png" alt="" />
    </div>
    <h1 className="noYet-title">Вы пока не привязали ни одной банковской карты</h1>
    {/* <p className="noYet-text">Активных займов пока нет, воспользуйтесь нашим предложением</p> */}
  </div>
));

export const Cards = React.memo(() => {
  useEffect(() => {
    getItemsPaymentFn();
  }, []);
  const [isLoading, itemsPayment] = useUnit([getItemsPaymentFx.pending, $itemsPayment]);
  if (isLoading && isEmpty(itemsPayment)) {
    return 'Loading';
  }

  const count = !isEmpty(itemsPayment) ? itemsPayment.length : 0;
  return (
    <>
      <h1 className="title">Мои карты</h1>

      {isEmpty(itemsPayment) && <CardsNot />}

      <div className="cards-items newLoan">
        {!isEmpty(itemsPayment) && itemsPayment.map(
          ({ id, paymentable_data: paymentableDate, paymentable_id: paymentableId }) => {
            const {
              first_six: firstChar,
              last_four: fourChar,
              bank_name: name,
              expire_date: date,
            } = paymentableDate;
            const typePayment = getPaymentType(firstChar).toUpperCase();
            const isExistImg = ['VISA', 'MASTERCARD'].includes(typePayment);

            return (
              <div className="cards-item" key={id}>
                {/* <div className="cards-radio default-radio">
          <label htmlFor="visa" className="radio-btn radio-btn-left">
            <input autoComplete="nope" type="radio" name="mainCards" id="visa" defaultChecked />
            Основная карта
            <div className="radio-block" />
          </label>
        </div> */}
                <div className="cards-block">
                  {isExistImg && (
                  <div className="cards-block__logo">
                    <img src={`/img/profile/${typePayment.toLowerCase()}.svg`} alt="" />
                  </div>
                  )}

                  <div className="cards-block__title">
                    {typePayment}
                    {' '}
                    <span className="number">
                      ****
                      {fourChar}
                    </span>
                  </div>
                  <div className="cards-block__num">{name}</div>
                </div>
                <h3 className="cards-title">
                  {typePayment}
                  {' '}
                  <span className="num">
                    ****
                    {fourChar}
                  </span>
                </h3>
                <p className="cards-date">
                  {getDateCard(date)}
                </p>
                {(count > 1) && (
                <div className="cards-button">
                  <Button
                    onClick={openModalFn.prepend(() => ({
                      importantOperation: true,
                      className: 'modal-card-remove',
                      content: RemoveCard,
                      // указываем свое событие на закрытие модального окна
                      closeCallback: closeModalFn,
                      successCallback: removeBindCardBankFn.prepend(() => paymentableId),
                      props: {
                        numberCard: `*${fourChar}`,
                      },
                    }))}
                    className="default-btn border"
                  >
                    Удалить
                  </Button>
                </div>
                )}
              </div>
            );
          },
        )}

        <div className="cards-item new" onClick={addCardBankFn}>
          <div className="cards-addNew-button">
            <img src="/img/profile/add.svg" alt="" />
          </div>
        </div>
      </div>
    </>
  );
});

export const CardsOld = React.memo(() => (
  <>
    <h1 className="title">Мои карты</h1>
    <div className="cards-items">
      <div className="cards-item">
        {/* <div className="cards-radio default-radio">
          <label htmlFor="visa" className="radio-btn radio-btn-left">
            <input autoComplete="nope" type="radio" name="mainCards" id="visa" defaultChecked />
            Основная карта
            <div className="radio-block" />
          </label>
        </div> */}
        <div className="cards-block">
          <div className="cards-block__logo"><img src="/img/profile/visa.svg" alt="" /></div>
          <div className="cards-block__title">
            VISA
            <span className="number">****4321</span>
          </div>
          <div className="cards-block__num">Bogdan Nikitin</div>
        </div>
        <h3 className="cards-title">
          VISA
          <span className="num">****4321</span>
        </h3>
        <p className="cards-date">До 07/23</p>
        <div className="cards-button">
          <a href="#" className="default-btn border">Удалить</a>
        </div>
      </div>
      <div className="cards-item">
        {/* <div className="cards-radio default-radio">
          <label htmlFor="master" className="radio-btn radio-btn-left">
            <input autoComplete="nope" type="radio" name="mainCards" id="master" />
            Основная карта
            <div className="radio-block" />
          </label>
        </div> */}
        <div className="cards-block">
          <div className="cards-block__logo"><img src="/img/profile/master.svg" alt="" /></div>
          <div className="cards-block__title">
            MASTER CARD
            <span className="number">****4321</span>
          </div>
          <div className="cards-block__num">Bogdan Nikitin</div>
        </div>
        <h3 className="cards-title">
          MASTER CARD
          <span className="num">****4321</span>
        </h3>
        <p className="cards-date">До 07/23</p>
        <div className="cards-button">
          <a href="#" className="default-btn border">Удалить</a>
        </div>
      </div>
      <a data-fancybox href="#addNewCard" className="cards-item new">
        <div className="cards-addNew-button">
          <img src="/img/profile/add.svg" alt="" />
        </div>
      </a>
    </div>
  </>
));
