import React, { Suspense, useEffect } from 'react';
import { useGate } from 'effector-react';
import {
  BrowserRouter, Switch, Redirect,
} from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { YMInitializer } from 'react-yandex-metrika';
import { get } from 'src/lib/lodash';
import { PAGES_PATH } from 'src/dict/path';
import { config } from 'src/dict/config';
import { AppGate } from 'src/models/App';
import { backFromFirstPageFn } from 'src/models/Helper/History';
// import { $isAuthenticated } from 'src/models/Auth';
import {
  BasicRoute as Route,
} from 'src/ui/components/Routes';
import { ModalWindow } from 'src/ui/components';
import { Home } from './children/Home/Home';
import { Login } from './children/Login';
import { Bind } from './children/Bind';
import { Regulation } from './children/Regulation';
import { Feedback } from './children/Feedback/Feedback';
import { RecoveryRoutes } from './children/Recovery/RecoveryRoutes';
import { RegistrationRoutes } from './children/Registration/RegistrationRoutes';
import { DocumentRoutes } from './children/Documents/DocumentRoutes';
import { ProfileRoutes } from './children/Profile/ProfileRoutes';
import { Loan } from './children/Repayment/Loan/Loan';
import { Result } from './children/Repayment/Payment/Result';

import 'react-toastify/dist/ReactToastify.css';

const {
  LOGIN, RECOVERY, REGISTRATION, PROFILE, FEEDBACK, DOCUMENTS,
  BIND, REGULATION, ESIA_RETURN_URL, LOAN_REPAYMENT, PAYMENT_RESULT,
} = PAGES_PATH;

export const App = () => {
  useEffect(() => {
    if (window.history && window.history.replaceState) {
      window.history.pushState('forward', null, window.location.href);
      window.history.forward();
    }
    window.addEventListener('popstate', backFromFirstPageFn);
  }, []);

  useGate(AppGate, {
    firstPage: get(window, 'location.pathname', ''),
    pathname: get(window, 'location.pathname', ''),
    pathParams: get(window, 'location.search', ''),
    navigator: get(window, 'navigator', ''),
    enqueueSnackbar: toast,
  });
  return (
    <>
      <BrowserRouter>
        <Suspense fallback="Загрузка...">

          <Switch>
            <Route path="/" component={Home} exact />
            <Route path={`/${LOGIN}`} component={Login} exact />
            <Route path={`/${BIND}`} component={Bind} exact />
            <Route path={`${ESIA_RETURN_URL}`} component={Bind} exact />
            <Route path={`/${REGULATION}`} component={Regulation} exact />
            <Route path={`/${RECOVERY}`} component={RecoveryRoutes} />
            <Route path={`/${REGISTRATION}`} component={RegistrationRoutes} />
            <Route path={`/${DOCUMENTS}`} component={DocumentRoutes} />
            <Route path={`/${FEEDBACK}`} component={Feedback} />
            <Route path={`/${PROFILE}`} component={ProfileRoutes} />
            <Route path={`/${LOAN_REPAYMENT}`} component={Loan} exact />
            <Route path={`/${PAYMENT_RESULT}/:id`} component={Result} exact />
            <Route path={`/${PAYMENT_RESULT}`} component={Result} exact />
            {/* <Redirect from="/m" to="/?utm_source=mts&utm_medium=sms&utm_campaign=test_m" /> */}
            <Redirect from="/m2" to="/?utm_source=mts&utm_campaign=2" />
            <Redirect from="/t2" to="/?utm_source=t2&utm_campaign=1" />
            <Redirect from="/t22" to="/?utm_source=t2&utm_campaign=2" />
            <Redirect to="/" />
          </Switch>
        </Suspense>
      </BrowserRouter>
      <ToastContainer limit="3" />
      <ModalWindow />
      <YMInitializer
        accounts={[Number.parseInt(config.YANDEX_METRIKA_NUMBER, 10)]}
        options={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true,
        }}
      />
    </>

  );
};

/*
 <Route path="/about" component={About} />
          <Route path="/why-us" component={WhyUs} />
          <Route path="/reviews" component={Reviews} />
          <Route path="/feedback" component={Feedback} />
          {!isAuthenticated && <Route path="/login" component={Login} />}
          <Route path="/registration" component={Registration} />
          <PrivateRoute path="/profile" component={ProfileRoutes} />
          <Redirect to="/" />
 */
