import React from 'react';
import { useUnit } from 'effector-react';
import {
  PROFILE_PATH, PROFILE_NEW_LOAN_PATH, PROFILE_NEW_LOAN_CARD_PATH,
} from 'src/dict/path';
import { YellowBlock, MainBlock } from 'src/ui/components';
import {
  Card, Contact, BankTransfer,
} from 'src/pages/children/Profile/children/shared/PaymentList';
import { $agreementToRepayId } from 'src/models/Loan/Agreement';

const { PROFILE, LOAN } = PROFILE_PATH;
const { PAYMENT } = PROFILE_NEW_LOAN_PATH;
const { CARDS } = PROFILE_NEW_LOAN_CARD_PATH;

const KeksBlock = () => (
  <>
    <div className="keks keks1">
      <img src="/img/other/successfull-keks.png" alt="" />
    </div>
    <div className="coin">
      <img src="/img/other/successfull-coin.png" alt="" />
    </div>
  </>
);

export const ListChoice = () => {
  const agreementToRepayId = useUnit($agreementToRepayId);
  // достаем id
  return (
    <div className="step">
      <YellowBlock
        title="Выбор способа погашения."
        text="Выберите способ погашения займа."
      />
      <MainBlock keksBlock={KeksBlock}>
        <div className="block-main__content">
          <Card url={`/${PROFILE}/${LOAN}/${agreementToRepayId}/${PAYMENT}/${CARDS}`} />
          <div className="block-line" />

          <Contact />
          <div className="block-line" />

          <BankTransfer />
          <div className="block-line" />
        </div>
      </MainBlock>
    </div>
  );
};
