import React from 'react';
import { pushHistoryFn } from 'src/models/Helper/History';
import { ButtonWithLoader as Button } from 'src/ui/components/Form';

export const Card = React.memo(({ url }) => (
  <div className="block-payment">
    <div className="block-payment__top">
      <div className="block-payment__img desktop">
        <img src="/img/form/visamastercardmir.png" alt="" />
      </div>
      <div className="block-payment__img mobile">
        <img src="/img/form/visamastercardmir-mobile.png" alt="" />

      </div>
      <div className="block-payment__info">
        <h2 className="block-payment__title">Банковская карта</h2>
        <p className="block-payment__text">Время - мгновенно</p>
      </div>
      <Button
        className="nextStep block-payment__btn desktop"
        onClick={pushHistoryFn.prepend(() => url)}
      >
        Выбрать
      </Button>
    </div>
    <p className="block-payment__text">
      Самый быстрый и удобный способ получения денег. Перевод осуществляется мгновенно.
    </p>
    <Button
      className="nextStep block-payment__btn mobile"
      onClick={pushHistoryFn.prepend(() => url)}
    >
      Выбрать
    </Button>

  </div>
));
