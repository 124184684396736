import React from 'react';
import { useStore } from 'effector-react';
import { get, isEmpty } from 'src/lib/lodash';
import { getDate } from 'src/lib/date';
import { $applicationDetail } from 'src/models/Loan/Application';
import { notifyErrorFn } from 'src/models/Helper/Notification';

export const WaitNewLoan = React.memo(() => {
  const nextAttempt = get(useStore($applicationDetail), 'next_attempt_at', '');

  if (isEmpty(nextAttempt)) return <div />;

  const dateAttempt = getDate(nextAttempt);

  return (
    <div
      onClick={notifyErrorFn.prepend(
        () => `Вы пока не можете отправить заявку на займ, пожалуйста, попробуйте после ${dateAttempt}`,
      )}
    >
      <button
        type="button"
        className="default-btn button-open disabled"
        // disabled
      >
        Получить деньги
      </button>
    </div>
  );
});
