import { attach, sample } from 'effector';
import { isEmpty } from 'src/lib/lodash';
import { $messagesDict } from 'src/models/Dictionaries';
import {
  // события
  notifySuccessFn, notifyErrorFn,
  // хранилища,
  $enqueueSnackbar,
  // эффекты
  sendSuccessFx, sendErrorFx,
} from './index';

/// ///////////////////////////////////////////////////////////
// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
/// ///////////////////////////////////////////////////////////

// Нотификейшены
/* $enqueueSnackbar
  .on(notifySuccessFn, (notify, message) => {
    notify.success(message, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: 'dark',
    });
  })
  .on(notifyErrorFn, (notify, message) => {
    notify.error(message, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: 'dark',
    });
  });
*/

/// ///////////////////////////////////////////////////////////
// ===================== БЛОК ОПЕРАТОРОВ =================== //
/// ///////////////////////////////////////////////////////////

// редирект через history.push
sample({
  clock: notifySuccessFn,
  fn: (data) => data,
  target: [
    attach({
      effect: sendSuccessFx,
      source: $enqueueSnackbar,
      mapParams: (message, notify) => ({
        // eslint-disable-next-line react/react-in-jsx-scope
        notify, message,
      }),
    }),
  ],
});

// редирект через history.push
sample({
  clock: notifyErrorFn,
  fn: (data) => data,
  target: [
    attach({
      effect: sendErrorFx,
      source: [$enqueueSnackbar, $messagesDict],
      mapParams: (message, [notify, dataDict]) => ({
        notify, message: (!isEmpty(dataDict[message]) ? dataDict[message] : message),
      }),
    }),
  ],
});

// $messagesDict.watch((state) => console.log('$messagesDict:', state));
