import React from 'react';
import { YellowBlock, MainBlock } from 'src/ui/components';
import { VerificationForm } from './children/VerificationForm';

const KeksBlock = () => (
  <>
    <div className="keks keks6">
      <img src="/img/form/keks-6.png" alt="" />
    </div>
    <span className="keks6-hand">
      <img src="/img/form/keks-6-hand.png" alt="" />
    </span>
  </>
);

export const Step5 = () => (
  <div className="step step6 active">
    <YellowBlock
      title="Подтвердите номер мобильного телефона!"
      text="Введите, пожалуйста, код подтверждения из SMS сообщения"
    />
    <MainBlock keksBlock={KeksBlock}>
      <div className="block-main__content">
        <VerificationForm />
      </div>
    </MainBlock>
  </div>
);
