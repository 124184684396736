import React, { useEffect } from 'react';
import { useUnit } from 'effector-react';
import { isEmpty } from 'src/lib/lodash';
import { getDateCard } from 'src/lib/date';
import { getPaymentType } from 'src/lib/payment';
import { sendToRepayFn } from 'src/models/Loan/Agreement';
import {
  getItemsPaymentFn, startBindCardBankFn,
  $itemsPayment,
  getItemsPaymentFx,
} from 'src/models/Payment';
import { hideLoaderNotTimePageFn } from 'src/models/Helper/Loader';

import 'src/ui/styles/CardBankIframe.css';

// const { VISA, MASTERCARD } = PAYMENT;

export const Cards = React.memo(() => {
  const [isLoading, itemsPayment] = useUnit([getItemsPaymentFx.pending, $itemsPayment]);
  useEffect(() => {
    getItemsPaymentFn();
  }, []);

  useEffect(() => {
    if (!isEmpty(itemsPayment) && !isLoading) {
      hideLoaderNotTimePageFn();
    }
  }, [isLoading, itemsPayment]);

  if (isLoading && isEmpty(itemsPayment)) {
    return 'Loading';
  }

  return (
    <>
      <div className="block-subtitle">
        Выберите банковскую карту для погашения займа или добавьте новую
      </div>

      <div className="cards-items newLoan">
        {!isEmpty(itemsPayment) && itemsPayment.map(
          ({
            id, paymentable_id: paymentableId, paymentable_data: paymentableDate, paymentable_type: paymentType,
          }) => {
            const {
              first_six: firstChar,
              last_four: fourChar,
              bank_name: name,
              expire_date: date,
            } = paymentableDate;
            const typePayment = getPaymentType(firstChar).toUpperCase();
            const isExistImg = ['VISA', 'MASTERCARD'].includes(typePayment);

            return (
              <div className="cards-item" key={id}>
                {/* <div className="cards-radio default-radio">
          <label htmlFor="visa" className="radio-btn radio-btn-left">
            <input autoComplete="nope" type="radio" name="mainCards" id="visa" defaultChecked />
            Основная карта
            <div className="radio-block" />
          </label>
        </div> */}
                <div
                  className="cards-block"
                  onClick={sendToRepayFn.prepend(() => ({
                    paymentId: paymentableId, paymentType,
                  }))}
                >
                  {isExistImg && (
                    <div className="cards-block__logo">
                      <img src={`/img/profile/${typePayment.toLowerCase()}.svg`} alt="" />
                    </div>
                  )}

                  <div className="cards-block__title">
                    {typePayment}
                    {' '}
                    <span className="number">
                      ****
                      {fourChar}
                    </span>
                  </div>
                  <div className="cards-block__num">{name}</div>
                </div>
                <h3 className="cards-title">
                  {typePayment}
                  {' '}
                  <span className="num">
                    ****
                    {fourChar}
                  </span>
                </h3>
                <p className="cards-date">
                  {getDateCard(date)}
                </p>

              </div>
            );
          },
        )}

        <div
          className="cards-item new"
          onClick={startBindCardBankFn}
          /* onClick={openModalFn.prepend(() => ({
            importantOperation: true,
            className: 'modal-addNewCard',
            content: NewCard,
            closeCallback: closeChangeCalcFn,
          }))} */
        >
          <div className="cards-addNew-button">
            <img src="/img/profile/add.svg" alt="" />
          </div>
        </div>
      </div>
    </>
  );
});
