import React from 'react';
import { useUnit } from 'effector-react';
import {
  Redirect,
  Route, Switch, withRouter,
} from 'react-router-dom';
import { get } from 'src/lib/lodash';
import { RECOVERY_PATH } from 'src/dict/path';
import { $routesStepsRecovery, $classNamesMap } from 'src/models/Recovery';
import { Step1, Step2 } from './children';

const { CONFIRM, CHANGE } = RECOVERY_PATH;

const pathMap = {
  [CONFIRM]: Step1,
  [CHANGE]: Step2,
};

export const RecoveryRoutes = withRouter(({ match: { url } }) => {
  const [{ pageClass, sectionClass }, routes] = useUnit([$classNamesMap, $routesStepsRecovery]);
  const baseUrl = url.slice(-1) === '/' ? url.slice(0, Math.max(0, url.length - 1)) : url;

  return (
    <main className={pageClass}>
      <section className={sectionClass}>
        <div className="wrapper">
          <Switch>

            {routes.map(
              // eslint-disable-next-line no-shadow,react-perf/jsx-no-new-function-as-prop
              ({ path }) => (
                <Route
                  exact
                  path={`${baseUrl}/${path}`}
                  component={get(pathMap, path, '')}
                  key={path}
                />
              ),
            )}
            <Redirect exact from={`${baseUrl}`} to={`${baseUrl}/${CONFIRM}`} />
          </Switch>
        </div>
      </section>
    </main>
  );
});
