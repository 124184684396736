import axios from 'axios';
import { config, daData } from 'src/dict/config';

const token = `${config.API_KEY_DADATA}`;

const instance = axios.create({
  baseURL: daData.URL,
  timeout: 5000,
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Token ${token}`,
  },
});

const instanceById = axios.create({
  baseURL: daData.URL_BY_ID,
  timeout: 5000,
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Token ${token}`,
  },
});

export const getDaDataSign = ({ type, data }) => instance.post(`/${type}`, data);
export const getDaDataByIdSign = (data) => instanceById.post('/findById/address', data);
