import { isEmpty } from 'src/lib/lodash';
import { validateSnils, validateRusNumber } from './legal';
import {
  isValidBirthDate, isValidDateCard, isValidRegDate,
} from './date';

// /^(\s*)?(\+)?([ ()+:=_-]?\d[ ()+:=_-]?){11,14}(\s*)?$/;
export const phonePattern = /^(\s*)?(\+)?([ ()+:=_-]?\d[ ()+:=_-]?){11,14}(\s*)?$/;

// export const yearPattern = /^(0?[1-9]|[12]\d|3[01]).(0?[1-9]|1[0-2]).((19|20)\d\d)$/;
export const dateYearPattern = /^(0?[1-9]|[12]\d|3[01]).(0?[1-9]|1[0-2]).(\d{4})$/;

export const emailPattern = /\S+@\S+\.\S+/;
export const letterPattern = /^[\s,.ЁА-яё-]+$/;
export const letterNumPattern = /^[\d/ЁА-яё]+$/;
export const letterAddressPattern = /^[\d\s,.а-яё-]+$/;
export const numHousePattern = /^[\d\s#./\\а-яё№-]+$/;
export const numberPattern = /^\d+$/;
// русские символы, цифры, пробелы, запятые, кавычки, точки, тире, дефисы
export const positionPattern = /^[\d\s,.ЁА-яё’”-]+$/;

export const passportCodeDepartmentPattern = /^\d{3}-\d{3}$/;
export const passportIdPattern = /^\d{3}\s\d{3}$/;
export const passportSeriesPattern = /^(\d{2})(\s)(\d{2})$/;

// export const numberCardPattern = /^\d{4}\s\d{4}\s\d{4}\s\d{4}|\d{4}\s\d{4}\s\d{4}\s\d{4}\s\d{3}$/;
export const numberCardPattern = /^(?:\d{4}\s){3}\d{4}|(?:\d{4}\s){4}\d{3}$/;
export const dateCardPattern = /^(0?[1-9]|1[0-2])\/(\d{2})$/;
export const cvvPattern = /^\d{3}$/;
export const snilsPattern = /^\d{3}-\d{3}-\d{3} \d{2}$/;

export const rules = {
  required: () => ({
    name: 'required',
    validator: (value) => ({
      isValid: !isEmpty(value),
      errorText: 'Данное поле обязательно для заполнения',
    }),
  }),
  requiredCheckbox: () => ({
    name: 'required',
    validator: (value) => ({
      isValid: !isEmpty(value),
      errorText: 'Данное поле обязательно',
    }),
  }),
  requiredAgreementCheckbox: () => ({
    name: 'required',
    validator: (value) => ({
      isValid: !isEmpty(value),
      errorText: 'Необходимо принять условия сервиса',
    }),
  }),
  email: () => ({
    name: 'email',
    validator: (value) => ({
      isValid: emailPattern.test(value),
      errorText: 'Указан некорректный email-адрес',
    }),
  }),
  phone: () => ({
    name: 'phone',
    validator: (value) => ({
      // isValid: phonePattern.test(value),
      isValid: validateRusNumber(value, phonePattern),
      errorText: 'Телефонный номер введен в неверном формате',
    }),
  }),
  number: () => ({
    name: 'number',
    validator: (value) => ({
      isValid: numberPattern.test(value),
      errorText: 'Для ввода допустимы только цифры',
    }),
  }),
  numberCard: () => ({
    name: 'numberCard',
    validator: (value) => ({
      isValid: numberCardPattern.test(value)
        && (function validationCardNum(val) {
          const cardNum = val.replaceAll('_', '').replace(/\s+/g, ' ').trim();
          return cardNum.length === 19 || cardNum.length === 23;
        }(value)),
      errorText: 'Номер некорректен',
    }),
  }),
  dateCard: () => ({
    name: 'dateCard',
    validator: (value) => ({
      isValid: (dateCardPattern.test(value)
        && isValidDateCard(value)),
      errorText: 'Дата некорректна',
    }),
  }),
  cvvCard: () => ({
    name: 'cvvCard',
    validator: (value) => ({
      isValid: cvvPattern.test(value),
      errorText: 'Дата некорректна',
    }),
  }),
  dateBirth: () => ({
    name: 'dateBirth',
    validator: (value) => ({
      isValid: isValidBirthDate(value),
      errorText: 'Введенная дата рождения некорректна',
    }),
  }),
  dateYear: () => ({
    name: 'dateYear',
    validator: (value) => ({
      isValid: dateYearPattern.test(value),
      errorText: 'Дата введена в неверном формате',
    }),
  }),
  isValidRegDate: () => ({
    name: 'isValidRegDate',
    validator: (value) => ({
      isValid: isValidRegDate(value),
      errorText: 'Дата введена в неверном формате',
    }),
  }),
  letter: () => ({
    name: 'letter',
    validator: (value) => ({
      isValid: letterPattern.test(value),
      errorText: 'Введенное значение некорректно',
    }),
  }),
  position: () => ({
    name: 'position',
    validator: (value) => ({
      isValid: positionPattern.test(value),
      errorText: 'Введенное значение некорректно',
    }),
  }),
  letterNum: () => ({
    name: 'apartment',
    validator: (value) => ({
      isValid: letterNumPattern.test(value),
      errorText: 'Введенное значение некорректно',
    }),
  }),
  letterAddress: () => ({
    name: 'letterAddress',
    validator: (value) => ({
      isValid: letterAddressPattern.test(value),
      errorText: 'Введенное значение некорректно',
    }),
  }),
  passportId: () => ({
    name: 'passportId',
    validator: (value) => ({
      isValid: passportIdPattern.test(value),
      errorText: 'Введенное значение некорректно',
    }),
  }),
  passportSeries: () => ({
    name: 'passportSeries',
    validator: (value) => ({
      isValid: passportSeriesPattern.test(value),
      errorText: 'Введенное значение некорректно',
    }),
  }),
  passportCodeDepartment: () => ({
    name: 'passportCodeDepartment',
    validator: (value) => ({
      isValid: passportCodeDepartmentPattern.test(value),
      errorText: 'Введенное значение некорректно',
    }),
  }),
  confirm: () => ({
    name: 'confirm',
    validator: (confirmation, { password }) => ({
      isValid: confirmation === password,
      errorText: 'Пароли не совпадают',
    }),
  }),
  minLength: (min) => ({
    name: 'minLength',
    validator: (value) => ({
      isValid: value.length >= min,
      errorText: `Минимальная длина ${min} символ(а/ов)`,
    }),
  }),
  maxLength: (max) => ({
    name: 'maxLength',
    validator: (value) => ({
      isValid: value.length <= max,
      errorText: `Максимальная длина ${max} символ(а/ов)`,
    }),
  }),
  keyboardLayoutOther: () => ({
    name: 'keyboardLayoutOther',
    validator: (value) => ({
      isValid: !(/[a-z]/gi.test(value)),
      errorText: 'Пожалуйста, смените раскладку клавиатуры',
    }),
  }),
  positionIsEmptySource: ($isStore) => ({
    name: 'positionIsEmptySource',
    source: $isStore,
    validator: (value, form, isStore) => (
      positionPattern.test(value) || isStore
    ),
    errorText: 'Введенное значение некорректно',
  }),
  phoneIsEmptySource: ($isStore) => ({
    name: 'phoneIsEmptySource',
    source: $isStore,
    validator: (value, form, isStore) => (
      // phonePattern.test(value) || isStore
      validateRusNumber(value, phonePattern) || isStore
    ),
    errorText: 'Телефонный номер введен в неверном формате',
  }),
  requiredIsEmptySource: ($isStore) => ({
    name: 'requiredIsEmptySource',
    source: $isStore,
    validator: (value, form, isStore) => !isEmpty(value) || isStore,
    errorText: 'Данное поле обязательно для заполнения',
  }),
  validateSnils: () => ({
    name: 'validateSnils',
    validator: (value) => {
      const val = `${Number(value.replace(/\D+/g, ''))}`;
      return (!isEmpty(val) && validateSnils(value)) || isEmpty(val);
    },
    errorText: 'Введенный СНИЛС некорректен',
  }),
};

// `${Number(data[PHONE].replace(/\D+/g, ''))}`;
