import { combine } from 'effector';
import { appDomain } from 'src/models/App';
import {
  getItemsPaymentSign,
  bindPaymentToAppSign,
  startBindCardBankSign,
  // bindCardBankSign,
  getBindCardBankSign,
  removeBindCardBankSign,
} from 'src/api/signatures/Payment';
import { timerSign } from 'src/signatures/Timer';
import { $isCardsToRepayPage } from 'src/models/Loan/Agreement';
import { $isCardsNewLoanPage } from 'src/models/Loan/Application';
import { $isProfileCardsPage } from 'src/models/Profile';

export const paymentDomain = appDomain.createDomain('Payment');

/// ///////////////////////////////////////////////////////////
// ================== СЛОВАРИ / КОНСТАНТЫ ================== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ СОБЫТИЙ ===================== //
/// ///////////////////////////////////////////////////////////

export const startBindCardBankFn = paymentDomain.createEvent();
export const addCardBankFn = paymentDomain.createEvent();
export const removeBindCardFn = paymentDomain.createEvent();
export const goBindCardsIfFailFn = paymentDomain.createEvent();
export const getFailBindCardBankFn = paymentDomain.createEvent();

// привязка новой карты
export const bindCardBankFn = paymentDomain.createEvent();
// привязываем карту к заявке
export const bindPaymentToAppFn = paymentDomain.createEvent();

export const openIframeAddCardBankFn = paymentDomain.createEvent();

// получение списка карт
export const getItemsPaymentFn = paymentDomain.createEvent();

export const removeBindCardBankFn = paymentDomain.createEvent();

// событие на сохранение нужного контекста при привязки карты
export const savePaymentContextBindCardFn = paymentDomain.createEvent();

// событие после успешной привязки карты
export const afterSuccessBindCardFn = paymentDomain.createEvent();

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ХРАНИЛИЦ ==================== //
/// ///////////////////////////////////////////////////////////

export const $processId = paymentDomain.createStore('');

export const $itemsPayment = paymentDomain.createStore('');

export const $itemsPaymentForExLoan = paymentDomain.createStore('');

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ЭФФЕКТОВ ==================== //
/// ///////////////////////////////////////////////////////////

export const getItemsPaymentFx = paymentDomain.createEffect(getItemsPaymentSign);
export const getItemsPaymentForExLoanFx = paymentDomain.createEffect(getItemsPaymentSign);

export const bindPaymentToAppFx = paymentDomain.createEffect(bindPaymentToAppSign);

export const startBindCardBankFx = paymentDomain.createEffect(startBindCardBankSign);

export const getBindCardBankFx = paymentDomain.createEffect(getBindCardBankSign);
export const timerGetBindCardBankFx = paymentDomain.createEffect(timerSign);
// удаленме банк-ой карты
export const removeBindCardBankFx = paymentDomain.createEffect(removeBindCardBankSign);

// привязка новой карты
// export const bindCardBankFx = paymentDomain.createEffect(bindCardBankSign);

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

export const $isCardsLoanPage = combine($isCardsToRepayPage, $isCardsNewLoanPage, $isProfileCardsPage,
  (isCardsToRepayPage, isCardsNewLoanPage, isProfileCardsPage) => (
    isCardsToRepayPage || isCardsNewLoanPage || isProfileCardsPage));

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ЭФФЕКТОВ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

// тут создаются эффекты через attach

/// ///////////////////////////////////////////////////////////
// ====================== ЮНИТЫ ФОРМ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем формы с помощью effector-forms

/// ///////////////////////////////////////////////////////////
//  ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ФОРМ + (ХРАНИЛИЩ, ЭФФЕКТОВ) //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ========================= ФАБРИКИ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем различные фабрики и хранилища с использованием фабрик
