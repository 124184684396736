import React from 'react';
import {
  Route, Switch, Redirect, withRouter, NavLink,
} from 'react-router-dom';
import { get } from 'src/lib/lodash';
import { CalculatorSidebar } from 'src/ui/components';
import {
  LegalInfo,
  BasicStandards,
  Archive,
  // FinancialStatements,
  // Reports,
} from './children';

import './Documents.css';

const routes = [
  {
    path: 'legal-info',
    component: LegalInfo,
    label: 'Юридическая информация',
    title: 'Юридическая информация',
  },
  {
    path: 'basic-standards',
    component: BasicStandards,
    label: 'Базовые стандарты',
    title: 'Базовые стандарты',
  },
  {
    path: 'archive',
    component: Archive,
    label: 'Архив',
    title: 'Архив',
  },
  /* {
  path: 'financial-statements',
  component: FinancialStatements,
  label: 'Бухгалтерская отчетность',
  title: 'Бухгалтерская отчетность',
}, */
  /* {
    path: 'reports',
    component: Reports,
    label: 'Отчетность',
    title: 'Отчетность',
  }, */
];

const DocumentRoutes = withRouter(({ location: { pathname }, match: { url } }) => {
  const baseUrl = url.slice(-1) === '/' ? url.slice(0, Math.max(0, url.length - 1)) : url;
  const currentPath = get(pathname.split('/'), '2', '');
  const title = get(routes.find((item) => item.path === currentPath), 'title', '');

  return (
    <>
      <main className="main legalPage">
        <section className="document">
          <div className="wrapper">
            <h1 className="title">Документы</h1>
            <h4 className="description">
              Работаем исключительно по стандартам Банка России и строго в рамках законодательства РФ.
            </h4>
            <div className="content">

              <div className="tabs mobile">
                {routes.map(({ path, label }) => (
                  <NavLink
                    activeClassName="tab active"
                    className="tab"
                    to={`${baseUrl}/${path}`}
                    key={label}
                  >
                    {label}
                  </NavLink>
                ))}
              </div>

              <div className="left">
                <div className="tabs">
                  {routes.map(({ path, label }) => (
                    <NavLink
                      activeClassName="tab active"
                      className="tab"
                      to={`${baseUrl}/${path}`}
                      key={label}
                    >
                      {label}
                    </NavLink>
                  ))}
                </div>
                <CalculatorSidebar />
              </div>

              <div className="right">
                <div className="topBlock"><img src="/img/other/topbgblock.svg" alt="" /></div>
                <div className="topBlock mobile"><img src="/img/other/topbgblock-mobile.svg" alt="" /></div>

                <div className="block">
                  <div className="block-content">
                    <h4 className="block-title">{title}</h4>

                    <Switch>
                      {routes.map(
                        // eslint-disable-next-line no-shadow,react-perf/jsx-no-new-function-as-prop
                        ({ path, component }) => (
                          <Route
                            exact
                            path={`${baseUrl}/${path}`}
                            component={component}
                            key={path}
                          />
                        ),
                      )}
                      <Redirect exact from={`${baseUrl}`} to={`${baseUrl}/legal-info`} />
                    </Switch>

                  </div>
                </div>
                <div className="bottomBlock mobile"><img src="/img/other/bottombgblock.svg" alt="" /></div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
});

export { DocumentRoutes };
/*
<div className="calculator-wrapper">
                    <div className="calculator-title">
                      Первый займ
                      {' '}
                      <span className="darkred">бесплатно</span>
                    </div>
                    <p className="calculator-subtitle">
                      При условии возврата займа в
                      {' '}
                      <a href="#">срок</a>
                    </p>

                    <div className="calculator-slider__block">
                      <div className="calculator-slider" data-min="0" data-max="60000" data-start="25000" />
                    </div>
                    <div className="calculator-top">
                      <p className="calculator-text">Сумма займа</p>
                      <div className="calculator-sum">
                        <span className="sum" />
                        <span className="cur">₽</span>
                      </div>
                    </div>
                    <div className="calculator-description">
                      <p>Вы вернете</p>
                      <div className="calculator-sums">
                        <p className="calculator-oldSum">
                          <span className="sum" id="oldSum" />
                          <span className="cur">₽</span>
                        </p>
                        <p className="calculator-totalSum">
                          <span className="sum" id="totalSum" />
                          <span className="cur">₽</span>
                        </p>
                      </div>
                    </div>
                    <div className="calculator-btn">
                      <a href="#" className="default-btn big">Получить бесплатно</a>
                    </div>
                  </div>
 */
