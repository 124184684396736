import React from 'react';
import { ButtonWithLoader as Button } from 'src/ui/components/Form';

const styleSubTitle = {
  margin: '30px',
  textAlign: 'left',
};

export const Refusal = React.memo((props) => {
  const {
    name, date, callBack, closeCallback,
  } = props;
  return (
    <div className="modal-main">
      <button
        type="button"
        className="close-modal__btn close-modal"
        onClick={closeCallback}
      >
        <img src="/img/modal/close.svg" alt="" />
      </button>
      <h2 className="modal-title">К сожалению, вам отказано в займе!</h2>
      <div className="sub-title">
        <div style={styleSubTitle}>
          {name}
          , не огорчайтесь! Попробуйте подать заявку после
          {' '}
          {date}
          , мы будем вас очень ждать!
          Однако наши партнеры готовы предложить вам займ!
        </div>

        <div style={styleSubTitle}>
          <div className="button">
            <Button
              type="button"
              className="nextStep"
              onClick={callBack}
            >
              Получить деньги
            </Button>
          </div>
        </div>
      </div>

    </div>
  );
});
