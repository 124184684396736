import React from 'react';
import { useUnit } from 'effector-react';
import { isEmpty } from 'src/lib/lodash';
import {
  STATUS_LOAN,
  $applicationStatus, $isRepeatNewLoan,
} from 'src/models/Loan/Application';
import { NewLoan } from './children/NewLoan';
// import { AgreeLoan } from './children/AgreeLoan';
import { WaitNewLoan } from './children/WaitNewLoan';

const { RECEIVED, REJECTED } = STATUS_LOAN;

export const ButtonLoan = React.memo(() => {
  const [status, isRepeatNewLoan] = useUnit([$applicationStatus, $isRepeatNewLoan]);
  let Component;
  switch (status) {
    case RECEIVED:
      // одобрена - переводим на подтверждение
      Component = NewLoan;
      break;
    case REJECTED:
      // отклонена - смотрим время
      Component = WaitNewLoan;
      break;
    case null:
      Component = !isEmpty(isRepeatNewLoan) ? NewLoan : WaitNewLoan;
      break;
    default:
    // новая заявка
      Component = WaitNewLoan;
  }

  return (
    <div className="profileTop-button">
      <Component />
    </div>
  );
});
