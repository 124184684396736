export const PAGES_PATH = {
  LOGIN: 'login',
  PROFILE: 'profile',
  REGISTRATION: 'registration',
  RECOVERY: 'recovery',
  FEEDBACK: 'feedback',
  DOCUMENTS: 'documents',
  BIND: 'bind-card-result',
  REGULATION: 'legal-links',
  LOAN_REPAYMENT: 'loan-repayment',
  PAYMENT_RESULT: 'payment-result',
  ESIA_RETURN_URL: '/esia/return-url',
};

export const REG_PATH = {
  REGISTRATION: PAGES_PATH.REGISTRATION,
  PERSONAL: 'personal',
  PASSPORT: 'passport',
  ADDRESS: 'address',
  EXPERIENCE: 'experience',
  VERIFICATION: 'verification',
};

export const RECOVERY_PATH = {
  CHANGE: 'change',
  CONFIRM: 'confirm',
};

export const PROFILE_PATH = {
  PERSONAL: 'personal',
  LOAN: 'loan',
  SECURITY: 'security',
  CARDS: 'cards',
  SERVICES: 'paid-services',
  EVENTS: 'events',
  REPORTS: 'reports',
  FEEDBACK: 'feedback',
  PARTNERS: 'offers-partners',
  HISTORY: 'correction-history',
  PROFILE: PAGES_PATH.PROFILE,
};

export const PROFILE_LOAN_PATH = {
  LOANS: 'list',
  NEW_LOAN: 'add',
  TO_REPAY: ':id',
};

export const PROFILE_NEW_LOAN_PATH = {
  PAYMENT: 'payment',
  CARD: 'card',
  // LOAN: 'loan',
  PROCESSING: 'processing',
  FAILURE: 'failure',
  SUCCESS: 'success',
  CONFIRM: 'confirm',
  AGREEMENT: 'agreement',
};

export const PROFILE_NEW_LOAN_CARD_PATH = {
  CARDS: 'cards',
};

export const AUTH_TO_PROFILE = `${PAGES_PATH.PROFILE}/${PROFILE_PATH.LOAN}`;
