import React from 'react';
import { isEmpty } from 'src/lib/lodash';
import { GENDER_FIELD_VALUES } from 'src/dict/fields';

const handlerClick = (val, onChange) => () => onChange(val);

export const Gender = React.memo(({ value, onChange }) => {
  const val = isEmpty(value) ? GENDER_FIELD_VALUES.MAN : value;
  return (
    <div className="default-radio default-radio-gender">
      <label htmlFor="man" className="radio-btn radio-btn-left">
        <input
          autoComplete="nope"
          type="radio"
          name="gender"
          id="man"
          onChange={handlerClick(GENDER_FIELD_VALUES.MAN, onChange)}
          checked={val === GENDER_FIELD_VALUES.MAN}
        />
        <div className="gender">
          <span className="icon">
            <img src="/img/form/man.png" alt="" />
          </span>
          Мужской
        </div>
      </label>
      <label htmlFor="woman" className="radio-btn radio-btn-right">
        <input
          autoComplete="nope"
          type="radio"
          name="gender"
          id="woman"
          onChange={handlerClick(GENDER_FIELD_VALUES.WOMAN, onChange)}
          checked={val === GENDER_FIELD_VALUES.WOMAN}
        />
        <div className="gender">
          <span className="icon">
            <img src="/img/form/woman.png" alt="" />
          </span>
          Женский
        </div>
      </label>
    </div>
  );
});
