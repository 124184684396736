import { createForm } from 'effector-forms';
import { rules } from 'src/lib/rules';
import {
  RECOVERY_INFO_FIELDS, RECOVERY_CODE_FIELDS,
} from 'src/dict/fields';
import { CODE_TIMER } from 'src/dict/config';
import {
  sendActivationCodeRecoverySign,
  activationCodeRecoverySign,
} from 'src/api/signatures/Auth';
import { recoveryDomain } from '../index';

// STEP 5 - верификация телефона

/// ///////////////////////////////////////////////////////////
// ================== СЛОВАРИ / КОНСТАНТЫ ================== //
/// ///////////////////////////////////////////////////////////

const { PHONE, LAST_NAME } = RECOVERY_INFO_FIELDS;
const {
  ONE, TWO, THREE, FOUR, FIVE, SIX,
} = RECOVERY_CODE_FIELDS;

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ СОБЫТИЙ ===================== //
/// ///////////////////////////////////////////////////////////

export const sendActivationCodeFn = recoveryDomain.createEvent('sendActivationCodeFn');
export const checkActivationCodeFn = recoveryDomain.createEvent('checkActivationCodeFn');

export const startTimerActivationCodeFn = recoveryDomain.createEvent('setTimerActivationCodeFn');
export const stopTimerActivationCodeFn = recoveryDomain.createEvent('setTimerActivationCodeFn');

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ХРАНИЛИЦ ==================== //
/// ///////////////////////////////////////////////////////////

export const $codeTest = recoveryDomain.createStore('', { name: 'codeTest' });

// токен проверки
export const $tokenSent = recoveryDomain.createStore('', { name: 'phoneTemp' });
// оставшееся время до перезагрузки кода
export const $valueTimer = recoveryDomain.createStore(CODE_TIMER, { name: 'timerActivationCode' });

export const $timerActivationCode = recoveryDomain.createStore(false, { name: 'timerActivationCode' });

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ЭФФЕКТОВ ==================== //
/// ///////////////////////////////////////////////////////////

// отправка кода на восстановление пароля
export const sendActivationCodeRecoveryFx = recoveryDomain.createEffect(sendActivationCodeRecoverySign);
// проверка кода на восстановление пароля
export const activationCodeRecoveryFx = recoveryDomain.createEffect(activationCodeRecoverySign);

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ЭФФЕКТОВ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

// тут создаются эффекты через attach

/// ///////////////////////////////////////////////////////////
// ====================== ЮНИТЫ ФОРМ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем формы с помощью effector-forms
export const stepInfoRecoveryForm = createForm({
  fields: {
    [PHONE]: { rules: [rules.required(), rules.phone()], init: '' },
    [LAST_NAME]: {
      rules: [
        rules.required(), rules.letter(), rules.maxLength(50),
      ],
      init: '',
    },
  },
  validateOn: ['blur', 'submit'],
  domain: recoveryDomain,
});

export const stepCodeRecoveryForm = createForm({
  fields: {
    [ONE]: { rules: [rules.required(), rules.number(), rules.maxLength(1)], init: '' },
    [TWO]: { rules: [rules.required(), rules.number(), rules.maxLength(1)], init: '' },
    [THREE]: { rules: [rules.required(), rules.number(), rules.maxLength(1)], init: '' },
    [FOUR]: { rules: [rules.required(), rules.number(), rules.maxLength(1)], init: '' },
    [FIVE]: { rules: [rules.required(), rules.number(), rules.maxLength(1)], init: '' },
    [SIX]: { rules: [rules.required(), rules.number(), rules.maxLength(1)], init: '' },
  },
  validateOn: ['blur', 'submit'],
  domain: recoveryDomain,
});

/// ///////////////////////////////////////////////////////////
//  ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ФОРМ + (ХРАНИЛИЩ, ЭФФЕКТОВ) //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ========================= ФАБРИКИ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем различные фабрики и хранилища с использованием фабрик
