export const config = {
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  API_PROTOCOL: process.env.REACT_APP_API_PROTOCOL,
  API_PROJECT_ID: process.env.REACT_APP_PROJECT_ID,
  NODE_ENV: process.env.REACT_APP_ENV,
  API_KEY_DADATA: process.env.REACT_APP_DADATA_API_KEY,
  SECRET_KEY_DADATA: process.env.REACT_APP_DADATA_SECRET_KEY,
  FORM_IN_DEVELOPMENT: process.env.REACT_APP_FORM_IN_DEVELOPMENT,
  SHOW_CONFIG_ENV: process.env.REACT_APP_SHOW_CONFIG_ENV,
  RECAPTCHA_KEY: process.env.REACT_APP_RECAPTCHA_KEY,
  YANDEX_METRIKA_NUMBER: process.env.REACT_APP_YANDEX_METRIKA_NUMBER,
  DECLINE_LANDING_URL: process.env.REACT_APP_DECLINE_LANDING_URL,
};

export const isDevelopment = ['development', 'local', 'dev', 'test'].includes(config.NODE_ENV);
// export const isShowConfig = isEmpty(config.SHOW_CONFIG_ENV) && config.SHOW_CONFIG_ENV.toLowerCase() === 'true';

export const token = {
  ACCESS: 'access',
  REFRESH: 'refresh',
  COOKIE_API_TOKEN: 'api_token',
};

export const statistic = {
  INFO: 'info',
  UTM_LIST: ['utm_medium', 'utm_source', 'utm_campaign', 'utm_term', 'utm_content'],
};

export const LS = {
  APP: 'order',
  STEP: 'step',
  USER: 'user',
  CITY: 'city',
  PAYMENT_CONTEXT: 'payment_context',
};

export const validate = {
  DATE: {
    LENGTH: 10,
  },
  DATE_CARD: {
    LENGTH: 5,
  },
};

export const daData = {
  URL: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest',
  URL_BY_ID: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs',
  ADDRESS: {
    PATH: 'address',
    MIN_LENGTH: 3,
    TIMEOUT_DEBOUNCE: 200,
    LIMIT: 5,
  },
  NAME: {
    PATH: 'fio',
    MIN_LENGTH: 2,
    TIMEOUT_DEBOUNCE: 200,
    LIMIT: 5,
    FILTER_PARTS: {
      SURNAME: 'SURNAME',
      NAME: 'NAME',
      PATRONYMIC: 'PATRONYMIC',
    },
  },
  EMAIL: {
    PATH: 'email',
    MIN_LENGTH: 3,
    TIMEOUT_DEBOUNCE: 200,
    LIMIT: 5,
  },
  FMS: {
    PATH: 'fms_unit',
    MIN_LENGTH: 3,
    TIMEOUT_DEBOUNCE: 200,
    LIMIT: 4,
  },
};

export const CODE_FIELDS = {
  ONE: 'one',
  TWO: 'two',
  THREE: 'three',
  FOUR: 'four',
  FIVE: 'five',
  SIX: 'six',
};

export const CODE_TIMER = 59;
