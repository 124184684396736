import React, { useEffect } from 'react';
import { useUnit } from 'effector-react';
import {
  $itemsPaymentForExLoan,
  getItemsPaymentForExLoanFx,
} from 'src/models/Payment';
import {
  $isActiveAgreement,
  getAgreementForExtendedLoanFx,
} from 'src/models/Loan/Agreement';
import { getAgreementDocsForLoanFx } from 'src/models/Loan/Application/Success';
import { Loan } from './children/Loan';

export const Loans = ({ items }) => {
  const [
    pendingDocs,
    pendingPayments,
    isActiveAgreement,
    itemsPaymentForExLoan,
  ] = useUnit([
    getAgreementDocsForLoanFx.pending,
    getAgreementForExtendedLoanFx.pending,
    $isActiveAgreement,
    $itemsPaymentForExLoan,
  ]);

  useEffect(() => {
    getItemsPaymentForExLoanFx();
  }, []);

  return (
    <>
      <h1 className="title">Мои займы</h1>
      {items.map((item) => (
        <Loan
          item={item}
          pendingDocs={pendingDocs}
          pendingPayments={pendingPayments}
          isActiveAgreement={isActiveAgreement}
          itemsPaymentForExLoan={itemsPaymentForExLoan}
        />
      ))}
    </>
  );
};
