import React from 'react';
import { Logo } from './children/Logo';
import { Menu } from './children/Menu';

export const Sidebar = React.memo(() => (
  <div className="profileMenu">
    <div className="profileMenu-content">
      <Logo />
      <Menu />
    </div>
  </div>
));
