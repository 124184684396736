import React, { useRef } from 'react';
import { useStore } from 'effector-react';
import InputMask from 'react-input-mask';
import { get, isEmpty } from 'src/lib/lodash';
import { useOutsideAlerter } from 'src/lib/hooks';
import '../shared/InputDaData.css';

const handlerChange = ({ onChange, onChangeInputFn }) => (e) => {
  onChange(e);
  onChangeInputFn(get(e, 'target.value', ''));
};

const handlerBlur = ({ notChoiceCodeFMSFn, onBlur }) => (e) => {
  onBlur(e);
  notChoiceCodeFMSFn();
};

const handlerClick = ({
  valueOption, onChange, onCloseOptionsFn, jumpField, choiceCodeFMSFn, options,
}) => () => {
  onChange(valueOption);
  choiceCodeFMSFn({
    ...get(options.find(({ value }) => value === valueOption), 'data', {}),
  });
  jumpField();
  onCloseOptionsFn();
};

export const InputDaDataFMS = React.forwardRef(({
  // пришло из модели DaData
  modelData: {
    $selectData, onChangeInputFn, onFocusInputFn, onCloseOptionsFn,
  },
  propsData,
}, ref) => {
  const {
    visibleOptions, placeholder, options, isOpen, minLength,
  } = useStore($selectData);
  const {
    choiceCodeFMSFn, // событие эффектора из вне, которое будет срабатывать при выборе из списка подсказок
    notChoiceCodeFMSFn, // событие эффектора из вне, которое будет срабатывать при потере фокуса
    typeAddress,
    mask, maskPlaceholder, maskPattern,
    value, label, disabled, name, type, tabIndex, jumpField, required,
    positionAbsolute,
    errorText, hasError, onChange, onBlur, // приходят из getPropsField - формы эффектора
    ...restProps
  } = propsData;

  const val = (isEmpty(value) ? '' : value);
  const isMinValue = (minLength <= val.length);
  const isOpenOptions = isMinValue && isOpen && !isEmpty(options);

  const hasFocused = name === get(document, 'activeElement.id', false);

  const fieldLabel = `${label}${(required ? '*' : '')}`;
  let validState = '';
  if (required && (hasError || (!isEmpty(`${val}`.trim()) && !hasFocused))) {
    validState = hasError ? 'invalid' : 'valid';
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onCloseOptionsFn);

  /* const styleContainer = positionAbsolute === false
    ? { position: 'static !important' } : { position: 'absolute' }; */

  const offsetWidth = get(document.getElementById(name), 'parentElement.parentElement.offsetWidth', '');
  const styleContainer = {
    position: 'absolute',
    width: isEmpty(offsetWidth) ? 0 : `${offsetWidth}px`,
  };

  return (
    <>
      <div
        className={`default-input ${validState} ${isOpenOptions ? ' select open' : ''}`}
      >
        <InputMask
          {...restProps}
          {...(!isEmpty(mask) ? { mask } : {})}
          {...(!isEmpty(maskPlaceholder) ? { maskPlaceholder } : {})}
          tabIndex={tabIndex}
          type={type}
          placeholder={placeholder}
          required={required}
          value={val}
          onChange={handlerChange({ onChange, onChangeInputFn })}
          onBlur={handlerBlur({ notChoiceCodeFMSFn, onBlur })}
          onFocus={onFocusInputFn}
          disabled={disabled}
          id={name}
          ref={ref}
        />
        <label>{fieldLabel}</label>
      </div>
      {required && hasError && !isEmpty(errorText) && <p className="invalid-text">{errorText}</p>}

      {isOpenOptions && (
        <div className="default-select container" style={styleContainer}>
          <div
            className="box"
          >
            <ul ref={wrapperRef}>
              <>
                {visibleOptions.map(({ label: labelOption, value: valueOption }, index) => (
                  <li
                    key={`${valueOption}_${index}`}
                    aria-selected={valueOption === val}
                    role="option"
                    onClick={
                      handlerClick({
                        valueOption,
                        onChange,
                        onCloseOptionsFn,
                        jumpField,
                        choiceCodeFMSFn,
                        options,
                      })
                    }
                  >
                    {labelOption}
                  </li>
                ))}
              </>
            </ul>
          </div>

        </div>
      )}
    </>
  );
});

InputDaDataFMS.defaultProps = {
  label: '',
  value: '',
  options: [],
  onChange: () => '',
  onBlur: () => '',
  required: false,
  positionAbsolute: true,
};
