import { sample } from 'effector';
import { $pathnameUrl } from 'src/models/App';
import { sendActivationCodeRecoveryFx } from 'src/models/Recovery/Confirm';
import {
  resetCodePageFn,
  $isCodePage, $isRecoveryPage,
} from './index';

/// ///////////////////////////////////////////////////////////
// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
/// ///////////////////////////////////////////////////////////

$isCodePage
  // если такой юзер есть то показываем страницу с подтверждением кода
  .on(sendActivationCodeRecoveryFx.doneData, () => true)
  .reset(resetCodePageFn);

/// ///////////////////////////////////////////////////////////
// ===================== БЛОК ОПЕРАТОРОВ =================== //
/// ///////////////////////////////////////////////////////////

// при любом изменении адреса возвращаем $isCodePage к дефолтному состоянию
sample({
  clock: sample({
    clock: $pathnameUrl,
    source: $isRecoveryPage,
    filter: (isRecoveryPage) => isRecoveryPage,
  }),
  target: resetCodePageFn,
});
