import { sample } from 'effector';
import {
  // события
  storageSetFn, storageRemoveFn, storageClearFn,
  // хранилища,
  // эффекты
  storageSetFx, storageRemoveFx, storageClearFx,
} from './index';

/// ///////////////////////////////////////////////////////////
// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ===================== БЛОК ОПЕРАТОРОВ =================== //
/// ///////////////////////////////////////////////////////////

// Запись в localstorage
sample({ clock: storageSetFn, target: storageSetFx });

// Удаление из localstorage
sample({ clock: storageRemoveFn, target: storageRemoveFx });

// ОЧистка localstorage
sample({ clock: storageClearFn, target: storageClearFx });
