import React from 'react';

const ErrorForm = () => (
  <>
    <div>
      <h6>Неизвестная ошибка</h6>
      <div>
        Произошла ошибка, которую мы еще не видели. Мы уже получили сведения об ошибке.
        <br />
        Вы поможете нам лучше понять ситуацию, если опишите, что произошло до этого.
      </div>
    </div>

    <div>Опишите действия, которые привели к ошибке</div>
    <div>
      <textarea />
    </div>
  </>
);

export { ErrorForm };
