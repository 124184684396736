import React from 'react';
import { useForm } from 'effector-forms';
import { get } from 'src/lib/lodash';
import { maskLetter } from 'src/lib/mask';
import { LOAN_REPAYMENT_FORM } from 'src/dict/fields';
import {
  getPropsField, getRefField, onSubmit,
} from 'src/lib/form';
import { loanRepaymentDomainForm } from 'src/models/Repayment';
import { setRefFieldsFn } from 'src/models/Helper/Ref';
import { getKeyboardLayoutFn } from 'src/models/Helper/Keyboard';
import { ButtonWithLoader as Button, Input } from 'src/ui/components/Form';

const { NUMBER, LAST_NAME } = LOAN_REPAYMENT_FORM;

export const Form = React.memo(() => {
  const { submit, ...restProps } = useForm(loanRepaymentDomainForm);
  return (
    <>
      <form onSubmit={onSubmit(submit)}>
        <h1 className="title">Погашение займа</h1>
        <div className="block-item ">
          <Input
            label="Номер договора"
            type="text"
            inputMode="tel"
            name={NUMBER}
            {...getPropsField({
              fieldName: NUMBER, props: restProps,
            })}
            ref={getRefField({
              fieldName: NUMBER, callBack: setRefFieldsFn,
            })}
            required
          />
        </div>
        <div className="block-item">
          <Input
            label="Фамилия"
            name={LAST_NAME}
            {...getPropsField({
              fieldName: LAST_NAME, props: restProps, mask: maskLetter,
            })}
            ref={getRefField({
              fieldName: LAST_NAME, callBack: setRefFieldsFn,
            })}
            onInput={getKeyboardLayoutFn.prepend((e) => ({
              field: get(restProps, `fields.${LAST_NAME}`),
              value: e.target.value,
            }))}
            required
          />
        </div>

        <div className="buttons">
          <div className="button">
            <Button
              type="submit"
              className="nextStep"
            >
              Найти договор
            </Button>
          </div>

        </div>
      </form>
    </>
  );
});
