import React from 'react';

export const Official = React.memo(() => (
  <div className="modal-description nocenter">
    {/* eslint-disable-next-line max-len */}
    <div>I. Лицом, на которое возложено или было возложено ранее (с момента сложения полномочий прошло менее 1 года) исполнение важных государственных функций, а именно:</div>
    <div>1. Главой государства (в том числе правящей королевской династии) или правительства;</div>
    <div>2. Министром, его заместителем или помощником;</div>
    <div>3. Высшим правительственным чиновником;</div>
    <div>4. Должностным лицом судебных органов власти (Верховный, Конституционный суд);</div>
    <div>5. Государственным прокурором и его заместителем;</div>
    <div>6. Высшим военным чиновником;</div>
    <div>7. Руководителем или членом Совета директоров Национальных Банков;</div>
    <div>8. Послом;</div>
    <div>9. Руководителем государственных корпораций;</div>
    <div>10. Членом Парламента или иного законодательного органа.</div>
    <br />

    <div>II. Лицом, облеченным общественным доверием, в частности:</div>
    {/* eslint-disable-next-line max-len */}
    <div>1. Руководителем, заместителем руководителя международных организаций (ООН, ОЭСР, ОПЕК, Олимпийский комитет, Всемирный Банк и т.д.), Членом Европарламента;</div>

    {/* eslint-disable-next-line max-len */}
    <div>2. Руководителем или членом международных судебных организаций (Суд по правам человека, Гаагский трибунал и др.).</div>
    <br />

    {/* eslint-disable-next-line max-len */}
    <div>III. Должностным лицом публичной международной организации, т.е. лицом, уполномоченным такой организацией действовать от ее имени.</div>
    <br />
    <div>IV. Лицом, замещающим (занимающим) одну из нижеперечисленных должностей:</div>
    <div>1. государственную должность в Российской Федерации;</div>
    <div>2. должность члена Совета директоров Центрального банка Российской Федерации;</div>
    {/* eslint-disable-next-line max-len */}
    <div>3. должность федеральной государственной службы, назначение на которую и освобождение от которой осуществляются Президентом Российской Федерации или Правительством Российской Федерации;</div>
    <div>4. должность в Центральном банке Российской Федерации;</div>
    <div>5. должность в государственной корпорации;</div>
    {/* eslint-disable-next-line max-len */}
    <div>6. должности в иных организациях, созданных Российской Федерацией на основании федеральных законов, включенные в перечни должностей, определяемые Президентом Российской Федерации.</div>
    <br />

    <div>V. Родственником вышеуказанной категории лиц, в частности:</div>
    <div>1. Супругом;</div>
    {/* eslint-disable-next-line max-len */}
    <div>2. Родственником по прямой восходящей и нисходящей линии (родителями и детьми, дедушкой, бабушкой и внуками);</div>
    {/* eslint-disable-next-line max-len */}
    <div>3. Полнородным или не полнородным (имеющими общих отца или мать) братом или сестрой, усыновителем или усыновленным</div>
    {/* eslint-disable-next-line max-len */}
    <div>VI. Доверительным собственником (управляющим) иностранной структуры без образования юридического лица, протектором.</div>

  </div>
));
