import React from 'react';
import { isEmpty } from 'src/lib/lodash';
import { sendYMGoalFx } from 'src/models/Statistic';
import { getMoneyFn } from 'src/models/Registration';
import { ButtonWithLoader } from './ButtonWithLoader';

const handlerClick = ({ ym }) => () => {
  if (!isEmpty(ym)) {
    sendYMGoalFx(ym);
  }
  getMoneyFn();
};

export const GetMoneyButton = React.memo(({ children, ...rest }) => {
  const text = isEmpty(children) ? 'Получить деньги' : children;
  return (
    <ButtonWithLoader {...rest} onClick={handlerClick(rest)}>
      {text}
    </ButtonWithLoader>
  );
});
