import {
  sample, combine, attach,
} from 'effector';
import { debounce } from 'patronum/debounce';
import { get, has, isEmpty } from 'src/lib/lodash';
import { daData } from 'src/dict/config';
import { appDomain } from 'src/models/App';
import {
  getDaDataByIdSign, getDaDataSign,
} from 'src/api/signatures/DaData';

export const inputDaDataAddressDomain = appDomain.createDomain('InputDaDataAddress');

export const getInputDaDataAddressModelApiFn = inputDaDataAddressDomain.createEvent('getInputDaDataAddressApiFn');

// Список существующих моделей
export const $objInputDaDataAddressModels = inputDaDataAddressDomain.createStore({}, { name: 'listSelects' });

const createInputDaDataAddressApi = (props) => {
  // props-ы, которые сохраняется в хранилищах
  const defaultValue = get(props, 'value', '');
  const defaultPlaceHolder = get(props, 'placeholder', ' ');
  const defaultRequired = get(props, 'required', false);
  const name = get(props, 'name', '');
  const formId = get(props, 'formId', '');
  const minLength = get(props, 'minLength', daData.ADDRESS.MIN_LENGTH);

  // props-ы, которые используется напрямую
  const limit = get(props, 'limit', daData.ADDRESS.LIMIT);
  const timeoutDebounce = get(props, 'timeoutDebounce', daData.ADDRESS.TIMEOUT_DEBOUNCE);
  // событие эффектора из вне, по которому будут формироваться данны для фильтрации
  const prepareChangeAddressFn = get(props, 'prepareChangeAddressFn', () => {});
  // событие эффектора из вне, следующее после prepareChangeAddressFn и запускающая запрос на получение данных
  const postChangeAddressFn = get(props, 'postChangeAddressFn', () => {});
  const clearChildrenAddressFn = get(props, 'clearChildrenAddressFn', () => {});

  const fromBound = get(props, 'from_bound', {});
  const toBound = get(props, 'to_bound', {});

  // ===================== ЮНИТЫ СОБЫТИЙ ===================== //
  const onChangeInputFn = inputDaDataAddressDomain.createEvent('onChangeInputFn');
  const onChangeInputDebounceFn = debounce({ source: onChangeInputFn, timeout: timeoutDebounce });

  // const onChangeOptionFn = inputDaDataDomain.createEvent('onChangeOptionFn');

  const onFocusInputFn = inputDaDataAddressDomain.createEvent('onFocusInputFn');
  const onBlurInputFn = inputDaDataAddressDomain.createEvent('onBlurInputFn');
  const onCloseOptionsFn = inputDaDataAddressDomain.createEvent('onCloseOptionstFn');

  // ===================== ЮНИТЫ ХРАНИЛИЦ ==================== //
  const $valueInput = inputDaDataAddressDomain.createStore(defaultValue, { name: 'value' });
  const $placeholder = inputDaDataAddressDomain.createStore(defaultPlaceHolder, { name: 'placeholder' });
  const $options = inputDaDataAddressDomain.createStore([], { name: 'options' });
  const $required = inputDaDataAddressDomain.createStore(defaultRequired, { name: 'required' });
  const $name = inputDaDataAddressDomain.createStore(name, { name: 'name' });
  const $minLength = inputDaDataAddressDomain.createStore(minLength, { name: 'minLength' });

  const $isOpen = inputDaDataAddressDomain.createStore(false, { name: 'isOpen' });

  // ==== ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
  const $visibleOptions = $options.map((items) => items.map(({ value }) => ({ value, label: value })));

  const $hasFocused = combine(
    $name, $valueInput, (nameElement) => nameElement === get(document, 'activeElement.id', false),
  );

  const $selectData = combine(
    $valueInput, $placeholder, $visibleOptions, $options, $required, $isOpen, $hasFocused, $minLength,
    (value, placeholder, visibleOptions, options, required, isOpen, hasFocused, minValue) => (
      {
        value, placeholder, visibleOptions, options, required, isOpen, hasFocused, minLength: minValue,
      }
    ),
  );

  // ================== БЛОК ЭФФЕКТОВ ============== //
  const getDaDataFx = inputDaDataAddressDomain.createEffect(getDaDataSign);

  // ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
  $valueInput
    .on(onChangeInputFn, (_, value) => value);

  $options
    // eslint-disable-next-line consistent-return
    .on(getDaDataFx.doneData, (_, result) => get(result, 'data.suggestions', []))
    .reset([onCloseOptionsFn, onBlurInputFn]);

  $isOpen
    .on([
      onFocusInputFn,
      onChangeInputFn,
    ], () => true)
    .on(onCloseOptionsFn, () => false);

  // ==================== БЛОК ОПЕРАТОРОВ ===================== //
  sample({
    clock: sample({
      clock: onChangeInputDebounceFn,
      source: $valueInput,
      filter: (valueInput) => valueInput.length >= minLength,
    }),
    target: prepareChangeAddressFn.prepend(() => ({ fieldName: name, formId })),
  });

  sample({
    clock: onChangeInputFn,
    source: $name,
    fn: (fieldName) => ({ fieldName, formId }),
    target: clearChildrenAddressFn,
  });

  sample({
    clock: sample({
      clock: postChangeAddressFn,
      filter: ({ fieldName }) => fieldName === name,
    }),
    target: attach({
      effect: getDaDataFx,
      source: $valueInput,
      mapParams: (eventParam, findText) => {
        // console.log('FX eventParam:', eventParam);
        ['fieldName'].forEach((item) => {
          delete eventParam[item];
        });
        return ({
          type: daData.ADDRESS.PATH,
          data: {
            query: findText,
            count: limit,
            ...(!isEmpty(fromBound) ? { from_bound: { value: fromBound } } : {}),
            ...(!isEmpty(toBound) ? { to_bound: { value: toBound } } : {}),
            ...(!isEmpty(eventParam) ? {
              restrict_value: true,
              locations: [{ ...eventParam }],
            } : {}),
          },
        });
      },
    }),
  });

  return ({
    $name,
    $selectData,
    onChangeInputFn,
    onBlurInputFn,
    onCloseOptionsFn,
    onFocusInputFn,
  });
};

export const getInputDaDataAddressModelApiFx = inputDaDataAddressDomain.createEffect(
  ({ sourceParam, eventParam }) => {
    const formId = get(eventParam, 'formId', '');
    const name = get(eventParam, 'name', '');
    const key = `${formId}_${name}`;
    // если такого имени в списке нет, создаем
    return has(sourceParam, key)
      ? { ...sourceParam }
      : { ...sourceParam, [key]: createInputDaDataAddressApi(eventParam) };
  },
);

export const getDataByIdFx = inputDaDataAddressDomain.createEffect(getDaDataByIdSign);

$objInputDaDataAddressModels
  .on(getInputDaDataAddressModelApiFx.doneData, (_, result) => result);

sample({
  clock: getInputDaDataAddressModelApiFn,
  source: $objInputDaDataAddressModels,
  // в eventParam приходят пропсы и имя InputDaData-a
  fn: (sourceParam, eventParam) => ({ sourceParam, eventParam }),
  target: getInputDaDataAddressModelApiFx,
});

/* sample({
  clock: getInputDaDataAddressModelApiFn,
  source: $objInputDaDataAddressModels,
  // в eventParam приходят пропсы и имя InputDaData-a
  fn: (sourceParam, eventParam) => {
    const name = get(eventParam, 'name', '');
    // если такого имени в списке нет, создаем
    return has(sourceParam, name)
      ? { ...sourceParam }
      : { ...sourceParam, [name]: createInputDaDataAddressApi(eventParam) };
  },
  target: $objInputDaDataAddressModels,
}); */
