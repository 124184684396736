import React from 'react';
import { useUnit } from 'effector-react';
import {
  Redirect,
  Route, Switch, withRouter,
} from 'react-router-dom';
import { get, isEmpty } from 'src/lib/lodash';
import { PROFILE_PATH } from 'src/dict/path';
import {
  $routeItems,
} from 'src/models/Profile';
import {
  Cards,
  LoanRoutes,
  CorrectionHistory,
  Events,
  Feedback,
  OffersPartners,
  PaidServices,
  UserInfo,
  Reports,
  Security,
} from './children';

const {
  PERSONAL, LOAN, SECURITY, CARDS, SERVICES, EVENTS, REPORTS,
  FEEDBACK, PARTNERS, HISTORY,
} = PROFILE_PATH;

const pathMap = {
  [PERSONAL]: UserInfo,
  [LOAN]: LoanRoutes,
  [SECURITY]: Security,
  [CARDS]: Cards,
  [SERVICES]: PaidServices,
  [EVENTS]: Events,
  [REPORTS]: Reports,
  [FEEDBACK]: Feedback,
  [PARTNERS]: OffersPartners,
  [HISTORY]: CorrectionHistory,
};

export const ProfileRoutes = withRouter(({ match: { url } }) => {
  const routes = useUnit($routeItems).filter((item) => !isEmpty(item.path));
  const baseUrl = url.slice(-1) === '/' ? url.slice(0, Math.max(0, url.length - 1)) : url;

  return (
    <Switch>
      {routes.map(
        ({ path }) => (
          <Route
            exact={(path !== LOAN)}
            key={`${path}_route`}
            path={`${baseUrl}/${path}`}
            component={get(pathMap, path, '')}
          />
        ),
      )}
      <Redirect exact from={`${baseUrl}`} to={`${baseUrl}/${LOAN}`} />
      {/* <Redirect to={`${baseUrl}`} /> */}
    </Switch>
  );
});
