import React from 'react';

export const Information = React.memo(() => (
  <section className="informationSection">
    <div className="wrapper">
      <h2 className="title">КексКредит - сервис онлайн займов</h2>
      <p>
        Годовая процентная ставка – 292% годовых. Проценты и пеня, начисляемые по Договору потребительского займа,
        не могут превышать 1,3 размер суммы займа.
      </p>
      <p>
        Пример расчета процентной ставки займа размером 15 000 руб. на срок 30 дней:
        Процентная ставка за пользование займом в каждый из 30 дней - 0,8% в день.
        Полная стоимость займа - 292% годовых.
      </p>
      <p>
        Сумма начисленных процентов за 30 дней пользования займом - 3 600 руб.
      </p>
      <p>
        Сумма к возврату: 15 000 + 3 600 = 18 600 руб.
      </p>
      <p>
        Проценты за пользование суммой займа при нарушении срока возврата займа
        и начисленных процентов на 10 дней - 1 200 руб.
      </p>
      <p>
        Пеня на сумму займа в размере 20% годовых от суммы займа начисляется
        за просрочку возврата займа на 10 дней - 82,1 руб.
      </p>
      <p>
        Сумма к возврату при нарушении срока возврата займа и начисленных процентов
        на 10 дней: 18 600 + 1 200 + 82,1 = 19 882,10 руб.
      </p>
    </div>
  </section>
));
