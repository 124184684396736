import { createForm } from 'effector-forms';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from 'src/lib/lodash';
import {
  letterPattern, phonePattern, rules,
} from 'src/lib/rules';
import {
  REG_STEP1_FIELDS, REG_STEP3_FIELDS,
  USER_FORM, REG_STEP3_DEV_FIELDS,
} from 'src/dict/fields';
import { userDomain } from 'src/models/User';
import {
  addAddressDataSign,
  updateAdditionalProfileUserSign,
} from 'src/api/signatures/User';
import {
  getDaDataByIdSign,
} from 'src/api/signatures/DaData';

export const personalDomain = userDomain.createDomain('Personal');

/// ///////////////////////////////////////////////////////////
// ================== СЛОВАРИ / КОНСТАНТЫ ================== //
/// ///////////////////////////////////////////////////////////

const {
  FIRST_NAME, LAST_NAME, IS_NOT_MIDDLE_NAME, MIDDLE_NAME,
  YEAR_OF_BIRTH, PHONE, EMAIL, UUID, // GENDER,
} = REG_STEP1_FIELDS;

const {
  HANDLER, REGISTRATION_DATE, IS_NOT_PHONE_HOME, PHONE_HOME,
} = REG_STEP3_FIELDS;
const {
  ADDRESS_TYPE, CITY, HOUSE,
} = REG_STEP3_DEV_FIELDS;

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ СОБЫТИЙ ===================== //
/// ///////////////////////////////////////////////////////////

export const toggleDataEditFn = personalDomain.createEvent();

export const addAddressFn = personalDomain.createEvent();

/* событие набора в поле ввода адреса (запускает формирование фильтрации)
 триггерится в модели компонента адреса */
export const prepareChangeAddressFn = personalDomain.createEvent('prepareChangeAddressFn');
/* событие вызывающееся после окончания prepareChangeAddressFn (тригеррит запрос на получение данных)
триггерится в модели шага адреса */
export const postChangeAddressFn = personalDomain.createEvent('postChangeAddressFn');
/* событие вызывающееся на набора в поле ввода адреса (запускает формирование полей которые очистятся)
 триггерится в модели компонента адреса */
export const clearChildrenAddressFn = personalDomain.createEvent('clearChildrenAddressFn');
/* событие вызывающееся после окончания clearChildrenAddressFn (тригеррит split где происходит очистка)
триггерится в модели шага адреса */
export const postClearChildrenAddressFn = personalDomain.createEvent('postClearChildrenAddressFn');
/* событие выбора option (запускает данных которые будут записаны в поля)
 триггерится в компоненте адреса */
export const choiceAddressFn = personalDomain.createEvent('choiceAddressFn');
/* событие вызывающееся после окончания choiceAddressFn (тригеррит split где происходит запись)
 триггерится в модели шага адреса */
export const postChoiceAddressFn = personalDomain.createEvent('postChoiceAddressFn');
/* событие вызывающееся при потере фокуса с адреса (служит для валидирования что данные выбраны из списка)
 триггерится в компоненте адреса */
export const notChoiceAddressFn = personalDomain.createEvent('notChoiceAddressFn');

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ХРАНИЛИЦ ==================== //
/// ///////////////////////////////////////////////////////////

export const $dataIsEdit = personalDomain.createStore({
  [USER_FORM.PERSONAL]: false,
  [USER_FORM.PASSPORT]: false,
  [USER_FORM.ADDRESS_REG]: false,
  [USER_FORM.ADDRESS_ACT]: false,
});
export const $isNotMiddleName = personalDomain.createStore(false);
export const $isNotPhoneHome = personalDomain.createStore(false);

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ЭФФЕКТОВ ==================== //
/// ///////////////////////////////////////////////////////////

export const updatePersonalDataFx = personalDomain.createEffect(updateAdditionalProfileUserSign);
export const addAddressFx = personalDomain.createEffect(addAddressDataSign);

export const getDataByIdFx = personalDomain.createEffect(getDaDataByIdSign);

export const addAddressDataActFx = personalDomain.createEffect(addAddressDataSign);

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ЭФФЕКТОВ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

// тут создаются эффекты через attach

/// ///////////////////////////////////////////////////////////
// ====================== ЮНИТЫ ФОРМ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем формы с помощью effector-forms

export const personaForm = createForm({
  fields: {
    [FIRST_NAME]: {
      rules: [rules.required(), rules.letter(), rules.maxLength(50)], init: '', validateOn: ['blur'],
    },
    [LAST_NAME]: {
      rules: [rules.required(), rules.letter(), rules.maxLength(50)], init: '', validateOn: ['blur'],
    },
    [IS_NOT_MIDDLE_NAME]: { init: false, units: { $value: $isNotMiddleName } },
    [MIDDLE_NAME]: {
      rules: [
        {
          source: $isNotMiddleName,
          validator: (value, form, isNotMiddleName) => !isEmpty(value) || isNotMiddleName,
          errorText: 'Данное поле обязательно для заполнения',
        },
        {
          source: $isNotMiddleName,
          validator: (value, form, isNotMiddleName) => letterPattern.test(value) || isNotMiddleName,
          errorText: 'Данное поле обязательно для заполнения',
        },
        {
          source: $isNotMiddleName,
          validator: (value, form, isNotMiddleName) => !isEmpty(value) && value.length <= 50 || isNotMiddleName,
          errorText: 'Максимальная длина 50 символ(а/ов)',
        },
      ],
      init: '',
      validateOn: ['blur'],
    },
    [YEAR_OF_BIRTH]: {
      rules: [
        rules.required(), rules.dateYear(), rules.dateBirth(),
      ],
      init: '',
      validateOn: ['blur'],
    },
    [PHONE_HOME]: {
      rules: [
        {
          source: $isNotPhoneHome,
          validator: (value, form, isNotPhoneHome) => !isEmpty(value) || isNotPhoneHome,
          errorText: 'Данное поле обязательно для заполнения',
        },
        {
          source: $isNotPhoneHome,
          validator: (value, form, isNotPhoneHome) => phonePattern.test(value) || isNotPhoneHome,
          errorText: 'Телефонный номер введен в неверном формате',
        },
      ],
      init: '',
      validateOn: ['blur'],
    },
    [IS_NOT_PHONE_HOME]: { init: false, units: { $value: $isNotPhoneHome } },
    [PHONE]: { init: '' },
    [EMAIL]: { init: '' },
    [UUID]: { init: uuidv4() },
  },
  validateOn: ['submit'],
  domain: personalDomain,
});

/// ///////////////////////////////////////////////////////////
//  ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ФОРМ + (ХРАНИЛИЩ, ЭФФЕКТОВ) //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ========================= ФАБРИКИ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем различные фабрики и хранилища с использованием фабрик

export const addAddressFormFb = (type) => {
  const fields = {
    [HANDLER]: { init: '' },
    [ADDRESS_TYPE]: { init: type },
    [CITY]: { rules: [rules.required()], init: '' },
    [HOUSE]: { rules: [rules.required()], init: '' },
  };

  if (type === USER_FORM.ADDRESS_REG) {
    fields[REGISTRATION_DATE] = {
      rules: [rules.required(), rules.dateYear(), rules.isValidRegDate()],
      init: '',
      validateOn: ['blur'],
    };
  }

  Object.values(REG_STEP3_DEV_FIELDS).forEach((item) => {
    if (isEmpty(fields[item])) {
      fields[item] = { init: '' };
    }
  });

  return createForm({
    fields,
    validateOn: ['submit'],
    domain: personalDomain,
  });
};

export const addressRegForm = addAddressFormFb(USER_FORM.ADDRESS_REG);
export const addressActualForm = addAddressFormFb(USER_FORM.ADDRESS_ACT);
