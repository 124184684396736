import { get, isEmpty } from 'src/lib/lodash';
import { REG_STEP3_DEV_FIELDS } from 'src/dict/fields';

const {
  REGION, CITY,
  STREET, HOUSE, HOUSING, APARTMENT, HOUSE_ALL_DATA,
  FIAS, KLADR, ZIP, RAW_DATA, SETTLEMENT, AREA,
} = REG_STEP3_DEV_FIELDS;

export const getParentData = (obj, key1, ke2) => {
  const arr = [];
  const val1 = get(obj, key1, '');
  if (!isEmpty(val1)) {
    arr.push(val1);
  }
  const val2 = get(obj, ke2, '');
  if (!isEmpty(val2)) {
    arr.push(val2);
  }
  return arr.join(', ');
};

export const getOptions = (addressData, key1, ke2, field1, field2) => {
  const val1 = get(addressData, key1, '');
  if (!isEmpty(val1)) {
    return ({ [field1]: val1 });
  }
  const val2 = get(addressData, ke2, '');
  if (!isEmpty(val2)) {
    return { [field2]: val2 };
  }
  return false;
};

export const getChildrenData = (obj, fields, val = '') => {
  const indexVal = !isEmpty(val) ? fields.indexOf(val) : 0;
  const fieldsClear = fields.splice((indexVal + 1), fields.length);
  fieldsClear.forEach((item) => {
    obj[item] = '';
  });
  return obj;
};

export const getObjAddress = (formValues, objMap) => {
  const obj = {};
  if (!isEmpty(get(formValues[objMap[HOUSE_ALL_DATA]], 'data.region_with_type', ''))) {
    obj[REGION] = get(formValues[objMap[HOUSE_ALL_DATA]], 'data.region_with_type', '');
  }

  if (!isEmpty(get(formValues[objMap[HOUSE_ALL_DATA]], 'data.area_with_type', ''))) {
    obj[AREA] = get(formValues[objMap[HOUSE_ALL_DATA]], 'data.area_with_type', '');
  }

  if (!isEmpty(get(formValues[objMap[HOUSE_ALL_DATA]], 'data.city_with_type', ''))) {
    obj[CITY] = get(formValues[objMap[HOUSE_ALL_DATA]], 'data.city_with_type', '');
  }

  if (!isEmpty(get(formValues[objMap[HOUSE_ALL_DATA]], 'data.settlement_with_type', ''))) {
    obj[SETTLEMENT] = get(formValues[objMap[HOUSE_ALL_DATA]], 'data.settlement_with_type', '');
  }

  if (!isEmpty(get(formValues[objMap[HOUSE_ALL_DATA]], 'data.street_with_type', ''))) {
    obj[STREET] = get(formValues[objMap[HOUSE_ALL_DATA]], 'data.street_with_type', '');
  }

  if (!isEmpty(formValues[objMap[HOUSE]])) {
    obj[HOUSE] = `${formValues[objMap[HOUSE]]}`;
  }

  if (!isEmpty(formValues[objMap[HOUSING]])) {
    obj[HOUSING] = `${formValues[objMap[HOUSING]]}`;
  }

  if (!isEmpty(formValues[objMap[APARTMENT]])) {
    obj[APARTMENT] = `${formValues[objMap[APARTMENT]]}`;
  }
  obj[FIAS] = get(formValues[objMap[HOUSE_ALL_DATA]], 'data.fias_id', '');
  obj[KLADR] = get(formValues[objMap[HOUSE_ALL_DATA]], 'data.kladr_id', '');
  obj[ZIP] = get(formValues[objMap[HOUSE_ALL_DATA]], 'data.postal_code', '');
  obj[RAW_DATA] = formValues[objMap[HOUSE_ALL_DATA]];

  return obj;
};
export const getDataAddress = (formValues) => {
  const obj = {};
  if (!isEmpty(get(formValues[HOUSE_ALL_DATA], 'data.region_with_type', ''))) {
    obj[REGION] = get(formValues[HOUSE_ALL_DATA], 'data.region_with_type', '');
  }

  if (!isEmpty(get(formValues[HOUSE_ALL_DATA], 'data.area_with_type', ''))) {
    obj[AREA] = get(formValues[HOUSE_ALL_DATA], 'data.area_with_type', '');
  }

  if (!isEmpty(get(formValues[HOUSE_ALL_DATA], 'data.city_with_type', ''))) {
    obj[CITY] = get(formValues[HOUSE_ALL_DATA], 'data.city_with_type', '');
  }

  if (!isEmpty(get(formValues[HOUSE_ALL_DATA], 'data.settlement_with_type', ''))) {
    obj[SETTLEMENT] = get(formValues[HOUSE_ALL_DATA], 'data.settlement_with_type', '');
  }

  if (!isEmpty(get(formValues[HOUSE_ALL_DATA], 'data.street_with_type', ''))) {
    obj[STREET] = get(formValues[HOUSE_ALL_DATA], 'data.street_with_type', '');
  }

  if (!isEmpty(formValues[HOUSE])) {
    obj[HOUSE] = `${formValues[HOUSE]}`;
  }

  if (!isEmpty(formValues[HOUSING])) {
    obj[HOUSING] = `${formValues[HOUSING]}`;
  }

  if (!isEmpty(formValues[APARTMENT])) {
    obj[APARTMENT] = `${formValues[APARTMENT]}`;
  }
  obj[FIAS] = get(formValues[HOUSE_ALL_DATA], 'data.fias_id', '');
  obj[KLADR] = get(formValues[HOUSE_ALL_DATA], 'data.kladr_id', '');
  obj[ZIP] = get(formValues[HOUSE_ALL_DATA], 'data.postal_code', '');
  obj[RAW_DATA] = formValues[HOUSE_ALL_DATA];

  return obj;
};
