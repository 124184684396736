import React from 'react';
import { useUnit } from 'effector-react';
import { useField } from 'effector-forms';
import { RECOVERY_INFO_FIELDS } from 'src/dict/fields';
import {
  sendActivationCodeFn,
  $codeTest,
  stepInfoRecoveryForm, stepCodeRecoveryForm,
} from 'src/models/Recovery/Confirm';
import { CodeForm } from 'src/ui/components';
import { CodeRepeat } from './children/CodeRepeat/CodeRepeat';
import 'src/ui/styles/VerificationForm.css';

const { PHONE } = RECOVERY_INFO_FIELDS;

export const Confirm = React.memo(() => {
  const codeTest = useUnit($codeTest);
  const { value: phone } = useField(stepInfoRecoveryForm.fields[PHONE]);

  return (
    <>
      <div className="block">
        <div className="block-content">

          <div className="block-topBg desktop">
            <img src="/img/other/topbgblock.svg" alt="" />
          </div>
          <div className="block-topBg mobile">
            <img src="/img/other/topbgblock2-mobile.svg" alt="" />
          </div>

          <div className="block-main">
            <div className="block-wrapper">

              <p className="text">
                На ваш номер телефона
                {' '}
                <span className="bold">{phone}</span>
                {' '}
                было отправлено SMS с кодом подтверждения.
                <br />
                Пожалуйста, введите код из SMS:
              </p>

              <CodeForm codeTest={codeTest} form={stepCodeRecoveryForm} />

              <CodeRepeat
                sendCodeFn={sendActivationCodeFn}
              />

            </div>

          </div>
        </div>
      </div>
    </>
  );
});
