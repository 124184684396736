import React from 'react';
import { isEmpty, has, get } from 'src/lib/lodash';
import './Button.css';

export const Button = React.memo(({ children, ...rest }) => {
  const nameClasses = [];
  const isDefault = get(rest, 'notDefault', true);
  if (isDefault) {
    nameClasses.push('default-btn');
  }

  if (!isEmpty(rest) && has(rest, 'className')) {
    nameClasses.push(get(rest, 'className', ''));
  }

  const isSubmit = get(rest, 'type', 'button') === 'submit';
  const props = { ...rest, className: nameClasses.join(' ') };

  return (
    <>
      {isSubmit
        ? (
          <button {...props} type="submit">
            {children}
          </button>
        )
        : (
          <button {...props} type="button">
            {children}
          </button>
        )}
    </>
  );
});
