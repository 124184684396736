import React from 'react';
import { useForm } from 'effector-forms';
import { get } from 'src/lib/lodash';
// import { getEventsField } from 'src/lib/form';
import { maskLetter } from 'src/lib/mask';
import { maskPhone } from 'src/lib/maskPlaceholder';
import { RECOVERY_INFO_FIELDS } from 'src/dict/fields';
import {
  getPropsField, getRefField, jumpFocusField, onSubmit,
} from 'src/lib/form';
import { setRefFieldsFn, jumpFocusFieldFn } from 'src/models/Helper/Ref';
import { getKeyboardLayoutFn } from 'src/models/Helper/Keyboard';
import { stepInfoRecoveryForm } from 'src/models/Recovery/Confirm';
import { ButtonWithLoader as Button, Input } from 'src/ui/components/Form';

const { PHONE, LAST_NAME } = RECOVERY_INFO_FIELDS;

export const RecoveryFom = React.memo(() => {
  const { submit, ...restProps } = useForm(stepInfoRecoveryForm);
  return (
    <>
      <form onSubmit={onSubmit(submit)}>
        <h1 className="title">Восстановление пароля</h1>
        {/* eslint-disable-next-line max-len */}
        <div className="sub-title">Для восстановления пароля от личного кабинета, введите номер телефона и вашу фамилию.</div>
        <div className="block-item">
          <Input
            label="Телефон"
            type="text"
            inputMode="tel"
            name={PHONE}
            {...maskPhone}
            {...getPropsField({
              fieldName: PHONE, props: restProps,
            })}
            ref={getRefField({
              fieldName: PHONE, callBack: setRefFieldsFn,
            })}
            jumpField={jumpFocusField({
              fieldName: LAST_NAME,
              callBack: jumpFocusFieldFn,
              trigger: { name: 'maxLength', data: { value: 18 } },
            })}
            required
          />
        </div>
        <div className="block-item">
          <Input
            label="Фамилия"
            name={LAST_NAME}
            {...getPropsField({
              fieldName: LAST_NAME, props: restProps, mask: maskLetter,
            })}
            ref={getRefField({
              fieldName: LAST_NAME, callBack: setRefFieldsFn,
            })}
            onInput={getKeyboardLayoutFn.prepend((e) => ({
              field: get(restProps, `fields.${LAST_NAME}`),
              value: e.target.value,
            }))}
            required
          />
        </div>

        <div className="buttons">
          <div className="button">
            <Button
              type="submit"
              className="nextStep"
            >
              Отправить код
            </Button>
          </div>

        </div>
      </form>
    </>
  );
});
