import React from 'react';
import { useUnit } from 'effector-react';
import { isEmpty } from 'src/lib/lodash';
import {
  $applicationStatus, $isRepeatNewLoan, $isProfileNewLoanPage, $isWaitingLoan,
} from 'src/models/Loan/Application';
import {
  $isActiveAgreement, $isProfileToRepayPage,
} from 'src/models/Loan/Agreement';
import { NoLoan } from './children/NoLoan/NoLoan';
import { Loan } from './children/Loan';
import { WaitLoan } from './children/WaitLoan';

export const Header = React.memo(() => {
  const [
    status,
    isProfileNewLoanPage,
    isProfileToRepayPage,
    isWaitingLoan,
    isActiveAgreement,
    isRepeatNewLoan,
  ] = useUnit([
    $applicationStatus, $isProfileNewLoanPage, $isProfileToRepayPage,
    $isWaitingLoan, $isActiveAgreement, $isRepeatNewLoan,
  ]);
  const isWait = !isEmpty(isWaitingLoan);
  return (
    <>
      {!isEmpty(status) && !isProfileNewLoanPage && isEmpty(isActiveAgreement) && (
        <>
          {isWait ? <WaitLoan /> : <NoLoan />}
        </>
      )}

      {!isEmpty(isRepeatNewLoan) && isEmpty(isActiveAgreement) && (
        <>
          <NoLoan />
        </>
      )}

      {isActiveAgreement && !isProfileToRepayPage && <Loan />}
    </>
  );
});
