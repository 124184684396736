import React, { useEffect } from 'react';
import { useUnit } from 'effector-react';
import { getFormatIntMoney, getFormatMoney } from 'src/lib/money';
import { getUnitDay, getDateInterval } from 'src/lib/date';
import {
  bindCalcAmountFn, bindCalcPeriodFn,
  backupDataCalcFn, changeBackupDataCalcFn, closeCalculatorPageFn,
  $calcCombineData,
} from 'src/models/Calculator';
import { ButtonWithLoader as Button } from 'src/ui/components/Form';
import 'nouislider/dist/nouislider.css';

export const Calculator = React.memo(({ closeCallback }) => {
  const {
    amountMin, amountMax, amount, periodMin, periodMax, period, amountGraceRefund,
  } = useUnit($calcCombineData);

  useEffect(() => {
    bindCalcAmountFn(document.querySelector('.calculator-slider'));
    bindCalcPeriodFn(document.querySelector('.calculator-term'));
    backupDataCalcFn({ amount, period });
    return () => {
      closeCalculatorPageFn();
    };
  }, []);

  return (
    <>
      <div className="modal-main">
        <button
          type="button"
          className="close-modal__btn close-modal"
          onClick={closeCallback}
        >
          <img src="/img/modal/close.svg" alt="" />
        </button>
        <div className="modal-wrapper">
          <h2 className="modal-title">Cумма и срок займа</h2>

          <p className="modal-text">Измените желаемую сумму и срок займа</p>
          <div className="modal-calc calculatorMain amountCalculator">
            <div className="calculator-top">
              <p className="calculator-text">Сумма займа</p>
              <div className="calculator-sum">
                <span className="sum">{getFormatMoney(amount)}</span>
                <span className="cur">₽</span>
              </div>
            </div>
            <div className="calculator-slider__block">
              <div
                className="calculator-slider"
                data-min={amountMin}
                data-max={amountMax}
                data-start={amount}
              />
            </div>
          </div>
          <div className="modal-calc calculatorMain termCalculator">
            <div className="calculator-top">
              <p className="calculator-text">Срок займа</p>
              <div className="calculator-sum">
                <span className="sum">{period}</span>
                <span className="cur day" id="days">{` ${getUnitDay(period)}`}</span>
              </div>
            </div>
            <div className="calculator-slider__block">
              <div
                className="calculator-term"
                data-min={periodMin}
                data-max={periodMax}
                data-start={period}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="modal-bottom">
        <div className="modal-wrapper">
          <div className="modal-bottom__item">
            <span className="modal-bottom__title">Вы вернете:</span>
            <div className="modal-bottom__info">
              <span className="sum" id="totalSum">{getFormatIntMoney(amountGraceRefund)}</span>
              <span className="cur">₽</span>
            </div>
          </div>
          <div className="modal-bottom__item">
            <span className="modal-bottom__title">Одним платежом до:</span>
            <span className="modal-bottom__info" id="finalDay">{getDateInterval(period)}</span>
          </div>
          <div className="modal-button">
            <Button
              onClick={changeBackupDataCalcFn}
              className="close-modal"
            >
              Применить
            </Button>
          </div>
        </div>
      </div>
    </>
  );
});
