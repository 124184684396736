import { createForm } from 'effector-forms';
import { v4 as uuidv4 } from 'uuid';
import { get } from 'src/lib/lodash';
import { isCurrentPath } from 'src/lib/url';
import { rules } from 'src/lib/rules';
import { isValidPassportDate } from 'src/lib/date';
import { storage } from 'src/lib/storage';
import { REG_PATH } from 'src/dict/path';
import { REG_STEP2_FIELDS } from 'src/dict/fields';
import { addPassportDataSign } from 'src/api/signatures/User';
import { $pathnameUrl } from 'src/models/App';
import { registrationDomain } from '../index';

// STEP 2 - паспортные данные

/// ///////////////////////////////////////////////////////////
// ================== СЛОВАРИ / КОНСТАНТЫ ================== //
/// ///////////////////////////////////////////////////////////

const { PASSPORT } = REG_PATH;

const {
  PASSPORT_ID, PASSPORT_SERIES, PASSPORT_DATE_ISSUE, CODE_DEPARTMENT,
  PASSPORT_ISSUE_BY, PLACE_BIRTH, UUID, SNILS,
} = REG_STEP2_FIELDS;

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ СОБЫТИЙ ===================== //
/// ///////////////////////////////////////////////////////////

// для обрботки появленяи ошибки для снилс при нажатии на кнопку отправки формы
export const submitFormFn = registrationDomain.createEvent('submitFnFn');

export const addPassportDataFn = registrationDomain.createEvent('addPassportDataFn');

/* событие вызывающееся на набора в поле ввода адреса (запускает формирование полей которые очистятся)
 триггерится в модели компонента адреса */
export const clearCodeFMSFn = registrationDomain.createEvent('clearCodeFMSFn');
/* событие выбора option (запускает данных которые будут записаны в поля)
 триггерится в компоненте адреса */
export const choiceCodeFMSFn = registrationDomain.createEvent('choiceCodeFMSFn');
/* событие вызывающееся при потере фокуса с адреса (служит для валидирования что данные выбраны из списка)
 триггерится в компоненте адреса */
export const notChoiceCodeFMSFn = registrationDomain.createEvent('notchoiceCodeFMSFn');

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ХРАНИЛИЦ ==================== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ЭФФЕКТОВ ==================== //
/// ///////////////////////////////////////////////////////////

export const addPassportDataFx = registrationDomain.createEffect(addPassportDataSign);

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

export const $isPassportPage = $pathnameUrl.map((path) => isCurrentPath(path, PASSPORT));

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ЭФФЕКТОВ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

// тут создаются эффекты через attach

/// ///////////////////////////////////////////////////////////
// ====================== ЮНИТЫ ФОРМ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем формы с помощью effector-forms

export const step2Form = createForm({
  fields: {
    [PASSPORT_SERIES]: {
      rules: [rules.required(), rules.passportSeries()], init: '', validateOn: ['blur'],
    },
    [PASSPORT_ID]: { rules: [rules.required(), rules.passportId()], init: '', validateOn: ['blur'] },
    [PASSPORT_DATE_ISSUE]: {
      rules: [
        {
          validator: (value) => isValidPassportDate(
            value,
            get(storage.get('user'), 'profile.birth_date', ''),
          ),
          errorText: 'Введенная дата некорректна',
        },
      ],
      init: '',
      validateOn: ['blur'],
    },
    [CODE_DEPARTMENT]: { rules: [rules.required(), rules.passportCodeDepartment()], init: '' },
    [PASSPORT_ISSUE_BY]: { rules: [rules.required()], init: '' },
    [SNILS]: {
      rules: [rules.required(), rules.validateSnils()],
      init: '',
      validateOn: ['blur'],
    },
    [PLACE_BIRTH]: { rules: [rules.required()], init: '', validateOn: ['blur'] },
    [UUID]: { init: uuidv4() },
  },
  validateOn: ['submit'],
  domain: registrationDomain,
});

/// ///////////////////////////////////////////////////////////
//  ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ФОРМ + (ХРАНИЛИЩ, ЭФФЕКТОВ) //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ========================= ФАБРИКИ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем различные фабрики и хранилища с использованием фабрик
