import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { get, isEmpty } from 'src/lib/lodash';
import { daData } from 'src/dict/config';
import {
  getInputDaDataFMSModelApiFn,
  $objInputDaDataFMSModels,
} from 'src/models/components/InputDaData/FMS';
import { InputDaDataFMS } from './InputDaDataFMS';

const {
  TIMEOUT_DEBOUNCE, MIN_LENGTH, PATH, LIMIT,
} = daData.FMS;

export const InputFMS = React.forwardRef(({
  clearCodeFMSFn,
  ...props
}, ref) => {
  const { name } = props;

  const dataInputDaDataApi = get(useStore($objInputDaDataFMSModels), name, {});
  useEffect(() => {
    getInputDaDataFMSModelApiFn({
      limit: LIMIT,
      typeAction: PATH,
      timeoutDebounce: TIMEOUT_DEBOUNCE,
      minLength: MIN_LENGTH,
      clearCodeFMSFn,
      ...props,
    });
  }, []);

  if (isEmpty(dataInputDaDataApi)) return <div />;

  return (
    <InputDaDataFMS
      modelData={{ ...dataInputDaDataApi }}
      propsData={{ ...props }}
      className="address"
      ref={ref}
    />
  );
});
