import React from 'react';
import { useStore } from 'effector-react';
import { NavLink, Link } from 'react-router-dom';
import { isEmpty } from 'src/lib/lodash';
import { PROFILE_PATH } from 'src/dict/path';
// import { logoutFn } from 'src/models/Auth';
import {
  logoutForProfileFn,
  $routeItems,
} from 'src/models/Profile';

const { PROFILE, PARTNERS } = PROFILE_PATH;

const getTwoLInes = (value) => {
  const arr = value.split(' ');
  if (arr.length < 2) {
    return value;
  }

  return (
    <>
      {arr[0]}
      <br />
      {arr.slice(1).join(' ')}
    </>
  );
};

export const Menu = React.memo(() => {
  const items = useStore($routeItems);
  return (
    <div className="profileMenu-list">
      {items.filter(({ visible }) => visible).map(
        ({ label, path, props: { icon1, icon2, nameClass } }) => {
          const advClass = !isEmpty(nameClass) ? nameClass : '';
          const toPath = `/${PROFILE}/${path}`;
          const isTwoLines = [PARTNERS, ''].includes(path);
          return (
            <div key={`${path}_menu`}>
              {!isEmpty(path)
                ? (
                  <NavLink
                    to={toPath}
                    className={`profileMenu-list-item ${advClass}`}
                  >
                    <span className="icon">
                      {!isEmpty(icon1) && <img className="white" src={`/img/profile/menu/${icon1}`} alt="" />}
                      {!isEmpty(icon2) && <img className="black" src={`/img/profile/menu/${icon2}`} alt="" />}
                    </span>
                    {!isTwoLines
                      ? <p>{label}</p>
                      : <p>{getTwoLInes(label)}</p>}
                  </NavLink>
                )
                : (
                  <Link
                    to="#"
                    className={`profileMenu-list-item ${advClass}`}
                  >
                    <span className="icon">
                      {!isEmpty(icon1) && <img className="white" src={`/img/profile/menu/${icon1}`} alt="" />}
                      {!isEmpty(icon2) ? <img className="black" src={`/img/profile/menu/${icon2}`} alt="" /> : ' '}
                    </span>
                    <p>{getTwoLInes(label)}</p>
                  </Link>
                )}
            </div>
          );
        },
      )}
      <a
        // href="#"
        className="profileMenu-list-item exit"
        onClick={logoutForProfileFn}
      >
        <span className="icon">
          <img className="white" src="/img/profile/menu/icon11.svg" alt="" />
        </span>
        <p>Выход</p>
      </a>

      {/*
      <a href="profile-showcase.html" className="profileMenu-list-item nocenter">
        <span className="icon">
          <img className="white" src="/img/profile/menu/icon9.svg" alt="" />
          <img className="black" src="/img/profile/menu/icon9-black.svg" alt="" />
        </span>
        <p>
          Предложения
          <br />
          партнеров
        </p>
      </a>
      <a href="#" className="profileMenu-list-item soon nocenter">
        <span className="icon">
          <img className="white" src="/img/profile/menu/icon10.svg" alt="" />
        </span>
        <p>
          Исправление
          <br />
          кредитной истории
        </p>
      </a>
            <a href="#" className="profileMenu-list-item exit">
        <span className="icon">
          <img className="white" src="/img/profile/menu/icon11.svg" alt="" />
        </span>
        <p>Выход</p>
      </a>
      */}
    </div>
  );
});
