import {
  attach, sample,
} from 'effector';
import { debounce } from 'patronum/debounce';
import { get, isEmpty } from 'src/lib/lodash';
import {
  AUTH_TO_PROFILE, PAGES_PATH, REG_PATH,
} from 'src/dict/path';
import { LOAN_SERVICE_FIELDS, LOGIN_FIELDS } from 'src/dict/fields';
import { LS, token } from 'src/dict/config';
import {
  pushHistoryFn,
} from 'src/models/Helper/History';
import {
  hideLoaderButtonFn, showLoaderButtonFn,
} from 'src/models/Helper/Loader';
import { closeModalFn } from 'src/models/components/Modal';
import {
  resetPathToBackFn,
  $pathToBack, logoutForProfileFn,
} from 'src/models/Profile';
import { activationCodeRecoveryFx } from 'src/models/Recovery/Confirm';
import {
  step1Form,
  addPersonalDataFx,
} from 'src/models/Registration/Personal';
import { authESIALoginFx } from 'src/models/Esia';
import { removeAgreementInStorageFn } from 'src/models/Loan/Agreement';
import { storageRemoveFn, storageSetFn } from 'src/models/Helper/Storage';
import { cookieRemoveFn } from 'src/models/Helper/Cookie';
import {
  logoutFn, clearAllStorageFn, clearRegStorageFn, goLoginPageFn,
  authLoginAfterFn,
  $tokenData,
  authLoginFx,
  loginForm,
} from './index';

const { PHONE, PASSWORD } = LOGIN_FIELDS;
const { LOGIN } = PAGES_PATH;
const {
  PASSPORT, ADDRESS, EXPERIENCE,
} = REG_PATH;

/// ///////////////////////////////////////////////////////////
// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
/// ///////////////////////////////////////////////////////////

// распихиваем полученные данные по соответствующим полям
// authESIALoginFx.doneData, (_, result) => get(result, 'data.url', ''));

/// ///////////////////////////////////////////////////////////
// ===================== БЛОК ОПЕРАТОРОВ =================== //
/// ///////////////////////////////////////////////////////////

sample({
  clock: [
    addPersonalDataFx.doneData,
    authLoginFx.doneData,
    activationCodeRecoveryFx.doneData,
    authESIALoginFx.doneData,
  ],
  fn: (result) => {
    const access = get(result, 'data.token', '');
    const refresh = get(result, 'data.refresh', '');
    return ({ accessToken: access, refreshToken: refresh });
  },
  target: [
    $tokenData,
    storageSetFn.prepend(({ accessToken, refreshToken }) => ({
      [token.ACCESS]: accessToken,
      [token.REFRESH]: refreshToken,
    })),
    /* cookieSetFn.prepend(({ accessToken }) => ({
      [token.COOKIE_API_TOKEN]: accessToken,
    })), */
  ],
});

sample({
  clock: loginForm.formValidated,
  target: [
    authLoginFx.prepend((data) => ({
      [PHONE]: `${Number(data[PHONE].replace(/\D+/g, ''))}`,
      [PASSWORD]: data[PASSWORD],
    })),
    showLoaderButtonFn,
    // pushHistoryFn.prepend(() => `/${PROFILE}`),
  ],
});

sample({
  clock: authLoginFx.finally,
  target: hideLoaderButtonFn,
});

sample({
  clock: authLoginFx.doneData,
  source: $pathToBack,
  fn: (pathToBack) => (isEmpty(pathToBack) ? `/${AUTH_TO_PROFILE}` : pathToBack),
  // clock: authLoginFx.fail,
  target: [
    // если это было на 1-ой странице регистрации
    closeModalFn,
    // если это было на 1-ой странице регистрации
    step1Form.reset,
    pushHistoryFn.prepend((path) => path),
    // при авторизации удаляем информацию которая может быть в LS и которая больша не нужна
    // удаляем уже ненужную информацию из LS
    clearRegStorageFn,
  ],
});

sample({
  clock: debounce({
    source: authLoginFx.doneData,
    timeout: 500,
  }),
  target: [
    authLoginAfterFn,
    resetPathToBackFn,
  ],
});

sample({
  clock: authLoginFx.fail,
  // clock: authLoginFx.doneData,
  source: loginForm.fields,
  fn: (form) => form,
  target: [
    attach({
      effect: (_, form) => {
        form[PHONE].addError({
          rules: 'required',
          errorText: '',
        });
        form[PASSWORD].addError({
          rules: 'required',
          errorText: 'Пользователь с указанным телефоном и паролем не найден',
        });
      },
    }),
    // notifyErrorFn.prepend(() => 'Пользователь с указанным телефоном и паролем не найден'),
  ],
});

// Завершаем сеанс
sample({
  clock: logoutFn,
  target: logoutForProfileFn,
});

// Очищаем LS
sample({
  clock: clearAllStorageFn,
  target: [
    // удаляем agreementId, если он есть
    removeAgreementInStorageFn,
    storageRemoveFn.prepend(() => [token.ACCESS, token.REFRESH]),
    cookieRemoveFn.prepend(() => token.COOKIE_API_TOKEN),
    clearRegStorageFn,
  ],
});

sample({
  clock: clearRegStorageFn,
  target: [
    // удаляем agreementId, если он есть
    storageRemoveFn.prepend(() => [
      LS.STEP, LS.APP, LS.USER, LS.PAYMENT_CONTEXT, LS.CITY, ADDRESS, EXPERIENCE, PASSPORT,
      LOAN_SERVICE_FIELDS.AGREEMENT_ID,
    ]),
  ],
});

sample({
  clock: goLoginPageFn,
  target: pushHistoryFn.prepend(() => `/${LOGIN}`),
});
