// inspired by https://gist.github.com/mkjiau/650013a99c341c9f23ca00ccb213db1c#gistcomment-3341286
import axios from 'axios';
import { isEmpty } from 'src/lib/lodash';
import { logoutFn } from 'src/models/Auth';
import { config, token } from 'src/dict/config';
import { storage } from 'src/lib/storage';

const baseUrl = !isEmpty(config.API_PROTOCOL)
  ? `${config.API_PROTOCOL}://${config.API_BASE_URL}/public-api/v1`
  : `${config.API_BASE_URL}/public-api/v1`;

const axiosConfig = {
  baseURL: baseUrl,
  headers: {
    'Project-Id': config.API_PROJECT_ID,
    // 'Access-Control-Allow-Origin': '*',
    // withCredentials: true,
    // mode: 'no-cors',
  },
};

const instance = axios.create(axiosConfig);
let refreshPromise;

class RefreshTokenError extends Error {
  constructor(message) {
    super(message);
    this.name = 'Unauthorized';
  }
}

initiateTokensInterceptors(instance);

const sendRefreshTokenRequest = async (refreshToken) => instance.post('/auth/refresh/', { refresh: refreshToken });

const refreshTokens = async () => {
  const refreshToken = storage.get(token.REFRESH);

  if (!refreshToken) {
    throw new RefreshTokenError('Пользователь не авторизован');
  }

  try {
    // save promise to keep 1 active JWT refresh request at any time.
    refreshPromise = refreshPromise || sendRefreshTokenRequest(refreshToken);

    const refreshedTokens = await refreshPromise;

    storage.set(token.REFRESH, refreshedTokens?.refresh);
    storage.set(token.ACCESS, refreshedTokens?.access);
  } finally {
    refreshPromise = undefined;
  }
};

const handleRetryRequestErrors = (errorFromSecondRequest) => {
  // console.log('errorFromSecondRequest:', errorFromSecondRequest);
  const isRetryRequestUnauthorized = [401, 403, 404].includes(
    errorFromSecondRequest.response?.status,
  ); // === 401;
  const isRefreshTokenRequestFailed = errorFromSecondRequest instanceof RefreshTokenError;
  const isFailedToRefreshTokens = isRefreshTokenRequestFailed || isRetryRequestUnauthorized;
  if (isFailedToRefreshTokens) {
    logoutFn();
  }

  throw errorFromSecondRequest;
};

const retryRequest = async (originalReq) => {
  const retryRequestObject = { ...originalReq };

  retryRequestObject.isRetryAttempt = true;
  retryRequestObject.headers.Authorization = instance.defaults.headers.common.Authorization;

  const retryRequestResponse = await instance.request(retryRequestObject);

  return retryRequestResponse;
};

const handleResponseError = async (error) => {
  const { config: originalReq, response } = error;
  // console.log('status:', originalReq);
  const isRefreshRequest = originalReq.url === '/auth/refresh/';
  const isLoginRequest = originalReq.url === '/auth/login';
  const isSecondAttempt = originalReq.isRetryAttempt;
  const isOriginalRequestUnauthorized = [401, 403].includes(response?.status); // === 401;

  if (isRefreshRequest || isLoginRequest || isSecondAttempt || !isOriginalRequestUnauthorized) {
    throw error;
  }

  try {
    await refreshTokens();
    const retryRequestResponse = retryRequest(originalReq);

    return retryRequestResponse;
  } catch (repeatedError) {
    return handleRetryRequestErrors(repeatedError);
  }
};

const addAuthHeaderToRequest = async (requestConfig) => {
  const accessToken = await storage.get(token.ACCESS);
  const updatedConfig = requestConfig;
  if (accessToken) {
    updatedConfig.headers.Authorization = `Bearer ${accessToken}`;
  }

  return updatedConfig;
};

function initiateTokensInterceptors(axiosInstance) {
  axiosInstance.interceptors.request.use(
    (requestConfig) => addAuthHeaderToRequest(requestConfig),
    (error) => Promise.reject(error),
  );

  axiosInstance.interceptors.response.use(
    (response) => response.data,
    (error) => handleResponseError(error),
  );
}

export { instance as axios };
// public-api/v1/products
