import React from 'react';
import { useUnit } from 'effector-react';
import { $firstName } from 'src/models/User';
import { YellowBlock, MainBlock } from 'src/ui/components';
import { EsiaButtonReg } from 'src/ui/components/Block/EsiaButtonReg';
import { PassportForm } from './children/PassportForm';

const KeksBlock = () => (
  <div className="keks keks3">
    <img src="/img/form/keks-3.png" alt="" />
  </div>
);

export const Step2 = () => {
  const userName = useUnit($firstName);

  return (
    <div className="step step3 active">
      <YellowBlock
        title={`${userName}, очень приятно!`}
        text="Пожалуйста, укажите ваши паспортные данные"
      />
      <MainBlock keksBlock={KeksBlock}>
        <div className="block-main__content">
          <PassportForm />
          <EsiaButtonReg />
        </div>
      </MainBlock>
    </div>
  );
};
