import {
  sample, attach, restore, split,
} from 'effector';
import { spread } from 'patronum/spread';
import { debounce } from 'patronum/debounce';
import { v4 as uuidv4 } from 'uuid';
import { get, isEmpty } from 'src/lib/lodash';
import { getErrorFromDict } from 'src/lib/errors';
import { storage } from 'src/lib/storage';
import {
  PAGES_PATH,
  PROFILE_LOAN_PATH, PROFILE_NEW_LOAN_PATH, PROFILE_PATH,
} from 'src/dict/path';
import { isCurrentPath } from 'src/lib/url';
import {
  statistic, CODE_TIMER, config,
} from 'src/dict/config';
import { APPLICATION_FIELDS } from 'src/dict/fields';
import { getCurrentTime } from 'src/lib/date';
import { $pathnameUrl } from 'src/models/App';
import { pushHistoryFn } from 'src/models/Helper/History';
import { notifyErrorFn } from 'src/models/Helper/Notification';
import { storageRemoveFn } from 'src/models/Helper/Storage';
import { showLoaderNotTimePageFn, hideLoaderPageFn } from 'src/models/Helper/Loader';
import {
  $amount as $amountCalc,
  $period as $periodCalc,
  $productId,
} from 'src/models/Calculator';
import { sendYMGoalFx } from 'src/models/Statistic';
import {
  getItemsPaymentFn,
  getItemsPaymentFx, bindPaymentToAppFx,
} from 'src/models/Payment';
import { $messagesDict, $purposesDict } from 'src/models/Dictionaries';
import {
  getCurrentUserFn,
  $userId,
} from 'src/models/User';
import { createDocsFromAfterAppFx } from 'src/models/Documents';
import { getActionRejectFn } from 'src/models/Loan/Application/Failure';
import {
  goToAgreementsFn,
  // goAgreementPageFn,
  goAgreementSuccessPageFn, // removeAgreementInStorageFn,
  $agreementId,
  sendCodeSigningApplicationFx, $agreementStatus,
} from 'src/models/Loan/Agreement';

import { closeModalFn, openModalFn } from 'src/models/components/Modal';
import { ProccessingError } from 'src/ui/components/Modals/ProccessingError';
import {
  STATUS_LOAN,
  // события
  sendStatisticUtmFn,
  toggleMakeLoanFn,
  sendApplicationProcessFn,
  sendCodeSigningApplicationFn,
  startTimerActivationCodeFn,
  stopTimerActivationCodeFn,
  createApplicationsFn,
  goNewLoanPageFn,
  goSuccessPageFn,
  goPaymentPageFn,
  goProcessingPageFn,
  goFailurePageFn,
  goConfirmPageFn,
  timerGetApplicationStatusFn,
  // хранилища
  $applicationId,
  $applicationStatus,
  $isOpenMakeLoan,
  $tokenSent,
  $codeTest,
  $valueTimer,
  $timerActivationCode,
  $isProfileNewLoanPage,
  $isNotExpired,
  $isRepeatNewLoan,
  $applicationItem,
  // эффекты
  sendApplicationProcessFx,
  createApplicationsFx,
  createApplicationsAfterRegFx,
  sendStatisticUtmFx,
  changeApplicationStatusFx,
  getApplicationFx,
  getApplicationAfterChangeStatusFx,
  timerGetApplicationStatusFx,
  getApplicationFn,
  getApplicationForPaymentFx,
} from './index';

const { INFO, UTM_LIST } = statistic;
const {
  SUCCESS, PAYMENT, FAILURE, AGREEMENT, CONFIRM,
} = PROFILE_NEW_LOAN_PATH;
const { PROFILE, LOAN } = PROFILE_PATH;
const { NEW_LOAN } = PROFILE_LOAN_PATH;
const {
  APPROVED,
  PROCESSING, REJECTED, RECEIVED,
} = STATUS_LOAN;
const {
  AMOUNT, PERIOD, PROJECT_ID, CLIENT_ID, PRODUCT_ID, UUID,
  PURPOSE_ID,
} = APPLICATION_FIELDS;

/// ///////////////////////////////////////////////////////////
// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
/// ///////////////////////////////////////////////////////////

$applicationItem
  .on([
    getApplicationFx.doneData,
    getApplicationAfterChangeStatusFx.doneData,
    getApplicationForPaymentFx.doneData,
  ], (_, result) => get(result, 'data', ''));

// $applicationItem.watch((item) => console.log('$applicationItem:', item));

$applicationId
  .on([
    createApplicationsFx.doneData,
    createApplicationsAfterRegFx.doneData,
  ], (_, result) => get(result, 'data.id', ''));

$applicationStatus
  .on(getApplicationFx.doneData, (_, result) => get(result, 'data.status', ''))
  .on(getApplicationAfterChangeStatusFx.doneData, (_, result) => get(result, 'data.status', ''))
  .on(getApplicationForPaymentFx.doneData, (_, result) => get(result, 'data.status', ''));

$isOpenMakeLoan
  .on(toggleMakeLoanFn, (_, value) => value);

$timerActivationCode
  .on(startTimerActivationCodeFn, () => true)
  .on(stopTimerActivationCodeFn, () => false);

// $applicationId.watch((state) => console.log('$applicationId:', state));

/// ///////////////////////////////////////////////////////////
// ===================== БЛОК ОПЕРАТОРОВ =================== //
/// ///////////////////////////////////////////////////////////
/// ///////////////////////////////////////////////////////////

sample({
  clock: $applicationStatus,
  fn: (status) => isEmpty(status),
  target: $isRepeatNewLoan,
});

sample({
  clock: [$isProfileNewLoanPage, $isNotExpired],
  source: [$isProfileNewLoanPage, $isNotExpired],
  filter: ([isProfileNewLoanPage, isNotExpired]) => !isEmpty(isProfileNewLoanPage) && !isEmpty(isNotExpired),
  target: goToAgreementsFn,
});

// проверяем привязана ли карта к займу
sample({
  clock: sample({
    clock: goToAgreementsFn,
    source: $isNotExpired,
    filter: (isNotExpired) => isNotExpired,
  }),
  source: $applicationId,
  filter: (applicationId) => !isEmpty(applicationId),
  target: getApplicationForPaymentFx,
});

split({
  source: sample(
    [
      $applicationStatus,
      $isNotExpired,
      $applicationItem,
    ],
    // событие
    [
      // если при загрузке данных есть активный займ - то его должно перекинуть на страницу займов
      sample({
        clock: $userId,
        source: [$agreementStatus, $pathnameUrl],
        // eslint-disable-next-line max-len
        filter: ([agreementStatus, pathnameUrl]) => !isEmpty(agreementStatus)
              && !isCurrentPath(pathnameUrl, PAGES_PATH.BIND),
      }),
      sample({
        clock: $userId,
        source: [$isProfileNewLoanPage, $isNotExpired, $pathnameUrl],
        filter: ([isProfileNewLoanPage, isNotExpired, pathnameUrl]) => (
          !isEmpty(isProfileNewLoanPage) && isNotExpired && !isCurrentPath(pathnameUrl, PAGES_PATH.BIND)
        ),
      }),
      getApplicationForPaymentFx.doneData,
    ],
  ),
  match: {
    toProcessing: ([status, isNotExpired]) => isNotExpired && [APPROVED, PROCESSING].includes(status),
    // == toProcessing: ([status, isNotExpired]) => isNotExpired && [PROCESSING].includes(status),

    toPayment: ([status, isNotExpired, applicationItem]) => isNotExpired && status === RECEIVED
        && isEmpty(get(applicationItem, 'paymentable_id')),
    // == toPayment:
    // ([status, isNotExpired, agreementId]) => isNotExpired && status === APPROVED && isEmpty(agreementId),

    toConfirm: ([status, isNotExpired, applicationItem]) => isNotExpired && status === RECEIVED
        && !isEmpty(get(applicationItem, 'paymentable_id')),
    // == toConfirm:
    // ([status, isNotExpired, agreementId]) => isNotExpired && status === APPROVED && !isEmpty(agreementId),
  },
  cases: {
    toProcessing: [
      goProcessingPageFn,
      // если страница перезагружена то мы просто запускаем еще раз таймер
      // timerGetApplicationStatusFx.prepend(() => 3000),
    ],
    toPayment: goPaymentPageFn,
    toConfirm: goSuccessPageFn,
    // __: goAgreementPageFn,
  },
});

sample({
  clock: goToAgreementsFn,
  source: $isNotExpired,
  filter: (isNotExpired) => !isNotExpired,
  target: notifyErrorFn.prepend(() => 'Срок действия предложения истек, пожалуйста, подайте заявку на заем еще раз'),
});

// если при активном займе
/* sample({
  clock: goPaymentPageFn,
  target: goAgreementPageFn,
}); */

sample({
  clock: goPaymentPageFn,
  target: getCurrentUserFn,
});

// /////////// создание заявки в ЛК //////////////////////

sample({
  clock: createApplicationsFn,
  target: // создаем заявку
      attach({
        effect: createApplicationsFx,
        source: [$amountCalc, $periodCalc, $productId, $userId, $purposesDict],
        mapParams: (_, [amount, period, productId, userId, purposesDict]) => {
          const obj = ({
            [AMOUNT]: amount,
            [PERIOD]: period,
            [PRODUCT_ID]: productId,
            [PROJECT_ID]: config.API_PROJECT_ID,
            [CLIENT_ID]: userId,
            [UUID]: uuidv4(),
            [PURPOSE_ID]: purposesDict.reduce((val, { id, name }) => {
              if (name.trim() === 'Деньги до зарплаты') {
                val = id;
              }
              return val;
            }, ''),
          });
          if (isEmpty(obj[PURPOSE_ID])) {
            obj[PURPOSE_ID] = purposesDict[0].id;
          }
          return obj;
        },
      }),
});

sample({
  clock: createApplicationsFx.doneData,
  target: [
    // после создания заявки в ЛК, сразу посылаем запрос на генерацию док-ов
    attach({
      effect: createDocsFromAfterAppFx,
      source: $applicationId,
      mapParams: (_, applicationId) => applicationId,
    }),
    // посылаем utm метки
    sendStatisticUtmFn,
  ],
});

// после создания док-ов перенаправляем на страницу процессинга
sample({
  clock: createDocsFromAfterAppFx.doneData,
  target: goProcessingPageFn,
});

sample({
  clock: createApplicationsFx.fail,
  target: [
    notifyErrorFn.prepend(
      (data) => get(data, 'error.response.data.message', ''),
    ),
  ],
});

// получаем данные о заявке
sample({
  clock: sample({
    clock: getApplicationFn,
    source: [$applicationId, $userId],
    filter: ([applicationId, userId]) => (!isEmpty(applicationId) && !isEmpty(userId)),
  }),
  source: $applicationId,
  target: getApplicationFx,
});

// если платежные payments_methods пустые - обнуляем $agreementId
/* sample({
  clock: getApplicationFx.doneData,
  filter: (result) => isEmpty(get(result, 'data.payout_methods', {})),
  fn: () => ({ agreementId: '' }),
  target: [
    removeAgreementInStorageFn,
    spread({
      targets: { agreementId: $agreementId },
    }),
  ],
}); */

sample({
  clock: getItemsPaymentFn,
  source: getItemsPaymentFx.pending,
  filter: (pending) => isEmpty(pending),
  target: [
    getItemsPaymentFx,
  ],
});

// /////////// end создание заявки //////////////////////

/* пожумать куда вынести статистику */
sample({
  clock: createApplicationsAfterRegFx.done,
  target: sendStatisticUtmFn,
});

sample({
  clock: sample({
    clock: sendStatisticUtmFn,
    source: $applicationId,
    /* если это со внешней стороны сайта (регистрации)
    и LS не пуст */
    filter: (applicationId) => !isEmpty(storage.get(INFO)) && !isEmpty(applicationId),
  }),
  target: attach({
    effect: sendStatisticUtmFx,
    source: $applicationId,
    mapParams: (_, applicationId) => {
      const statInfo = storage.get(INFO);
      const utmParams = {};
      const isUtm = UTM_LIST.some((utm) => get(statInfo, utm, false));
      if (isUtm) {
        UTM_LIST.forEach((utm) => {
          utmParams[utm] = get(statInfo, utm, '');
        });
      }
      return ({
        applicationId,
        data: {
          id: uuidv4(),
          // fingerprint_id: '',
          landing_uri: get(statInfo, 'landingUri'),
          user_agent: get(statInfo, 'userAgent'),
          referer: get(statInfo, 'referer'),
          datetime: get(statInfo, 'datetime'),
          query_params: {
            ___query_string: get(statInfo, '__query_string', ''),
            ...get(statInfo, 'query_string', {}),
            ...utmParams,
          },
        },
      });
    },
  }),
});
/* end */

// Посылаем заявку на обработку
sample({
  clock: sendApplicationProcessFn,
  source: sendApplicationProcessFx.pending,
  filter: (pending) => isEmpty(pending),
  target: [
    attach({
      effect: sendApplicationProcessFx,
      source: $applicationId,
      mapParams: (_, applicationId) => `${applicationId}`,
    }),
    closeModalFn,
  ],
});

// Ошибка отправки заявки
split({
  source: sample(
    restore(sendApplicationProcessFx.fail, {}),
    sendApplicationProcessFx.fail,
  ),
  match: {
    true: (data) => {
      const response = get(data, 'error.response', {});
      const status = get(response, 'status', '');
      // const errors = get(response, 'data.errors', {});
      return ([422, 404].includes(status));
    },
  },
  cases: {
    true: [
      showLoaderNotTimePageFn,
      // открываем модалку
      openModalFn.prepend(() => ({
        importantOperation: true,
        className: 'modal-agreement',
        content: ProccessingError,
        props: {
          callBack: sendApplicationProcessFn,
        },
      })),
    ],
    __: [
      // показываем прелоадер - без таймера
      showLoaderNotTimePageFn,
      goFailurePageFn,
      getActionRejectFn,
      notifyErrorFn.prepend(
        (data) => get(
          data,
          'error.response.status.data.message',
          'При попытке отправки заявки на процессинг произошла ошибка',
        ),
      ),
      // стираем данные о utm метках
      // !!==
      storageRemoveFn.prepend(() => [INFO]),
    ],
  },
});

/* после удачной отсылки заявки запускаем таймер:
- через 2 сек посылаем запрос на палучение статуса */
sample({
  clock: goProcessingPageFn,
  target: timerGetApplicationStatusFn,
});

sample({
  clock: timerGetApplicationStatusFn,
  source: timerGetApplicationStatusFx.pending,
  filter: (pending) => isEmpty(pending),
  target: timerGetApplicationStatusFx.prepend(() => 3000),
});

// когда таймер на получение заявки истек посылаем запрос на получение данных
sample({
  clock: timerGetApplicationStatusFx.done,
  target: attach({
    effect: getApplicationAfterChangeStatusFx,
    source: $applicationId,
    mapParams: (_, applicationId) => applicationId,
  }),
});

/* при получении данных о заявки, в зависимости от статуса, решаем
мониторит заявку дальше или перенаправлять далее в зависимости от статуса */
split({
  source: sample(
    restore(getApplicationAfterChangeStatusFx.doneData, {}),
    getApplicationAfterChangeStatusFx.done,
  ),
  match: {
    [RECEIVED]: (data) => RECEIVED === get(data, 'data.status', ''),
    [REJECTED]: (data) => REJECTED === get(data, 'data.status', ''),
  },
  cases: {
    [RECEIVED]: [
      goPaymentPageFn,
    ],
    [REJECTED]: [
      // показываем прелоадер - без таймера
      showLoaderNotTimePageFn,
      // загружаем актуальные данные
      getCurrentUserFn,
      // перенаправляем на страницу отказа
      goFailurePageFn,
      // вызываемchangeValueCalcAmountFn
      getActionRejectFn,
      // стираем данные о utm метках
      storageRemoveFn.prepend(() => [INFO]),
    ],
    // так как статус еще не известен, запускаем таймер по новой
    __: timerGetApplicationStatusFn,
  },
});

sample({
  clock: changeApplicationStatusFx.fail,
  target: [
    notifyErrorFn.prepend(
      (data) => get(
        data,
        'error.response.data.message',
        'При попытке изменения статуса произошла ошибка',
      ),
    ),
  ],
});

sample({
  clock: sample({
    clock: sendCodeSigningApplicationFn,
    source: $agreementId,
    filter: (agreementId) => !isEmpty(agreementId),
  }),
  source: $agreementId,
  target: [
    sendCodeSigningApplicationFx,
    // посылаем utm метки
    sendStatisticUtmFn,
  ],
});

sample({
  clock: sendCodeSigningApplicationFx.fail,
  target: notifyErrorFn.prepend(
    (data) => get(data, 'error.response.data.message', ''),
  ),
});

// обрабатываем ошибку Паспорта
split({
  clock: sendCodeSigningApplicationFx.failData,
  source: restore(sendCodeSigningApplicationFx.fail, {}),
  match: {
    error424: (error) => (get(error, 'error.response.status') === 424),
  },
  cases: {
    error424: [
      hideLoaderPageFn,
      notifyErrorFn.prepend(() => 'Ошибка подрписания оферты'),
      goPaymentPageFn,
    ],
    __: [
      hideLoaderPageFn,
      attach({
        source: $messagesDict,
        effect: (messagesDict, value) => {
          notifyErrorFn(getErrorFromDict('Ошибка подрписания оферты')({ dict: messagesDict, data: value }));
        },
      }),
    ],
  },
});

sample({
  clock: sendCodeSigningApplicationFx.doneData,
  fn: (data) => {
    const nextAttemptAt = get(data, 'data.next_attempt_at', '');

    const isSent = get(data, 'data.is_sent', '');
    const tokenSent = get(data, 'data.token', '');
    const code = get(data, 'data.code', '');

    const dateParts = nextAttemptAt.split('T');
    // console.log('dateParts:', dateParts);
    if (dateParts.length < 2) {
      return {};
    }
    const timeParts = dateParts[1].split('+');
    const time = timeParts[0];
    const secondsParts = time.split(':');
    const seconds = Number.parseInt(secondsParts[2], 10);

    const currentTimer = getCurrentTime();
    const currentSecondsParts = currentTimer.split(':');
    const currentSeconds = Number.parseInt(currentSecondsParts[2], 10);

    const valueTimer = isSent ? CODE_TIMER
      : (seconds < currentSeconds
        ? (seconds + 59 - currentSeconds) : (seconds - currentSeconds));
    return { valueTimer, tokenSent, code };
  },
  target:
      [
        spread({
          targets: {
            valueTimer: $valueTimer,
            tokenSent: $tokenSent,
            code: $codeTest,
          },
        }),
        startTimerActivationCodeFn,
      ],
});

// после успешной привязки карты переккидываем на страницу усапеха (success)
sample({
  clock: bindPaymentToAppFx.done,
  // filter: $isProfileNewLoanPage,
  target: [
    // редиректим на страницу усапеха с инфой о займе и пердшествующей поддтверждению заявки
    goSuccessPageFn,
    sendYMGoalFx.prepend(() => 'REG_CARD_LINKED'),
  ],
});

// ===================== ПЕРЕНАПРАВЛЕНИЯ =====================
sample({
  clock: goNewLoanPageFn,
  target: pushHistoryFn.prepend(() => `/${PROFILE}/${LOAN}/${NEW_LOAN}`),
});

sample({
  clock: debounce({
    source: goSuccessPageFn,
    timeout: 10,
  }),
  target: pushHistoryFn.prepend(() => `/${PROFILE}/${LOAN}/${NEW_LOAN}/${SUCCESS}`),
});

sample({
  clock: debounce({
    source: goFailurePageFn,
    timeout: 10,
  }),
  target: pushHistoryFn.prepend(() => `/${PROFILE}/${LOAN}/${NEW_LOAN}/${FAILURE}`),
});

sample({
  clock: debounce({
    source: goPaymentPageFn,
    timeout: 10,
  }),
  target: pushHistoryFn.prepend(() => `/${PROFILE}/${LOAN}/${NEW_LOAN}/${PAYMENT}`),
});

sample({
  clock: goProcessingPageFn,
  target: pushHistoryFn.prepend(() => `/${PROFILE}/${LOAN}/${NEW_LOAN}/${PROFILE_NEW_LOAN_PATH.PROCESSING}`),
});

sample({
  clock: goAgreementSuccessPageFn,
  target: pushHistoryFn.prepend(() => `/${PROFILE}/${LOAN}/${NEW_LOAN}/${AGREEMENT}`),
});

sample({
  clock: goConfirmPageFn,
  target: pushHistoryFn.prepend(() => `/${PROFILE}/${LOAN}/${NEW_LOAN}/${CONFIRM}`),
});

/*
sample({
  clock: sendApplicationProcessFx.done,
  target: storageRemoveFn.prepend(() => INFO),
});
*/
