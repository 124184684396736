import {
  sample, attach, restore, // split,
} from 'effector';
import { debounce } from 'patronum/debounce';
// import { get, isEmpty } from 'src/lib/lodash';
// import { jumpFocusFieldFn } from 'src/models/Helper/Ref';
import {
  // события
  getKeyboardLayoutFn,
  // onKeyDownFn, digitFn, backSpaceFn, removeValFieldFn,
  // хранилища,
  // эффекты
  // resetFieldFx, onChangeFieldFx, resetErrorFieldFx,
} from './index';

/// ///////////////////////////////////////////////////////////
// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ===================== БЛОК ОПЕРАТОРОВ =================== //
/// ///////////////////////////////////////////////////////////

sample({
  clock: sample({
    clock: debounce({
      source: getKeyboardLayoutFn,
      timeout: 100,
    }),
    filter: ({ value }) => (/[a-z]/gi.test(value)),
  }),
  source: restore(getKeyboardLayoutFn, {}),
  fn: ({ field }) => field,
  target: attach({
    effect: (_, field) => {
      field.addError({
        rules: 'required',
        errorText: 'Пожалуйста, смените раскладку клавиатуры',
      });
    },
  }),
});

// событие нажатия клавиши
/*
split({
  source: sample(restore(onKeyDownFn, {}), onKeyDownFn),
  match: {
    backSpace: ({ event }) => event.code === 'Backspace',
    digit: ({ event }) => [...'0123456789'].includes(event.code.replace('Digit', '')),
  },
  cases: {
    backSpace: [], // backSpaceFn,
    digit: [], // digitFn,
    __: [],
  },
});

// событие на Backspace
sample({
  clock: sample({
    clock: backSpaceFn,
    source: restore(backSpaceFn, {}),
    filter: ({ fields }) => !isEmpty(fields.prev),
  }),
  source: restore(backSpaceFn, {}),
  fn: ({ fields, form }) => ({
    form,
    fields: { current: fields.current, next: fields.prev },
    isValue: !isEmpty(get(form.values, fields.current, '')),
  }),
  target: removeValFieldFn,
});

split({
  source: sample(restore(removeValFieldFn, {}), removeValFieldFn),
  match: {
    true: ({ isValue }) => isValue,
  },
  cases: {
    true: resetFieldFx.prepend(({ fields: { current }, form }) => ({ fieldName: current, form })),
    __: [
      resetFieldFx.prepend(({ fields: { next }, form }) => ({ fieldName: next, form })),
      jumpFocusFieldFn.prepend(({ fields: { next } }) => next),
    ],
  },
});

// событие на число
sample({
  clock: sample({
    // clock: digitFn,
    clock: debounce({
      source: digitFn,
      timeout: 0,
    }),
    source: restore(digitFn, {}),
    filter: ({ fields: { next, current }, form }) => (
      !isEmpty(next) && !isEmpty(get(form.values, current, ''))
    ),
  }),
  fn: ({ event, fields, form }) => ({
    form,
    fields,
    currentValue: get(form.values, fields.current, ''),
    newValue: event.target.value,
  }),
  target: [
    onChangeFieldFx.prepend(
      ({ fields: { current }, form, currentValue }) => ({ fieldName: current, form, value: currentValue }),
    ),
    resetErrorFieldFx.prepend(
      ({ fields: { current }, form }) => ({ fieldName: current, form }),
    ),
    jumpFocusFieldFn.prepend(({ fields: { next } }) => next),
    onChangeFieldFx.prepend(
      ({ fields: { next }, form, newValue }) => ({ fieldName: next, form, value: newValue }),
    ),
    resetErrorFieldFx.prepend(
      ({ fields: { next }, form }) => ({ fieldName: next, form }),
    ),
  ],
});
*/

/* sample({
  clock: sample({
    // clock: backSpaceFn,
    clock: debounce({
      source: backSpaceFn,
      timeout: 0,
    }),
    source: restore(backSpaceFn, ''),
    filter: ({ event, fieldName, currentField }) => {
      console.log('currentField:', currentField);
      console.log('fieldName:', fieldName);
      return event.code === 'Backspace';
    },
  }),
  fn: ({ fieldName }) => fieldName,
  target: jumpFocusFieldFn,
});
 */
