import React from 'react';
import { CalculatorSidebar } from 'src/ui/components';
import { FeedbackForm } from 'src/ui/components/Form/FeedbackForm';

import './Feedback.css';

export const Feedback = React.memo(() => (
  <>
    <main className="main legalPage feedback">
      <section className="document">
        <div className="wrapper">

          <h1 className="title fadeInUp">Обратная связь</h1>
          <h4 className="description fadeInUp">
            Если у вас есть какие-либо вопросы или предложения,
            пожалуйста, заполните и отправьте форму обратной связи!
            {' '}
          </h4>
          <div className="separator" />
          <div className="content">

            <div className="left fadeInLeft">
              <CalculatorSidebar />
            </div>

            <div className="right fadeInUp">
              <div className="topBlock"><img src="/img/other/topbgblock.svg" alt="" /></div>
              <div className="topBlock mobile"><img src="/img/other/topbgblock-mobile.svg" alt="" /></div>

              <div className="block">
                <div className="block-content">
                  <FeedbackForm />
                </div>
              </div>

              <div className="bottomBlock mobile"><img src="/img/other/bottombgblock.svg" alt="" /></div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </>
));
