import React from 'react';
import { useUnit } from 'effector-react';
import {
  Redirect,
  Route, Switch, withRouter,
} from 'react-router-dom';
import { get, isEmpty } from 'src/lib/lodash';
import { PROFILE_LOAN_PATH } from 'src/dict/path';
import {
  $routeItems,
} from 'src/models/Loan';
import { List } from './children/List/List';
import { AddRoutes } from './children/Add/AddRoutes';
import { ToRepayRoutes } from './children/ToRepay/ToRepayRoutes';

const { NEW_LOAN, TO_REPAY, LOANS } = PROFILE_LOAN_PATH;

const pathMap = {
  [LOANS]: List,
  [NEW_LOAN]: AddRoutes,
  [TO_REPAY]: ToRepayRoutes,
};

export const LoanRoutes = withRouter(({ match: { url } }) => {
  const routes = useUnit($routeItems).filter((item) => !isEmpty(item.path));
  const baseUrl = url.slice(-1) === '/' ? url.slice(0, Math.max(0, url.length - 1)) : url;
  return (
    <Switch>
      {routes.map(
        ({ path }) => (
          <Route
            key={`${path}_route`}
            path={`${baseUrl}/${path}`}
            component={get(pathMap, path, '')}
          />
        ),
      )}
      <Redirect exact from={`${baseUrl}`} to={`${baseUrl}/${LOANS}`} />
      {/* <Redirect to={`${baseUrl}`} /> */}
    </Switch>
  );
});
