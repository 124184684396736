import { combine } from 'effector';
import { isEmpty } from 'src/lib/lodash';
import { isCurrentPath } from 'src/lib/url';
import {
  PROFILE_LOAN_PATH, PROFILE_NEW_LOAN_PATH, PROFILE_PATH,
} from 'src/dict/path';
import { CODE_TIMER } from 'src/dict/config';
import { $pathnameUrl } from 'src/models/App';
import {
  loanDomain,
  $isProfileLoanPage,
} from 'src/models/Loan';
import {
  createApplicationsSign,
  getApplicationSign,
  sendStatisticUtmSign,
  sendApplicationProcessSign,
  changeApplicationStatusSign,
} from 'src/api/signatures/Application';
import { timerSign } from 'src/signatures/Timer';

export const applicationDomain = loanDomain.createDomain('Application');

/// ///////////////////////////////////////////////////////////
// ================== СЛОВАРИ / КОНСТАНТЫ ================== //
/// ///////////////////////////////////////////////////////////

const { CARDS } = PROFILE_PATH;
const { NEW_LOAN } = PROFILE_LOAN_PATH;
const {
  PROCESSING, PAYMENT, SUCCESS, FAILURE, AGREEMENT, CONFIRM,
} = PROFILE_NEW_LOAN_PATH;

export const STATUS_LOAN = {
  NEW_LOAN: 'new_loan',
  CORRECTION: 'wait_correction',
  PROCESSING: 'wait_offer',
  RECEIVED: 'offers_received',
  APPROVED: 'loan_approved',
  REJECTED: 'loan_rejected',
};

/*
wait_correction - это когда ядро попросило что-то изменить в заявке.
loan_approved - это заявка одобрена, но еще договор не получен фронтом, все еще ждем offers_recieved
offers_recieved - заявка одобрена, договор получен, можно переходить на следующий шаг
loan_rejected - заявка отклонена
 */

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ СОБЫТИЙ ===================== //
/// ///////////////////////////////////////////////////////////

// перенаправления
export const goNewLoanPageFn = applicationDomain.createEvent();
export const goFailurePageFn = applicationDomain.createEvent();
export const goSuccessPageFn = applicationDomain.createEvent();
export const goPaymentPageFn = applicationDomain.createEvent();
export const goProcessingPageFn = applicationDomain.createEvent();
export const goConfirmPageFn = applicationDomain.createEvent();

export const createApplicationsFn = applicationDomain.createEvent();

export const sendStatisticUtmFn = applicationDomain.createEvent();

export const toggleMakeLoanFn = applicationDomain.createEvent();

export const sendApplicationProcessFn = applicationDomain.createEvent();

export const sendCodeSigningApplicationFn = applicationDomain.createEvent();

export const getApplicationFn = applicationDomain.createEvent();

// final reg
export const startTimerActivationCodeFn = applicationDomain.createEvent();
export const stopTimerActivationCodeFn = applicationDomain.createEvent();
export const timerGetApplicationStatusFn = applicationDomain.createEvent();

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ХРАНИЛИЦ ==================== //
/// ///////////$applicationDetail////////////////////////////////////////////////

// данные из current
export const $applicationDetail = applicationDomain.createStore('');
export const $applicationItem = applicationDomain.createStore('');

export const $applicationId = applicationDomain.createStore('');

export const $applicationStatus = applicationDomain.createStore('');
export const $applicationUpdatedAt = applicationDomain.createStore('');
export const $applicationExpiredAt = applicationDomain.createStore('');

export const $itemsHistoryLoan = applicationDomain.createStore([]);

export const $repaymentDate = applicationDomain.createStore([]);

export const $rateLoan = applicationDomain.createStore(0);

export const $limitLoan = applicationDomain.createStore(0);

export const $amountLoan = applicationDomain.createStore(0);

export const $isOpenMakeLoan = applicationDomain.createStore(false);

export const $codeTest = applicationDomain.createStore('');
// токен проверки
export const $tokenSent = applicationDomain.createStore('');
// оставшееся время до перезагрузки кода
export const $valueTimer = applicationDomain.createStore(CODE_TIMER);

export const $timerActivationCode = applicationDomain.createStore(false);
export const $isRepeatNewLoan = applicationDomain.createStore(false);

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ЭФФЕКТОВ ==================== //
/// ///////////////////////////////////////////////////////////

// стандартный эффект создания заявки
export const createApplicationsFx = applicationDomain.createEffect(createApplicationsSign);
// эффект создания заявки непосредствено после регистрации
export const createApplicationsAfterRegFx = applicationDomain.createEffect(createApplicationsSign);

// получение данных о заявке
export const getApplicationFx = applicationDomain.createEffect(getApplicationSign);
// получение заявки - ее статуса во время процессинга
export const getApplicationAfterChangeStatusFx = applicationDomain.createEffect(getApplicationSign);

// получаяем данные о заявке при нажатии на кнопку "Забрать деньги" чтобы понять привязаны ли карта уже к заявке или нет
export const getApplicationForPaymentFx = applicationDomain.createEffect(getApplicationSign);

export const sendStatisticUtmFx = applicationDomain.createEffect(sendStatisticUtmSign);

export const sendApplicationProcessFx = applicationDomain.createEffect(sendApplicationProcessSign);

export const changeApplicationStatusFx = applicationDomain.createEffect(changeApplicationStatusSign);
export const timerGetApplicationStatusFx = applicationDomain.createEffect(timerSign);

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

export const $isProfileNewLoanPage = combine($isProfileLoanPage, $pathnameUrl, (isProfileLoanPage, path) => (
  isProfileLoanPage && isCurrentPath(path, NEW_LOAN)
));
export const $isPaymentNewLoanPage = combine($isProfileNewLoanPage, $pathnameUrl, (isProfileNewLoanPage, path) => (
  isProfileNewLoanPage && isCurrentPath(path, PAYMENT)
));
export const $isCardsNewLoanPage = combine($isProfileNewLoanPage, $pathnameUrl, (isProfileNewLoanPage, path) => (
  isProfileNewLoanPage && isCurrentPath(path, CARDS)
));
export const $isProcessingPage = combine($isProfileNewLoanPage, $pathnameUrl, (isProfileNewLoanPage, path) => (
  isProfileNewLoanPage && isCurrentPath(path, PROCESSING)
));
export const $isSuccessNewLoanPage = combine($isProfileNewLoanPage, $pathnameUrl, (isProfileNewLoanPage, path) => (
  isProfileNewLoanPage && isCurrentPath(path, SUCCESS)
));
export const $isAgreementNewLoanPage = combine($isProfileNewLoanPage, $pathnameUrl, (isProfileNewLoanPage, path) => (
  isProfileNewLoanPage && isCurrentPath(path, AGREEMENT)
));
export const $isConfirmNewLoanPage = combine($isProfileNewLoanPage, $pathnameUrl, (isProfileNewLoanPage, path) => (
  isProfileNewLoanPage && isCurrentPath(path, CONFIRM)
));
export const $isFailureNewLoanPage = combine($isProfileNewLoanPage, $pathnameUrl, (isProfileNewLoanPage, path) => (
  isProfileNewLoanPage && isCurrentPath(path, FAILURE)
));

export const $isWaitingLoan = $applicationStatus.map(
  (status) => [STATUS_LOAN.APPROVED, STATUS_LOAN.PROCESSING, STATUS_LOAN.RECEIVED].includes(status),
);

export const $isReceivedLoan = $applicationStatus.map(
  (status) => [STATUS_LOAN.RECEIVED].includes(status),
);

export const $isStatusProcessing = $applicationStatus.map((status) => (
  [STATUS_LOAN.PROCESSING, STATUS_LOAN.APPROVED].includes(status)
  // == [STATUS_LOAN.PROCESSING].includes(status)
));

export const $dateExpired = $applicationExpiredAt.map((dateTime) => {
  if (!isEmpty(dateTime)) {
    const date = new Date(dateTime);
    date.setDate(date.getDate() + 1); // добавляем 1 день
    return date;
  }
  return '';
});

export const $isNotExpired = combine(
  $isWaitingLoan, $dateExpired, (isWaitingLoan, dateExpired) => {
    if (!isEmpty(dateExpired)) {
      const currentDatetime = new Date();
      return isWaitingLoan && (currentDatetime.getTime() < dateExpired.getTime());
    }
    return isWaitingLoan;
  },
);

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ЭФФЕКТОВ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

// тут создаются эффекты через attach

/// ///////////////////////////////////////////////////////////
// ====================== ЮНИТЫ ФОРМ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем формы с помощью effector-forms

/// ///////////////////////////////////////////////////////////
//  ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ФОРМ + (ХРАНИЛИЩ, ЭФФЕКТОВ) //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ========================= ФАБРИКИ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем различные фабрики и хранилища с использованием фабрик

/* Статусы заявки
new_loan->wait_offer: Клиент отправил заявку, ожидание оферты
wait_offer->wait_correction: Верификатор ожидает от клиента исправления данных
wait_correction->wait_offer: Клиент исправил данные, отправил верификатору
wait_offer->loan_approved: Заявка одобрена, ожидание оферты
loan_approved->offers_received: Получена оферта
wait_offer->loan_rejected: Заявка отклонена
loan_approved->offers_expired: Срок действия одобренного предложения истек
offers_received->offers_expired: Срок действия одобренного предложения истек
*/
