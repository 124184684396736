import React from 'react';
import { useGate, useUnit } from 'effector-react';
import { useHistory } from 'react-router-dom';

import { Header } from 'src/ui/blocks/Profile/Content/Header/Header';
import { Sidebar } from 'src/ui/blocks/Profile/Sidebar/Sidebar';

import { get } from 'src/lib/lodash';
import {
  RouteGate,
  $webpClass,
} from 'src/models/App';
import { $loaderPage } from 'src/models/Helper/Loader';
import { $openMenuMobile } from 'src/models/Helper/MenuMobile';
import { $classNamesMap } from 'src/models/Profile';
import './Profile.css';

const ProfileLayout = ({ children }) => {
  useGate(RouteGate, {
    pathname: get(window, 'location.pathname', ''),
    pathParams: get(window, 'location.search', ''),
    history: useHistory(),
  });
  const [
    pageClass, openMenuMobile, loader, webpClass,
  ] = useUnit([$classNamesMap, $openMenuMobile, $loaderPage, $webpClass]);
  // profile-loans-noYet.html - У вас еще нет займов => profilePage-loans
  // profile-loans-processing.html - Мы обрабатываем вашу заявку => profilePage-loans
  // profile-loans-successfull.html - Займ одобрен для выдачи => profilePage-successfullLoan successfullLoanPage
  // keks-profile/profile-loans-failure.html - Мы не можем выдать вам займ, => profilePage-failure failurePage
  // keks-profile/profile-loans-way.html - Выбор способа получения. => profilePage-wayLoans
  // keks-profile/profile-loans-agreement.html - Подпишите договор  => profilePage-agreement agreementPage
  // keks-profile/profile-loans-card.html - На какую карту выдаем займ? => profilePage-cardsLoans

  return (
    <>
      <div className={`${loader} ${webpClass}`}>
        <div className="loaded-box" />
        <div className={`profilePage ${pageClass} ${openMenuMobile}`}>
          <Sidebar />

          <div className="content">

            <Header />

            <div className="profilePage-main">
              <div className="wrapper">
                { children }
              </div>
            </div>
          </div>

        </div>

      </div>
    </>
  );
};

export { ProfileLayout };
