import { axios } from 'src/api/axios';

export const createApplicationsSign = (data) => axios.post('/applications', data);
/* response
data: {
  amount: 24000
  client_id: "edf62d77-4589-48bf-b68d-8247be3365cd"
  id: "05a0005c-c19c-428f-b787-03e5c4321ba9"
  paymentable_id: null
  paymentable_type: null
  payout_methods: [{
      value: "bank_card"
  }]
  value: "bank_card"
  project_id: "7ea95562-3395-4a62-bc65-cb0e76e5cef4"
  purpose_id: "3a180e8b-f81a-4e8f-8e54-3ff60b136b36"
  status: "new_loan"
}
 */

export const getApplicationSign = (applicationId) => axios.get(`/applications/${applicationId}`);
/*
data: {
  amount: 10000
  client_id: "433fe926-cc86-4044-8345-e89da08f15d0"
  id: "0a603fcb-feae-4d9a-849c-6ad27e3adb87"
  paymentable_id: null
  paymentable_type: null
  payout_methods: [{value: "bank_card"}]
    0: {value: "bank_card"}
  project_id: "7ea95562-3395-4a62-bc65-cb0e76e5cef4"
  purpose_id: null
  status: "new_loan"
  term: 16
}
 */
export const sendStatisticUtmSign = ({ applicationId, data }) => axios.post(
  `/applications/${applicationId}/utm`, data,
);

export const sendApplicationProcessSign = (applicationId) => axios.post(
  `/applications/${applicationId}/confirm`,
);

export const changeApplicationStatusSign = ({ applicationId, data }) => axios.post(
  `/applications/${applicationId}/fake-change-status`, data,
);

// привязываем карту к заявке
/* export const bindCardBankWithApplicationSign = ({ applicationId, data }) => axios.patch(
  `/applications/${applicationId}/`, data,
); */

/* response
data: [{id: "88b85444-b0bc-4e24-a5ee-c2b3fa181793", primary_id: null, parent_id: null,…}]
0: {§
(amount_body + amount_percent) - сколько венем - рассчитанная за 15 дней
но если чел вернет на 5-ый день вернет меньше - видно из дебт

  agreed_at: null
  amount_body: 10000 - сколько взял чел в долг
  amount_percent: 0 - суммы переплаты
  application_id: "6c04ecd8-cbfe-48fd-8cab-7534151c9f39"
  client_id: "f309d7f2-17ba-45c0-81e2-ccc3d037b36a"
  date_end: "2022-04-21"
  date_start: "2022-04-06"
  debt: { - задолжность
    amount: 10000 - общая сумма которая осталось к выплати
    body: 10000 - остаток тела
    overdue: 0 - остаток просроченных процентов
    percent: 0 - остатко процентов
  }
  id: "88b85444-b0bc-4e24-a5ee-c2b3fa181793"
  number: "422443118"
  parent_id: null
  primary_id: null
  product_id: "312909e9-76a6-4778-b173-5b3f072638a5"
  rate: 0
  rate_psk: 0
  rate_year: 0
  status: "waiting_agree"
  term: 15
  type: "primary"
}
 */
