import React from 'react';
import { isEmpty } from 'src/lib/lodash';
import { getDateReverse } from 'src/lib/date';
import { USER_FORM } from 'src/dict/fields';

export const View = React.memo(({ data, type }) => {
  const {
    region, area, city, settlement, street, house, block, flat, registered_at: regAt,
  } = data;
  const regionArea = !isEmpty(region) ? region : area;
  const citySettlement = !isEmpty(city) ? city : settlement;
  const registeredAt = !isEmpty(regAt) ? getDateReverse(regAt) : '-';
  let build = house;
  if (!isEmpty(block)) {
    build = `${house}к${block}`;
  }

  if (!isEmpty(flat)) {
    build = `${build}, ${flat}`;
  }
  if (isEmpty(data)) return <div>Загрузка..</div>;
  return (
    <>
      <div className="block-items">
        <div className="block-item">
          <div className="block-item-info">
            <p className="block-label">Область</p>
            <p className="block-text">{regionArea}</p>
          </div>
          <div className="default-input">
            <input type="text" tabIndex="0" defaultValue={regionArea} />
            <label>Область</label>
          </div>
        </div>
        <div className="block-item">
          <div className="block-item-info">
            <p className="block-label">Населенный пункт</p>
            <p className="block-text">{citySettlement}</p>
          </div>
          <div className="default-input">
            <input type="text" tabIndex="0" defaultValue={citySettlement} />
            <label>Населенный пункт</label>
          </div>
        </div>
      </div>

      <div className="block-items">
        <div className="block-item">
          <div className="block-item-info">
            <p className="block-label">Улица</p>
            <p className="block-text">{street}</p>
          </div>
          <div className="default-input">
            <input type="text" tabIndex="0" defaultValue={street} />
            <label>Улица</label>
          </div>
        </div>
        <div className="block-item">
          <div className="block-item-info">
            <p className="block-label">Дом, квартира</p>
            <p className="block-text">{build}</p>
          </div>
          <div className="default-input">
            <input type="text" tabIndex="0" defaultValue={build} />
            <label>Дом, квартира</label>
          </div>
        </div>
      </div>

      {type === USER_FORM.ADDRESS_REG && (
      <div className="block-items">
        <div className="block-item">
          <div className="block-item-info">
            <p className="block-label">Дата регистрации (в паспорте)</p>
            <p className="block-text">{registeredAt}</p>
          </div>

          <div className="default-input">
            <input type="text" tabIndex="0" defaultValue={registeredAt} />
            <label>Дата регистрации (в паспорте)</label>
          </div>
        </div>
      </div>
      )}
      {/* <a data-fancybox href="#saveСhanges" className="default-btn block-button-save">Сменить</a> */}
    </>
  );
});
