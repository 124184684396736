import { get, isEmpty } from 'src/lib/lodash';
import { isDevelopment } from 'src/dict/config';

export const getErrorFromDict = (textError = '') => ({ dict, data }) => {
  const key = get(data, 'error.response.data.message', '');
  if (!isEmpty(dict[key])) {
    return dict[key];
  }
  const errors = get(data, 'error.response.data.errors', []);

  // eslint-disable-next-line consistent-return
  const arrErrors = Object.values(errors).reduce((arr, arrVal) => {
    if (!isEmpty(arrVal[0]) && !isEmpty(dict[arrVal[0]])) {
      arr.push(dict[arrVal[0]]);
    }
    return arr;
  }, []);

  if (!isEmpty(arrErrors)) {
    return arrErrors[0];
  }
  return textError;
};

if (isDevelopment) {
  // eslint-disable-next-line no-console
  console.error = function (message) {
    const warn = [
      'Warning: Each child in a list should have a unique "key"',
      'Cannot update during an existing state transition (such as within `render`)',
      'unit call from pure function is deprecated',
      'Warning: unstable_flushDiscreteUpdates: Cannot flush updates',
      "Warning: Can't perform a React state update on an unmounted component",
      // eslint-disable-next-line max-len
      'Warning: Cannot update a component (`%s`) while rendering a different component (`%s`)',
    ];
    if (typeof message === 'string') {
      const check = warn.some((item) => message.includes(item));
      if (!check) {
        throw new Error(message);
      }
    } else {
      throw new TypeError(message);
    }
    // console.log('message:', message, typeof message);
  };
}
