export const PAYMENT_CONTEXT = {
  ISSUANCE_CREDIT: 'issuance_credit',
  TO_REPAY: 'to_repay',
};

export const PAYMENT = {
  VISA: 'Visa',
  MASTERCARD: 'MasterCard',
  MIR: 'Mir',
  MAESTRO: 'Maestro',
  UNIONPAY: 'China UnionPay',
  DISCOVER: 'Discover',
  JSB: 'JCB International',
  DINERS_CLUB: 'Diners Club',
  AMERICAN_EXPRESS: 'American Express',
  OTHER: '',
};
/*
2-Мир
3- American Express, JCB International, Diners Club
____30,36,38-Diners Club
____31,35-JCB International
____34,37-American Express
4- VISA
5- MasterCard, Maestro
____50,56,57,58-Maestro
____51,52,53,54,55-MasterCard
6- Maestro, China UnionPay, Discover
____60-Discover
____62 - China UnionPay
____63, 67 - Maestro
7-УЭК
 */
