import React from 'react';
import { useUnit } from 'effector-react';
import { get, isEmpty } from 'src/lib/lodash';
import { getDateReverse } from 'src/lib/date';
import { getFormatMoney } from 'src/lib/money';
import {
  closeMenuMobileFn, openMenuMobileFn,
  $openMenuMobile,
} from 'src/models/Helper/MenuMobile';
import {
  intentionToRepayFn,
  $agreementActiveItem,
} from 'src/models/Loan/Agreement';
import { Sidebar } from 'src/ui/blocks/Profile/Sidebar/Sidebar';

const Loan = () => {
  const [
    openMenuMobile, agreementActiveItem,
  ] = useUnit([$openMenuMobile, $agreementActiveItem]);

  const isOpen = !isEmpty(openMenuMobile);
  const handlerOpenMenu = isOpen ? closeMenuMobileFn : openMenuMobileFn;

  const id = get(agreementActiveItem, 'id', '');
  const amount = get(agreementActiveItem, 'debt.amount', '');
  // const percent = get(agreementActiveItem, 'amount_percent', '');
  const date = get(agreementActiveItem, 'date_end', '');
  const dateEnd = !isEmpty(date) ? getDateReverse(date) : date;

  const text = 'Сумма к погашению';
  return (
    <>
      <div className="profileTop makePayment">

        <div className="profileTop-top-mobile">
          <a href="/" className="profileTop-logo">
            <img src="/img/main/logo.png" alt="" />
          </a>

          <div className="profileTop-button">
            <button
              type="button"
              className="default-btn button-open"
              onClick={intentionToRepayFn.prepend(() => id)}
            >
              Внести платеж
            </button>
          </div>

          <div className="profileTop-menuButton" onClick={handlerOpenMenu}>
            <img className="open" src="/img/icons/menu.svg" alt="" />
            <img className="close" src="/img/icons/menuclose.svg" alt="" />
          </div>
        </div>

        <div className="profileTop-top">
          <div className="wrapper">

            <div className="profileTop-content profileTop-content-makePayment">
              <div className="profileTop-items">
                <div className="profileTop-item profileTop-item-limit">

                  <div className="profileTop-item-icon">
                    <img src="/img/profile/coinIcon.png" alt="" />
                  </div>

                  <div className="profileTop-item-info">
                    <p className="profileTop-item-description">{text}</p>
                    <p className="profileTop-item-title">
                      {getFormatMoney(amount)}
                      {' '}
                      ₽
                    </p>
                  </div>

                </div>

                {/*  <<div className="profileTop-item profileTop-item-loan">

                 div className="profileTop-item-icon">
                    <img src="/img/profile/percentIcon.png" alt="" />
                  </div>

                  <div className="profileTop-item-info">
                     <p className="profileTop-item-description">Ставка</p>
                    <p className="profileTop-item-title">
                      {percent}
                      {' '}
                      %
                    </p>
                  </div>

                </div> */}

                <div className="profileTop-item profileTop-item-date">

                  <div className="profileTop-item-icon">
                    <img src="/img/profile/dateIcon.png" alt="" />
                  </div>

                  <div className="profileTop-item-info">
                    <p className="profileTop-item-description">Дата погашения</p>
                    <p className="profileTop-item-title">{dateEnd}</p>
                  </div>

                </div>
              </div>

              <div className="profileTop-button">
                <button
                  type="button"
                  className="default-btn button-open"
                  onClick={intentionToRepayFn.prepend(() => id)}
                  /* onClick={openModalFn.prepend(() => ({
                    className: 'modal-changeAmountAndTerm modal-repayLoan fancybox-content',
                    content: CalculatorToRepay,
                    // указываем свое событие на закрытие модального окна
                    closeCallback: closeChangeCalcFn,
                  }))} */
                >
                  Внести платеж
                </button>
              </div>
            </div>
          </div>
        </div>

        <Sidebar />
      </div>
    </>
  );
};

export { Loan };
