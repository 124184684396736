import React from 'react';
import { useUnit } from 'effector-react';
import { get } from 'src/lib/lodash';
import {
  goLoanRepaymentPageFn,
  $isPaymentSuccessDataPage, $amountToRepay,
  loanRepaymentDomainForm,
} from 'src/models/Repayment';
import { ButtonWithLoader as Button } from 'src/ui/components/Form';

export const Result = React.memo(() => {
  const [
    isPaymentSuccessDataPage, formData, amountToRepay,
  ] = useUnit([$isPaymentSuccessDataPage, loanRepaymentDomainForm.$values, $amountToRepay]);
  const number = get(formData, 'number', '');

  const description = isPaymentSuccessDataPage
    // eslint-disable-next-line max-len
    ? `Оплата на сумму ${amountToRepay} руб. по договору ${number} успешно произведена! Спасибо!`
    : `Ошибка при оплате по договору ${number}!`;

  const textButton = isPaymentSuccessDataPage ? 'Хорошо' : 'Попробовать еще раз';

  return (
    <>
      <h1 className="title">{' '}</h1>
      <div className="sub-title">{description}</div>
      <div className="buttons">
        <div className="button">
          <Button
            onClick={goLoanRepaymentPageFn}
            type="button"
            className="nextStep"
          >
            {textButton}
          </Button>
        </div>

      </div>
    </>
  );
});
