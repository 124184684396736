import React from 'react';
import { useUnit } from 'effector-react';
import { get } from 'src/lib/lodash';
import {
  toggleDataEditFn,
  $dataIsEdit,
  addressRegForm, addressActualForm,
} from 'src/models/User/Personal';
import { $itemAddressData } from 'src/models/User';
import { USER_FORM } from 'src/dict/fields';
import { View } from './children/View';
import { Form } from './children/Form';

export const Address = React.memo(({ type }) => {
  // данные для формы
  const itemAddressData = useUnit($itemAddressData);
  const data = get(itemAddressData, type, {});
  const form = type === USER_FORM.ADDRESS_REG ? addressRegForm : addressActualForm;
  const title = type === USER_FORM.ADDRESS_REG ? 'Адрес регистрации' : 'Адрес фактического проживания';

  // режим
  const isEdit = get(useUnit($dataIsEdit), type, false);
  const classEdit = isEdit ? 'edit' : '';

  const fiasId = get(data, 'fias', '');
  return (
    <>
      <div className={`block ${classEdit}`}>
        <div className="edit">
          {!isEdit
            ? (
              <div
                className="edit-info"
                onClick={toggleDataEditFn.prepend(
                  () => ({ form: type, value: true }),
                )}
              >
                <span className="text">Редактировать</span>
                <span className="icon">
                  <img src="/img/profile/edit.svg" alt="" />
                </span>
              </div>
            )
            : (
              <div
                className="edit-cancel"
                onClick={toggleDataEditFn.prepend(
                  () => ({ form: type, value: false }),
                )}
              >
                Отменить
              </div>
            )}
        </div>

        <h2 className="block-title">{title}</h2>
        {isEdit
          ? (
            <Form
              fiasId={fiasId}
              type={type}
              form={form}
              data={data}
            />
          )
          : (
            <View
              data={data}
              type={type}
            />
          )}
      </div>
    </>
  );
});
