import React, { useEffect } from 'react';
import { useForm } from 'effector-forms';
import { getPropsField, onSubmit } from 'src/lib/form';
import { maskPhone } from 'src/lib/maskPlaceholder';
import { REG_STEP5_PHONE } from 'src/dict/fields';
import {
  closeChangePhonePageFn, openChangePhonePageFn,
  stepPhoneForm,
} from 'src/models/Registration/Verification';
import { Input, Button } from 'src/ui/components/Form';

const { PHONE } = REG_STEP5_PHONE;

export const Form = React.memo(({ closeCallback }) => {
  const {
    submit, eachValid, ...restProps
  } = useForm(stepPhoneForm);

  useEffect(() => {
    openChangePhonePageFn();
    return () => {
      closeChangePhonePageFn();
    };
  }, []);

  return (
    <div className="modal-main">
      <button
        type="button"
        className="close-modal__btn close-modal"
        onClick={closeCallback}
      >
        <img src="/img/modal/close.svg" alt="" />
      </button>
      <div className="modal-wrapper">
        <h2 className="modal-title">Изменить мобильный телефон</h2>

        <br />
        <form onSubmit={onSubmit(submit)}>

          <div className="block-item">
            <Input
              label="Телефон"
              type="text"
              inputMode="tel"
              name={PHONE}
              {...maskPhone}
              {...getPropsField({
                fieldName: PHONE, props: restProps,
              })}
              required
            />
          </div>

          <br />
          <div className="modal-button">
            <Button type="submit">
              Сохранить
            </Button>
            <br />
            <Button
              className="border newCodeBtn"
              onClick={closeCallback}
            >
              Отмена
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
});
