import React from 'react';

export const Reports = React.memo(() => (
  <>
    <h1 className="title">Отчеты</h1>
    <div className="report block">
      <div className="block-info">
        <div className="block-info-top">
          <div className="block-title">Описание услуги по займу, общей суммой 2 500 ₽</div>
          <div className="block-date">25 Июн 2020 - 21:40</div>
        </div>
        <div className="block-info-bottom">
          <div className="block-sum">2 500 ₽</div>
          <div className="block-buttons">
            <div className="block-cancel">
              <a href="#" className="cancel">Отменить подписку</a>
            </div>
            <div className="block-button">
              <a href="#" className="default-btn">Персональные предложения</a>
            </div>
          </div>
        </div>
      </div>
      <div className="block-main active">
        <div className="block-main-top">
          <h3 className="block-subtitle">Отчет по автоматической подачи заявки на займ</h3>
          <div className="block-main-arrow">
            <div className="icon"><img src="/img/icons/selectarrow.svg" alt="" /></div>
          </div>
        </div>
        <div className="block-items">
          <div className="block-item">
            <div className="block-item-logo">
              <img src="/img/profile/otpbank.png" alt="" />
            </div>
            <div className="block-item-title">Отп Банк</div>
            <div className="block-item-text">
              Раньше весь процесс получения кредита занимал 10 дней.
              Теперь мы можем авторизовать ссуду примерно за 30 минут.
            </div>
            <div className="block-item-date block-date"><span>25 Июн 2020 - 21:40</span></div>
          </div>
          <div className="block-line" />
          <div className="block-item">
            <div className="block-item-logo">
              <img src="/img/profile/otpbank.png" alt="" />
            </div>
            <div className="block-item-title">Отп Банк</div>
            <div className="block-item-text">
              Раньше весь процесс получения кредита занимал 10 дней.
              Теперь мы можем авторизовать ссуду примерно за 30 минут.
            </div>
            <div className="block-item-date block-date"><span>25 Июн 2020 - 21:40</span></div>
          </div>
          <div className="block-line" />
          <div className="block-item">
            <div className="block-item-logo">
              <img src="/img/profile/otpbank.png" alt="" />
            </div>
            <div className="block-item-title">Отп Банк</div>
            <div className="block-item-text">
              Раньше весь процесс получения кредита занимал 10 дней.
              Теперь мы можем авторизовать ссуду примерно за 30 минут.
            </div>
            <div className="block-item-date block-date"><span>25 Июн 2020 - 21:40</span></div>
          </div>
        </div>
      </div>
    </div>
    <div className="report block">
      <div className="block-info">
        <div className="block-info-top">
          <div className="block-title">Описание услуги по займу</div>
          <div className="block-date">25 Июн 2020 - 21:40</div>
        </div>
        <div className="block-info-bottom">
          <div className="block-sum">2 500 ₽</div>
          <div className="block-buttons">
            <div className="block-cancel">
              <a href="#" className="cancel">Отменить подписку</a>
            </div>
            <div className="block-button">
              <a href="#" className="default-btn">Персональные предложения</a>
            </div>
          </div>
        </div>
      </div>
      <div className="block-main active">
        <div className="block-main-top">
          <h3 className="block-subtitle">Отчет по автоматической подачи заявки на займ</h3>
          <div className="block-main-arrow">
            <div className="icon"><img src="/img/icons/selectarrow.svg" alt="" /></div>
          </div>
        </div>
        <div className="block-items">
          <div className="block-item">
            <div className="block-item-logo">
              <img src="/img/profile/otpbank.png" alt="" />
            </div>
            <div className="block-item-title">Отп Банк</div>
            <div className="block-item-text">
              Раньше весь процесс получения кредита занимал 10 дней.
              Теперь мы можем авторизовать ссуду примерно за 30 минут.
            </div>
            <div className="block-item-date block-date"><span>25 Июн 2020 - 21:40</span></div>
          </div>
          <div className="block-line" />
          <div className="block-item">
            <div className="block-item-logo">
              <img src="/img/profile/otpbank.png" alt="" />
            </div>
            <div className="block-item-title">Отп Банк</div>
            <div className="block-item-text">
              Раньше весь процесс получения кредита занимал 10 дней.
              Теперь мы можем авторизовать ссуду примерно за 30 минут.
            </div>
            <div className="block-item-date block-date"><span>25 Июн 2020 - 21:40</span></div>
          </div>
          <div className="block-line" />
          <div className="block-item">
            <div className="block-item-logo">
              <img src="/img/profile/otpbank.png" alt="" />
            </div>
            <div className="block-item-title">Отп Банк</div>
            <div className="block-item-text">
              Раньше весь процесс получения кредита занимал 10 дней.
              Теперь мы можем авторизовать ссуду примерно за 30 минут.
            </div>
            <div className="block-item-date block-date"><span>25 Июн 2020 - 21:40</span></div>
          </div>
        </div>
      </div>
    </div>
  </>
));
