import './App/init';

import './Helper/History/init';
import './Helper/Keyboard/init';
import './Helper/Loader/init';
import './Helper/MenuMobile/init';
import './Helper/Notification/init';
import './Helper/Ref/init';
import './Helper/Storage/init';
import './Helper/Cookie/init';

import './Auth/init';
import './Esia/init';
import './Repayment/init';
import './Calculator/init';
import './Documents/init';
import './Dictionaries/init';
import './Event/init';
import './Feedback/init';
import './Payment/init';

import './Loan/Application/init';
import './Loan/Application/Success/init';
import './Loan/Application/Failure/init';
import './Loan/Agreement/init';

import './Registration/init';

import './Registration/Personal/init';
import './Registration/Passport/init';
import './Registration/Address/init';
import './Registration/Experience/init';
import './Registration/Verification/init';

import './Recovery/init';
import './Recovery/Confirm/init';

import './Statistic/init';
import './User/init';
import './User/Personal/init';
import './Profile/init';

// import './components/Modal';
// import './components/Popover';
// import './components/Select';

import { isDevelopment } from 'src/dict/config';
// Исключаем Faker из бандла
const initFaker = () => {
  import('./Registration/initFaker')
    .then(() => {})
    .catch(() => {});
};
if (isDevelopment) {
  initFaker();
}
