import React, { useState } from 'react';
import { isEmpty } from 'src/lib/lodash';

const onLoadDefault = (onLoad) => () => onLoad(true);

export const Iframe = React.memo(({
  classNameNotContent, id, link, title,
}) => {
  const [isLoad, onLoad] = useState(false);
  return (
    <>
      {(!isLoad || isEmpty(link)) && <div className={classNameNotContent}>Loading..</div>}
      <iframe
        onLoad={onLoadDefault(onLoad)}
        id={id}
        src={link}
        title={title}
      />
    </>
  );
});

Iframe.defaultProps = {
  classNameNotContent: {},
  id: 'iframeName',
  title: 'titleName',
  link: '',
};
