import { sample, combine } from 'effector';
import { isCurrentPath } from 'src/lib/url';
import {
  PAGES_PATH, PROFILE_PATH,
} from 'src/dict/path';
import {
  appDomain,
  $pathnameUrl, settingsDomain,
} from 'src/models/App';
import { $userId } from 'src/models/User';

export const profileDomain = appDomain.createDomain('Profile');

/// ///////////////////////////////////////////////////////////
// ================== СЛОВАРИ / КОНСТАНТЫ ================== //
/// ///////////////////////////////////////////////////////////

const { PROFILE } = PAGES_PATH;
const {
  PERSONAL, LOAN, SECURITY, CARDS, SERVICES, EVENTS, REPORTS,
  FEEDBACK, PARTNERS,
} = PROFILE_PATH;

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ СОБЫТИЙ ===================== //
/// ///////////////////////////////////////////////////////////

export const goCardsPageFn = profileDomain.createEvent();
export const toProfileFn = profileDomain.createEvent();
export const checkVerifiedFn = profileDomain.createEvent();

export const resetPathToBackFn = profileDomain.createEvent();

export const declineLandingUrlFn = profileDomain.createEvent();

export const infoRefusalFn = profileDomain.createEvent();
export const logoutForProfileFn = profileDomain.createEvent();

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ХРАНИЛИЦ ==================== //
/// ///////////////////////////////////////////////////////////

export const $pathToBack = settingsDomain.createStore('');

export const $classNamesMap = settingsDomain.createStore('');

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ЭФФЕКТОВ ==================== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

// Проверка на URL ЛК
export const $isProfilePage = $pathnameUrl.map((path) => isCurrentPath(path, PROFILE));

// Страница персональной информации
export const $isProfilePersonalPage = combine($isProfilePage, $pathnameUrl, (isProfilePage, path) => (
  isProfilePage && isCurrentPath(path, PERSONAL)
));

export const $isProfileCardsPage = combine($isProfilePage, $pathnameUrl, (isProfilePage, path) => (
  path === `/${PROFILE}/${CARDS}`
));

// Страница обратной связи
export const $isProfileFeedbackPage = combine($isProfilePage, $pathnameUrl, (isProfilePage, path) => (
  isProfilePage && isCurrentPath(path, FEEDBACK)
));

// пока не используются
export const $isProfileSecurityPage = $pathnameUrl.map((path) => isCurrentPath(path, SECURITY));
export const $isProfileServicesPage = $pathnameUrl.map((path) => isCurrentPath(path, SERVICES));
export const $isProfileEventsPage = $pathnameUrl.map((path) => isCurrentPath(path, EVENTS));
export const $isProfileReportsPage = $pathnameUrl.map((path) => isCurrentPath(path, REPORTS));
export const $isProfilePartnersPage = $pathnameUrl.map((path) => isCurrentPath(path, PARTNERS));

export const $routeItems = combine(
  $userId, () => [
    {
      label: 'Мои данные',
      path: PERSONAL,
      active: true,
      visible: true,
      props: {
        icon1: 'icon1.svg',
        icon2: 'icon1-black.svg',
      },
    },
    {
      label: 'Мои займы',
      path: LOAN,
      active: true,
      visible: true,
      props: {
        icon1: 'icon2.svg',
        icon2: 'icon2-black.svg',
      },
    },
    {
      label: 'Безопасность',
      path: SECURITY,
      active: false,
      visible: true,
      props: {
        icon1: 'icon3.svg',
        icon2: 'icon3-black.svg',
      },
    },
    {
      label: 'Мои карты',
      path: CARDS,
      active: true,
      visible: true,
      props: {
        icon1: 'icon4.svg',
        icon2: 'icon4-black.svg',
      },
    },
    {
      label: 'Платные услуги',
      path: SERVICES,
      active: true,
      props: {
        icon1: 'icon5.svg',
        icon2: 'icon5-black.svg',
      },
    },
    {
      label: 'События',
      path: EVENTS,
      active: false,
      visible: true,
      props: {
        icon1: 'icon6.svg',
        icon2: 'icon6-black.svg',
      },
    },
    {
      label: 'Отчеты',
      path: REPORTS,
      active: false,
      visible: true,
      props: {
        icon1: 'icon7.svg',
        icon2: 'icon7-black.svg',
      },
    },
    {
      label: 'Обратная связь',
      path: FEEDBACK,
      active: true,
      visible: true,
      props: {
        icon1: 'icon8.svg',
        icon2: 'icon8-black.svg',
      },
    },
    {
      label: 'Предложения партнеров',
      path: PARTNERS,
      active: false,
      visible: true,
      props: {
        icon1: 'icon9.svg',
        icon2: 'icon9-black.svg',
        nameClass: 'nocenter',
      },
    },
    {
      label: 'Исправление кредитной истории',
      path: '',
      active: false,
      visible: true,
      props: {
        icon1: 'icon10.svg',
        icon2: '',
        nameClass: 'soon nocenter',
      },
    },
  ].filter(({ active }) => active),
);

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ЭФФЕКТОВ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

// тут создаются эффекты через attach

/// ///////////////////////////////////////////////////////////
// ====================== ЮНИТЫ ФОРМ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем формы с помощью effector-forms

/// ///////////////////////////////////////////////////////////
//  ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ФОРМ + (ХРАНИЛИЩ, ЭФФЕКТОВ) //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ========================= ФАБРИКИ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем различные фабрики и хранилища с использованием фабрик

export const profileClassOperator = ({ isPage: $isPage, pageClass }) => {
  sample({
    clock: $pathnameUrl,
    source: $isPage,
    // eslint-disable-next-line no-shadow
    filter: (isPage) => isPage,
    // eslint-disable-next-line no-shadow
    fn: () => pageClass,
    target: $classNamesMap,
  });
};
