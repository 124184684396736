import { axios } from 'src/api/axios';

export const changePasswordUserSign = (data) => axios.post('/clients/current/change-password', data);

export const updateAdditionalInfoUserSign = (data) => axios.patch('/clients/current/info', data);

export const updateAdditionalProfileUserSign = (data) => axios.patch('/clients/current/profile', data);

export const updateContactsUserSign = (data) => axios.post('/clients/current/contacts', data);

export const getWorksUserSign = () => axios.get('/clients/current/works');

export const addWorksUserSign = (data) => axios.post('/clients/current/works', data);

export const addPassportDataSign = (data) => axios.post('/clients/current/identities', data);

export const getItemsIdentitiesDataSign = () => axios.get('/clients/current/identities');
/* data [{
    birth_place: "dwdqw"
    id: "06381221-9d4b-4518-bad6-7322480b6666"
    issued_at: "2021-12-11"
    issued_by: "МП № 111 ОУФМС РОССИИ ПО САНКТ-ПЕТЕРБУРГУ И ЛЕНИНГРАДСКОЙ ОБЛ. В КИРИШСКОМ РАЙОНЕ"
    number: "111211"
    series: "1111"
    subdivision: "470-027"
    type: "passport"
}] */

export const getItemIdentitiesDataSign = (identityId) => axios.get(
  `/clients/current/identities/${identityId}`,
);
/* data {
    birth_place: "dwdqw"
    id: "06381221-9d4b-4518-bad6-7322480b6666"
    issued_at: "2021-12-11"
    issued_by: "МП № 111 ОУФМС РОССИИ ПО САНКТ-ПЕТЕРБУРГУ И ЛЕНИНГРАДСКОЙ ОБЛ. В КИРИШСКОМ РАЙОНЕ"
    number: "111211"
    series: "1111"
    subdivision: "470-027"
    type: "passport"
} */

export const addAddressDataSign = (data) => axios.post('/clients/current/addresses', data);

export const getAddressDataSign = (addressId) => axios.get(`/clients/current/addresses/${addressId}`);
/* data: {
      area: null
      block: "3"
      building: null
      city: "г Санкт-Петербург"
      fias: "8508fcf7-8af5-45b7-8c9b-adabc3e1cfcd"
      flat: "11"
      house: "57"
      id: "1e2243e2-817d-4293-85c1-343474b344c9"
      kladr: "78000000000018200"
      region: "г Санкт-Петербург"
      registered_at: null
      settlement: null
      street: "пр-кт Большевиков"
      type: "registration"
      zip: "193315"
} */

export const getCurrentUserSign = () => axios.get('/clients/current');
/* getCurrentUserSign
data: {
  id: "edf62d77-4589-48bf-b68d-8247be3365cd"
  is_password_specified: true
  is_repeated: false
  is_verified: true
  phone: "79791110099"
  profile: {
    birth_date: "1989-11-11"
    client_id: "edf62d77-4589-48bf-b68d-8247be3365cd"
    education: {id: "1c53aa59-b8fa-4dcc-8c67-21454511ea77", name: "Неполное среднее"}
    id: "1c53aa59-b8fa-4dcc-8c67-21454511ea77"
    name: "Неполное среднее"
    employment: {
      id: "d50484d1-17d0-4beb-9318-5718285da22a"
      name: "Постоянное трудоустройство"
      first_name: "Фтестодин"
      id: "14ca7d40-bfce-40ef-8f04-8ab966521812"
      last_name: "Фтестодин"
      marital_status: {
        id: "ae9d80f1-7ed7-4a9a-84cd-aa5ca040d3e9"
        name: "Замужем / Женат"
      }
      middle_name: "Фтестодин"
    }
  }
*/
