import React from 'react';
import InputMask from 'react-input-mask';
import { get, isEmpty } from 'src/lib/lodash';

const handlerChange = ({ onChange, jumpField, maskPattern }) => (e) => {
  onChange(e);
  jumpField(e, maskPattern);
};

export const Input = React.forwardRef(({
  value, onChange, onBlur, // приходят из getPropsField - формы эффектора
  placeholder, name, label, type, tabIndex, typeInput,
  mask, maskPlaceholder, maskPattern, jumpField,
  required, errorText, hasError, disabled, isTextError, ...restProps
}, ref) => {
  const hasFocused = name === get(document, 'activeElement.id', false);
  const fieldLabel = `${label}${(required ? '*' : '')}`;

  const nameClasses = [];
  if (required && (hasError || (!isEmpty(`${value}`.trim()) && !hasFocused))) {
    nameClasses.push(hasError ? 'invalid' : ' valid');
  }

  const isDate = typeInput === 'date';
  if (isDate) {
    nameClasses.push('datepickerBlock');
  }

  return (
    <>
      <div className={`default-input ${nameClasses.join(' ')}`}>
        {!isEmpty(mask)
          ? (
            <InputMask
              {...restProps}
              {...(!isEmpty(mask) ? { mask } : {})}
              {
                ...(!isEmpty(maskPlaceholder) || maskPlaceholder === null
                  ? { maskPlaceholder } : {})}
              tabIndex={tabIndex}
              type={type}
              placeholder={placeholder}
              required={required}
              value={(isEmpty(value) ? '' : value)}
              onChange={handlerChange({ onChange, jumpField, maskPattern })}
              onBlur={onBlur}
              disabled={disabled}
              id={name}
              ref={ref}
            />
          )
          : (
            <input
              {...restProps}
              tabIndex={tabIndex}
              type={type}
              placeholder={placeholder}
              required={required}
              value={(isEmpty(value) ? '' : value)}
              onChange={onChange}
              onBlur={onBlur}
              disabled={disabled}
              id={name}
              ref={ref}
            />
          )}
        <label>{fieldLabel}</label>

        {isDate && (
        <div className="icon">
          <img src="/img/icons/calendar.svg" alt="" />
        </div>
        )}
      </div>
      {required
      && isTextError
      && hasError
      && !isEmpty(errorText)
      && <p className="invalid-text">{errorText}</p>}
    </>
  );
});

Input.defaultProps = {
  tabIndex: 0,
  type: 'text',
  name: '',
  label: '',
  typeInput: '',
  placeholder: ' ',
  value: '',
  hasError: false,
  errorText: '',
  onChange: () => '',
  onBlur: () => '',
  jumpField: () => '',
  required: false,
  disabled: false,
  isTextError: true,
  mask: '',
  maskPlaceholder: '',
  maskPattern: '',
};
