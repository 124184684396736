import React from 'react';
import { useUnit } from 'effector-react';
import { isEmpty } from 'src/lib/lodash';
import { Sidebar } from 'src/ui/blocks/Profile/Sidebar/Sidebar';
import {
  closeMenuMobileFn, openMenuMobileFn,
  $openMenuMobile,
} from 'src/models/Helper/MenuMobile';
import {
  $applicationStatus, $isRepeatNewLoan, $isOpenMakeLoan,
  STATUS_LOAN,
} from 'src/models/Loan/Application';
import { $isLoadingUser } from 'src/models/User';
import { Limit } from './children/Limit';
import { First } from './children/First';
import { NewLoan } from './children/NewLoan/NewLoan';
import { ButtonLoan } from './children/ButtonLoan/ButtonLoan';

// import { PromoCode } from './children/PromoCode';

const { REJECTED } = STATUS_LOAN;

export const NoLoan = React.memo(() => {
  const [
    isRepeatNewLoan, isOpenMakeLoan, isLoadingUser, openMenuMobile, status,
  ] = useUnit([$isRepeatNewLoan, $isOpenMakeLoan, $isLoadingUser, $openMenuMobile, $applicationStatus,
  ]);
  const activeContent = isOpenMakeLoan ? 'active' : '';

  const isOpen = !isEmpty(openMenuMobile);
  const handlerOpenMenu = isOpen ? closeMenuMobileFn : openMenuMobileFn;

  return (
    <>
      <div className={`profileTop noLoan ${activeContent}`}>

        <div className="profileTop-top-mobile">

          <a href="/" className="profileTop-logo desktop">
            <img src="/img/main/logo.png" alt="" />
          </a>
          <a href="/" className="profileTop-logo adaptive">
            <img src="/img/logo-adaptive.png" alt="" />
          </a>

          <ButtonLoan isOpenMakeLoan={isOpenMakeLoan} />

          <div className="profileTop-menuButton" onClick={handlerOpenMenu}>
            <img className="open" src="/img/icons/menu.svg" alt="" />
            <img className="close" src="/img/icons/menuclose.svg" alt="" />
          </div>

        </div>

        <div className="profileTop-top">
          <div className="wrapper">
            <div className="profileTop-content profileTop-content-noLoan">
              <div className="profileTop-items">

                {isEmpty(isLoadingUser)
                  && status !== REJECTED
                  && isEmpty(isRepeatNewLoan)
                  && <Limit />}

                {isEmpty(isRepeatNewLoan) && <First />}

              </div>

              <ButtonLoan />

            </div>
          </div>
        </div>

        <NewLoan />

        <Sidebar />

      </div>

      {/* <PromoCode /> */}
    </>
  );
});
