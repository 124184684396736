import React from 'react';
import { YellowBlock, MainBlock } from 'src/ui/components';
import { ConfirmForm } from './children/ConfirmForm';

const KeksBlock = () => (
  <>
    <div className="keks keks1">
      <img src="/img/other/offer-keks.png" alt="" />
    </div>
  </>
);

export const Confirm = () => (
  <div className="step step6 active">
    <YellowBlock
      title="Подпишите договор и получите ваш займ!"
      // text="Введите, пожалуйста, код подтверждения из SMS сообщения"
    />
    <MainBlock keksBlock={KeksBlock}>
      <div className="block-main__content">
        <ConfirmForm />
      </div>
    </MainBlock>
  </div>
);
