import {
  sample, split, restore, // attach,
} from 'effector';
import { spread } from 'patronum/spread';
import { get, isEmpty } from 'src/lib/lodash';
import { storage } from 'src/lib/storage';
import { PAGES_PATH } from 'src/dict/path';
import { LOAN_SERVICE_FIELDS } from 'src/dict/fields';
import { LS, token } from 'src/dict/config';
import { addPersonalDataFx } from 'src/models/Registration/Personal';
import { activationCodeRecoveryFx } from 'src/models/Recovery/Confirm';
import { pushHistoryFn } from 'src/models/Helper/History';
import { mountAppFn } from 'src/models/App';
import {
  logoutFn,
  $isLoginPage,
  authLoginFx, $tokenData,
} from 'src/models/Auth';
import { $isProfilePage, $isProfilePersonalPage } from 'src/models/Profile';
import {
  $applicationDetail, $applicationExpiredAt, $applicationId,
  $applicationStatus, $applicationUpdatedAt, $isRepeatNewLoan,
} from 'src/models/Loan/Application';
import {
  $agreementId, $agreementStatus, $agreementStartDate, $agreementEndDate,
} from 'src/models/Loan/Agreement';
import { getPersonalProductsCalcFn } from 'src/models/Calculator';
import {
  recoveryPassAfterFn,
  $isRecoveryPage,
} from 'src/models/Recovery';
import { $isRegistrationPage } from 'src/models/Registration';
import { $isLoanRepaymentPage, $isPaymentResultPage } from 'src/models/Repayment';
import { cookieRemoveFn } from 'src/models/Helper/Cookie';
import { storageRemoveFn } from 'src/models/Helper/Storage';
import { notifyErrorFn, notifySuccessFn } from 'src/models/Helper/Notification';
import { hideLoaderButtonFn, showLoaderButtonFn } from 'src/models/Helper/Loader';
import {
  userDomain,
  // события
  resetUserStatesFn,
  // хранилища
  $user,
  $snils,
  $userId,
  $phone,
  $lastName,
  $firstName,
  $middleName,
  $yearOfBirth,
  $gender,
  $email,
  $isPasswordSpecified,
  $isRepeated,
  $isVerified,
  $maritalStatus,
  $education,
  $employment,

  $passportId,
  $itemIdentitiesData,
  $addressPermanentId,
  $itemAddressData,
  $addressActualId,
  $workId,
  // эффекты
  updateContactsUserFx,
  updateContactsUserAfterRegFx,
  getCurrentUserFromRegFx,
  getCurrentUserFromAuthFx,
  updateAdditionalInfoUserFx,
  updateAdditionalInfoUserAfterRegFx,
  getItemIdentitiesDataFx,
  changePasswordUserFx,
  getAddressDataFx,
  getCurrentUserFx,
  getCurrentUserFn,
  getCurrentUserOnlyQueryFx,
  getCurrentUserAfterConfirmSuccessFx,
  changePasswordForm,
} from './index';

userDomain.onCreateStore((store) => {
  store.reset(resetUserStatesFn);
});

const { APP } = LS;
const { PROFILE } = PAGES_PATH;
const { AGREEMENT_ID } = LOAN_SERVICE_FIELDS;

/// ///////////////////////////////////////////////////////////
// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
/// ///////////////////////////////////////////////////////////

$user
  .on([
    getCurrentUserFromAuthFx.doneData,
    getCurrentUserFromRegFx.doneData,
    getCurrentUserFx.doneData,
    getCurrentUserOnlyQueryFx.doneData,
    getCurrentUserAfterConfirmSuccessFx.doneData,
    // также возвращает данные юзера
    authLoginFx.doneData,
  ], (_, result) => get(result, 'data', {}))
  .on(activationCodeRecoveryFx.doneData, (_, result) => get(result, 'data', {}))
  .on(addPersonalDataFx.doneData, (state, result) => (
    { ...state, ...get(result, 'data', {}) }
  ))
  .on([
    updateContactsUserFx.doneData,
    updateAdditionalInfoUserAfterRegFx.doneData,
  ], (state, result) => {
    const { type, contact } = get(result, 'data', {});
    return ({ ...state, [type]: contact });
  })
  .on([
    updateAdditionalInfoUserFx.doneData,
    updateContactsUserAfterRegFx.doneData,
  ], (state, result) => {
    const objInfo = get(result, 'data', {});
    return ({ ...state, ...objInfo });
  });

$itemIdentitiesData
  .on(getItemIdentitiesDataFx.doneData, (_, result) => get(result, 'data', {}));

$itemAddressData
  .on(getAddressDataFx.doneData, (state, result) => {
    const data = get(result, 'data', {});
    const type = get(data, 'type', {});
    return ({ ...state, [type]: data });
  });

/// ///////////////////////////////////////////////////////////
// ===================== БЛОК ОПЕРАТОРОВ =================== //
/// ///////////////////////////////////////////////////////////

// Первая загрузка приложения
sample({
  clock: sample({
    clock: mountAppFn,
    source: [$tokenData, $isProfilePage],
    filter: ([{ accessToken }, isProfilePage]) => !isEmpty(accessToken)
      && isEmpty(isProfilePage),
  }),
  target: getCurrentUserFn,
});

sample({
  clock: sample({
    clock: getCurrentUserFn,
    source: getCurrentUserFx.pending,
    filter: (pending) => isEmpty(pending),
  }),
  target: getCurrentUserFx,
});

// если ошибка - вызываем logoutFn и он сам разберется что делать
sample({
  clock: getCurrentUserFx.fail,
  target: [
    $tokenData.reinit,
    storageRemoveFn.prepend(() => [token.ACCESS, token.REFRESH]),
    cookieRemoveFn.prepend(() => token.COOKIE_API_TOKEN),
  ],
});

// после каждого получения юзера - проверяем надо ли его редеректить
split({
  source: sample([
    restore(getCurrentUserFx.doneData, {}),
    $isProfilePage, $isLoginPage, $isRecoveryPage, $isRegistrationPage,
    $isLoanRepaymentPage, $isPaymentResultPage,
  ], getCurrentUserFx.doneData),
  match: {
    // если юзер на странице логина но он имеет живой токен и он верифицирован
    toProfile: ([{ data }, , isLoginPage, isRecoveryPage, isRegistrationPage,
      isLoanRepaymentPage, isPaymentResultPage]) => (
      (isLoginPage
              || isRecoveryPage
              || isRegistrationPage
              || isLoanRepaymentPage
              || isPaymentResultPage
      ) && !isEmpty(get(data, 'is_verified', ''))
    ),
    // если юзер в профайле и он не верифицирован или нет информации что он верифицирован
    toLogin: ([{ data }, isProfilePage]) => isProfilePage && isEmpty(get(data, 'is_verified', '')),
    // если юзер в профайле и он верифицирован
    isProfile: ([{ data }, isProfilePage]) => isProfilePage && !isEmpty(get(data, 'is_verified', '')),
  },
  cases: {
    toProfile: [
      pushHistoryFn.prepend(() => `/${PROFILE}`),
      // запрашиваем персональные продукты
      getPersonalProductsCalcFn,
    ],
    // если юзер не авторизирован - шлем нв логин
    toLogin: logoutFn,
    isProfile: getPersonalProductsCalcFn,
  },
});

sample({
  clock: $user,
  fn: (userData) => {
    const contacts = get(userData, 'contacts', []);
    const emailContact = contacts.find(({ type }) => type === 'email');

    const activeAgreement = get(userData, 'active_agreement', {});
    const statusAgreement = get(activeAgreement, 'status', '');
    const activeApplication = (statusAgreement !== 'active') ? get(userData, 'active_application', {}) : {};

    const appId = get(activeApplication, 'id', '');
    const applicationId = !isEmpty(appId) ? appId : get(storage.get(APP), 'id', '');

    // Если нет agreementId, то достаем из LS
    // const agrId = get(activeAgreement, 'id', '');
    const agrId = !isEmpty(activeAgreement) ? get(activeAgreement, 'id', '') : storage.get(AGREEMENT_ID);
    const agreementId = !isEmpty(agrId) ? agrId : '';

    return ({
      ...userData,
      ...get(userData, 'profile', {}),
      gender: get(userData, 'info.gender', {}),
      snils: get(userData, 'info.snils', {}),
      //  если
      active_application: activeApplication,
      active_application_id: applicationId,
      active_application_status: get(activeApplication, 'status', ''),
      active_application_updatedAt: get(activeApplication, 'updated_at', ''),
      active_application_expiredAt: get(activeApplication, 'expired_at', ''),
      email: !isEmpty(emailContact) ? get(emailContact, 'contact', '') : '',
      active_agreement_id: agreementId,
      active_agreement_status: statusAgreement,
      active_agreement_startAt: get(activeAgreement, 'date_start', ''),
      active_agreement_endAt: get(activeAgreement, 'date_end', ''),

      isRepeatNewLoan: isEmpty(get(activeApplication, 'status', '')
        && isEmpty(get(activeAgreement, 'status', ''))),
    });
  },
  target: spread({
    targets: {
      // из корня
      id: $userId,
      phone: $phone,
      is_password_specified: $isPasswordSpecified,
      is_repeated: $isRepeated,
      is_verified: $isVerified,
      // из profile
      // client_id === $userId
      last_name: $lastName,
      first_name: $firstName,
      middle_name: $middleName,
      birth_date: $yearOfBirth,
      marital_status: $maritalStatus,
      education: $education,
      employment: $employment,
      email: $email,
      gender: $gender,
      snils: $snils,

      isRepeatNewLoan: $isRepeatNewLoan,

      actual_passport_id: $passportId,
      actual_registration_address_id: $addressPermanentId,
      actual_residence_address_id: $addressActualId,
      actual_work_id: $workId,

      active_application: $applicationDetail,
      active_application_id: $applicationId,
      active_application_status: $applicationStatus,
      active_application_updatedAt: $applicationUpdatedAt,
      active_application_expiredAt: $applicationExpiredAt,

      active_agreement_id: $agreementId,
      active_agreement_status: $agreementStatus,
      active_agreement_startAt: $agreementStartDate,
      active_agreement_endAt: $agreementEndDate,
    },
  }),
});

sample({
  clock: sample({
    clock: [$passportId, $isProfilePersonalPage],
    source: [$passportId, $isProfilePersonalPage],
    filter: ([id, isProfilePersonalPage]) => (
      !isEmpty(id) && !isEmpty(isProfilePersonalPage)
    ),
  }),
  filter: $userId,
  target: getItemIdentitiesDataFx.prepend(([id]) => id),
});

sample({
  clock: sample({
    clock: [$addressPermanentId, $isProfilePersonalPage],
    source: [$addressPermanentId, $isProfilePersonalPage],
    filter: ([id, isProfilePersonalPage]) => (
      !isEmpty(id) && !isEmpty(isProfilePersonalPage)
    ),
  }),
  filter: $userId,

  target: getAddressDataFx.prepend(([id]) => id),
});

sample({
  clock: sample({
    clock: [$addressActualId, $isProfilePersonalPage],
    source: [$addressActualId, $isProfilePersonalPage],
    filter: ([id, isProfilePersonalPage]) => (
      !isEmpty(id) && !isEmpty(isProfilePersonalPage)
    ),
  }),
  filter: $userId,
  target: getAddressDataFx.prepend(([id]) => id),
});

sample({
  clock: sample({
    clock: changePasswordForm.formValidated,
    source: changePasswordUserFx.pending,
    filter: (pending) => isEmpty(pending),
  }),
  source: changePasswordForm.formValidated,
  fn: (data) => data,
  target: [
    showLoaderButtonFn,
    changePasswordUserFx,
  ],
});

split({
  source: sample($isProfilePage, changePasswordUserFx.done),
  match: $isProfilePage,
  cases: {
    true: [
      changePasswordForm.reset,
      notifySuccessFn.prepend(() => 'Пароль изменен'),
    ],
    __: [
      changePasswordForm.reset,
      // запрашиваем данные о юзере
      getCurrentUserOnlyQueryFx,
      pushHistoryFn.prepend(() => `/${PROFILE}`),
    ],
  },
});

sample({
  clock: changePasswordUserFx.finally,
  target: hideLoaderButtonFn,
});

sample({
  clock: changePasswordUserFx.fail,
  target: notifyErrorFn.prepend(
    (data) => get(data, 'error.response.data.message', ''),
  ),
});

sample({
  clock: changePasswordUserFx.done,
  target: recoveryPassAfterFn,
});
