import React from 'react';
import { USER_FORM } from 'src/dict/fields';
import { Personal } from './children/Personal/Personal';
import { Passport } from './children/Passport/Passport';
import { Address } from './children/Address/Address';
// import { Social } from './children/Social/Social';

const { ADDRESS_REG, ADDRESS_ACT } = USER_FORM;
export const UserInfo = React.memo(() => (
  <>
    <h1 className="title">Мои данные</h1>

    <Personal />

    <Passport />

    <Address type={ADDRESS_REG} />

    <Address type={ADDRESS_ACT} />

    {/* <Social /> */}
  </>
));
