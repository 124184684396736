import {
  restore, sample, split, attach,
} from 'effector';
import { spread } from 'patronum/spread';
import noUiSlider from 'nouislider';
import { get, isEmpty } from 'src/lib/lodash';
import { storage } from 'src/lib/storage';
import { PAGES_PATH } from 'src/dict/path';
import { INTENT_TO_REPAY } from 'src/dict/fields/loan';
import { $pathnameUrl } from 'src/models/App';
import {
  notifySuccessFn, notifyErrorFn,
} from 'src/models/Helper/Notification';
import {
  hideLoaderButtonFn,
  hideLoaderNotTimePageFn, showLoaderButtonFn, showLoaderNotTimePageFn,
} from 'src/models/Helper/Loader';
import { pushHistoryFn } from 'src/models/Helper/History';
import { closeModalFn, openModalFn } from 'src/models/components/Modal';
import { openIframeAddCardBankFn } from 'src/models/Payment';
import { storageRemoveFn, storageSetFn } from 'src/models/Helper/Storage';
import { CalculatorRepayment } from 'src/ui/components';
import {
  // события
  changeValueCalcAmountToRepayFn, closeCalculatorToRepayFn, bindCalcAmountToRepayFn,
  changeAmountToRepayFn, goLoanRepaymentPageFn,
  // хранилища
  $repaymentIntentId, $repaymentIntentItem, $amountToRepay, $selectorCalcAmountToRepay,
  $isPaymentResultPage, $isPaymentSuccessPage, $isPaymentFailPage, $isClearDataRepayment,
  // эффекты
  getStatusToRepayFx, createIntentToRepayFx, timerToRepayStatusFx, sendRepaymentIframeFx,
  // формы
  loanRepaymentDomainForm, resetRepaymentFn, $isLoanRepaymentPage,
} from './index';

const { LOAN_REPAYMENT } = PAGES_PATH;
const {
  // CREATED,
  // WAITING_REFRESH_AGREEMENT,
  WAITING_REPAYMENT_AMOUNT,
  WAITING_REPAYMENT,
} = INTENT_TO_REPAY;

/// ///////////////////////////////////////////////////////////
// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
/// ///////////////////////////////////////////////////////////

$selectorCalcAmountToRepay.reset(closeCalculatorToRepayFn);

$amountToRepay
  .reset(resetRepaymentFn)
  .on(changeValueCalcAmountToRepayFn, (_, value) => Number.parseFloat(get(value, '0', 0), 10));

$repaymentIntentId
  .reset(resetRepaymentFn)
  .on(createIntentToRepayFx.doneData, (_, result) => get(result, 'data.repayment.id', ''));

$repaymentIntentItem
  .reset(resetRepaymentFn)
  .on([
    getStatusToRepayFx.doneData,
    createIntentToRepayFx.doneData,
  ], (_, result) => ({
    amountMin: get(result, 'data.amount_interval.min', ''),
    amountMax: get(result, 'data.amount_interval.max', ''),
    ...get(result, 'data.repayment', {}),
  }));
/*
$repaymentIntentItem.watch((state) => console.log('$repaymentIntentItem:', state));
$repaymentIntentId.watch((state) => console.log('$repaymentIntentId:', state));
$amountToRepay.watch((state) => console.log('$amountToRepay:', state));
*/
/// ///////////////////////////////////////////////////////////
// ===================== БЛОК ОПЕРАТОРОВ =================== //
/// ///////////////////////////////////////////////////////////

sample({
  clock: loanRepaymentDomainForm.formValidated,
  target: [
    createIntentToRepayFx,
    // показываем лоадер
    showLoaderNotTimePageFn,
    // блокируем кнопку
    showLoaderButtonFn,
  ],
});

split({
  clock: createIntentToRepayFx.failData,
  source: restore(createIntentToRepayFx.fail, {}),
  match: {
    error404: (error) => (get(error, 'error.response.status') === 404),
  },
  cases: {
    error404: notifyErrorFn.prepend(() => 'Договор с такими данными не найден.'),
    __: notifyErrorFn.prepend(() => 'Упс, что-то пошло не так! Обновите страницу и попробуйте еще раз. '
      + 'Если ошибка повторится, пожалуйста, обратитесь в службу поддержки.'),
  },
});

// При ошибке создания интента (до появленияв ыбора суммы погашения)
sample({
  clock: createIntentToRepayFx.fail,
  // скрываем лоадер
  target: hideLoaderNotTimePageFn,
});

// При любом исходы эффекта создания интента (до появленияв ыбора суммы погашения)
sample({
  clock: createIntentToRepayFx.finally,
  // разблокирем  кнопку
  target: hideLoaderButtonFn,
});

// При срабатывании таймера / успешном ответе запроса для отображдения iframe для карты погашения
sample({
  clock: [timerToRepayStatusFx.done, sendRepaymentIframeFx.done],
  source: [$repaymentIntentId, loanRepaymentDomainForm.$values],
  fn: ([repaymentIntentId, formData]) => ({
    repaymentIntentId,
    data: Object.entries(formData).map((item) => item),
  }),
  // запускаем запрос на проучение статуса, который пишется в $repaymentIntentItem
  target: getStatusToRepayFx,
});

// разблокируем кнопку при любом раскладе
sample({
  clock: sendRepaymentIframeFx.finally,
  target: hideLoaderButtonFn,
});

// Полинг для:
split({
  source: sample({
    clock: $repaymentIntentItem,
    source: [$repaymentIntentItem, loanRepaymentDomainForm.$values, $amountToRepay, $isLoanRepaymentPage],
    filter: ([data]) => !isEmpty(data),
  }),
  match: {
    // 0. зайи погашен
    finish: ([data]) => (
      WAITING_REPAYMENT_AMOUNT === get(data, 'status', '')
            && get(data, 'amountMin', 0) === 0
            && get(data, 'amountMax', 0) === get(data, 'amountMin', 0)
    ),
    // 1. модальное окно для выбора суммы погашения
    choiceAmount: ([data]) => (
      WAITING_REPAYMENT_AMOUNT === get(data, 'status', '')
        && get(data, 'amountMin', 0) !== 0
        && get(data, 'amountMax', 0) !== 0
    ),
    // 2. iframe для карты погашения
    // eslint-disable-next-line unicorn/no-unreadable-array-destructuring
    openIframe: ([
      { status, payment_url: cardBindingUrl }, , , isLoanRepaymentPage,
    ]) => (
      !isEmpty(status) && status === WAITING_REPAYMENT
        && !isEmpty(cardBindingUrl)
        && !isEmpty(isLoanRepaymentPage)
    ),
    // 3. страница с результатом успешной оплаты погашения
    successPayment: ([{ status }]) => !isEmpty(status) && status === 'success',
    // 4. страница с результатом неудачной оплаты погашения
    failPayment: ([{ status }]) => !isEmpty(status) && status === 'error',
  },
  cases: {
    finish: [
      hideLoaderNotTimePageFn,
      notifySuccessFn.prepend(() => 'Займ по этому договору погашен'),
    ],
    choiceAmount: [
      // заканчиваем показывать прелоадер
      hideLoaderNotTimePageFn,
      // открываем модальное окно с выбором суммы погашения
      openModalFn.prepend(() => ({
        className: 'modal-changeAmountAndTerm modal-repayLoan fancybox-content',
        content: CalculatorRepayment,
        // указываем строгое закрытие
        importantOperation: true,
        // указываем свое событие на закрытие модального окна
        closeCallback: closeModalFn,
      })),
    ],
    // открываем iframe
    openIframe: [
      openIframeAddCardBankFn.prepend(([data]) => (
        ({ data: { ...data, card_binding_url: data.payment_url } })
      )),
      // пишем в хранилище: id, сумма погашения, фамилия, иномер договора,
      storageSetFn.prepend(([data, formData, amountToRepay]) => ({
        repayment: { ...data, ...formData, amountToRepay },
      })),
    ],
    successPayment: [
      // редеректим на страницу начала с get параметром success
      pushHistoryFn.prepend(() => `/${LOAN_REPAYMENT}?success`),
    ],
    failPayment: [
      // редеректим на страницу начала с get параметром fail
      pushHistoryFn.prepend(() => `/${LOAN_REPAYMENT}?fail`),
      notifyErrorFn.prepend(() => 'Ошбка оплаты'),
    ],
    // error: [],
    __: timerToRepayStatusFx.prepend(() => 2000),
  },
});

// Сохраняем селектор калькулятора погашения в хранилище
sample({
  clock: bindCalcAmountToRepayFn,
  source: $selectorCalcAmountToRepay,
  filter: (selectorCalcAmount) => isEmpty(selectorCalcAmount),
  fn: (_, selector) => selector,
  target: $selectorCalcAmountToRepay,
});

// при изменении положения ползунка меняем значение в хранилище для калькулятора погашения
sample({
  clock: $selectorCalcAmountToRepay,
  filter: (selectorCalcAmount) => !isEmpty(selectorCalcAmount),
  fn: (selector) => selector,
  target: attach({
    source: [$repaymentIntentItem, $amountToRepay],
    effect: ([
      { amountMax, amountMin }, sum], selector) => {
      noUiSlider.create(selector, {
        start: sum,
        range: { min: amountMin, max: amountMax },
        step: 50,
        connect: 'lower',
      });
      selector.noUiSlider.on('update', changeValueCalcAmountToRepayFn);
    },
  }),
});

// при нажатии кнопки в модальном окне калькулятора погашения
sample({
  clock: changeAmountToRepayFn,
  fn: (agreementId) => agreementId,
  // посылаем запрос на получение ссылки на форму оплаты
  target: [
    showLoaderNotTimePageFn,
    showLoaderButtonFn,
    // закрытие модального окна
    closeModalFn,
    // послыаем запрос, после которого статус платежной сессии поменяется на "ожидание платежной ссылки"
    attach({
      source: [$repaymentIntentId, $amountToRepay, loanRepaymentDomainForm.$values],
      effect: sendRepaymentIframeFx,
      mapParams: (_, [repaymentIntentId, amountToRepay, formData]) => ({
        repaymentIntentId,
        data: {
          ...formData,
          amount: (amountToRepay * 100),
          paymentable_type: 'bank_card',
        },
      }),
    }),
  ],
});

// ///////////////////////////// РАБОТА  ПОСЛЕ РЕДИРЕКТА ////////////////////////////////
sample({
  clock: $pathnameUrl,
  filter: $isPaymentResultPage,
  // получаем id платежной сессии
  fn: () => {
    const data = storage.get('repayment');
    return ({
      ...data,
      repaymentIntentId: get(data, 'id', ''),
    });
  },
  target: [
    // показываем лоадер
    showLoaderNotTimePageFn,
    // запускаем таймер
    timerToRepayStatusFx,
    // заполняем хранилища
    loanRepaymentDomainForm.setForm,
    spread({
      targets: {
        repaymentIntentId: $repaymentIntentId,
      },
    }),
  ],
});

// достаем данные из LS и засовываем в хранилища для отображения данных на странице успеха/неудачи
sample({
  clock: [$pathnameUrl, $isPaymentSuccessPage, $isPaymentFailPage],
  source: [$isPaymentSuccessPage, $isPaymentFailPage],
  filter: ([isPaymentSuccessPage, isPaymentFailPage]) => (
    !isEmpty(isPaymentSuccessPage) || !isEmpty(isPaymentFailPage)
  ),
  fn: () => {
    const data = storage.get('repayment');
    return ({
      ...data,
      repaymentIntentId: get(data, 'id', ''),
      amountToRepay: get(data, 'amountToRepay', ''),
    });
  },
  target: [
    // заполняем хранилища
    loanRepaymentDomainForm.setForm,
    spread({
      targets: {
        repaymentIntentId: $repaymentIntentId,
        amountToRepay: $amountToRepay,
      },
    }),
  ],
});

// если зашли на страницу success/fail, а данных нет - редиректим на фоорму
sample({
  clock: [$pathnameUrl, $isPaymentSuccessPage, $isPaymentFailPage],
  source: [$isPaymentSuccessPage, $isPaymentFailPage],
  filter: ([isPaymentSuccessPage, isPaymentFailPage]) => (
    isEmpty(storage.get('repayment')) && (!isEmpty(isPaymentSuccessPage) || !isEmpty(isPaymentFailPage))
  ),
  target: goLoanRepaymentPageFn,
});

// ecли не стрнаица начала с success и не странице редиректа  - стираем LS
sample({
  clock: [$pathnameUrl, $isClearDataRepayment],
  filter: $isClearDataRepayment,
  target: [
    // notifySuccessFn.prepend(() => 'LS очищен'),
    storageRemoveFn.prepend(() => 'repayment'),
    resetRepaymentFn,
    loanRepaymentDomainForm.reset,
  ],
});

sample({
  clock: goLoanRepaymentPageFn,
  target: pushHistoryFn.prepend(() => `/${LOAN_REPAYMENT}`),
});
