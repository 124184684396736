import React, { useEffect } from 'react';
import Swiper, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';

Swiper.use([Navigation]);

export const Reviews = React.memo(() => {
  useEffect(() => {
    const reviewsSlider = document.querySelector('.reviews-swiper');

    if (reviewsSlider) {
      // eslint-disable-next-line no-new
      new Swiper(reviewsSlider, {
        slidesPerView: 1,
        slidesPerColumn: 2,
        spaceBetween: 10,
        slidesPerColumnFill: 'row',
        navigation: {
          nextEl: '.reviewsSection .reviews-button-next',
          prevEl: '.reviewsSection .reviews-button-prev',
        },
      });
    }
  }, []);

  return (
    <section className="reviewsSection" id="reviews">
      <div className="wrapper">
        <div className="content">
          <div className="left">
            <h2 className="title">
              Отзывы о
              {' '}
              <span className="blue">КексКредит</span>
            </h2>
            <div className="buttons">
              <div className="reviews-button-prev reviews-button">
                <img src="/img/icons/arrow-prev.svg" alt="" />
              </div>
              <div className="reviews-button-next reviews-button">
                <img src="/img/icons/arrow-next.svg" alt="" />
              </div>
            </div>
            <div className="keks">
              <img src="/img/main/reviews/keks.png" alt="" />
            </div>
          </div>
          <div className="right">
            <div className="swiper-container reviews-swiper">
              <div className="swiper-wrapper">
                <div className="swiper-slide block">
                  <div className="block-bg block-bg__top">
                    <img src="/img/main/reviews/slide-top.svg" alt="" />
                  </div>
                  <div className="block-content">
                    <p className="text">
                      Отличный сервис! Быстрое оформление и одобрение!
                      Потратил всего 10 минут! Был очень удивлен скоростью.
                    </p>
                    <div className="block-info">
                      <div className="user">
                        {/* <span className="avatar">
                          <img src="/img/main/reviews/user2.png" alt="" />
                        </span> */}
                        <p className="name">Сергей Кузнецов</p>
                      </div>
                      {/* <p className="date">24 Мар 2021</p> */}
                    </div>
                  </div>
                  <div className="block-bg block-bg__bottom">
                    <img src="/img/main/reviews/slide-bottom2.svg" alt="" />
                  </div>
                </div>

                <div className="swiper-slide block">
                  <div className="block-bg block-bg__top">
                    <img src="/img/main/reviews/slide-top.svg" alt="" />
                  </div>
                  <div className="block-content">
                    <p className="text">
                      Сократили на работе, а на носу были праздники, нужны деньги на подарки,
                      пришлось взять займ. Без проблем оформил заявку и забрал деньги. Спасибо!
                    </p>
                    <div className="block-info">
                      <div className="user">
                        {/* <span className="avatar">
                          <img src="/img/main/reviews/user1.png" alt="" />
                        </span> */}
                        <p className="name">Дмитрий Потапов</p>
                      </div>
                      {/* <p className="date">12 Янв 2020</p> */}
                    </div>
                  </div>
                  <div className="block-bg block-bg__bottom">
                    <img src="/img/main/reviews/slide-bottom2.svg" alt="" />
                  </div>
                </div>

                {/* <div className="swiper-slide block">
                  <div className="block-bg block-bg__top">
                    <img src="/img/main/reviews/slide-top.svg" alt="" />
                  </div>
                  <div className="block-content">
                    <p className="text">
                      Не плохая организация, по сервису сделала своих конкурентов.
                      Правда переплата не маленькая, но, если нужно срочно деньги выбора нет.
                      Важно давать себе отчет, что сможешь выплати...
                    </p>
                    <div className="block-info">
                      <div className="user">
                        <span className="avatar">
                          <img src="/img/main/reviews/user1.png" alt="" />
                        </span>
                        <p className="name">Андрей Бовер</p>
                      </div>
                     <p className="date">12 Янв 2020</p>
                    </div>
                  </div>
                  <div className="block-bg block-bg__bottom">
                    <img src="/img/main/reviews/slide-bottom2.svg" alt="" />
                  </div>
                </div> */}

                <div className="swiper-slide block">
                  <div className="block-bg block-bg__top">
                    <img src="/img/main/reviews/slide-top.svg" alt="" />
                  </div>
                  <div className="block-content">
                    <p className="text">
                      Затопил соседей, срочно потребовались деньги. Нашел КексКредит, взял займ.
                      Вернул досрочно, без процентов. Норм сервис, советую.
                    </p>
                    <div className="block-info">
                      <div className="user">
                        {/* <span className="avatar">
                          <img src="/img/main/reviews/user2.png" alt="" />
                        </span> */}
                        <p className="name">Алексей Пятов</p>
                      </div>
                      {/* <p className="date">24 Мар 2021</p> */}
                    </div>
                  </div>
                  <div className="block-bg block-bg__bottom">
                    <img src="/img/main/reviews/slide-bottom.svg" alt="" />
                  </div>
                </div>

                <div className="swiper-slide block">
                  <div className="block-bg block-bg__top">
                    <img src="/img/main/reviews/slide-top.svg" alt="" />
                  </div>
                  <div className="block-content">
                    <p className="text">
                      Долго искала займ, чтобы без заморочек, наконец, нашла КексКредит.
                      Часто пользуюсь, очень удобно!
                    </p>
                    <div className="block-info">
                      <div className="user">
                        {/* <span className="avatar">
                          <img src="/img/main/reviews/user1.png" alt="" />
                        </span> */}
                        <p className="name">Яна Кочеткова</p>
                      </div>
                      {/* <p className="date">12 Янв 2020</p> */}
                    </div>
                  </div>
                  <div className="block-bg block-bg__bottom">
                    <img src="/img/main/reviews/slide-bottom.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});
