import React, { useEffect } from 'react';
import { useForm } from 'effector-forms';
import { get, isEmpty } from 'src/lib/lodash';
import { maskNumHouses } from 'src/lib/mask';
import { getDateReverse } from 'src/lib/date';
import { maskDate } from 'src/lib/maskPlaceholder';
import {
  getPropsField, getRefField, jumpFocusField, onSubmit,
} from 'src/lib/form';
import { REG_STEP3_DEV_FIELDS, USER_FORM } from 'src/dict/fields';
import {
  choiceAddressFn, clearChildrenAddressFn, notChoiceAddressFn,
  postChangeAddressFn, prepareChangeAddressFn,
  getDataByIdFx,
} from 'src/models/User/Personal';
import { jumpFocusFieldFn, setRefFieldsFn } from 'src/models/Helper/Ref';
import {
  ButtonWithLoader as Button, Input, InputAddress, InputDate,
} from 'src/ui/components';

const {
  ADDRESS_TYPE,
  REGION_CITY_STREET, HOUSE, HOUSING, APARTMENT,
  REGISTRATION_DATE,
} = REG_STEP3_DEV_FIELDS;

const methodsAddress = {
  prepareChangeAddressFn,
  postChangeAddressFn,
  choiceAddressFn,
  clearChildrenAddressFn,
  notChoiceAddressFn,
};

export const Form = ({
  fiasId, form, data,
}) => {
  const {
    submit, eachValid, ...restProps
  } = useForm(form);
  const type = restProps.fields[ADDRESS_TYPE].value;

  useEffect(() => {
    if (!isEmpty(fiasId)) {
      // достаем данные
      getDataByIdFx({ query: fiasId, formId: type });
    }
  }, [fiasId]);

  useEffect(() => {
    if (!isEmpty(data)) {
      const regAt = get(data, REGISTRATION_DATE, '');
      form.setForm({
        [REGISTRATION_DATE]: (!isEmpty(regAt) ? getDateReverse(regAt) : ''),
        [APARTMENT]: get(data, APARTMENT, ''),
        [HOUSING]: get(data, HOUSING, ''),
      });
    }
  }, [data]);

  return (
    <>
      <form autoComplete="off" onSubmit={onSubmit(submit)}>
        <div className="block-items">
          <div className="block-item" style={{ width: '100%' }}>
            <InputAddress
              from_bound="region"
              to_bound="street"
              label="Регион / Населенный пункт / Улица"
              name={REGION_CITY_STREET}
              formId={type}
              {...methodsAddress}
              {...getPropsField({
                fieldName: REGION_CITY_STREET, props: restProps, // mask: maskLetterAddress,
              })}
              ref={getRefField({
                fieldName: REGION_CITY_STREET, callBack: setRefFieldsFn,
              })}
            /* jumpField={jumpFocusField({
              fieldName: HOUSE_PER,
              callBack: jumpFocusFieldFn,
            })} */
              required
            />
          </div>
        </div>

        <div className="block-items three">

          <div className="block-item">
            <InputAddress
              from_bound="house"
              to_bound="house"
              label="Дом"
              name={HOUSE}
              formId={type}
              {...methodsAddress}
              {...getPropsField({
                fieldName: HOUSE, props: restProps,
              })}
              ref={getRefField({
                fieldName: HOUSE, callBack: setRefFieldsFn,
              })}
              jumpField={jumpFocusField({
                fieldName: APARTMENT,
                callBack: jumpFocusFieldFn,
              })}
              minLength={1}
              required
            />
          </div>

          <div className="block-item">
            <Input
              label="Корпус"
              name={HOUSING}
              {...getPropsField({
                fieldName: HOUSING, props: restProps,
              })}
              ref={getRefField({
                fieldName: HOUSING, callBack: setRefFieldsFn,
              })}
            />
          </div>

          <div className="block-item">
            <Input
              label="Квартира"
              name={APARTMENT}
              {...getPropsField({
                fieldName: APARTMENT, props: restProps, mask: maskNumHouses,
              })}
              ref={getRefField({
                fieldName: APARTMENT, callBack: setRefFieldsFn,
              })}
            />
          </div>

        </div>

        {type === USER_FORM.ADDRESS_REG && (
          <div className="block-items">
            <div className="block-item" style={{ width: '100%' }}>
              <InputDate
                label="Дата регистрации (в паспорте)"
                type="text"
                inputMode="numeric"
                name={REGISTRATION_DATE}
                {...maskDate}
                {...getPropsField({
                  fieldName: REGISTRATION_DATE, props: restProps,
                })}
                ref={getRefField({
                  fieldName: REGISTRATION_DATE, callBack: setRefFieldsFn,
                })}
                required
              />
            </div>
          </div>
        )}

        <Button
          type="submit"
          className="default-btn block-button-save"
        >
          Сохранить
        </Button>
      </form>
    </>
  );
};
