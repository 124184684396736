import {
  sample, combine, attach,
} from 'effector';
import { debounce } from 'patronum/debounce';
import { get, has, isEmpty } from 'src/lib/lodash';
import { daData } from 'src/dict/config';
import { appDomain } from 'src/models/App';
import { getDaDataSign } from 'src/api/signatures/DaData';

export const inputDaDataDomain = appDomain.createDomain('InputDaData');

export const getInputDaDataModelApiFn = inputDaDataDomain.createEvent('getInputDaDataApiFn');

// Список существующих моделей
export const $objInputDaDataModels = inputDaDataDomain.createStore({}, { name: 'listSelects' });

const createInputDaDataApi = (props) => {
  // props-ы, которые сохраняется в хранилищах
  const defaultValue = get(props, 'value', '');
  const defaultPlaceHolder = get(props, 'placeholder', ' ');
  const defaultRequired = get(props, 'required', false);
  const name = get(props, 'name', '');
  const typeAction = get(props, 'typeAction', daData.NAME.PATH);
  const payload = get(props, 'payload', {});
  const minLength = get(props, 'minLength', daData.NAME.MIN_LENGTH);

  // props-ы, которые используется напрямую
  const limit = get(props, 'limit', daData.NAME.LIMIT);
  const timeoutDebounce = get(props, 'timeoutDebounce', daData.NAME.TIMEOUT_DEBOUNCE);

  // ===================== ЮНИТЫ СОБЫТИЙ ===================== //
  const onChangeInputFn = inputDaDataDomain.createEvent('onChangeInputFn');
  const onChangeInputDebounceFn = debounce({ source: onChangeInputFn, timeout: timeoutDebounce });

  // const onChangeOptionFn = inputDaDataDomain.createEvent('onChangeOptionFn');

  const onFocusInputFn = inputDaDataDomain.createEvent('onFocusInputFn');
  const onBlurInputFn = inputDaDataDomain.createEvent('onBlurInputFn');
  const onCloseOptionsFn = inputDaDataDomain.createEvent('onCloseOptionstFn');

  // ===================== ЮНИТЫ ХРАНИЛИЦ ==================== //
  const $valueInput = inputDaDataDomain.createStore(defaultValue, { name: 'value' });
  const $placeholder = inputDaDataDomain.createStore(defaultPlaceHolder, { name: 'placeholder' });
  const $options = inputDaDataDomain.createStore([], { name: 'options' });
  const $required = inputDaDataDomain.createStore(defaultRequired, { name: 'required' });
  const $name = inputDaDataDomain.createStore(name, { name: 'name' });
  const $typeAction = inputDaDataDomain.createStore(typeAction, { name: 'typeAction' });
  const $payload = inputDaDataDomain.createStore(payload, { name: 'payload' });
  const $minLength = inputDaDataDomain.createStore(minLength, { name: 'minLength' });

  const $isOpen = inputDaDataDomain.createStore(false, { name: 'isOpen' });

  // ==== ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
  const $visibleOptions = $options.map((val) => val);
  const $hasFocused = combine(
    $name, $valueInput, (nameElement) => nameElement === get(document, 'activeElement.id', false),
  );

  const $selectData = combine(
    $valueInput, $placeholder, $visibleOptions, $required, $isOpen, $hasFocused, $minLength,
    (value, placeholder, options, required, isOpen, hasFocused, minValue) => (
      {
        value, placeholder, options, required, isOpen, hasFocused, minLength: minValue,
      }
    ),
  );

  // ================== БЛОК ЭФФЕКТОВ ============== //
  const getDaDataFx = inputDaDataDomain.createEffect(getDaDataSign);

  // ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
  $valueInput
    .on(onChangeInputFn, (_, value) => value);

  $options
    // eslint-disable-next-line consistent-return
    .on(getDaDataFx.doneData, (_, result) => {
      const items = get(result, 'data.suggestions', []);
      if (!isEmpty(items)) {
        return items.map(({ value }) => ({ value, label: value }));
      }
    })
    .reset([onCloseOptionsFn, onBlurInputFn]);

  $isOpen
    .on([
      onFocusInputFn,
      onChangeInputFn,
    ], () => true)
    .on(onCloseOptionsFn, () => false);

  sample({
    clock: sample({
      clock: onChangeInputDebounceFn,
      source: $valueInput,
      filter: (valueInput) => valueInput.length >= minLength,
    }),
    target: attach({
      effect: getDaDataFx,
      source: [$typeAction, $payload],
      mapParams: (text, [type, params]) => ({ type, data: { query: text, count: limit, ...params } }),
    }),
  });

  return ({
    $name,
    $selectData,
    onChangeInputFn,
    onBlurInputFn,
    onCloseOptionsFn,
    onFocusInputFn,
  });
};

export const getInputDaDataModelApiFx = inputDaDataDomain.createEffect(
  ({ sourceParam, eventParam }) => {
    const name = get(eventParam, 'name', '');
    // если такого имени в списке нет, создаем
    return has(sourceParam, name)
      ? { ...sourceParam }
      : { ...sourceParam, [name]: createInputDaDataApi(eventParam) };
  },
);

$objInputDaDataModels
  .on(getInputDaDataModelApiFx.doneData, (_, result) => result);

sample({
  clock: getInputDaDataModelApiFn,
  source: $objInputDaDataModels,
  // в eventParam приходят пропсы и имя InputDaData-a
  fn: (sourceParam, eventParam) => ({ sourceParam, eventParam }),
  target: getInputDaDataModelApiFx,
});

/* sample({
  clock: getInputDaDataModelApiFn,
  source: $objInputDaDataModels,
  // в eventParam приходят пропсы и имя InputDaData-a
  fn: (sourceParam, eventParam) => {
    const name = get(eventParam, 'name', '');
    // если такого имени в списке нет, создаем
    return has(sourceParam, name)
      ? { ...sourceParam }
      : { ...sourceParam, [name]: createInputDaDataApi(eventParam) };
  },
  target: $objInputDaDataModels,
}); */
