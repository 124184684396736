import React from 'react';
import {
  MainBlock, YellowBlock,
} from 'src/ui/components';
import { FailureForm } from './children/FailureForm';
import './Failure.css';

const KeksBlock = () => (
  <div className="keks keks1">
    <img src="/img/other/failure-keks.png" alt="" />
  </div>
);

export const Failure = () => (
  <>
    <YellowBlock
      title="К сожалению, мы не можем выдать вам займ!"
      // text="Введите, пожалуйста, код подтверждения из SMS сообщения"
    />
    <MainBlock keksBlock={KeksBlock}>
      <FailureForm />
    </MainBlock>
  </>
);
