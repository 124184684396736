import React, { useState } from 'react';
import Select from 'react-select';
import {
  get, isEmpty,
} from 'src/lib/lodash';
import './SelectWithInput.css';

const customStyles = {
  container: () => ({
    width: '100%',
    border: 0,
    lineHeight: '27px',
    padding: '7px 4px 6px 7px',
    fontSize: '15px',
    borderRadius: '15px',
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? '#61c1d6' : '#fff',
    color: state.isSelected ? '#fff' : '#00090b',
    fontFamily: 'Rubik, sans-serif',
    fontSize: '15px',
    lineHeight: '25px',
    padding: '7px 20px 8px 10px',
    position: 'relative',
    cursor: 'pointer',
    borderBottom: '1px solid #eee',
    width: '95%',
    marginLeft: '8px',
  }),
  menuList: () => ({
    borderTop: '2px solid #61c1d6',
    margin: 0,
    padding: 0,
  }),
  indicatorSeparator: () => ({
    width: 0,
  }),
  menu: () => ({
    padding: 0,
    margin: 0,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return {
      ...provided,
      opacity,
      transition,
    };
  },
};

const handlerChange = ({ onChange, jumpField }) => (e) => {
  onChange(e);
  jumpField();
};

const handlerInputChange = (callback) => (val) => callback(val);

const getLabel = (obj) => (isEmpty(obj) ? obj : get(obj, 'label', ''));

const getValue = (obj) => (isEmpty(obj) ? obj : get(obj, 'value', ''));

const styleMenuListAbsolute = {
  margin: 0,
  padding: 0,
  position: 'absolute',
  width: '101%',
  zIndex: 100,
  marginLeft: '-9px',
  marginTop: '-3px',
  borderTop: 0,
  backgroundColor: '#ffffff',
  borderRadius: '0 0 15px 15px',
};

export const filterOption = (value) => (option, inputValue) => (
  (isEmpty(value) || value !== inputValue)
    ? (option.label.toString().toLowerCase().match(inputValue.toLowerCase()) || []).length > 0
    : option.label.toString().toLowerCase());

export const SelectWithInput = React.forwardRef(({
  value, onChange, onBlur, // приходят из getPropsField - формы эффектора
  options, placeholder, name, label, jumpField,
  positionAbsolute,
  required, errorText, hasError, disabled, ...restProps
}, ref) => {
  const [inputText, setInputText] = useState('');
  const fieldLabel = `${label}${(required ? '*' : '')}`;
  let validState = '';
  let colorBorder = '#61c1d6';
  if (required && (hasError && (isEmpty(`${inputText}`.trim())))) {
    validState = 'invalid';
    colorBorder = '#e7525b';
  } else if (value) {
    validState = 'valid';
    colorBorder = '#50b621';
  }

  let styleMenuList = {};
  if (positionAbsolute) {
    styleMenuList = {
      menuList: () => ({
        ...styleMenuListAbsolute,
        border: `2px solid ${colorBorder}`,
      }),
      option: (provided, state) => ({
        ...customStyles.option(provided, state),
        width: '95%',
        marginLeft: '8px',
      }),
    };
  }

  return (
    <>
      <div className={`default-input ${validState} withInput`}>
        <Select
          // используем для обноления компонента при подгрузке данных из LS
          key={getValue(value)}
          onInputChange={handlerInputChange(setInputText)}
          className="react-select-container"
          classNamePrefix="react-select"
          defaultInputValue={getLabel(value)}
          {...restProps}
          filterOption={filterOption(getLabel(value))}
          placeholder={placeholder}
          styles={{
            ...customStyles,
            ...styleMenuList,
          }}
          options={options}
          onChange={handlerChange({ onChange, jumpField })}
          required={required}
          onBlur={onBlur}
          disabled={disabled}
          id={name}
          ref={ref}
          openMenuOnFocus
        />
        {isEmpty(value) && isEmpty(inputText) && <label>{fieldLabel}</label>}
      </div>
      {required
      && hasError
      && !isEmpty(errorText)
      && isEmpty(inputText) && <p className="invalid-text">{errorText}</p>}
    </>
  );
});

SelectWithInput.defaultProps = {
  positionAbsolute: true,
  jumpField: () => '',
};
