import React from 'react';
import { toProfileFn } from 'src/models/Profile';
import { ButtonWithLoader as Button } from 'src/ui/components/Form';

const SignIn = React.memo(() => (
  <div className="header-btn login">
    <Button className="white" onClick={toProfileFn}>
      Личный кабинет
    </Button>
  </div>
));

export { SignIn };
