import React from 'react';
import { LINKS_DOCS } from 'src/dict/docs';

export const Archive = React.memo(() => (
  <>
    <div className="block-line" />
    <div className="block-doc">
      <p>
        ООО «МКК «Варенье Кредит» с 23.06.2022г., не является членом
        Союза «Микрофинансовый Альянс «Институты развития малого среднего бизнеса».
        Адрес Союза «Микрофинансовый Альянс»: 127030, г. Москва, ул. Сущевская, д. 21, офис 513.
        {' '}
        <a
          href="https://www.alliance-mfo.ru/"
          target="_blank"
          rel="noreferrer"
        >
          www.alliance-mfo.ru
        </a>
      </p>
    </div>

    <div className="block-docs">
      <a
        href={LINKS_DOCS.BASE_STANDARD}
        className="block-doc"
        target="_blank"
        rel="noreferrer"
      >
        <span className="icon"><img src="/img/icons/pdf.svg" alt="" /></span>
        <p>
          {/* eslint-disable-next-line max-len */}
          Базовый стандарт совершения микрофинансовой организацией операций на финансовом рынке (27.04.2018 - 24.04.2023)
          {' '}
          <span className="size">(370 kb)</span>
        </p>
      </a>

      <a
        href={LINKS_DOCS.LIST_PARTICIPANTS}
        className="block-doc"
        target="_blank"
        rel="noreferrer"
      >
        <span className="icon"><img src="/img/icons/pdf.svg" alt="" /></span>
        <p>
          Состав участников
          {' '}
          <span className="size">(105 kb)</span>
        </p>
      </a>
    </div>
  </>
));
