import React from 'react';
import { URL_DOCS, LINKS_DOCS } from 'src/dict/docs';

export const LegalInfo = React.memo(() => (
  <>
    {/* <p className="block-text">
      Мы с 2013 года предоставляем нашим клиентам удобную и прозрачную инфраструктуру, соединяя
      желающих взять займы онлайн и частных инвесторов, вкладывающихся в них.
    </p> */}

    {/* <div className="docs-swiper">
      <div className="slider-buttons">
        <div className="slider-button-prev slider-button"><img src="/img/icons/arrow-prev-sm.svg" alt="" /></div>
        <div className="slider-button-next slider-button"><img src="/img/icons/arrow-next-sm.svg" alt="" /></div>
      </div>
      <div className="block-items">
        <div className="swiper-container 123123" data-mobile="false">
          <div className="swiper-wrapper">
            <a href="#" className="block-item swiper-slide">
              <span className="block-item__img"><img src="/img/documents/img1.png" alt="" /></span>
              <p className="block-item__title">Свидетельство МФК</p>
            </a>
            <a href="#" className="block-item swiper-slide">
              <span className="block-item__img"><img src="/img/documents/img1.png" alt="" /></span>
              <p className="block-item__title">Свидетельство МФК</p>
            </a>
            <a href="#" className="block-item swiper-slide">
              <span className="block-item__img"><img src="/img/documents/img1.png" alt="" /></span>
              <p className="block-item__title">Свидетельство МФК</p>
            </a>
            <a href="#" className="block-item swiper-slide">
              <span className="block-item__img"><img src="/img/documents/img1.png" alt="" /></span>
              <p className="block-item__title">Свидетельство МФК</p>
            </a>
            <a href="#" className="block-item swiper-slide">
              <span className="block-item__img"><img src="/img/documents/img1.png" alt="" /></span>
              <p className="block-item__title">Свидетельство МФК</p>
            </a>
            <a href="#" className="block-item swiper-slide noSlide" />
          </div>
        </div>
      </div>
    </div> */}
    <div className="block-line" />
    {/* <h4 className="block-title">Юридическая база</h4> */}
    <div className="block-docs">
      <a
        href={LINKS_DOCS.MKK}
        className="block-doc"
        target="_blank"
        rel="noreferrer"
      >
        <span className="icon"><img src="/img/icons/pdf.svg" alt="" /></span>
        <p>
          Свидетельство МКК
          {' '}
          <span className="size">(210 kb)</span>
        </p>
      </a>

      <a
        href={LINKS_DOCS.CPO}
        className="block-doc"
        target="_blank"
        rel="noreferrer"
      >
        <span className="icon"><img src="/img/icons/pdf.svg" alt="" /></span>
        <p>
          Свидетельство СРО
          {' '}
          <span className="size">(746 kb)</span>
        </p>
      </a>

      <a
        href={`${URL_DOCS}Политика в отношении обработки персональных данных.pdf`}
        className="block-doc"
        target="_blank"
        rel="noreferrer"
      >
        <span className="icon"><img src="/img/icons/pdf.svg" alt="" /></span>
        <p>
          Политика обработки персональных данных
          {' '}
          <span className="size">(8,6 mb)</span>
        </p>
      </a>

      <a
        href={`${URL_DOCS}Правила предоставления займов.pdf`}
        className="block-doc"
        target="_blank"
        rel="noreferrer"
      >
        <span className="icon"><img src="/img/icons/pdf.svg" alt="" /></span>
        <p>
          Правила предоставления займов
          {' '}
          <span className="size">(8,5 mb)</span>
        </p>
      </a>
      <a
        href={`${URL_DOCS}Общие условия договора потребительского займа.pdf`}
        className="block-doc"
        target="_blank"
        rel="noreferrer"
      >
        <span className="icon"><img src="/img/icons/pdf.svg" alt="" /></span>
        <p>
          Общие условия договора потребительского займа
          {' '}
          <span className="size">(8,5 mb)</span>
        </p>
      </a>
      <a
        href={`${URL_DOCS}Устав.pdf`}
        className="block-doc"
        target="_blank"
        rel="noreferrer"
      >
        <span className="icon"><img src="/img/icons/pdf.svg" alt="" /></span>
        <p>
          Устав
          {' '}
          <span className="size">(1,7 mb)</span>
        </p>
      </a>

      <a
        href={`${URL_DOCS}inn.pdf`}
        className="block-doc"
        target="_blank"
        rel="noreferrer"
      >
        <span className="icon"><img src="/img/icons/pdf.svg" alt="" /></span>
        <p>
          ИНН
          {' '}
          <span className="size">(227 kb)</span>
        </p>
      </a>

      <a
        href={LINKS_DOCS.DISCLOSED}
        className="block-doc"
        target="_blank"
        rel="noreferrer"
      >
        <span className="icon"><img src="/img/icons/pdf.svg" alt="" /></span>
        <p>
          Информация, подлежащая раскрытию
          {' '}
          <span className="size">(316 kb)</span>
        </p>
      </a>

      <a
        href={LINKS_DOCS.HOLIDAY}
        className="block-doc"
        target="_blank"
        rel="noreferrer"
      >
        <span className="icon"><img src="/img/icons/pdf.svg" alt="" /></span>
        <p>
          Информация для заемщиков по кредитным каникулам
          {' '}
          <span className="size">(152 kb)</span>
        </p>
      </a>

      <a
        href={LINKS_DOCS.CBO}
        className="block-doc"
        target="_blank"
        rel="noreferrer"
      >
        <span className="icon"><img src="/img/icons/pdf.svg" alt="" /></span>
        <p>
          Брошюра ЦБ РФ о кредитных каникулах для участников СВО
          {' '}
          <span className="size">(158 kb)</span>
        </p>
      </a>

      <a
        href={LINKS_DOCS.LOAN_CONDITIONS}
        className="block-doc"
        target="_blank"
        rel="noreferrer"
      >
        <span className="icon"><img src="/img/icons/pdf.svg" alt="" /></span>
        <p>
          Условия предоставления займов (виды займов)
          {' '}
          <span className="size">(711 kb)</span>
        </p>
      </a>

    </div>
    <div className="block-line" />
  </>
));
