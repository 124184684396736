import { attach, sample } from 'effector';
import { spread } from 'patronum/spread';
import { get } from 'src/lib/lodash';
import { getCurrentTime } from 'src/lib/date';
import { REG_STEP5_CODE, REG_STEP5_PHONE } from 'src/dict/fields';
import { CODE_TIMER, LS } from 'src/dict/config';
import { notifyErrorFn, notifySuccessFn } from 'src/models/Helper/Notification';
import { storageRemoveFn } from 'src/models/Helper/Storage';
import { sendStatisticUtmFn, goProcessingPageFn } from 'src/models/Loan/Application';
import { sendYMGoalFx } from 'src/models/Statistic';
import {
  getCurrentUserFn,
  $phone,
  updatePhoneUserFx,
} from 'src/models/User';
import {
  lockButtonFn, unLockButtonFn,
} from 'src/models/Registration';
import { closeModalFn } from 'src/models/components/Modal';
import {
  sendActivationCodeFx, activationCodeFx,
  // checkActivationCodeFn,
  sendActivationCodeFn, stopTimerActivationCodeFn, startTimerActivationCodeFn,
  openChangePhonePageFn, closeChangePhonePageFn,
  $phoneTemp, $tokenSent, $timerActivationCode, $valueTimer, $codeTest,
  stepPhoneForm, step5Form,
} from './index';
import { hideLoaderButtonFn, showLoaderButtonFn } from '../../Helper/Loader';

const { PHONE, PHONE_MOBILE } = REG_STEP5_PHONE;
const {
  ONE, TWO, THREE, FOUR, FIVE, SIX,
} = REG_STEP5_CODE;

const { STEP } = LS;

/// ///////////////////////////////////////////////////////////
// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
/// ///////////////////////////////////////////////////////////

$timerActivationCode
  .on(startTimerActivationCodeFn, () => true)
  .on(stopTimerActivationCodeFn, () => false);

/// ///////////////////////////////////////////////////////////
// ===================== БЛОК ОПЕРАТОРОВ =================== //
/// ///////////////////////////////////////////////////////////

// посылка кода
sample({
  clock: sendActivationCodeFn,
  target: [
    // посылаем utm метки
    sendStatisticUtmFn,
    sendActivationCodeFx,
    lockButtonFn,
  ],
});

sample({
  clock: sendActivationCodeFx.fail,
  target: notifyErrorFn.prepend(
    (data) => get(data, 'error.response.data.message', ''),
  ),
});

sample({
  clock: sendActivationCodeFx.doneData,
  fn: (data) => {
    const nextAttemptAt = get(data, 'data.next_attempt_at', '');

    const isSent = get(data, 'data.is_sent', '');
    const tokenSent = get(data, 'data.token', '');
    const code = get(data, 'data.code', '');

    const dateParts = nextAttemptAt.split('T');
    // console.log('dateParts:', dateParts);
    if (dateParts.length < 2) {
      return {};
    }
    const timeParts = dateParts[1].split('+');
    const time = timeParts[0];
    const secondsParts = time.split(':');
    const seconds = Number.parseInt(secondsParts[2], 10);

    const currentTimer = getCurrentTime();
    const currentSecondsParts = currentTimer.split(':');
    const currentSeconds = Number.parseInt(currentSecondsParts[2], 10);

    const valueTimer = isSent ? CODE_TIMER
      : (seconds < currentSeconds
        ? (seconds + 59 - currentSeconds) : (seconds - currentSeconds));
    return { valueTimer, tokenSent, code };
  },
  target:
    [
      spread({
        targets: {
          valueTimer: $valueTimer,
          tokenSent: $tokenSent,
          code: $codeTest,
        },
      }),
      startTimerActivationCodeFn,
      unLockButtonFn,
    ],
});

/* sample({
  clock: [$valueTimer, $tokenSent],
  target: [
    startTimerActivationCodeFn,
  ],
}); */

sample({
  clock: openChangePhonePageFn,
  source: $phone,
  fn: (phone) => phone,
  target: $phoneTemp,
});

sample({
  clock: stepPhoneForm.formValidated,
  target: [
    updatePhoneUserFx.prepend((form) => ({
      type: PHONE_MOBILE,
      contact: `${Number(form[PHONE].replace(/\D+/g, ''))}`,
    })),
  ],
});

sample({
  clock: updatePhoneUserFx.done,
  source: stepPhoneForm.formValidated,
  fn: (form) => ({
    phone: form[PHONE],
    // обнуляем токен
    tokenSent: '',
  }),
  // target: [],
  target: [
    spread(({
      targets: {
        tokenSent: $tokenSent,
        phone: $phone,
      },
    })),
    closeModalFn,
    step5Form.reset,
    sendYMGoalFx.prepend(() => 'REG_PHONE_VER_PHONE_CHANGED'),
  ],
});

sample({
  clock: closeChangePhonePageFn,
  target: stepPhoneForm.reset,
});

// при копировании в поле нескольких цифр
/* sample({
  clock: sample({
    clock: step5Form.$values,
    filter: (values) => Object.values(values).some((value) => `${value}`.length > 1),
  }),
  source: step5Form.$values,
  fn: (values) => {
    const [field, val] = Object.entries(values).find(([, value]) => value.length > 1);
    return getDataCodeConfirm(field, val, values);
  },
  target: [
    jumpFocusFieldFn.prepend(() => SIX),
    step5Form.setForm,
  ],
}); */

sample({
  clock: step5Form.formValidated,
  target: [
    showLoaderButtonFn,
    // создаем заявку
    attach({
      effect: activationCodeFx,
      source: [$tokenSent],
      mapParams: (form, [tokenSent]) => ({
        token: tokenSent,
        code: `${form[ONE]}`
          + `${form[TWO]}`
          + `${form[THREE]}`
          + `${form[FOUR]}`
          + `${form[FIVE]}`
          + `${form[SIX]}`,
      }),
    }),
  ],
});

sample({
  clock: activationCodeFx.fail,
  target: [
    // снимаем блок с кнопки
    hideLoaderButtonFn,
    notifyErrorFn.prepend(
      (data) => {
        const status = get(data, 'error.request.status', '');
        if (status === 410) {
          return 'Срок действия введенного SMS кода истек! Пожалуйста, запросите новый!';
        }
        return get(data, 'error.response.data.message', '');
      },
    ),
  ],
});

sample({
  clock: activationCodeFx.done,
  target: [
    getCurrentUserFn,
    notifySuccessFn.prepend(() => 'Номер мобильного телефона подтвержден'),
    goProcessingPageFn,
    // снимаем блок с кнопки
    hideLoaderButtonFn,
    sendYMGoalFx.prepend(() => 'REG_PHONE_VERIFIED'),
    storageRemoveFn.prepend(() => [STEP]),
  ],
});
