import React from 'react';

const styleSubTitle = {
  margin: '30px 50px 30px 60px',
  textAlign: 'left',
};
const styleLink = {
  fontSize: '20px',
};

export const EsiaNotConfirmed = React.memo((props) => {
  const {
    closeCallback,
  } = props;
  return (

    <div className="modal-main">
      <button
        type="button"
        className="close-modal__btn close-modal"
        onClick={closeCallback}
      >
        <img src="/img/modal/close.svg" alt="" />
      </button>
      <h2 className="modal-title">Не удалось привязать профиль из ГосУслуг</h2>
      <div className="sub-title">

        <div style={styleSubTitle}>
          <p>
            Ваша учетная запись на портале ГосУслуги не подтверждена.
            Инструкцию по подтверждению учетной записи на портале
            {' '}
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Госуслуги вы можете найти по этой ссылке:
            {' '}
            <a
              style={styleLink}
              className="default-checkbox__text"
              href="https://www.gosuslugi.ru/help/faq/popular/2"
              target="_blank"
              rel="noreferrer"
            >
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              "Подтверждение учетной записи".
            </a>
          </p>
          <br />
          <p>
            Если у вас нет учетной записи на портале ГосУслуги,
            то инструкцию по регистрации вы можете найти по этой ссылке:
            <br />
            <a
              style={styleLink}
              className="default-checkbox__text"
              href="https://www.gosuslugi.ru/help/faq/popular/1"
              target="_blank"
              rel="noreferrer"
            >
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              "Регистрация на портале ГосУслуги".
            </a>

          </p>
        </div>

      </div>
    </div>
  );
});
