import React from 'react';
import { FeedbackForm } from 'src/ui/components/Form/FeedbackForm';

export const Feedback = React.memo(() => (
  <>
    <h1 className="title">Обратная связь</h1>

    <div className="container">
      <div className="block">
        <FeedbackForm />
      </div>

      <div className="right">
        <div className="block-YellowBlock">
          <div className="keks">
            <img src="/img/profile/keks1.png" alt="" />
          </div>
          <div className="block-yellow">
            <div className="block-yellow__top">
              <img src="/img/profile/feedback/yellowTop.svg" alt="" />
            </div>
            <div className="block-yellow__main">
              <h3 className="block-yellow__title">Остались вопросы?</h3>
              <p className="block-yellow__text">
                Если у вас есть какие-либо вопросы или предложения,
                пожалуйста, заполните и отправьте форму обратной связи!
              </p>
            </div>
            <div className="block-yellow__bottom">
              <img src="/img/profile/feedback/yellowBottom.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
));
