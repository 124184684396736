export const APPLICATION_FIELDS = {
  AMOUNT: 'amount', // Сумма - из калькулятора
  PERIOD: 'term', // Срок - из калькулятора
  PROJECT_ID: 'project_id', // ИД проекта - задается в конфигурации значение из справочника, является константой
  CLIENT_ID: 'client_id', // ИД клиента - раннее сгенерированный
  PURPOSE_ID: 'purpose_id', // ИД цели займа
  PRODUCT_ID: 'product_id', // из калькулятора
  UUID: 'uuid',
};

export const LOAN_FAILURE_FIELDS = {
  AGREEMENT: 'agreement_failure',
};

export const LOAN_SERVICE_FIELDS = {
  AGREEMENT_ID: 'agreement_id',
};

export const INTENT_TO_REPAY = {
  CREATED: 'created',
  WAITING_REFRESH_AGREEMENT: 'waiting_refresh_agreement',
  WAITING_REPAYMENT_AMOUNT: 'waiting_repayment_amount',
  WAITING_REPAYMENT: 'waiting_repayment',
  REPAYMENT_ERROR: 'repayment_error',
  REPAYMENT_SUCCESS: 'repayment_success',
};

// Статусы после подписания:`
export const STATUS_AGREEMENT = {
  // eslint-disable-next-line max-len
  // Пока договор находится в не подписанном состоянии, он является офертой. По истечении суток, будет помечен как удаленный.
  WAITING_AGREE: 'waiting_agree', // ожидание подписания, оферта
  AGREE: 'agree', // подписан, не просрочен
  ACTIVE: 'active', // подписан, не просрочен /// ??????
  REPAID: 'repaid', // погашен
  EXPIRED: 'expired', // просрочен
  OVERDUE: 'overdue', // просрочен /// ??????
};

export const LOAN_REPAYMENT_FORM = {
  NUMBER: 'number',
  LAST_NAME: 'last_name',
};
