import React from 'react';
import { isEmpty } from 'src/lib/lodash';

const handlerClick = (val, onChange) => () => onChange(val);

export const Checkbox = React.memo(({
  name, value, required, label, onChange, errorText = '', hasError = '',
}) => {
  const fieldLabel = `${label}${(required ? '*' : '')}`;

  const checkValid = !isEmpty(hasError) ? 'invalid' : '';
  return (
    <>
      <input
        type="checkbox"
        id={name}
        checked={value}
        onChange={handlerClick(!value, onChange)}
      />
      {!isEmpty(fieldLabel)
      && (
      <label htmlFor={name} className="default-checkbox__label">
        <div className={`default-checkbox__block ${checkValid}`} />
        <p className="default-checkbox__text">{fieldLabel}</p>
      </label>
      )}
      {hasError && !isEmpty(errorText) && <p className="invalid-text">{errorText}</p>}
    </>
  );
});

Checkbox.defaultProps = {
  name: '',
  label: '',
  value: false,
  onChange: () => '',
  required: false,
};
