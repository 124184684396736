import { appDomain } from 'src/models/App';
import {
  createDocsSign,
  getTemplatesDocSign,
  getTemplateDocSign,
} from 'src/api/signatures/Documents';
import {
  downloadFileSign,
  openFileSign,
} from 'src/signatures/Donwload';

export const documentsDomain = appDomain.createDomain('Documents');

/// ///////////////////////////////////////////////////////////
// ================== СЛОВАРИ / КОНСТАНТЫ ================== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ СОБЫТИЙ ===================== //
/// ///////////////////////////////////////////////////////////

export const createDocsFn = documentsDomain.createEvent();

export const getDocsFn = documentsDomain.createEvent();

export const getDocFn = documentsDomain.createEvent();

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ХРАНИЛИЦ ==================== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ЭФФЕКТОВ ==================== //
/// ///////////////////////////////////////////////////////////

export const createDocsFx = documentsDomain.createEffect(createDocsSign);
export const createDocsFromAfterAppFx = documentsDomain.createEffect(createDocsSign);

export const getDocsFx = documentsDomain.createEffect(getTemplatesDocSign);

export const getDocFx = documentsDomain.createEffect(getTemplateDocSign);

export const downloadDocFx = documentsDomain.createEffect(downloadFileSign);
export const openDocFx = documentsDomain.createEffect(openFileSign);

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ЭФФЕКТОВ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

// тут создаются эффекты через attach

/// ///////////////////////////////////////////////////////////
// ====================== ЮНИТЫ ФОРМ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем формы с помощью effector-forms

/// ///////////////////////////////////////////////////////////
//  ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ФОРМ + (ХРАНИЛИЩ, ЭФФЕКТОВ) //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ========================= ФАБРИКИ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем различные фабрики и хранилища с использованием фабрик
