import React from 'react';
import { useUnit } from 'effector-react';
import { isEmpty } from 'src/lib/lodash';
import {
  $isRepaymentResultDataPage,
} from 'src/models/Repayment';
import { Form } from './children/Form';
import { Result } from './children/Result';
import 'src/ui/styles/Login.css';

export const Loan = React.memo(() => {
  const [
    isRepaymentResultDataPage,
  ] = useUnit([$isRepaymentResultDataPage]);

  const nameClass = isEmpty(isRepaymentResultDataPage)
    ? 'block-topBg' : 'block-topBg-repayment';
  return (
    <>
      <main className="loginPage authorization">
        <section className="section">
          <div className="wrapper">

            <div className="block">
              <div className="block-content">

                <div className={`${nameClass} desktop`}>
                  <img src="/img/other/topbgblock.svg" alt="" />
                </div>
                <div className={`${nameClass} mobile`}>
                  <img src="/img/other/topbgblock2-mobile.svg" alt="" />
                </div>

                <div className="block-main">
                  <div className="block-wrapper">
                    {!isEmpty(isRepaymentResultDataPage) && (
                      <Result />
                    )}

                    {isEmpty(isRepaymentResultDataPage) && (
                      <Form />
                    )}
                  </div>

                </div>
              </div>
            </div>

          </div>
        </section>
      </main>
    </>
  );
});
