import { sample, split } from 'effector';
import persist from 'effector-localstorage';
import { debounce } from 'patronum/debounce';
import { get } from 'src/lib/lodash';
import { REG_PATH } from 'src/dict/path';
import { REG_STEP4_FIELDS } from 'src/dict/fields';
import { pushHistoryFn } from 'src/models/Helper/History';
import { storageRemoveFn, storageSetFn } from 'src/models/Helper/Storage';
import { hideLoaderButtonFn, showLoaderButtonFn } from 'src/models/Helper/Loader';
import {
  addWorksUserFx, updateAdditionalInfoUserFx, updateAdditionalProfileUserFx,
  updateWorkPhoneUserFx,
} from 'src/models/User';
import { loadDataToFormOperator } from 'src/models/Registration';
import { sendYMGoalFx } from 'src/models/Statistic';
import { createDocsFn } from 'src/models/Documents';
import {
  addExperienceDataFn,
  $isNotWorkActivityType,
  step4Form, $isExperiencePage,
} from './index';

const { EXPERIENCE, VERIFICATION, REGISTRATION } = REG_PATH;
const {
  SCOPE_EMPLOYMENT, POSITION, PLACE_WORK, PHONE_WORK,
  EDUCATION, FAMILY_STATUS, ACTIVITY_TYPE, INCOME_PER_MONTH,
  COST_LOANS_PER_MONTH, EXISTENCE_BANKRUPTCY_CASE,
} = REG_STEP4_FIELDS;

/// ///////////////////////////////////////////////////////////
// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ===================== БЛОК ОПЕРАТОРОВ =================== //
/// ///////////////////////////////////////////////////////////

loadDataToFormOperator({
  form: step4Form,
  key: EXPERIENCE,
});

// сохранение в LS
persist({
  store: step4Form.$values,
  key: EXPERIENCE,
});

sample({
  clock: step4Form.formValidated,
  target: [
    showLoaderButtonFn,
    addExperienceDataFn,
  ],
});

split({
  source: addExperienceDataFn,
  match: $isNotWorkActivityType,
  cases: {
    true: updateAdditionalInfoUserFx.prepend((data) => ({
      // = перенос в профайл => [EDUCATION]: get(data[EDUCATION], 'value', ''),
      // = перенос в профайл => [FAMILY_STATUS]: get(data[FAMILY_STATUS], 'value', ''),
      // = перенос в профайл => [ACTIVITY_TYPE]: get(data[ACTIVITY_TYPE], 'value', ''),
      [INCOME_PER_MONTH]: data[INCOME_PER_MONTH],
      [COST_LOANS_PER_MONTH]: `${Number(data[COST_LOANS_PER_MONTH])}`.replace(/\D+/g, ''),
      [EXISTENCE_BANKRUPTCY_CASE]: data[EXISTENCE_BANKRUPTCY_CASE],
    })),
    __: updateWorkPhoneUserFx.prepend((data) => ({
      type: PHONE_WORK,
      contact: `${Number(data[PHONE_WORK].replace(/\D+/g, ''))}`,
    })),
  },
});

sample({
  clock: updateAdditionalInfoUserFx.doneData,
  source: step4Form.formValidated,
  fn: (data) => ({
    [EDUCATION]: get(data[EDUCATION], 'value', ''),
    [FAMILY_STATUS]: get(data[FAMILY_STATUS], 'value', ''),
    [ACTIVITY_TYPE]: get(data[ACTIVITY_TYPE], 'value', ''),
  }),
  target: updateAdditionalProfileUserFx,
});

sample({
  clock: updateWorkPhoneUserFx.doneData,
  source: step4Form.formValidated,
  fn: (data, params) => ({
    [SCOPE_EMPLOYMENT]: get(data[SCOPE_EMPLOYMENT], 'value', ''),
    [POSITION]: data[POSITION],
    [PLACE_WORK]: data[PLACE_WORK],
    phone_id: get(params, 'data.id', ''),
  }),
  target: addWorksUserFx,
});

sample({
  clock: addWorksUserFx.done,
  source: step4Form.formValidated,
  fn: (data) => ({
    // = перенос в профайл => [EDUCATION]: get(data[EDUCATION], 'value', ''),
    // = перенос в профайл => [FAMILY_STATUS]: get(data[FAMILY_STATUS], 'value', ''),
    // = перенос в профайл => [ACTIVITY_TYPE]: get(data[ACTIVITY_TYPE], 'value', ''),
    [INCOME_PER_MONTH]: data[INCOME_PER_MONTH],
    [COST_LOANS_PER_MONTH]: Number(`${data[COST_LOANS_PER_MONTH]}`.replace(/\D+/g, '')),
    [EXISTENCE_BANKRUPTCY_CASE]: data[EXISTENCE_BANKRUPTCY_CASE],
  }),
  target: updateAdditionalInfoUserFx,
});

sample({
  // при завершении 4-ого шага:
  clock: updateAdditionalProfileUserFx.done,
  filter: $isExperiencePage,
  target: [
    // после успешной фиксации доп данных сразу посылаем запрос на генерацию док-ов
    createDocsFn,
    // и записываем в локалсторадж что у нас новый шаг
    storageSetFn.prepend(() => ({
      step: VERIFICATION,
    })),
    sendYMGoalFx.prepend(() => 'REG_EXP_DATA_SENT'),
  ],
});

sample({
  clock: debounce({
    source: updateAdditionalProfileUserFx.done,
    timeout: 100,
  }),
  filter: $isExperiencePage,
  target: [
    // очищаем форму от данных
    // step4Form.reset,
    // редиректим юзера на след шаг
    pushHistoryFn.prepend(() => `/${REGISTRATION}/${VERIFICATION}`),
    // снимаем блок с кнопки
    hideLoaderButtonFn,
    storageRemoveFn.prepend(() => [EXPERIENCE]),
  ],
});
