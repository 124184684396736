import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { getFormatMoney } from 'src/lib/money';
import { getUnitDay } from 'src/lib/date';
import {
  bindCalcAmountFn, closeCalculatorPageFn, bindCalcPeriodFn,
  $calcCombineData,
} from 'src/models/Calculator';
import 'nouislider/dist/nouislider.css';

export const CalculatorNewLoan = React.memo(() => {
  const {
    amountMin, amountMax, amount, periodMin, periodMax, period,
  } = useStore($calcCombineData);

  useEffect(() => {
    bindCalcAmountFn(document.querySelector('.calculator-slider'));
    bindCalcPeriodFn(document.querySelector('.calculator-term'));
    return closeCalculatorPageFn;
  }, []);

  return (
    <>
      <div className="newLoan calculatorMain amountCalculator">

        <div className="calculator-slider__block">
          <div
            className="calculator-slider"
            data-min={amountMin}
            data-max={amountMax}
            data-start={amount}
          />
        </div>
        <div className="calculator-top">
          <p className="calculator-text">Сумма займа</p>
          <div className="calculator-sum">
            <span className="sum">{getFormatMoney(amount)}</span>
            <span className="cur">₽</span>
          </div>
        </div>

      </div>

      <div className="newLoan calculatorMain termCalculator">
        <div className="calculator-slider__block">
          <div
            className="calculator-term"
            data-min={periodMin}
            data-max={periodMax}
            data-start={period}
          />
        </div>
        <div className="calculator-top">
          <p className="calculator-text">Срок займа</p>
          <div className="calculator-sum">
            <span className="sum">{period}</span>
            <span className="cur day" id="days">{` ${getUnitDay(period)}`}</span>
          </div>
        </div>

      </div>
    </>
  );
});
