import React from 'react';
import {
  Switch, Route, withRouter, // Redirect,
} from 'react-router-dom';
import { PROFILE_NEW_LOAN_CARD_PATH } from 'src/dict/path';

import { ListChoice } from './children/ListChoice';
import { Cards } from './children/Cards/Cards';

const { CARDS } = PROFILE_NEW_LOAN_CARD_PATH;

export const PaymentRoutes = withRouter(({ match: { url } }) => {
  const baseUrl = url.slice(-1) === '/' ? url.slice(0, Math.max(0, url.length - 1)) : url;
  return (
    <Switch>
      <Route path={`${baseUrl}/${CARDS}`} component={Cards} />

      {/* <Redirect exact from={`${baseUrl}`} to={`${baseUrl}/${CARDS}`} /> */}
      <Route exact path={baseUrl} component={ListChoice} />
    </Switch>
  );
});
