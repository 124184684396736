import React from 'react';
import { ButtonWithLoader as Button } from 'src/ui/components/Form';

export const BankTransfer = React.memo(() => (
  <div className="block-payment disabled">
    <div className="block-payment__top">
      <div className="block-payment__img desktop"><img src="/img/form/transfer.png" alt="" /></div>
      <div className="block-payment__img mobile"><img src="/img/form/transfer-mobile.png" alt="" /></div>
      <div className="block-payment__info">
        <h2 className="block-payment__title">Банковский перевод</h2>
        <p className="block-payment__text">Время - до 1 часа</p>
      </div>
      <Button className="nextStep block-payment__btn desktop">Выбрать</Button>
    </div>
    <p className="block-payment__text">
      Удобный и быстрый способ оплатить за товары и услуги через
      интернет
    </p>
    <Button
      className="nextStep block-payment__btn mobile"
    >
      Выбрать
    </Button>
  </div>
));
