import React from 'react';
import { URL_DOCS } from 'src/dict/docs';

const url = URL_DOCS;

export const BasicStandards = React.memo(() => (
  <>
    <div className="block-line" />
    <div className="block-docs">
      <a
        href={`${url}Базовый_стандарт_защиты_прав_и_интересов_получателей_финансовых.pdf`}
        className="block-doc"
        target="_blank"
        rel="noreferrer"
      >
        <span className="icon"><img src="/img/icons/pdf.svg" alt="" /></span>
        <p>
          Базовый стандарт защиты прав и интересов физических и юридических лиц - получателей финансовых услуг
          {' '}
          <span className="size">(277 kb)</span>
        </p>
      </a>

      <a
        href={`${url}Базовый_стандарт_по_управлению_рисками_микрофинансовой_организации.pdf`}
        className="block-doc"
        target="_blank"
        rel="noreferrer"
      >
        <span className="icon"><img src="/img/icons/pdf.svg" alt="" /></span>
        <p>
          Базовый стандарт по управлению рисками микрофинансовых организаций
          {' '}
          <span className="size">(315 kb)</span>
        </p>
      </a>

      <a
        href={`${url}Базовый_стандарт_совершения_микрофинансовой_организацией_операций.pdf`}
        className="block-doc"
        target="_blank"
        rel="noreferrer"
      >
        <span className="icon"><img src="/img/icons/pdf.svg" alt="" /></span>
        <p>
          Базовый стандарт совершения микрофинансовой организацией операций на финансовом рынке
          {' '}
          <span className="size">(370 kb)</span>
        </p>
      </a>

    </div>
    <div className="block-line" />
  </>
));
