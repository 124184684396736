import React from 'react';
import { useUnit } from 'effector-react';
import { ButtonWithLoader as Button } from 'src/ui/components/Form';
import { $lockButton } from 'src/models/Registration';
import { sendYMGoalFx } from 'src/models/Statistic';
import {
  stopTimerActivationCodeFn,
  $valueTimer, $timerActivationCode,
} from 'src/models/Registration/Verification';
import { Timer } from 'src/ui/components/Сonfirmation/Timer';

const handlerClick = (sendCodeFn) => (e) => {
  e.preventDefault();
  sendCodeFn();
  sendYMGoalFx('REG_PHONE_VER_GET_SMS_AGAIN');
};

export const CodeRepeat = ({ sendCodeFn }) => {
  const [lockButton, valueTimer, timerActive] = useUnit([$lockButton, $valueTimer, $timerActivationCode]);

  const textButton = lockButton ? 'Отправка ...' : 'Отправить SMS код повторно';
  return (
    <>
      {!timerActive ? (
        <Button
          default={false}
          className="border newCodeBtn"
          onClick={handlerClick(sendCodeFn)}
          disabled={lockButton}
        >
          {textButton}
        </Button>
      )
        : (
          <Timer
            // key={Date.now()}
            stopTimer={stopTimerActivationCodeFn}
            timerActive={timerActive}
            value={valueTimer}
          />
        )}
    </>
  );
};
