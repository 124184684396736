import React from 'react';

export const Offers = React.memo(() => (
  <>
    <h1 className="title">Предложения партнеров</h1>
    <div className="offers mobile">
      <h3 className="block-title">Предодобренные предложения</h3>
      <p className="block-text">Иван, ниже ваши предодобренные предложения, воспользуйтесь ими в первую очередь.</p>
      <div className="loans-items">
        <div className="loans-item">
          <div className="loans-item-info">
            <p>Одобрено</p>
          </div>
          <div className="loans-item-logo">
            <img src="/img/profile/logo1.png" alt="" />
          </div>
          <div className="block-line" />
          <div className="loans-item-bottom">
            <p className="loans-item-sum">22 000 ₽</p>
            <p className="loans-item-text">
              от
              <span className="bold">0,08%</span>
              {' '}
              в день
            </p>
            <div className="loans-item-button">
              <a href="#" className="default-btn">Оформить заявку</a>
            </div>
          </div>
        </div>
        <div className="loans-item">
          <div className="loans-item-info">
            <p>Одобрено</p>
          </div>
          <div className="loans-item-logo">
            <img src="/img/profile/logo2.png" alt="" />
          </div>
          <div className="block-line" />
          <div className="loans-item-bottom">
            <p className="loans-item-sum">22 000 ₽</p>
            <p className="loans-item-text">
              от
              <span className="bold">0,08%</span>
              {' '}
              в день
            </p>
            <div className="loans-item-button">
              <a href="#" className="default-btn">Оформить заявку</a>
            </div>
          </div>
        </div>
        <div className="loans-item">
          <div className="loans-item-info">
            <p>Одобрено</p>
          </div>
          <div className="loans-item-logo">
            <img src="/img/profile/logo3.png" alt="" />
          </div>
          <div className="block-line" />
          <div className="loans-item-bottom">
            <p className="loans-item-sum">22 000 ₽</p>
            <p className="loans-item-text">
              от
              <span className="bold">0,08%</span>
              {' '}
              в день
            </p>
            <div className="loans-item-button">
              <a href="#" className="default-btn">Оформить заявку</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="offers">
      <div className="block">
        <div className="block-top">
          <h3 className="block-title">Предодобренные предложения</h3>
          <p className="block-text">
            Иван, ниже ваши предодобренные предложения, воспользуйтесь ими в первую
            очередь.
            {' '}
          </p>
        </div>
        <div className="offers-info">
          <p>Одобрено</p>
        </div>
        <div className="offers-items">
          <div className="offers-item">
            <div className="offers-item-logo"><img src="/img/profile/logo1.png" alt="" /></div>
            <div className="offers-item-sum">22 000 ₽</div>
            <div className="offers-item-info">
              от
              <span className="bold">0,08%</span>
              {' '}
              в день
            </div>
            <div className="offers-item-btn">
              <a href="#" className="default-btn">Оформить заявку</a>
            </div>
          </div>
          <div className="block-line" />
          <div className="offers-item">
            <div className="offers-item-logo"><img src="/img/profile/logo2.png" alt="" /></div>
            <div className="offers-item-sum">22 000 ₽</div>
            <div className="offers-item-info">
              от
              <span className="bold">0,08%</span>
              {' '}
              в день
            </div>
            <div className="offers-item-btn">
              <a href="#" className="default-btn">Оформить заявку</a>
            </div>
          </div>
          <div className="block-line" />
          <div className="offers-item">
            <div className="offers-item-logo"><img src="/img/profile/logo3.png" alt="" /></div>
            <div className="offers-item-sum">22 000 ₽</div>
            <div className="offers-item-info">
              от
              <span className="bold">0,08%</span>
              {' '}
              в день
            </div>
            <div className="offers-item-btn">
              <a href="#" className="default-btn">Оформить заявку</a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </>
));
