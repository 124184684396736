import React from 'react';
import { ChanePassFom } from './children/ChanePassFom';
import 'src/ui/styles/Login.css';

export const Step2 = React.memo(() => (
  <>
    <div className="block">
      <div className="block-content">

        <div className="block-topBg desktop">
          <img src="/img/other/topbgblock.svg" alt="" />
        </div>
        <div className="block-topBg mobile">
          <img src="/img/other/topbgblock2-mobile.svg" alt="" />
        </div>

        <div className="block-main">
          <div className="block-wrapper">

            <ChanePassFom />

          </div>

        </div>
      </div>
    </div>
  </>
));
