import { axios } from 'src/api/axios';

export const sendCodeSigningApplicationSign = (agreementId) => axios.post(
  `/agreements/${agreementId}/send-signing-code`,
);

export const signAgreementApplicationSign = ({ agreementId, data }) => axios.post(
  `/agreements/${agreementId}/sign`, data,
);

export const getAgreementsSign = () => axios.get(
  '/agreements',
);

export const getAgreementSign = (agreementId) => axios.get(
  `/agreements/${agreementId}`,
);

export const getAgreementDocSign = (agreementId) => axios.get(
  `/agreements/${agreementId}/documents`,
);

// инициализация намерения оплаты по займу
export const createIntentToRepaySign = (agreementId) => axios.post(
  `/agreements/${agreementId}/repayment-intent`,
);

export const getStatusToRepaySign = ({ agreementId, repaymentIntentId }) => axios.get(
  `/agreements/${agreementId}/repayment-intent/${repaymentIntentId}`,
);

export const sendToRepaySign = ({
  agreementId, repaymentIntentId, data, // amount, paymentableId, paymentableType,
}) => axios.put(`/agreements/${agreementId}/repayment-intent/${repaymentIntentId}`, data);

export const getAgreementsApplicationSign = (applicationId) => axios.get(
  `/applications/${applicationId}/agreements`,
);
