import React from 'react';

export const TopSection = React.memo(({ children }) => (
  <section className="blueSection">
    <div className="wrapper">
      <div className="content">
        <div className="keks">
          <img src="/img/main/blueSection/keks.png" alt="" />
        </div>
        <div className="left">
          <h1>
            Первый
            <br />
            займ бесплатно!
            {' '}
            <span className="black">0%</span>
          </h1>
        </div>
        <div className="right">
          <div className="keks-mobile__coins">
            <img src="/img/main/blueSection/mobile-coins.png" alt="" />
          </div>
          <div className="keks-mobile__img">
            <img src="/img/main/blueSection/mobile-keks.png" alt="" />
          </div>
          <div className="calculator-main">
            <div className="bgtop mobile">
              <img src="/img/main/blueSection/topWhiteBlockMobile.svg" alt="" />
            </div>
            <div className="bgtop">
              <img src="/img/main/blueSection/topYellowBlock.svg" alt="" />
            </div>

            {children}

            <div className="bgbottom">
              <img src="/img/main/blueSection/topYellowBlock.svg" alt="" />
            </div>
            <div className="bgbottom mobile">
              <img src="/img/main/blueSection/topWhiteBlockMobile.svg" alt="" />
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
));
