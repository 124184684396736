import { isEmpty } from 'src/lib/lodash';
import { decodeUrl } from 'src/lib/url';

export const downloadFileSign = ({
  data, fileName, type, typeMeme,
}) => {
  // for IE 11
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    const csvData = new Blob([data], { type });
    window.navigator.msSaveOrOpenBlob(csvData, decodeUrl(fileName));
  }
  const typeBlob = isEmpty(typeMeme) ? type : typeMeme;
  const blob = new Blob([data], { type: typeBlob });
  // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet here represents the xlsx type
  const downloadObj = document.createElement('a');
  const href = window.URL.createObjectURL(blob); // Create download link
  downloadObj.href = href;
  downloadObj.download = `${fileName}.${type}`; // File name after download
  // eslint-disable-next-line no-undef
  document.body.append(downloadObj);
  downloadObj.click(); // click to download
  downloadObj.remove(); // Remove the element after downloading
  window.URL.revokeObjectURL(href); // Release the blob object]
};

export const openFileSign = ({
  data, fileName, type, typeMeme,
}) => {
  // for IE 11
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    const csvData = new Blob([data], { type });
    window.navigator.msSaveOrOpenBlob(csvData, decodeUrl(fileName));
  }
  const typeBlob = isEmpty(typeMeme) ? type : typeMeme;
  const blob = new Blob([data], { type: typeBlob });
  const href = window.URL.createObjectURL(blob); // Create download link
  window.open(href);
  window.URL.revokeObjectURL(href); // Release the blob object]
};
