import { createForm } from 'effector-forms';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from 'src/lib/lodash';
import { isCurrentPath } from 'src/lib/url';
import {
  emailPattern, letterPattern, rules,
} from 'src/lib/rules';
import { REG_PATH } from 'src/dict/path';
import {
  REG_STEP1_FIELDS, GENDER_FIELD_VALUES, // AGREEMENT_DOCS,
} from 'src/dict/fields';
import { addPersonalDataSign } from 'src/api/signatures/Registration';
import { $pathnameUrl } from 'src/models/App';
import { registrationDomain } from '../index';

// STEP 1

export const personalRegDomain = registrationDomain.createDomain('PersonalRegDomain');

/// ///////////////////////////////////////////////////////////
// ================== СЛОВАРИ / КОНСТАНТЫ ================== //
/// ///////////////////////////////////////////////////////////

const { PERSONAL } = REG_PATH;

const { MAN } = GENDER_FIELD_VALUES;

const {
  PHONE, FIRST_NAME, LAST_NAME, IS_NOT_MIDDLE_NAME, MIDDLE_NAME,
  YEAR_OF_BIRTH, GENDER, IS_NOT_EMAIL, EMAIL, PURPOSE, AGREEMENT, UUID,
} = REG_STEP1_FIELDS;

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ СОБЫТИЙ ===================== //
/// ///////////////////////////////////////////////////////////

// изменение массива согласий, которые должны быть одобрены
export const changeAgreementDocsFn = personalRegDomain.createEvent('changeAgreementDocsFn');

export const addPersonalDataFn = personalRegDomain.createEvent('addPersonalDataFn');

export const step1FinishFn = personalRegDomain.createEvent('step1FinishFn');

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ХРАНИЛИЦ ==================== //
/// ///////////////////////////////////////////////////////////

export const $isNotEmail = personalRegDomain.createStore(false, { name: 'agreementDocs' });
export const $isNotMiddleName = personalRegDomain.createStore(false, { name: 'agreementDocs' });

// массив согласий, которые должны быть одобрены
export const $agreementDocs = personalRegDomain.createStore([], { name: 'agreementDocs' });

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ЭФФЕКТОВ ==================== //
/// ///////////////////////////////////////////////////////////

export const addPersonalDataFx = personalRegDomain.createEffect(addPersonalDataSign);

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

export const $isPersonalPage = $pathnameUrl.map((path) => isCurrentPath(path, PERSONAL));

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ЭФФЕКТОВ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

// тут создаются эффекты через attach

/// ///////////////////////////////////////////////////////////
// ====================== ЮНИТЫ ФОРМ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем формы с помощью effector-forms
export const step1Form = createForm({
  fields: {
    [PHONE]: { rules: [rules.required(), rules.phone()], init: '', validateOn: ['blur'] },
    [FIRST_NAME]: {
      rules: [rules.required(), rules.letter(), rules.maxLength(50)], init: '', validateOn: ['blur'],
    },
    [LAST_NAME]: {
      rules: [rules.required(), rules.letter(), rules.maxLength(50)], init: '', validateOn: ['blur'],
    },
    [IS_NOT_MIDDLE_NAME]: { init: false, units: { $value: $isNotMiddleName } },
    [MIDDLE_NAME]: {
      rules: [
        {
          source: $isNotMiddleName,
          validator: (value, form, isNotMiddleName) => !isEmpty(value) || isNotMiddleName,
          errorText: 'Данное поле обязательно для заполнения',
        },
        {
          source: $isNotMiddleName,
          validator: (value, form, isNotMiddleName) => letterPattern.test(value) || isNotMiddleName,
          errorText: 'Данное поле обязательно для заполнения',
        },
        {
          source: $isNotMiddleName,
          validator: (value, form, isNotMiddleName) => value.length <= 50 || isNotMiddleName,
          errorText: 'Максимальная длина 50 символ(а/ов)',
        },
      ],
      init: '',
      validateOn: ['blur'],
    },
    [YEAR_OF_BIRTH]: {
      rules: [
        /* {
          validator: (value) => isValidBirthDate(value),
          errorText: 'Введенная дата некорректна',
        }, */
        rules.required(), rules.dateYear(), rules.dateBirth(),
      ],
      init: '',
      validateOn: ['blur'],
    },
    [GENDER]: { init: MAN },
    [IS_NOT_EMAIL]: { init: false, units: { $value: $isNotEmail } },
    [EMAIL]: {
      rules: [
        {
          source: $isNotEmail,
          validator: (value, form, isNotEmail) => !isEmpty(value) || isNotEmail,
          errorText: 'Данное поле обязательно для заполнения',
        },
        {
          source: $isNotEmail,
          validator: (value, form, isNotEmail) => emailPattern.test(value) || isNotEmail,
          errorText: 'Указан некорректный email-адрес',
        },
      ],
      init: '',
    },
    [PURPOSE]: { rules: [rules.required()], init: '', validateOn: ['blur'] },
    [AGREEMENT]: { rules: [rules.requiredAgreementCheckbox()], init: false },
    [UUID]: { init: uuidv4() },
  },
  validateOn: ['submit'],
  domain: personalRegDomain,
});

/// ///////////////////////////////////////////////////////////
//  ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ФОРМ + (ХРАНИЛИЩ, ЭФФЕКТОВ) //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ========================= ФАБРИКИ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем различные фабрики и хранилища с использованием фабрик
