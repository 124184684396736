import React from 'react';
import { createApplicationsFn } from 'src/models/Loan/Application';
import { CalculatorNewLoan } from 'src/ui/components/Calculators';
import { Button } from 'src/ui/components/Form';
// import { EsiaButtonProfile } from 'src/ui/components/Block/EsiaButtonProfile';
import { EsiaButtonReg } from 'src/ui/components/Block/EsiaButtonReg';
import { InfoCalc } from './children/InfoCalc';
// import { Services } from './children/Services';

/*
  const {
    amountMin, amountMax, amount, periodMin, periodMax, period,
  } = useStore($calcCombineData);
 */
export const NewLoan = React.memo(() => (
  <>
    <div className="profileTop-main">
      <div className="wrapper">

        <div className="block-line" />

        <div className="profileTop-noLoan profileTop-main-content">
          <div className="profileTop-content-left">
            <CalculatorNewLoan />

            <InfoCalc />

            <div className="profileTop-noLoan-buttons">
              <Button
                onClick={createApplicationsFn}
                className="default-btn"
              >
                Получить деньги
              </Button>
            </div>

            <EsiaButtonReg />
          </div>

          <div className="profileTop-content-right">
            <InfoCalc />
          </div>
          {/*
          <div className="profileTop-content-right">
            <Services />
          </div>
          */}
        </div>
      </div>
    </div>
  </>
));
