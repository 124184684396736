import {
  restore, sample, split, attach,
} from 'effector';
import { spread } from 'patronum/spread';
import { get, isEmpty } from 'src/lib/lodash';
import {
  LOAN_FAILURE_FIELDS,
} from 'src/dict/fields';
import { notifySuccessFn } from 'src/models/Helper/Notification';
import {
  hideLoaderNotTimePageFn, showLoaderNotTimePageFn,
} from 'src/models/Helper/Loader';
import { infoRefusalFn } from 'src/models/Profile';
import {
  changeAgreementDocsFn, getActionRejectFn,
  // хранилища
  $agreementDocs, $urlAfterReject,
  // эффекты
  timerAfterRejectFx,
  // формы
  failureForm,
} from './index';

const { AGREEMENT } = LOAN_FAILURE_FIELDS;

/// ///////////////////////////////////////////////////////////
// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
/// ///////////////////////////////////////////////////////////

$urlAfterReject.on(getActionRejectFn,
  (_, result) => (
    get(result, 'data.refusal_showcase.redirect_url', '')
  ));

/// ///////////////////////////////////////////////////////////
// ===================== БЛОК ОПЕРАТОРОВ =================== //
/// ///////////////////////////////////////////////////////////

/* при получении общего согласия проставляем согласие везде,
и если согласия нет - снимаем галочки везде */
sample({
  clock: failureForm.fields[AGREEMENT].$value,
  source: $agreementDocs,
  fn: (agreementDocs, agreement) => ({
    agreement,
    agreementDocs: agreementDocs.map((item) => ({ ...item, value: !isEmpty(agreement) })),
  }),
  target: spread({
    targets: {
      agreement: failureForm.fields[AGREEMENT].$value,
      agreementDocs: $agreementDocs,
    },
  }),
});

sample({
  clock: changeAgreementDocsFn,
  source: $agreementDocs,
  fn: (agreementDocs, { id: agreementDocsId, value: agreementDocsValue }) => {
    const items = agreementDocs.map(
      (itemDoc) => ((agreementDocsId !== itemDoc.id) ? { ...itemDoc }
        : { ...itemDoc, value: agreementDocsValue }),
    );
    return ({
      agreementDocs: items,
      agreement: !items.some(({ value }) => !value),
    });
  },
  target: spread({
    targets: {
      agreement: failureForm.fields[AGREEMENT].$value,
      agreementDocs: $agreementDocs,
    },
  }),
});

sample({
  clock: failureForm.formValidated,
  target: [
    notifySuccessFn.prepend(
      () => 'Ищем наилучший вариант у наших партнеров!',
    ),
  ],
});

sample({
  clock: getActionRejectFn,
  target: showLoaderNotTimePageFn,
});

// Ошибка отправки заявки
split({
  source: sample(
    restore(getActionRejectFn, {}),
    getActionRejectFn,
  ),
  match: {
    redirect: (data) => {
      const {
        redirect_timeout: timeOut,
        redirect_url: url,
      } = get(data, 'data.refusal_showcase', {});
      return (!isEmpty(url) && isEmpty(timeOut));
    },
    timeOutRedirect: (data) => {
      const {
        redirect_timeout: timeOut,
        redirect_url: url,
      } = get(data, 'data.refusal_showcase', {});
      return (!isEmpty(url) && !isEmpty(timeOut));
    },
    // redirect: ({ url, timeOut }) => !isEmpty(url) && isEmpty(timeOut) && false,
    // timeOutRedirect: ({ url, timeOut }) => !isEmpty(url) && !isEmpty(timeOut) && false,
  },
  /*
  refusal_showcase: {
    redirect_timeout: 7
    redirect_url: "https://xn----7sbbznkmbdf6b3hpb.com/"
  }
   */
  cases: {
    redirect: timerAfterRejectFx.prepend(() => 0),
    timeOutRedirect: [
      hideLoaderNotTimePageFn,
      timerAfterRejectFx.prepend(
        (data) => {
          const value = Number.parseInt(
            get(data, 'data.refusal_showcase.redirect_timeout', 0),
            10,
          );
          return (value * 1000);
        },
      )],
    __: [
      hideLoaderNotTimePageFn,
      infoRefusalFn,
    ],
  },
});

sample({
  clock: timerAfterRejectFx.done,
  source: $urlAfterReject,
  target: attach({
    source: $urlAfterReject,
    async effect(url) {
      if (url) {
        window.location.href = url;
      }
    },
  }),
});
