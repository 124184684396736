import React from 'react';
import { GetMoneyButton } from 'src/ui/components/Form';

export const Why = React.memo(() => (
  <section className="whySection" id="why">
    <div className="wrapper">
      <div className="content">
        <div className="left">
          <h2 className="title">
            Почему люди выбирают
            {' '}
            <span className="blue">КексКредит</span>
          </h2>
          <div className="why-btn">
            <GetMoneyButton size="big" ym="GET_MONEY_LANDING_MID_BT" />
          </div>
        </div>

        <div className="right">
          <div className="items">
            <div className="item">
              <div className="item-icon" />
              <p className="item-text">
                <span className="bold">Деньги</span>
                {' '}
                у Вас
                {' '}
                <span className="bold">сразу</span>
                {' '}
                после одобрения
              </p>
            </div>

            <div className="item">
              <div className="item-icon" />
              <p className="item-text">
                Настоящий
                {' '}
                <span className="bold">
                  онлайн-займ! Никаких звонков ни вам, ни вашим друзьям
                </span>
              </p>
            </div>

            <div className="item">
              <div className="item-icon" />
              <p className="item-text">
                <span className="span bold">Любая</span>
                {' '}
                кредитная история
              </p>
            </div>

            <div className="item">
              <div className="item-icon" />
              <p className="item-text">
                Выдаем деньги
                {' '}
                <span className="bold">круглосуточно!</span>
              </p>
            </div>

            <div className="item">
              <div className="item-icon" />
              <p className="item-text">
                <span className="bold">Досрочное</span>
                {' '}
                погашение
                {' '}
                <span className="bold">без комиссий</span>
                {' '}
                в любое время!
              </p>
            </div>
            <div className="keks">
              <img src="/img/main/why/keks.png" alt="" />
            </div>
          </div>
        </div>
        <div className="why-btn__mobile">
          <GetMoneyButton size="big" ym="GET_MONEY_LANDING_MID_BT" />
        </div>
      </div>
    </div>
  </section>
));
