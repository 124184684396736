import React from 'react';

export const Events = React.memo(() => (
  <>
    <div className="events-top">
      <h1 className="title">События</h1>
      <div className="events-select default-select selectAbsolute">
        <div className="default-input">
          <select name="ex1" className="step__select">
            <option />
            <option>1</option>
            <option>2</option>
            <option>3</option>
          </select>
          <label>Цель займа*</label>
        </div>
      </div>
    </div>
    <div className="events-items">
      <div className="events-item new">
        <h3 className="events-item__title">Списание с карты $ 25 ****2114</h3>
        <p className="events-item__date">23 Окт 2021 - 20:45</p>
      </div>
      <div className="events-item new">
        <h3 className="events-item__title">Списание с карты $ 25 ****2114</h3>
        <p className="events-item__date">23 Окт 2021 - 20:45</p>
      </div>
      <div className="events-item ">
        <h3 className="events-item__title">Списание с карты $ 25 ****2114</h3>
        <p className="events-item__date">23 Окт 2021 - 20:45</p>
      </div>
      <div className="events-item ">
        <h3 className="events-item__title">Списание с карты $ 25 ****2114</h3>
        <p className="events-item__date">23 Окт 2021 - 20:45</p>
      </div>
      <div className="events-item ">
        <h3 className="events-item__title">Списание с карты $ 25 ****2114</h3>
        <p className="events-item__date">23 Окт 2021 - 20:45</p>
      </div>
    </div>
  </>
));
