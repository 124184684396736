import { sample } from 'effector';
import { debounce } from 'patronum/debounce';
import { isCurrentPath } from 'src/lib/url';
import { PAGES_PATH } from 'src/dict/path';
import {
  scrollTopFn,
  $pathnameUrl,
} from 'src/models/App';
import {
  showLoaderPageFn, hideLoaderPageFn,
  showLoaderNotTimePageFn, hideLoaderNotTimePageFn,
  showLoaderButtonFn, hideLoaderButtonFn,
  // хранилища,
  $loaderPage, $loaderButton,
  // эффекты
} from './index';

/// ///////////////////////////////////////////////////////////
// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
/// ///////////////////////////////////////////////////////////

$loaderPage
  .on([showLoaderPageFn, showLoaderNotTimePageFn], () => {
    document.body.classList.add('scroll-hide');
    return 'loaded';
  })
  .on([hideLoaderPageFn, hideLoaderNotTimePageFn], () => {
    document.body.classList.remove('scroll-hide');
    return '';
  });

$loaderButton
  .on(showLoaderButtonFn, () => true)
  .on(hideLoaderButtonFn, () => false);

/// ///////////////////////////////////////////////////////////
// ===================== БЛОК ОПЕРАТОРОВ =================== //
/// ///////////////////////////////////////////////////////////

// запускаем loader - не профиль

sample({
  clock: $pathnameUrl,
  filter: (pathnameUrl) => (
    !isCurrentPath(pathnameUrl, PAGES_PATH.PROFILE)
  ),
  target: showLoaderPageFn,
});

// скроллирование вверх страницы
sample({
  clock: showLoaderPageFn,
  // clock: $pathnameUrl,
  target: [
    // запускаем прокрутку вверх, чтобы юзер перейдя на новую страницу не оказался в ее футере
    scrollTopFn.prepend(() => window.scrollTo({ top: 0, behavior: 'smooth' })),
  ],
});

// скрываем loader - не профиль
sample({
  clock: debounce(({
    source: scrollTopFn,
    timeout: 1150,
  })),
  source: $pathnameUrl,
  filter: (pathnameUrl) => (
    !isCurrentPath(pathnameUrl, PAGES_PATH.PROFILE) && !isCurrentPath(pathnameUrl, PAGES_PATH.BIND)
  ),
  target: hideLoaderPageFn,
});
