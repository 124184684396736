import { createForm } from 'effector-forms';
import { rules } from 'src/lib/rules';
import { REG_STEP5_PHONE, REG_STEP5_CODE } from 'src/dict/fields';
import { CODE_TIMER } from 'src/dict/config';
import {
  sendActivationCodeSign,
  activationCodeSign,
} from 'src/api/signatures/Registration';
import { registrationDomain } from '../index';

// STEP 5 - верификация телефона

/// ///////////////////////////////////////////////////////////
// ================== СЛОВАРИ / КОНСТАНТЫ ================== //
/// ///////////////////////////////////////////////////////////

const { PHONE } = REG_STEP5_PHONE;
const {
  ONE, TWO, THREE, FOUR, FIVE, SIX,
} = REG_STEP5_CODE;

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ СОБЫТИЙ ===================== //
/// ///////////////////////////////////////////////////////////

export const openChangePhonePageFn = registrationDomain.createEvent('openChangePhonePageFn');
export const closeChangePhonePageFn = registrationDomain.createEvent('closeChangePhonePageFn');

export const sendActivationCodeFn = registrationDomain.createEvent('sendActivationCodeFn');
export const checkActivationCodeFn = registrationDomain.createEvent('checkActivationCodeFn');

export const startTimerActivationCodeFn = registrationDomain.createEvent('setTimerActivationCodeFn');
export const stopTimerActivationCodeFn = registrationDomain.createEvent('setTimerActivationCodeFn');

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ХРАНИЛИЦ ==================== //
/// ///////////////////////////////////////////////////////////

export const $codeTest = registrationDomain.createStore('', { name: 'codeTest' });

export const $phoneTemp = registrationDomain.createStore('', { name: 'phoneTemp' });

// токен проверки
export const $tokenSent = registrationDomain.createStore('', { name: 'phoneTemp' });
// оставшееся время до перезагрузки кода
export const $valueTimer = registrationDomain.createStore(CODE_TIMER, { name: 'timerActivationCode' });

export const $timerActivationCode = registrationDomain.createStore(false, { name: 'timerActivationCode' });

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ЭФФЕКТОВ ==================== //
/// ///////////////////////////////////////////////////////////

// отправка кода на верификацию
export const sendActivationCodeFx = registrationDomain.createEffect(sendActivationCodeSign);
// проверка кода на верификацию
export const activationCodeFx = registrationDomain.createEffect(activationCodeSign);

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ЭФФЕКТОВ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

// тут создаются эффекты через attach

/// ///////////////////////////////////////////////////////////
// ====================== ЮНИТЫ ФОРМ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем формы с помощью effector-forms
export const stepPhoneForm = createForm({
  fields: {
    [PHONE]: { rules: [rules.required(), rules.phone()], init: '', units: { $value: $phoneTemp } },
  },
  validateOn: ['blur', 'submit'],
});

export const step5Form = createForm({
  fields: {
    [ONE]: { rules: [rules.required(), rules.number(), rules.maxLength(1)], init: '' },
    [TWO]: { rules: [rules.required(), rules.number(), rules.maxLength(1)], init: '' },
    [THREE]: { rules: [rules.required(), rules.number(), rules.maxLength(1)], init: '' },
    [FOUR]: { rules: [rules.required(), rules.number(), rules.maxLength(1)], init: '' },
    [FIVE]: { rules: [rules.required(), rules.number(), rules.maxLength(1)], init: '' },
    [SIX]: { rules: [rules.required(), rules.number(), rules.maxLength(1)], init: '' },
  },
  validateOn: ['blur', 'submit'],
  domain: registrationDomain,
});

/// ///////////////////////////////////////////////////////////
//  ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ФОРМ + (ХРАНИЛИЩ, ЭФФЕКТОВ) //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ========================= ФАБРИКИ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем различные фабрики и хранилища с использованием фабрик
