import React, { useEffect } from 'react';
import { useUnit } from 'effector-react';
import { useForm } from 'effector-forms';
import { get } from 'src/lib/lodash';
import {
  maskDate,
  maskPassportCodeDepartment,
  maskPassportId,
  maskPassportSeries, maskSnils,
} from 'src/lib/maskPlaceholder';
import { validateSnils } from 'src/lib/legal';
import { getDateReverse } from 'src/lib/date';
import {
  getPropsField, getRefField, jumpFocusField, onSubmit,
} from 'src/lib/form';
import { REG_STEP2_FIELDS } from 'src/dict/fields';
import {
  choiceCodeFMSFn,
  clearCodeFMSFn,
  notChoiceCodeFMSFn,
  step2Form,
} from 'src/models/Registration/Passport';
import { jumpFocusFieldFn, setRefFieldsFn } from 'src/models/Helper/Ref';
import { $itemIdentitiesData, $snils } from 'src/models/User';
import {
  ButtonWithLoader as Button,
  // Gender,
  Input, InputDate, InputFMS,
} from 'src/ui/components';

// const { GENDER } = REG_STEP1_FIELDS;
const {
  PASSPORT_SERIES, PASSPORT_ID, PASSPORT_DATE_ISSUE,
  CODE_DEPARTMENT, PASSPORT_ISSUE_BY, PLACE_BIRTH, SNILS,
} = REG_STEP2_FIELDS;

const methodsPassport = {
  choiceCodeFMSFn,
  clearCodeFMSFn,
  notChoiceCodeFMSFn,
};

export const Form = React.memo(() => {
  const {
    submit, eachValid, ...restProps
  } = useForm(step2Form);

  const [itemIdentitiesData, snils] = useUnit([
    $itemIdentitiesData, $snils,
  ]);

  useEffect(() => {
    const series = get(itemIdentitiesData, PASSPORT_SERIES, '');
    const number = get(itemIdentitiesData, PASSPORT_ID, '');
    const issuedAt = get(itemIdentitiesData, PASSPORT_DATE_ISSUE, '');
    step2Form.setInitialForm({
      ...itemIdentitiesData,
      [SNILS]: snils,
      [PASSPORT_SERIES]: `${series.slice(0, 2)} ${series.slice(2, 4)}`,
      [PASSPORT_ID]: `${number.slice(0, 3)} ${number.slice(3, 6)}`,
      [PASSPORT_DATE_ISSUE]: getDateReverse(issuedAt),
      // [GENDER]: gender,
    });
  }, [itemIdentitiesData]);

  return (
    <>
      <form autoComplete="off" onSubmit={onSubmit(submit)}>
        <div className="block-items three">
          <div className="block-item">
            <Input
              label="Серия"
              type="text"
              inputMode="numeric"
              name={PASSPORT_SERIES}
              {...maskPassportSeries}
              {...getPropsField({
                fieldName: PASSPORT_SERIES, props: restProps, // maskPassportSeries
              })}
              ref={getRefField({
                fieldName: PASSPORT_SERIES, callBack: setRefFieldsFn,
              })}
              jumpField={jumpFocusField({
                fieldName: PASSPORT_ID,
                callBack: jumpFocusFieldFn,
                trigger: { name: 'maxLength', data: { value: 5 } },
              })}
              required
            />
          </div>
          <div className="block-item">
            <Input
              label="Номер паспорта"
              type="text"
              inputMode="numeric"
              name={PASSPORT_ID}
              {...maskPassportId}
              {...getPropsField({
                fieldName: PASSPORT_ID, props: restProps, // mask: maskPassportId,
              })}
              ref={getRefField({
                fieldName: PASSPORT_ID, callBack: setRefFieldsFn,
              })}
              jumpField={jumpFocusField({
                fieldName: PASSPORT_DATE_ISSUE,
                callBack: jumpFocusFieldFn,
                trigger: { name: 'maxLength', data: { value: 7 } },
              })}
              required
            />
          </div>
          <div className="block-item">
            <Input
              label="Место рождения"
              type="text"
              name={PLACE_BIRTH}
              {...getPropsField({
                fieldName: PLACE_BIRTH, props: restProps,
              })}
              ref={getRefField({
                fieldName: PLACE_BIRTH, callBack: setRefFieldsFn,
              })}
              required
            />
          </div>
        </div>

        <div className="block-items three">
          <div className="block-item">
            <InputDate
              label="Дата выдачи паспорта"
              type="text"
              inputMode="numeric"
              name={PASSPORT_DATE_ISSUE}
              {...maskDate}
              {...getPropsField({
                fieldName: PASSPORT_DATE_ISSUE, props: restProps,
              })}
              ref={getRefField({
                fieldName: PASSPORT_DATE_ISSUE, callBack: setRefFieldsFn,
              })}
              required
            />
          </div>

          <div className="block-item">
            <InputFMS
              label="Код подразделения"
              type="text"
              inputMode="numeric"
              name={CODE_DEPARTMENT}
              {...maskPassportCodeDepartment}
              {...methodsPassport}
              {...getPropsField({
                fieldName: CODE_DEPARTMENT, props: restProps,
              })}
              ref={getRefField({
                fieldName: CODE_DEPARTMENT, callBack: setRefFieldsFn,
              })}
              jumpField={jumpFocusField({
                fieldName: SNILS,
                callBack: jumpFocusFieldFn,
              })}
              required
            />
          </div>
          <div className="block-item">
            <Input
              label="Кем выдан"
              type="text"
              inputMode="numeric"
              name={PASSPORT_ISSUE_BY}
              {...getPropsField({
                fieldName: PASSPORT_ISSUE_BY, props: restProps,
              })}
              ref={getRefField({
                fieldName: PASSPORT_ISSUE_BY, callBack: setRefFieldsFn,
              })}
              disabled
              required
            />
          </div>
        </div>

        <div className="block-items">
          <div className="block-item">
            <Input
              label="СНИЛС"
              type="text"
              inputMode="numeric"
              name={SNILS}
              {...maskSnils}
              {...getPropsField({
                fieldName: SNILS, props: restProps,
              })}
              ref={getRefField({
                fieldName: SNILS, callBack: setRefFieldsFn,
              })}
              jumpField={jumpFocusField({
                fieldName: PLACE_BIRTH,
                callBack: jumpFocusFieldFn,
                trigger: { name: 'maxLength', data: { value: 14 } },
                validateFunc: validateSnils,
              })}
              required
            />
          </div>
          {/* <div className="block-item block-gender">
            <Gender
              {...getPropsField({
                fieldName: GENDER, props: restProps,
              })}
            />
          </div> */}
        </div>
        <Button
          type="submit"
          className="default-btn block-button-save"
        >
          Сохранить
        </Button>
      </form>
    </>
  );
});
