import { createForm } from 'effector-forms';
import { rules } from 'src/lib/rules';
import { AGREEMENT_DOCS, LOAN_FAILURE_FIELDS } from 'src/dict/fields';
import { applicationDomain } from 'src/models/Loan/Application';
import { addPersonalDataSign } from 'src/api/signatures/Registration';
import { timerSign } from 'src/signatures/Timer';

/// ///////////////////////////////////////////////////////////
// ================== СЛОВАРИ / КОНСТАНТЫ ================== //
/// ///////////////////////////////////////////////////////////

const { AGREEMENT } = LOAN_FAILURE_FIELDS;

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ СОБЫТИЙ ===================== //
/// ///////////////////////////////////////////////////////////

// изменение массива согласий, которые должны быть одобрены
export const changeAgreementDocsFn = applicationDomain.createEvent('changeAgreementDocsF1n');
// при отказе запрашиваем куда и через какое время редиректим
export const getActionRejectFn = applicationDomain.createEvent('signGetActionRejectFn');

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ХРАНИЛИЦ ==================== //
/// ///////////////////////////////////////////////////////////

// массив согласий, которые должны быть одобрены
export const $agreementDocs = applicationDomain.createStore(AGREEMENT_DOCS, { name: 'agreementDocs' });
export const $urlAfterReject = applicationDomain.createStore(AGREEMENT_DOCS, { name: 'urlAfterReject' });

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ЭФФЕКТОВ ==================== //
/// ///////////////////////////////////////////////////////////

export const addPersonalDataFx = applicationDomain.createEffect(addPersonalDataSign);

export const timerAfterRejectFx = applicationDomain.createEffect(timerSign);

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ЭФФЕКТОВ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

// тут создаются эффекты через attach

/// ///////////////////////////////////////////////////////////
// ====================== ЮНИТЫ ФОРМ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем формы с помощью effector-forms
export const failureForm = createForm({
  fields: {
    [AGREEMENT]: { rules: [rules.requiredAgreementCheckbox()], init: false },
  },
  validateOn: ['submit'],
  domain: applicationDomain,
});

/// ///////////////////////////////////////////////////////////
//  ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ФОРМ + (ХРАНИЛИЩ, ЭФФЕКТОВ) //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ========================= ФАБРИКИ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем различные фабрики и хранилища с использованием фабрик
