import React, { useEffect } from 'react';
import { useUnit } from 'effector-react';
import { isEmpty } from 'src/lib/lodash';
import { getValueFromMask, maskPhone } from 'src/lib/mask';
import { $phone } from 'src/models/User';
import {
  // checkActivationCodeFn,
  sendActivationCodeFn,
  $codeTest,
  step5Form,
} from 'src/models/Registration/Verification';
import { CodeForm } from 'src/ui/components';
import { CodeRepeat } from './children/CodeRepeat/CodeRepeat';
import { ChanePhone } from './children/ChanePhone/ChanePhone';
import 'src/ui/styles/VerificationForm.css';

export const VerificationForm = () => {
  const [valuePhone, codeTest] = useUnit([$phone, $codeTest]);

  useEffect(() => {
    sendActivationCodeFn();
  }, []);

  if (isEmpty(valuePhone)) return <div />;

  const phone = getValueFromMask(valuePhone, maskPhone);
  return (
    <>
      <p className="text">
        На ваш номер телефона
        {' '}
        <span className="bold">{phone}</span>
        {' '}
        было отправлено SMS с кодом подтверждения.
        <br />
        Пожалуйста, введите код из SMS:
      </p>

      <CodeForm codeTest={codeTest} form={step5Form} />

      <CodeRepeat
        sendCodeFn={sendActivationCodeFn}
      />

      <ChanePhone />

      <p className="description grey">
        Обратите внимание! Полученный код будет также являться вашим паролем от личного кабинета.
      </p>
    </>
  );
};
