import React, { useEffect } from 'react';
import { useUnit } from 'effector-react';
import { isEmpty } from 'src/lib/lodash';
import { getFormatMoney } from 'src/lib/money';
import { getUnitDay } from 'src/lib/date';
import { $isReceivedLoan } from 'src/models/Loan/Application';
import {
  goToAgreementsFn, getAgreementsApplicationTempFn, getAgreementsFn,
  $agreementItems, $agreementAppItem, // $agreementAppItemTemp,
} from 'src/models/Loan/Agreement';
import { Loans } from './children/Loans/Loans';
import { NotLoans } from './children/NotLoans';

export const List = React.memo(() => {
  const [{
    term: period,
    amount_body: amount,
  }, isReceivedLoan, agreementItems] = useUnit([$agreementAppItem, $isReceivedLoan, $agreementItems]);

  const isLoans = !isEmpty(agreementItems);

  useEffect(() => {
    if (isReceivedLoan) {
      getAgreementsApplicationTempFn();
    }
    getAgreementsFn();
  }, [isReceivedLoan]);

  const styleNotificationColorWait = { background: '#61D679' };
  return (
    <>
      {isReceivedLoan && !isEmpty(period) && (
        <div className="loan block approach full-line">
          <div className="block-notification" style={styleNotificationColorWait}>
            <p>Займ одобрен! Заберите деньги!!</p>
          </div>
          <div className="block-info">
            <div className="block-info-items">
              <div className="block-info-item">
                <div className="block-info-item-icon">
                  <img src="/img/profile/coinIcon.png" alt="" />
                </div>
                <div className="block-info-item-info">
                  <p className="block-info-item-description">Одобренный лимит</p>
                  <p className="block-info-item-title">
                    {getFormatMoney(amount)}
                    ₽
                  </p>
                </div>
              </div>
              <div className="block-info-item term">
                <div className="block-info-item-icon">
                  <img src="/img/profile/dateIcon.png" alt="" />
                </div>
                <div className="block-info-item-info">
                  <p className="block-info-item-description">Одобренный срок</p>
                  <p className="block-info-item-title">
                    {period}
                    {' '}
                    {getUnitDay(period)}
                  </p>
                </div>
              </div>
            </div>
            <div className="block-info-btns">
              {/* <div className="block-info-secondaryBtn">
              &nbsp;
              </div> */}
              <div className="block-info-btn" onClick={goToAgreementsFn}>
                <button type="button" className="default-btn">Забрать деньги</button>
              </div>
            </div>
          </div>
        </div>
      )}

      {isLoans ? <Loans items={agreementItems} /> : <NotLoans />}
    </>
  );
});
