import React from 'react';
import { PAGES_PATH } from 'src/dict/path';

const { REGISTRATION } = PAGES_PATH;

export const UserNotExists = React.memo(({ closeCallback }) => (
  <div className="modal-main user-exist">
    <button
      type="button"
      className="close-modal__btn close-modal"
      onClick={closeCallback}
    >
      <img src="/img/modal/close.svg" alt="" />
    </button>
    <h2 className="modal-title">Клиент с указанными данными не найден.</h2>
    <div className="sub-title">
      <div>
        Если у вас нет аккаунта, вы можете
        {' '}
        <a href={`/${REGISTRATION}`}>
          зарегистрироваться.
        </a>
      </div>
    </div>
  </div>
));
