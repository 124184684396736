import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { getFormatMoney } from 'src/lib/money';
import { getDateReverse, getUnitDay } from 'src/lib/date';
/* import {
  // closeChangeCalcFn,
  // $calcCombineData,
} from 'src/models/Calculator'; */
import {
  getAgreementsApplicationFn,
  $agreementsCombineData,
} from 'src/models/Loan/Agreement';
import { getApplicationFn } from 'src/models/Loan/Application';

// import { openModalFn } from 'src/models/components/Modal';
// import { Calculator } from '../../../../shared/Calculator';

export const InfoParams = React.memo(() => {
  // const { amount, period } = useStore($calcCombineData);
  const {
    termAgreements: period,
    amountBodyAgreements: amount,
    dateAgreements: date,
  } = useStore($agreementsCombineData);

  useEffect(() => {
    getApplicationFn();
    getAgreementsApplicationFn();
  }, []);

  return (
    <>
      <div className="successful-item">
        <h3 className="successful-item__title">
          Вам одобрено:
        </h3>
        <div className="successful-item__block">
          <div className="successful-item__icon">
            <img src="/img/form/coin.svg" alt="" />
          </div>
          <p>
            {getFormatMoney(amount)}
            ₽
          </p>
        </div>
      </div>

      <div className="successful-item">
        <h3 className="successful-item__title">
          Срок займа:
        </h3>
        <div className="successful-item__block">
          <div className="successful-item__icon">
            <img src="/img/form/calendar.svg" alt="" />
          </div>
          <p>
            {period}
            {' '}
            {getUnitDay(period)}
          </p>
        </div>
      </div>

      <div className="info">
        <div className="info-item">
          <p className="info-item__title">Вы вернете:</p>
          <p>
            {getFormatMoney(amount)}
            ₽
          </p>
        </div>
        <div className="info-item">
          <p className="info-item__title">Одним платежом до:</p>
          <p>{getDateReverse(date)}</p>
        </div>
      </div>
      {/*
      <a
        className="btn-change"
        onClick={openModalFn.prepend(() => ({
          className: 'modal-changeAmountAndTerm',
          content: Calculator,
          // указываем свое событие на закрытие модального окна
          closeCallback: closeChangeCalcFn,
        }))}
      >
        Изменить сумму и срок
      </a>
      */}
    </>
  );
});
