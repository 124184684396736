import React from 'react';
import { get } from 'src/lib/lodash';
import { formatISODateLoan } from 'src/lib/date';
import { getPaymentType } from 'src/lib/payment';

export const Intent = ({ item, itemsPaymentForExLoan }) => {
  const {
    amount,
    created_at: createdAt,
    paymentable_type: paymentableType,
    paymentable_id: paymentableId,
  } = item;

  let strRepay = 'Оплата с ';
  if (paymentableType === 'bank_card') {
    const firstChar = get(
      itemsPaymentForExLoan, `${paymentableId}.paymentable_data.first_six`, '**** **',
    );
    const typePayment = getPaymentType(firstChar).toUpperCase();
    const cardNumber = get(
      itemsPaymentForExLoan, `${paymentableId}.paymentable_data.last_four`, '****',
    );
    strRepay += `карты ${typePayment} ** **** ${cardNumber}`;
  }

  return (
    <>
      <div className="block-history-item">
        <p className="block-history-sum">
          {(amount / 100) }
          {' '}
          ₽
        </p>
        <p className="block-history-text">{strRepay}</p>
        <p className="block-history-date">{formatISODateLoan(createdAt)}</p>
      </div>
    </>
  );
};
