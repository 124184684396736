import { get, isEmpty, isObject } from 'src/lib/lodash';
import { storage } from 'src/lib/storage';
import { helperDomain } from 'src/models/Helper';

/// ///////////////////////////////////////////////////////////
// ================== СЛОВАРИ / КОНСТАНТЫ ================== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ СОБЫТИЙ ===================== //
/// ///////////////////////////////////////////////////////////

export const storageSetFn = helperDomain.createEvent('storageSetFn');

export const storageRemoveFn = helperDomain.createEvent('storageRemoveFn');

export const storageClearFn = helperDomain.createEvent('storageClearFn');

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ХРАНИЛИЦ ==================== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ЭФФЕКТОВ ==================== //
/// ///////////////////////////////////////////////////////////

export const storageSetFx = helperDomain.createEffect((value) => {
  if (isObject(value)) {
    Object.keys(value).forEach((key) => {
      storage.set(key, get(value, key, ''));
    });
    return {};
  }
  const val = storage.get(value);
  return isEmpty(val) ? '' : val;
});

export const storageRemoveFx = helperDomain.createEffect((val) => {
  if (Array.isArray(val)) {
    val.forEach((key) => {
      storage.remove(key);
    });
  } else {
    storage.remove(val);
  }
});

export const storageClearFx = helperDomain.createEffect(() => storage.clear());

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ЭФФЕКТОВ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

// тут создаются эффекты через attach

/// ///////////////////////////////////////////////////////////
// ====================== ЮНИТЫ ФОРМ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем формы с помощью effector-forms

/// ///////////////////////////////////////////////////////////
//  ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ФОРМ + (ХРАНИЛИЩ, ЭФФЕКТОВ) //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ========================= ФАБРИКИ ======================= //
/// ///////////////////////////////////////////////////////////
