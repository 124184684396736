import { attach, sample } from 'effector';
import { spread } from 'patronum/spread';
import { get } from 'src/lib/lodash';
import { getCurrentTime } from 'src/lib/date';
import { RECOVERY_CODE_FIELDS, RECOVERY_INFO_FIELDS } from 'src/dict/fields';
import { PAGES_PATH, RECOVERY_PATH } from 'src/dict/path';
import { CODE_TIMER } from 'src/dict/config';
import { pushHistoryFn } from 'src/models/Helper/History';
import { notifyErrorFn } from 'src/models/Helper/Notification';
import { showLoaderButtonFn, hideLoaderButtonFn } from 'src/models/Helper/Loader';

import {
  lockButtonFn, unLockButtonFn,
} from 'src/models/Registration';
import { closeModalFn, openModalFn } from 'src/models/components/Modal';
import {
  UserNotExists,
} from 'src/pages/children/Recovery/children/Step1/children/Recovery/children/children/UserNotExists';
// import { getDataCodeConfirm } from 'src/models/Helper/helper';
// import { jumpFocusFieldFn } from 'src/models/Helper/Ref';
import {
  sendActivationCodeRecoveryFx, activationCodeRecoveryFx,
  sendActivationCodeFn, stopTimerActivationCodeFn, startTimerActivationCodeFn,
  $tokenSent, $timerActivationCode, $valueTimer,
  stepInfoRecoveryForm, stepCodeRecoveryForm, $codeTest,
} from './index';

const { RECOVERY } = PAGES_PATH;
const { CHANGE } = RECOVERY_PATH;

const { PHONE, LAST_NAME } = RECOVERY_INFO_FIELDS;
const {
  ONE, TWO, THREE, FOUR, FIVE, SIX,
} = RECOVERY_CODE_FIELDS;

/// ///////////////////////////////////////////////////////////
// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
/// ///////////////////////////////////////////////////////////

$timerActivationCode
  .on(startTimerActivationCodeFn, () => true)
  .on(stopTimerActivationCodeFn, () => false);

/// ///////////////////////////////////////////////////////////
// ===================== БЛОК ОПЕРАТОРОВ =================== //
/// ///////////////////////////////////////////////////////////

sample({
  clock: stepInfoRecoveryForm.formValidated,
  target: [
    lockButtonFn,
    showLoaderButtonFn,
    sendActivationCodeRecoveryFx.prepend((data) => ({
      [PHONE]: `${Number(data[PHONE].replace(/\D+/g, ''))}`,
      [LAST_NAME]: data[LAST_NAME],
    })),
  ],
});

sample({
  clock: sendActivationCodeRecoveryFx.finally,
  target: [
    unLockButtonFn,
    hideLoaderButtonFn,
  ],
});

sample({
  clock: sendActivationCodeFn,
  target: [
    attach({
      effect: sendActivationCodeRecoveryFx,
      source: [
        stepInfoRecoveryForm.fields[PHONE].$value,
        stepInfoRecoveryForm.fields[LAST_NAME].$value,
      ],
      mapParams: (_, [phone, lastName]) => ({
        [PHONE]: `${Number(phone.replace(/\D+/g, ''))}`,
        [LAST_NAME]: lastName,
      }),
    }),
    showLoaderButtonFn,
    lockButtonFn,
  ],
});

sample({
  clock: sendActivationCodeRecoveryFx.fail,
  target: openModalFn.prepend(() => ({
    className: 'user-not-exist',
    content: UserNotExists,
    // указываем свое событие на закрытие модального окна
    closeCallback: closeModalFn,
  })),
  /* notifyErrorFn.prepend(
    (data) => get(data, 'error.response.data.message', ''),
  ), */
});

sample({
  clock: sendActivationCodeRecoveryFx.doneData,
  fn: (data) => {
    const nextAttemptAt = get(data, 'data.next_attempt_at', '');

    const isSent = get(data, 'data.is_sent', '');
    const tokenSent = get(data, 'data.token', '');
    const code = get(data, 'data.code', '');

    const dateParts = nextAttemptAt.split('T');
    // console.log('dateParts:', dateParts);
    if (dateParts.length < 2) {
      return {};
    }
    const timeParts = dateParts[1].split('+');
    const time = timeParts[0];
    const secondsParts = time.split(':');
    const seconds = Number.parseInt(secondsParts[2], 10);

    const currentTimer = getCurrentTime();
    const currentSecondsParts = currentTimer.split(':');
    const currentSeconds = Number.parseInt(currentSecondsParts[2], 10);

    const valueTimer = isSent ? CODE_TIMER
      : (seconds < currentSeconds
        ? (seconds + 59 - currentSeconds) : (seconds - currentSeconds));
    return { valueTimer, tokenSent, code };
  },
  target:
    [
      spread({
        targets: {
          valueTimer: $valueTimer,
          tokenSent: $tokenSent,
          code: $codeTest,
        },
      }),
      startTimerActivationCodeFn,
    ],
});

// при копировании в поле нескольких цифр
/* sample({
  clock: sample({
    clock: stepCodeRecoveryForm.$values,
    filter: (values) => Object.values(values).some((value) => `${value}`.length > 1),
  }),
  source: stepCodeRecoveryForm.$values,
  fn: (values) => {
    const [field, val] = Object.entries(values).find(([, value]) => value.length > 1);
    return getDataCodeConfirm(field, val, values);
  },
  target: [
    jumpFocusFieldFn.prepend(() => SIX),
    stepCodeRecoveryForm.setForm,
  ],
}); */

sample({
  clock: stepCodeRecoveryForm.formValidated,
  target: [
    // создаем заявку
    attach({
      effect: activationCodeRecoveryFx,
      source: [
        $tokenSent,
        stepInfoRecoveryForm.fields[PHONE].$value,
        stepInfoRecoveryForm.fields[LAST_NAME].$value,
      ],
      mapParams: (form, [tokenSent, phone, lastName]) => ({
        [PHONE]: `${Number(phone.replace(/\D+/g, ''))}`,
        [LAST_NAME]: lastName,
        token: tokenSent,
        code: `${form[ONE]}`
            + `${form[TWO]}`
            + `${form[THREE]}`
            + `${form[FOUR]}`
            + `${form[FIVE]}`
            + `${form[SIX]}`,
      }),
    }),
  ],
});

sample({
  clock: activationCodeRecoveryFx.done,
  target: [
    stepInfoRecoveryForm.reset,
    stepCodeRecoveryForm.reset,
    pushHistoryFn.prepend(() => `/${RECOVERY}/${CHANGE}`),
  ],
});

sample({
  clock: activationCodeRecoveryFx.fail,
  target: notifyErrorFn.prepend(
    (data) => {
      const status = get(data, 'error.request.status', '');
      if (status === 410) {
        return 'Срок действия введенного SMS кода истек! Пожалуйста, запросите новый!';
      }
      return get(data, 'error.response.data.message', '');
    },
  ),
});
