import { combine } from 'effector';
import { isCurrentPath } from 'src/lib/url';
import { $pathnameUrl, appDomain } from 'src/models/App';
import { $userId } from 'src/models/User';
import { PROFILE_LOAN_PATH, PROFILE_PATH } from 'src/dict/path';

export const loanDomain = appDomain.createDomain('Loan');

const { LOAN, PROFILE } = PROFILE_PATH;
const { NEW_LOAN, LOANS, TO_REPAY } = PROFILE_LOAN_PATH;

/// ///////////////////////////////////////////////////////////
// ================== СЛОВАРИ / КОНСТАНТЫ ================== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ СОБЫТИЙ ===================== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ХРАНИЛИЦ ==================== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ЭФФЕКТОВ ==================== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

export const $isProfileLoanPage = $pathnameUrl.map(
  (path) => {
    const arrPath = path.split('/');
    return (isCurrentPath(path, PROFILE) && isCurrentPath(path, LOAN) && arrPath[2] === LOAN);
  },
);

export const $routeItems = combine(
  $userId, () => [
    {
      label: 'Мои займы',
      path: LOANS,
      active: true,
      visible: true,
      props: {
        icon1: 'icon2.svg',
        icon2: 'icon2-black.svg',
      },
    },
    {
      label: '',
      path: NEW_LOAN,
      active: true,
      visible: false,
      // disable: true,  по умолчанию false
    },
    {
      label: '',
      path: TO_REPAY,
      active: true,
      visible: false,
      // disable: true,  по умолчанию false
    },
  ].filter(({ active }) => active),
);

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ЭФФЕКТОВ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

// тут создаются эффекты через attach

/// ///////////////////////////////////////////////////////////
// ====================== ЮНИТЫ ФОРМ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем формы с помощью effector-forms

/// ///////////////////////////////////////////////////////////
//  ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ФОРМ + (ХРАНИЛИЩ, ЭФФЕКТОВ) //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ========================= ФАБРИКИ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем различные фабрики и хранилища с использованием фабрик
