import React from 'react';
import { get, isEmpty } from 'src/lib/lodash';

export const Textarea = React.forwardRef(({
  value, onChange, onBlur, // приходят из getPropsField - формы эффектора
  placeholder, name, label, type, tabIndex, typeInput,
  mask, maskPlaceholder, maskPattern, jumpField,
  required, errorText, hasError, disabled, isTextError, ...restProps
}, ref) => {
  const hasFocused = name === get(document, 'activeElement.id', false);
  const fieldLabel = `${label}${(required ? '*' : '')}`;

  const nameClasses = [];
  if (required && (hasError || (!isEmpty(`${value}`.trim()) && !hasFocused))) {
    nameClasses.push(hasError ? 'invalid' : ' valid');
  }

  const isDate = typeInput === 'date';
  if (isDate) {
    nameClasses.push('datepickerBlock');
  }
  return (
    <>
      <div className={`default-input ${nameClasses.join(' ')}`}>
        <textarea
          {...restProps}
          tabIndex={tabIndex}
          placeholder={placeholder}
          required={required}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          id={name}
          ref={ref}
          rows="4"
          value={(isEmpty(value) ? '' : value)}
        />
        <label>{fieldLabel}</label>

        {isDate && (
        <div className="icon">
          <img src="/img/icons/calendar.svg" alt="" />
        </div>
        )}
      </div>
      {required
      && isTextError
      && hasError
      && !isEmpty(errorText)
      && <p className="invalid-text">{errorText}</p>}
    </>
  );
});

Textarea.defaultProps = {
  tabIndex: 0,
  name: '',
  label: '',
  typeInput: '',
  placeholder: ' ',
  value: '',
  hasError: false,
  errorText: '',
  onChange: () => '',
  onBlur: () => '',
  jumpField: () => '',
  required: false,
  disabled: false,
  isTextError: true,
  mask: '',
  maskPlaceholder: '',
  maskPattern: '',
};
