import React from 'react';
import { closeModalFn } from 'src/models/components/Modal';
import { ButtonWithLoader as Button } from 'src/ui/components';

const styleButtons = {
  padding: '30px',
  paddingBottom: 0,
};

const handlerRemoveCard = (successCallback) => () => {
  successCallback();
  closeModalFn();
};

export const RemoveCard = ({ closeCallback, successCallback, numberCard }) => (
  <>
    <div className="modal-main">
      <button
        type="button"
        className="close-modal__btn close-modal"
        onClick={closeCallback}
      >
        <img src="/img/modal/close.svg" alt="" />
      </button>

      <div className="modal-wrapper">
        <h2 className="modal-title">Удаление карты</h2>
        <p className="modal-text">{`Вы действительно хотите удалить карту ${numberCard}?`}</p>
      </div>

      <div className="buttons" style={styleButtons}>
        <div className="button">
          <Button
            className="nextStep"
            onClick={handlerRemoveCard(successCallback)}
          >
            Удалить
          </Button>
        </div>
      </div>
    </div>
  </>
);
