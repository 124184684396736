import { combine } from 'effector';
import { findObjectWithMinProp } from 'src/lib/payment';
import { PRODUCT_FIELDS } from 'src/dict/fields';
import { dictionariesDomain } from 'src/models/App';
import {
  getProductsCalcSign,
  getProductsForUserCalcSign,
} from 'src/api/signatures/Calculator';
import { isEmpty } from '../../lib/lodash';

/// ///////////////////////////////////////////////////////////
// ================== СЛОВАРИ / КОНСТАНТЫ ================== //
/// ///////////////////////////////////////////////////////////

const { RATE, PRIORITY, GRACE_RATE } = PRODUCT_FIELDS;

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ СОБЫТИЙ ===================== //
/// ///////////////////////////////////////////////////////////

// export const openCalculatorPageFn = calculatorDomain.createEvent('openCalculatorPageFn');
export const closeCalculatorPageFn = dictionariesDomain.createEvent();

// бекаппим текущие значения калькулятора на 1-ом шаге регистрации
export const backupDataCalcFn = dictionariesDomain.createEvent();

// событие обновления бекапа новыми данными в модальном окне для изменения данных
export const changeBackupDataCalcFn = dictionariesDomain.createEvent();

// событие закрытия модального окна для изменения данных
export const closeChangeCalcFn = dictionariesDomain.createEvent();

// событие для загрузки продуктов для калькулятора
export const getProductsCalcFn = dictionariesDomain.createEvent();
export const getPersonalProductsCalcFn = dictionariesDomain.createEvent();

// событие изменения данных в калькуляторе / выбор параметров
export const changeValueCalcAmountFn = dictionariesDomain.createEvent();
export const changeValueCalcPeriodFn = dictionariesDomain.createEvent();

// события присваивания селектора для калькулятора
export const bindCalcAmountFn = dictionariesDomain.createEvent();
export const bindCalcPeriodFn = dictionariesDomain.createEvent();

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ХРАНИЛИЦ ==================== //
/// ///////////////////////////////////////////////////////////

// для хранения возможных продуктов
export const $productsCalc = dictionariesDomain.createStore([]);

// для хранения отфильтрованных продуктов
export const $products = dictionariesDomain.createStore([]);

// текущий продукт
export const $productCalc = dictionariesDomain.createStore({});

// для хранения селектора калькулятора
export const $selectorCalcAmount = dictionariesDomain.createStore('');
export const $selectorCalcPeriod = dictionariesDomain.createStore('');

// для хранения id текущего продукта
export const $productId = dictionariesDomain.createStore('');

export const $amountLimit = dictionariesDomain.createStore(0);

export const $amountMin = dictionariesDomain.createStore(0);
export const $amountMax = dictionariesDomain.createStore(0);
export const $amountStep = dictionariesDomain.createStore(0);
export const $amount = dictionariesDomain.createStore(0);

export const $periodMin = dictionariesDomain.createStore(0);
export const $periodMax = dictionariesDomain.createStore(0);
export const $periodStep = dictionariesDomain.createStore(0);
export const $period = dictionariesDomain.createStore(0);
export const $isChangePeriodInterval = dictionariesDomain.createStore(false);
export const $gracePeriod = dictionariesDomain.createStore(0);
export const $graceOffset = dictionariesDomain.createStore(0);

// для сохранения оригинадьных значений в модальном окна для изменения суммы и срокв займа (1-ый шаг)
export const $amountBackup = dictionariesDomain.createStore(0);
export const $periodBackup = dictionariesDomain.createStore(0);

export const $rate = dictionariesDomain.createStore(0);
export const $graceRate = dictionariesDomain.createStore(0);
export const $countDaysInYear = dictionariesDomain.createStore((() => {
  let count = 0;
  const year = (new Date()).getFullYear();
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < 13; i++) {
    count += new Date(year, i, 0).getDate();
  }
  return count;
})(), { name: 'countDays' });

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ЭФФЕКТОВ ==================== //
/// ///////////////////////////////////////////////////////////

// загрузка продуктов для калькулятора
export const getProductsCalcFx = dictionariesDomain.createEffect(getProductsCalcSign);
// персональные продукты текущего клиента
export const getProductsForUserCalcFx = dictionariesDomain.createEffect(getProductsForUserCalcSign);

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

// при изменении суммы автоматом расчитываем сумму возврата
/* export const $amountRefund = combine($amount, $rate, $period, $countDaysInYear,
  (amount, rate, period, countDaysInYear) => (
    amount + (amount * rate / countDaysInYear * (period - 1)))); */
export const $amountRefund = combine($products, $amount, $period, $countDaysInYear,
  (products, amount, period, countDaysInYear) => {
    const item = findObjectWithMinProp(products, PRIORITY);
    if (!isEmpty(item) && item[GRACE_RATE] !== null) {
      return amount + (amount * item[RATE] / countDaysInYear * (period - 1));
    }
    return null;
  });

// при изменении суммы автоматом расчитываем сумму возврата
/* export const $amountGraceRefund = combine($amount, $graceRate, $period, $countDaysInYear,
  (amount, graceRate, period, countDaysInYear) => (
    amount + (amount * graceRate / countDaysInYear * (period - 1)))); */

export const $amountGraceRefund = combine($products, $amount, $period, $countDaysInYear,
  (products, amount, period, countDaysInYear) => {
    const item = findObjectWithMinProp(products, PRIORITY);
    if (!isEmpty(item)) {
      if (item[GRACE_RATE] !== null) {
        return amount + (amount * item[GRACE_RATE] / countDaysInYear * (period - 1));
      }
      const val = amount + (amount * item[RATE] / countDaysInYear * (period - 1));
      // console.log('val:', val);
      return val;
    }
    return null;
  });

// для компактной передачи нескольких хранилищ в компонент
export const $calcCombineData = combine(
  $productsCalc, $rate, $amountRefund, $amountGraceRefund, $graceRate,
  $amountMin, $amountMax, $amountStep, $amount,
  $periodMin, $periodMax, $periodStep, $period, $amountLimit,
  (
    productsCalc, rate, amountRefund, amountGraceRefund, graceRate,
    amountMin, amountMax, amountStep, amount,
    periodMin, periodMax, periodStep, period, amountLimit,
  ) => (
    {
      amountLimit,
      productsCalc,
      rate,
      graceRate,
      amountRefund,
      amountGraceRefund,
      amountMin,
      amountMax,
      amountStep,
      amount,
      periodMin,
      periodMax,
      periodStep,
      period,
    }
  ),
);

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ЭФФЕКТОВ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

// тут создаются эффекты через attach

/// ///////////////////////////////////////////////////////////
// ====================== ЮНИТЫ ФОРМ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем формы с помощью effector-forms

/// ///////////////////////////////////////////////////////////
//  ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ФОРМ + (ХРАНИЛИЩ, ЭФФЕКТОВ) //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ========================= ФАБРИКИ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем различные фабрики и хранилища с использованием фабрик
/*
// фабрика для калькулятора сохранена на случай отдельных случаев
export const createCalculatorApi = () => {
  const bindCalcAmountFn = calculatorDomain.createEvent('createCalcFn');
  const changeValueCalcAmountFn = calculatorDomain.createEvent('changeValueCalcAmountFn');

  const $calcSelector = calculatorDomain.createStore({}, { name: 'calcSelector' });
  const $calcMin = calculatorDomain.createStore(0, { name: 'calcMin' });
  const $calcMax = calculatorDomain.createStore(60000, { name: 'calcMax' });
  const $calcSum = calculatorDomain.createStore(25000, { name: 'calcSum' });
  const $calcStep = calculatorDomain.createStore(500, { name: 'calcStep' });

  const $calcSumOld = $calcSum.map((sum) => (sum + (sum / 100 * 20)));

  const $calcCombineData = combine(
    $calcSelector, $calcSum, $calcSumOld,
    (calcSelector, calcSum, calcSumOld) => ({ calcSelector, calcSum, calcSumOld }),
  );

  $calcSelector
    .on(bindCalcAmountFn, (_, value) => value);

  $calcSum
    .on(changeValueCalcAmountFn, (_, value) => Number.parseInt(get(value, '0', 0), 10));

  sample({
    clock: $calcSelector,
    target: attach({
      source: [$calcMin, $calcMax, $calcSum, $calcStep],
      effect: ([min, max, sum, step], selector) => {
        noUiSlider.create(selector, {
          start: sum,
          range: { min, max },
          step,
          connect: 'lower',
        });
      },
    }),
  });

  return ({
    $calcCombineData,
    bindCalcAmountFn,
    changeValueCalcAmountFn,
  });
};

export const calculatorModelApi = createCalculatorApi();
*/
