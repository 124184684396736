import React from 'react';
import PropTypes from 'prop-types';

// import { config } from 'src/dict/config';
import { ErrorForm } from './children/ErrorForm';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  /* componentDidCatch(error) {
    if (config.NODE_ENV === 'production') {
      console.log('error:', error);
      // Sentry.captureException(error);
    }
  } */

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? <ErrorForm /> : children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ErrorBoundary };
