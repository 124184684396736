import { isEmpty } from 'src/lib/lodash';
import { PAYMENT } from 'src/dict/payment';

export const getPaymentType = (val) => val;
export const getPaymentType2 = (val) => {
  const firstChar = !isEmpty(val[0]) ? val[0] : '';

  let typePayment = '';
  switch (firstChar) {
    case '1':
      typePayment = PAYMENT.MIR;
      break;
    /* case '30':
    case '36':
    case '38':
      typePayment = PAYMENT.DINERS_CLUB;
      break; */
    case '4':
      typePayment = PAYMENT.VISA;
      break;
    /* case '50':
    case '56':
    case '57':
    case '58':
    case '63':
    case '67':
      typePayment = PAYMENT.MAESTRO;
      break; */
    case '5':
    /* case '51':
    case '52':
    case '53':
    case '54':
    case '55': */
      typePayment = PAYMENT.MASTERCARD;
      break;
    /* case '60':
      typePayment = PAYMENT.DISCOVER;
      break;
    case '62':
      typePayment = PAYMENT.UNIONPAY;
      break; */
    default:
      typePayment = PAYMENT.OTHER;
  }
  return typePayment;
};

export const findObjectWithMinProp = (objects, key) => {
  let min = Number.POSITIVE_INFINITY;
  let selectedObject = null;

  // eslint-disable-next-line no-restricted-syntax
  for (const obj of objects) {
    if (obj[key] < min) {
      min = obj[key];
      selectedObject = obj;
    }
  }

  return selectedObject;
};

/*
2-Мир
4- VISA
3- American Express, JCB International, Diners Club
____30,36,38-Diners Club
____31,35-JCB International
____34,37-American Express
5- MasterCard, Maestro
____50,56,57,58-Maestro
____51,52,53,54,55-MasterCard
6- Maestro, China UnionPay, Discover
____60-Discover
____62 - China UnionPay
____63, 67 - Maestro
 */
