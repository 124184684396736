import React from 'react';
import { LINKS_DOCS } from 'src/dict/docs';
import { GetMoneyButton } from 'src/ui/components/Form';

export const Work = React.memo(() => (
  <section className="workSection">
    <div className="wrapper">
      <h2
        className="title fadeInDown"
        data-wow-duration=".7s"
        data-wow-delay=".3s"
      >
        <span className="blue">КексКредит</span>
        {' '}
        работает
      </h2>
      <div className="items">
        <div
          className="item sm sm-1 fadeInUp"
          data-wow-duration=".7s"
          data-wow-delay=".3s"
        >
          <div className="item-bg item-bg__left">
            <img src="/img/main/work/block-bg5.svg" alt="" />
          </div>
          <div className="item-bg item-bg__right">
            <img src="/img/main/work/block-bg6.svg" alt="" />
          </div>

          <div className="item-block">
            <h2 className="item-title">3 мин</h2>
            <p className="item-description">на заявку</p>
          </div>

          <div className="item-bg__bottom">
            <div className="item-bg__main" />
            <div className="item-bg item-bg__left">
              <img src="/img/main/work/block-bg5-bottom.svg" alt="" />
            </div>
            <div className="item-bg item-bg__right">
              <img src="/img/main/work/block-bg6-bottom.svg" alt="" />
            </div>
          </div>
        </div>
        <div
          className="item big fadeInUp"
          data-wow-duration=".7s"
          data-wow-delay=".5s"
        >
          <div className="item-bg item-bg__left">
            <img src="/img/main/work/block-bg1.svg" alt="" />
          </div>
          <div className="item-bg item-bg__right">
            <img src="/img/main/work/block-bg2.svg" alt="" />
          </div>
          <div className="item-block">
            <h2 className="item-title">
              6 809 058
              <span className="cur">₽</span>
            </h2>
            <p className="item-description">выдано сегодня</p>
          </div>
          <div className="item-bg__bottom">
            <div className="item-bg__main" />
            <div className="item-bg item-bg__left">
              <img src="/img/main/work/block-bg1-bottom.svg" alt="" />
            </div>
            <div className="item-bg item-bg__right">
              <img src="/img/main/work/block-bg2-bottom.svg" alt="" />
            </div>
          </div>
        </div>
        <div
          className="item sm sm-2 fadeInUp"
          data-wow-duration=".7s"
          data-wow-delay=".3s"
        >
          <div className="item-bg item-bg__left mobile">
            <img src="/img/main/work/block-bg5.svg" alt="" />
          </div>
          <div className="item-bg item-bg__left desktop">
            <img src="/img/main/work/block-bg3.svg" alt="" />
          </div>
          <div className="item-bg item-bg__right">
            <img src="/img/main/work/block-bg4.svg" alt="" />
          </div>
          <div className="item-block">
            <h2 className="item-title">
              98
              <span className="per">%</span>
            </h2>
            <p className="item-description">одобряемых заявок</p>
          </div>
          <div className="item-bg__bottom">
            <div className="item-bg__main" />
            <div className="item-bg item-bg__left mobile">
              <img src="/img/main/work/block-bg5-bottom.svg" alt="" />
            </div>
            <div className="item-bg item-bg__left desktop">
              <img src="/img/main/work/block-bg3-bottom.svg" alt="" />
            </div>
            <div className="item-bg item-bg__right">
              <img src="/img/main/work/block-bg4-bottom.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div
        className="text-block fadeInUp"
        data-wow-duration=".7s"
        data-wow-delay=".3s"
      >
        <p className="text">
          КексКредит предоставляет современный и выгодный онлайн сервис по получению займов.
        </p>
        <p className="text">
          Статус МКК присваивается Центральным Банком РФ и свидетельствует
          о высокой надежности микрофинансовой организации.
        </p>
      </div>
      <div
        className="buttons fadeIn"
        data-wow-duration=".7s"
        data-wow-delay=".5s"
      >
        <a
          href={LINKS_DOCS.MKK}
          target="_blank"
          download
          className="default-btn border"
          rel="noreferrer"
        >
          <span className="icon">
            <img
              src="/img/icons/pdf.svg"
              alt=""
            />
          </span>
          {' '}
          Свидетельство МКК
          {' '}
          <span className="grey">(210 kb)</span>
        </a>
        <a
          target="_blank"
          href={LINKS_DOCS.CPO}
          download
          className="default-btn border"
          rel="noreferrer"
        >
          <span className="icon">
            <img
              src="/img/icons/pdf.svg"
              alt=""
            />
          </span>
          {' '}
          Свидетельство СРО
          {' '}
          <span className="grey">(746 kb)</span>
        </a>
        <GetMoneyButton ym="GET_MONEY_LANDING_MID_BT" />
      </div>
    </div>
  </section>
));
