import React from 'react';

export const CodeCopy = React.memo(({ code }) => {
  const styleCode = { fontWeight: '900', marginTop: '5px' };
  return (
    <>
      <div className="validation-inputs">
        <span id="codeConfirmCopy" style={styleCode}>{code}</span>
      </div>
    </>
  );
});
