import { axios } from 'src/api/axios';

export const createIntentRepaymentSign = (data) => axios.post('/repayment/intent', data);

export const getStatusRepaymentSign = ({ repaymentIntentId, data }) => {
  const params = new URLSearchParams([...data]);
  return axios.get(`/repayment/intent/${repaymentIntentId}`, { params });
};

export const sendRepaymentIframeSign = ({ repaymentIntentId, data }) => (
  axios.put(`/repayment/intent/${repaymentIntentId}`, data)
);
