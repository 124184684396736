import React from 'react';
import { useGate } from 'effector-react';
import { useHistory } from 'react-router-dom';
import { get } from 'src/lib/lodash';
import { RouteGate } from 'src/models/App';
import { Header } from 'src/ui/blocks/Header/Header';
import { Footer } from 'src/ui/blocks/Footer/Footer';

const LoaderLayout = ({ children }) => {
  useGate(RouteGate, {
    pathname: get(window, 'location.pathname', ''),
    history: useHistory(),
  });

  return (
    <div className="loaded not_webp">
      <div className="loaded-box" />

      <div className="otherpage">
        <Header />
        {children}
        <Footer />
      </div>
    </div>
  );
};

export { LoaderLayout };
