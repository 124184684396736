import React from 'react';
import { useUnit } from 'effector-react';
import {
  Route, Switch, withRouter, Redirect,
} from 'react-router-dom';
import { get } from 'src/lib/lodash';
import { REG_PATH } from 'src/dict/path';
import {
  $routesStepsRegistration, $classNamesMap,
} from 'src/models/Registration';
import {
  Step1, Step2, Step3, Step4, Step5,
} from './children';
import './Registration.css';

const {
  PERSONAL, PASSPORT, ADDRESS, EXPERIENCE, VERIFICATION,
} = REG_PATH;

const pathMap = {
  [PERSONAL]: Step1,
  [PASSPORT]: Step2,
  [ADDRESS]: Step3,
  [EXPERIENCE]: Step4,
  [VERIFICATION]: Step5,
};

export const RegistrationRoutes = withRouter(({ match: { url } }) => {
  const [{ pageClass, sectionClass }, routes] = useUnit([$classNamesMap, $routesStepsRegistration]);
  const baseUrl = url.slice(-1) === '/' ? url.slice(0, Math.max(0, url.length - 1)) : url;

  return (
    <main className={pageClass}>
      <section className={sectionClass}>
        <div className="wrapper">
          <Switch>

            {routes.map(
              // eslint-disable-next-line no-shadow,react-perf/jsx-no-new-function-as-prop
              ({ path }) => (
                <Route
                  path={`${baseUrl}/${path}`}
                  component={get(pathMap, path, '')}
                  key={path}
                />
              ),
            )}

            <Redirect exact from={`${baseUrl}`} to={`${baseUrl}/${PERSONAL}`} />
            <Redirect to="/" />
          </Switch>
        </div>
      </section>
    </main>
  );
});
