import React, { useEffect } from 'react';
import { useForm } from 'effector-forms';
import { get, isEmpty } from 'src/lib/lodash';
import {
  getPropsField, onSubmit,
  // getRefField,
} from 'src/lib/form';
// import { maskNum } from 'src/lib/maskPlaceholder';
// import { maskNumber } from 'src/lib/mask';
import { REG_STEP5_CODE } from 'src/dict/fields';
// import { onKeyDownFn } from 'src/models/Helper/Keyboard';
// import { setRefFieldsFn } from 'src/models/Helper/Ref';
import { CodeCopy } from 'src/ui/components/Сonfirmation/CodeCopy';
import {
  InputOneChar as Input,
} from 'src/ui/components/Form/Input';
import {
  ButtonWithLoader as Button,
} from 'src/ui/components/Form/Buttons/ButtonWithLoader';

const {
  ONE, TWO, THREE, FOUR, FIVE, SIX,
} = REG_STEP5_CODE;

const arrFieldNames = [ONE, TWO, THREE, FOUR, FIVE, SIX];

const handlerKeydown = (inputElements, index) => (e) => {
  // если код клавиши backspace и текущее поле пусто
  // фокусируем ввод перед текущим. Затем происходит событие
  // который очистит поле ввода «до».
  // eslint-disable-next-line unicorn/prefer-keyboard-event-key
  if (e.keyCode === 8 && e.target.value === '') {
    inputElements[Math.max(0, index - 1)].focus();
  }
};

const handlerInput = (inputElements, index, restProps) => (e) => {
  // взять первый символ ввода
  // это на самом деле ломается, если вы вводите эмодзи, например 👨‍👩‍👧‍👦....
  // но я готов игнорировать безумные практики кода безопасности.
  const arr = e.target.value.split('').filter((item) => !isEmpty(item) && item !== ' ');
  const [first, ...rest] = arr;
  if (![...'0123456789'].includes(first)) {
    return;
  }
  // first будет неопределенным при вводе backspace, поэтому установите для ввода значение ""
  e.target.value = first ?? '';

  const field = arrFieldNames[index];
  const { onChange } = getPropsField({
    fieldName: field, props: restProps,
  });

  onChange(e.target.value);

  const lastInputBox = index === inputElements.length - 1;
  const insertedContent = first !== undefined;
  if (insertedContent && !lastInputBox) {
    // продолжаем вводить остальную часть строки
    inputElements[index + 1].focus();
    inputElements[index + 1].value = rest.join('');
    inputElements[index + 1].dispatchEvent(new Event('input'));
  }
};

export const CodeForm = ({ form, codeTest }) => {
  const { submit, eachValid, ...restProps } = useForm(form);
  const inValidCode = Object.values(REG_STEP5_CODE).some((field) => (
    get(restProps, 'hasError', () => '')(field)
  ));

  useEffect(() => {
    const inputElements = [...document.querySelectorAll('input.code-input')];

    inputElements.forEach((ele, index) => {
      ele.addEventListener('keydown', handlerKeydown(inputElements, index), false);
      ele.addEventListener('input', handlerInput(inputElements, index, restProps), false);
    });

    inputElements[0].focus();
    return (
      inputElements.forEach((ele) => {
        ele.removeEventListener('keydown', handlerKeydown, false);
        ele.removeEventListener('input', handlerInput, false);
      })
    );
  }, []);

  return (
    <>
      {!isEmpty(codeTest) && <CodeCopy code={codeTest} />}

      <form onSubmit={onSubmit(submit)}>
        <div className="validation-inputs">
          <Input
            className="validation-input"
            type="text"
            inputMode="numeric"
            name={ONE}
            {...getPropsField({
              fieldName: ONE, props: restProps,
            })}
            /* ref={getRefField({
              fieldName: ONE, callBack: setRefFieldsFn,
            })}
            jumpField={jumpFocusField({
              fieldName: TWO,
              callBack: jumpFocusFieldFn,
              trigger: { name: 'maxLength', data: { value: 1 } },
            })}
            onKeyDown={onKeyDownFn.prepend((e) => ({
              fields: { current: ONE, next: TWO },
              form: restProps,
              event: e,
            }))} */
            required
          />

          <Input
            className="validation-input"
            name={TWO}
            type="text"
            inputMode="numeric"
            {...getPropsField({
              fieldName: TWO, props: restProps,
            })}
            /* ref={getRefField({
              fieldName: TWO, callBack: setRefFieldsFn,
            })}
            jumpField={jumpFocusField({
              fieldName: THREE,
              callBack: jumpFocusFieldFn,
              trigger: { name: 'maxLength', data: { value: 1 } },
            })}
            onKeyDown={onKeyDownFn.prepend((e) => ({
              fields: { current: TWO, prev: ONE, next: THREE },
              form: restProps,
              event: e,
            }))} */
            required
          />

          <Input
            className="validation-input"
            name={THREE}
            type="text"
            inputMode="numeric"
            {...getPropsField({
              fieldName: THREE, props: restProps,
            })}
            /*  ref={getRefField({
               fieldName: THREE, callBack: setRefFieldsFn,
             })}
             jumpField={jumpFocusField({
               fieldName: FOUR,
               callBack: jumpFocusFieldFn,
               trigger: { name: 'maxLength', data: { value: 1 } },
             })}
             onKeyDown={onKeyDownFn.prepend((e) => ({
               fields: { current: THREE, prev: TWO, next: FOUR },
               form: restProps,
               event: e,
             }))} */
            required
          />

          <Input
            className="validation-input"
            name={FOUR}
            type="text"
            inputMode="numeric"
            {...getPropsField({
              fieldName: FOUR, props: restProps,
            })}
            /* ref={getRefField({
              fieldName: FOUR, callBack: setRefFieldsFn,
            })}
            jumpField={jumpFocusField({
              fieldName: FIVE,
              callBack: jumpFocusFieldFn,
              trigger: { name: 'maxLength', data: { value: 1 } },
            })}
            onKeyDown={onKeyDownFn.prepend((e) => ({
              fields: { current: FOUR, prev: THREE, next: FIVE },
              form: restProps,
              event: e,
            }))} */
            required
          />

          <Input
            className="validation-input"
            name={FIVE}
            type="text"
            inputMode="numeric"
            {...getPropsField({
              fieldName: FIVE, props: restProps,
            })}
            /* ref={getRefField({
              fieldName: FIVE, callBack: setRefFieldsFn,
            })}
            jumpField={jumpFocusField({
              fieldName: SIX,
              callBack: jumpFocusFieldFn,
              trigger: { name: 'maxLength', data: { value: 1 } },
            })}
            onKeyDown={onKeyDownFn.prepend((e) => ({
              fields: { current: FIVE, prev: FOUR, next: SIX },
              form: restProps,
              event: e,
            }))} */
            required
          />

          <Input
            className="validation-input"
            name={SIX}
            type="text"
            inputMode="numeric"
            {...getPropsField({
              fieldName: SIX, props: restProps,
            })}
            /* ref={getRefField({
              fieldName: SIX, callBack: setRefFieldsFn,
            })}
            onKeyDown={onKeyDownFn.prepend((e) => ({
              fields: { current: SIX, prev: FIVE },
              form: restProps,
              event: e,
            }))} */
            required
          />

        </div>
        {inValidCode && (
          <p className="invalid-text">Пожалуйста, введите код из SMS</p>
        )}
        <Button type="submit">Подтвердить</Button>
      </form>
    </>
  );
};
