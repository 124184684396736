import { axios } from 'src/api/axios';

export const getConfigDictSign = () => axios.get('/dictionaries/config');
export const getMessagesDictSign = () => axios.get('/dictionaries/messages/ru');

export const getPurposesDictSign = () => axios.get('/dictionaries/purposes');

export const getEducationsDictSign = () => axios.get('/dictionaries/educations');

export const getMaritalStatusesDictSign = () => axios.get('/dictionaries/marital-statuses');

export const getWorkScopesDictSign = () => axios.get('/dictionaries/work-scopes');

export const getEmploymentsDictSign = () => axios.get('/dictionaries/employments');

export const getMessagesDictSignMock = async () => {
  await new Promise((r) => setTimeout(r, 400));
  return [
    { 'error.0': 'text 0' },
    { 'error.1': 'text 1' },
    { 'error.2': 'text 2' },
  ];
};
