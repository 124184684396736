import React from 'react';

export const Services = React.memo(() => (
  <div className="block">
    <div className="block-wrapper">
      <div className="block-top">
        <div className="block-info">
          <h2 className="block-title">Подписка</h2>
          <p className="block-description">Недельный план</p>
        </div>
        <div className="block-sum">399 ₽</div>
      </div>
      <div className="block-main">
        <p className="block-item">Быстрый кредит всего за 10 минут</p>
        <p className="block-item">Еженедельные выплаты без задержек</p>
        <p className="block-item">Моментальные кредиты</p>
        <p className="block-item">Гибкие условия и широкий выбор компаний</p>
        <div className="block-btn">
          <a href="#" className="default-btn border">Отменить</a>
        </div>
      </div>
    </div>
  </div>
));
