import React from 'react';
import { useUnit } from 'effector-react';
import { isEmpty } from 'src/lib/lodash';
import { getDateReverse } from 'src/lib/date';
import { GENDER_VALUES } from 'src/dict/fields/registration';
import {
  $gender, $snils, $itemIdentitiesData,
} from 'src/models/User';
import {
  getValueFromMask, hideNumberPassport, hideSeriesPassport, hideSnils,
  maskSnils,
} from 'src/lib/mask';

export const View = React.memo(() => {
  const [itemIdentitiesData, gender, snils] = useUnit([$itemIdentitiesData, $gender, $snils]);
  if (isEmpty(itemIdentitiesData)) return <div>Загрузка..</div>;

  const {
    birth_place: birthPlace,
    issued_at: issuedAt,
    issued_by: issuedBy,
    number: numberPassport,
    series,
    subdivision,
  } = itemIdentitiesData;
  const valSnils = !isEmpty(snils) ? getValueFromMask(snils, maskSnils) : '-';
  return (
    <>
      <div className="block-items three">
        <div className="block-item">
          <div className="block-item-info">
            <p className="block-label">Серия</p>
            <p className="block-text">{hideSeriesPassport(series)}</p>
          </div>
        </div>
        <div className="block-item">
          <div className="block-item-info">
            <p className="block-label">Номер паспорта</p>
            <p className="block-text">{hideNumberPassport(numberPassport)}</p>
          </div>
        </div>
        <div className="block-item">
          <div className="block-item-info">
            <p className="block-label">Место рождения</p>
            <p className="block-text">{birthPlace}</p>
          </div>
        </div>
      </div>

      <div className="block-items three">
        <div className="block-item">
          <div className="block-item-info">
            <p className="block-label">Дата выдачи паспорта</p>
            <p className="block-text">{getDateReverse(issuedAt)}</p>
          </div>
        </div>
        <div className="block-item">
          <div className="block-item-info">
            <p className="block-label">Код подразделения</p>
            <p className="block-text">{subdivision}</p>
          </div>
        </div>
        <div className="block-item">
          <div className="block-item-info">
            <p className="block-label">Ваш пол</p>
            <p className="block-text">{GENDER_VALUES[gender]}</p>
          </div>
        </div>
      </div>

      <div className="block-items three">
        <div className="block-item">
          <div className="block-item-info">
            <p className="block-label">Кем выдан</p>
            <p className="block-text">{issuedBy}</p>
          </div>
        </div>
      </div>

      <div className="block-items three">
        <div className="block-item">
          <div className="block-item-info">
            <p className="block-label">СНИЛС</p>
            <p className="block-text">{hideSnils(valSnils)}</p>
          </div>
        </div>
      </div>
    </>
  );
});
