import React from 'react';
import { useUnit } from 'effector-react';
import { $isCodePage } from 'src/models/Recovery';
import { Recovery } from './children/Recovery/Recovery';
import { Confirm } from './children/Confirm/Confirm';

export const Step1 = React.memo(() => {
  const isCodePage = useUnit($isCodePage);

  return (
    <>
      {isCodePage ? <Confirm /> : <Recovery />}
    </>
  );
});
