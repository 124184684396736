export const PRODUCT_FIELDS = {
  PRODUCT_ID: 'id',
  NAME: 'name',
  DESCRIPTION: 'description',
  TYPE: 'type',
  PERIOD_DEFAULT: 'term',
  PERIOD_MIN: 'term_min',
  PERIOD_MAX: 'term_max',
  PERIOD_STEP: 'term_step',
  // GRACE_PERIOD: 'term_step',

  AMOUNT_DEFAULT: 'sum',
  AMOUNT_MIN: 'sum_min',
  AMOUNT_MAX: 'sum_max',
  AMOUNT_STEP: 'sum_step',

  RATE_MIN: 'rate_min',
  RATE_MAX: 'rate_max',

  STEP: 'step',
  RATE: 'rate',
  GRACE_RATE: 'grace_rate',
  PRIORITY: 'priority',
};
