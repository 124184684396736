import React from 'react';
import {
  Switch, Route, withRouter, Redirect,
} from 'react-router-dom';
import { PROFILE_NEW_LOAN_PATH } from 'src/dict/path';
import { PaymentRoutes } from './children/Payment/PaymentRoutes';
import { Processing } from './children/Processing';
import { Failure } from './children/Failure/Failure';
import { Success } from './children/Success/Success';
import { Agreement } from './children/Agreement/Agreement';
import { Confirm } from './children/Confirm/Confirm';

import './children/Success/Success.css';

const {
  PAYMENT, PROCESSING, FAILURE, SUCCESS, AGREEMENT, CONFIRM,
} = PROFILE_NEW_LOAN_PATH;

export const AddRoutes = withRouter(({ match: { url } }) => {
  const baseUrl = url.slice(-1) === '/' ? url.slice(0, Math.max(0, url.length - 1)) : url;
  return (
    <Switch>
      <Route path={`${baseUrl}/${PAYMENT}`} component={PaymentRoutes} />

      <Route exact path={`${baseUrl}/${PROCESSING}`} component={Processing} />
      <Route path={`${baseUrl}/${SUCCESS}`} component={Success} />
      <Route exact path={`${baseUrl}/${AGREEMENT}`} component={Agreement} />
      <Route exact path={`${baseUrl}/${CONFIRM}`} component={Confirm} />
      <Route exact path={`${baseUrl}/${FAILURE}`} component={Failure} />

      <Redirect exact from={`${baseUrl}`} to={`${baseUrl}/${PROCESSING}`} />
    </Switch>
  );
});
