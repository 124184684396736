import React from 'react';
import { useStore } from 'effector-react';
import { Button } from 'src/ui/components/Form/Buttons/Button/Button';
import { $firstName } from 'src/models/User';
import { getAfterConfirmSuccessPageFn } from 'src/models/Loan/Application/Success';

export const ToLoan = React.memo(({ closeCallback }) => {
  const firstName = useStore($firstName);
  return (
    <div className="modal-main to-loan">
      <button
        type="button"
        className="close-modal__btn close-modal"
        onClick={closeCallback}
      >
        <img src="/img/modal/close.svg" alt="" />
      </button>
      <div className="sub-title">
        <strong>{firstName}</strong>
        , ваш займ успешно подтвержден, пожалуйста, ожидайте поступления денежных средств.
        Зачисление денежных средств происходит в пределах 10 минут
      </div>

      <Button
        type="button"
        className="nextStep"
        onClick={getAfterConfirmSuccessPageFn}
      >
        Хорошо
      </Button>
    </div>
  );
});
