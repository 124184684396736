import React, { useEffect } from 'react';
import { useUnit } from 'effector-react';
import { get, isEmpty } from 'src/lib/lodash';
import { getFormatMoney } from 'src/lib/money';
import { ButtonWithLoader as Button } from 'src/ui/components/Form';
import 'nouislider/dist/nouislider.css';
import {
  bindCalcAmountToRepayFn,
  closeCalculatorToRepayFn,
  $amountToRepay,
  changeValueCalcAmountToRepayFn,
  changeAmountToRepayFn,
  $repaymentIntentItem,
} from 'src/models/Repayment';

export const CalculatorRepayment = React.memo(({ closeCallback }) => {
  const [
    amount, repaymentIntentItem,
  ] = useUnit([$amountToRepay, $repaymentIntentItem]);

  const amountMax = get(repaymentIntentItem, 'amountMax', '');

  const amountMin = get(repaymentIntentItem, 'amountMin', '');
  const agreementId = get(repaymentIntentItem, 'agreement.id', '');

  const amountNext = amountMax - amount;
  const amountDefault = isEmpty(amount) ? get(repaymentIntentItem, 'amount', '') : amount;

  useEffect(() => {
    changeValueCalcAmountToRepayFn([amountMax]);
    bindCalcAmountToRepayFn(document.querySelector('.repaySlider'));
    return () => {
      closeCalculatorToRepayFn();
    };
  }, []);

  return (
    <>
      <div className="modal-main">
        <button
          type="button"
          className="close-modal__btn close-modal"
          onClick={closeCallback}
        >
          <img src="/img/modal/close.svg" alt="" />
        </button>
        <div className="modal-wrapper">
          <h2 className="modal-title">Погашение займа</h2>

          <p className="modal-text">Выберите желаемую сумму погашения займа</p>
          <div className="modal-calc calculatorMain amountCalculator">
            <div className="calculator-top">
              <p className="calculator-text">Сумма погашения</p>
              <div className="calculator-sum">
                <span className="sum">{getFormatMoney(amount)}</span>
                <span className="cur">₽</span>
              </div>
            </div>
            <div className="calculator-slider__block">
              <div
                className="calculator-slider repaySlider"
                data-min={amountMin}
                data-max={amountMax}
                data-start={amountDefault}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="modal-bottom">
        <div className="modal-wrapper">
          <div className="modal-bottom__item">
            <span className="modal-bottom__title">Вы погасите:</span>
            <div className="modal-bottom__info">
              <span className="sum" id="totalSum">{getFormatMoney(amount)}</span>
              <span className="cur"> ₽</span>
            </div>
          </div>

          <div className="modal-bottom__item">
            <span className="modal-bottom__title">Остаток задолженности:</span>
            <div className="modal-bottom__info">
              <span className="sum" id="totalSum">{getFormatMoney(amountNext)}</span>
              <span className="cur">₽</span>
            </div>
          </div>

          <div className="modal-button">
            <Button
              onClick={changeAmountToRepayFn.prepend(() => agreementId)}
              className="close-modal"
            >
              Оплатить
            </Button>
          </div>
        </div>
      </div>
    </>
  );
});
