import React from 'react';

export const MainBlock = React.memo(({ keksBlock, children }) => {
  const styleCss = { textAlign: 'left' };
  return (
    <div className="block">

      {keksBlock()}

      <div className="block-content">
        <div className="block-topBg desktop"><img src="/img/other/topbgblock.svg" alt="" /></div>
        <div className="block-topBg mobile"><img src="/img/other/topbgblock2-mobile.svg" alt="" /></div>

        <div className="block-main">
          <div className="block-wrapper" style={styleCss}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
});
