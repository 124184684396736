import React from 'react';
import { get, isEmpty } from 'src/lib/lodash';
import { getFormatMoney } from 'src/lib/money';
import { formatOtherDate } from 'src/lib/date';
import { ProgressBar } from 'src/ui/components';
import { Intent } from './children/Intent';

const stylePayment = { textAlign: 'center' };
const styleDocs = { marginTop: '14px' };

export const Info = ({
  item, pendingDocs, pendingPayments, itemsPaymentForExLoan,
}) => {
  const {
    amount_body: amount,
    rate: percent,
    date_start: dateStart, date_end: dateEnd,
    agreementDocs,
  } = item;

  const itemsIntent = get(item, 'repayment_intents', []).filter(({ status }) => status === 'success');
  return (
    <div className="block-bottom">

      <div className="block-items">

        <div className="block-item">
          <p className="block-item-description">Сумма займа</p>
          <p className="block-item-title">
            {getFormatMoney(amount)}
            {' '}
            ₽
          </p>
        </div>

        <div className="block-item">
          <p className="block-item-description">Процентная ставка</p>
          <p className="block-item-title">
            {percent}
            {' '}
            %
          </p>
        </div>

        <div className="block-item">
          <p className="block-item-description">Дата оформления займа</p>
          <p className="block-item-title">{formatOtherDate(dateStart)}</p>
        </div>

        <div className="block-item">
          <p className="block-item-description">Дата возврата займа</p>
          <p className="block-item-title">{formatOtherDate(dateEnd)}</p>
        </div>

      </div>

      <div className="block-line" />

      <div className="block-history">
        <h3 className="block-subtitle">История оплат</h3>

        {/* Заменить на данные когда будет история оплат */}
        {!isEmpty(itemsIntent) ? (
          <div className="block-history-items">
            {itemsIntent.map((itemIntent) => (
              <Intent
                item={itemIntent}
                itemsPaymentForExLoan={itemsPaymentForExLoan}
              />
            ))}
          </div>
        )
          : (
            <>
              {pendingPayments ? <ProgressBar />
                : <div className="block-history-items" style={stylePayment}>Оплат еще нет</div>}
            </>
          )}
      </div>
      <br />

      <div className="block-line" />

      <div className="block-history">
        <h3 className="block-subtitle">Документы по договору</h3>

        {pendingDocs && isEmpty(agreementDocs) && <ProgressBar />}
        <div className="block-history-items" style={styleDocs}>

          {agreementDocs.map(({ link, name }) => (
            <div className="modal" key={link}>
              -
              {' '}
              <a
                className="default-checkbox__text"
                href={link}
                target="_blank"
                rel="noreferrer"
              >
                {name}
              </a>
            </div>
          ))}
        </div>

      </div>

    </div>
  );
};
