import { sample, attach } from 'effector';
import { spread } from 'patronum/spread';
import { get, isEmpty } from 'src/lib/lodash';
import { storage } from 'src/lib/storage';
import {
  LOAN_SERVICE_FIELDS,
  REG_STEP_SUCCESS_FINAL_FIELDS,
} from 'src/dict/fields';
import { PROFILE_NEW_LOAN_PATH } from 'src/dict/path';
import { statistic, LS } from 'src/dict/config';
import { mountAppFn } from 'src/models/App';
import { sendYMGoalFx } from 'src/models/Statistic';
import { getCurrentUserOnlyQueryFx } from 'src/models/User';
import { closeModalFn, openModalFn } from 'src/models/components/Modal';
import { hideLoaderButtonFn, showLoaderButtonFn } from 'src/models/Helper/Loader';
import { notifyErrorFn } from 'src/models/Helper/Notification';
import { storageRemoveFn } from 'src/models/Helper/Storage';
import {
  // goAgreementPageFn,
  goAgreementSuccessPageFn, goAgreementPageFn,
  $agreementId,
  signAgreementApplicationFx, timerAgreementActiveStatusFx,
} from 'src/models/Loan/Agreement';
import {
  // события
  startTimerActivationCodeFn,
  goConfirmPageFn,
  // хранилища
  $tokenSent, $valueTimer,
} from 'src/models/Loan/Application';
import {
  ToLoan,
} from 'src/ui/components/Modals/ToLoan';
import {
  changeAgreementDocsFn, getConfirmSuccessPageFn, getAgreementDocsFn, getAgreementDocsForLoanFn,
  $agreementDocs,
  getAgreementDocsFx, getAgreementDocsForLoanFx,
  stepAgreementForm, stepConfirmForm, getAfterConfirmSuccessPageFn,
} from './index';

// const { AGREEMENT } = REG_STEP_SUCCESS_CONFIRM_FIELDS;
const { INFO } = statistic;
const { PAYMENT_CONTEXT } = LS;
const { AGREEMENT } = PROFILE_NEW_LOAN_PATH;
const { AGREEMENT_ID } = LOAN_SERVICE_FIELDS;
const {
  ONE, TWO, THREE, FOUR, FIVE, SIX,
} = REG_STEP_SUCCESS_FINAL_FIELDS;

/// ///////////////////////////////////////////////////////////
// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
/// ///////////////////////////////////////////////////////////

$agreementDocs
  .on(getAgreementDocsFx.doneData, (_, result) => {
    const items = get(result, 'data', result);
    return items.map(({ id, name, link }) => ({
      id, label: name, value: false, link,
    }));
  });

/// ///////////////////////////////////////////////////////////
// ===================== БЛОК ОПЕРАТОРОВ =================== //
/// ///////////////////////////////////////////////////////////

// Извлекаем
sample({
  clock: sample({
    clock: mountAppFn,
    // source: $isSuccessNewLoanPage,
    filter: () => !isEmpty(storage.get(AGREEMENT_ID)),
  }),
  fn: () => storage.get(AGREEMENT_ID),
  target: $agreementId,
});

// $agreementId.watch((agreementId) => console.log('success init $agreementId:', agreementId));
/* sample({
  clock: sample({
    clock: getAgreementsApplicationFn,
    source: [$agreementId, $isSuccessNewLoanPage],
    filter: ([applicationId, isSuccessNewLoanPage]) => !isEmpty(applicationId) && !isEmpty(isSuccessNewLoanPage),
  }),
  target: attach({
    effect: getAgreementsApplicationFx,
    source: $agreementId,
    mapParams: (_, agreementId) => applicationId,
  }),
}); */

sample({
  clock: sample({
    clock: getConfirmSuccessPageFn,
    source: $agreementId,
    filter: (agreementId) => !isEmpty(agreementId),
  }),
  // редиректим юзера на след шаг -
  target: goAgreementSuccessPageFn,
});

sample({
  clock: getAgreementDocsFn,
  source: $agreementId,
  target: getAgreementDocsFx,
});

// на странице займов в информацие подробней
sample({
  clock: getAgreementDocsForLoanFn,
  target: getAgreementDocsForLoanFx,
});

/* при получении общего согласия проставляем согласие везде,
и если согласия нет - снимаем галочки везде */
sample({
  clock: stepAgreementForm.fields[AGREEMENT].$value,
  source: $agreementDocs,
  fn: (agreementDocs, agreement) => ({
    agreement,
    agreementDocs: agreementDocs.map((item) => ({ ...item, value: !isEmpty(agreement) })),
  }),
  target: spread({
    targets: {
      agreement: stepAgreementForm.fields[AGREEMENT].$value,
      agreementDocs: $agreementDocs,
    },
  }),
});

sample({
  clock: changeAgreementDocsFn,
  source: $agreementDocs,
  fn: (agreementDocs, { id: agreementDocsId, value: agreementDocsValue }) => {
    const items = agreementDocs.map(
      (itemDoc) => ((agreementDocsId !== itemDoc.id) ? { ...itemDoc }
        : { ...itemDoc, value: agreementDocsValue }),
    );
    return ({
      agreementDocs: items,
      agreement: !items.some(({ value }) => !value),
    });
  },
  target: spread({
    targets: {
      agreement: stepAgreementForm.fields[AGREEMENT].$value,
      agreementDocs: $agreementDocs,
    },
  }),
});

sample({
  clock: sample({
    clock: stepAgreementForm.formValidated,
    source: $agreementId,
    filter: (agreementId) => !isEmpty(agreementId),
  }),
  target: goConfirmPageFn,
});

sample({
  clock: [$valueTimer, $tokenSent],
  target: [
    startTimerActivationCodeFn,
  ],
});

// при копировании в поле нескольких цифр
/* sample({
  clock: sample({
    clock: stepConfirmForm.$values,
    filter: (values) => Object.values(values).some((value) => `${value}`.length > 1),
  }),
  source: stepConfirmForm.$values,
  fn: (values) => {
    const [field, val] = Object.entries(values).find(([, value]) => value.length > 1);
    return getDataCodeConfirm(field, val, values);
  },
  target: [
    jumpFocusFieldFn.prepend(() => SIX),
    stepConfirmForm.setForm,
  ],
}); */

sample({
  clock: sample({
    clock: stepConfirmForm.formValidated,
    source: $agreementId,
    filter: (agreementId) => !isEmpty(agreementId),
  }),
  source: stepConfirmForm.formValidated,
  target: [
    showLoaderButtonFn,
    // создаем заявку
    attach({
      effect: signAgreementApplicationFx,
      source: [$tokenSent, $agreementId],
      mapParams: (form, [tokenSent, agreementId]) => ({
        agreementId,
        data: {
          token: tokenSent,
          code: `${form[ONE]}`
            + `${form[TWO]}`
            + `${form[THREE]}`
            + `${form[FOUR]}`
            + `${form[FIVE]}`
            + `${form[SIX]}`,
        },
      }),
    }),
  ],
});

sample({
  clock: signAgreementApplicationFx.fail,
  target: [
    // снимаем блок с кнопки
    hideLoaderButtonFn,
    notifyErrorFn.prepend(
      (data) => {
        const status = get(data, 'error.request.status', '');
        if (status === 410) {
          return 'Срок действия введенного SMS кода истек! Пожалуйста, запросите новый!';
        }
        return get(data, 'error.response.data.message', '');
      },
    ),
  ],
});

sample({
  clock: signAgreementApplicationFx.done,
  target: [
    // снимаем блок с кнопки
    hideLoaderButtonFn,
    openModalFn.prepend(() => ({
      importantOperation: true,
      className: 'modal-toLoan-success',
      content: ToLoan,
      // указываем свое событие на закрытие модального окна
      closeCallback: getAfterConfirmSuccessPageFn,
      // successModalFn: goAgreementPageFn,
    })),
    sendYMGoalFx.prepend(() => 'REG_OFFER_SIGNED'),
    // стираем данные о utm метках
    storageRemoveFn.prepend(() => [INFO, AGREEMENT_ID, PAYMENT_CONTEXT]),
    // перезапрашиваем двнные юзера - current
    getCurrentUserOnlyQueryFx,
  ],
});

sample({
  clock: getAfterConfirmSuccessPageFn,
  target: [
    // реди ректим на список займов
    goAgreementPageFn,
    // закрываем модалку
    closeModalFn,
    // запускаем таймер для опроса - когда у займ будет агктивным
    timerAgreementActiveStatusFx.prepend(() => 2000),
  ],
});

// ===================== ПЕРЕНАПРАВЛЕНИЯ =====================
