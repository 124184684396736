import React from 'react';

export const First = React.memo(() => (
  <div className="profileTop-item profileTop-item-loan">

    <div className="profileTop-item-icon">
      <img src="/img/profile/percentIcon.png" alt="" />
    </div>

    <div className="profileTop-item-info">

      {/* <p className="profileTop-item-description">Ваши займы</p> */}
      <p className="profileTop-item-description">
        {' '}
        <br />
      </p>
      <p className="profileTop-item-title">
        Первый займ
        {' '}
        <span className="darkred">бесплатно</span>
      </p>

      <p className="profileTop-item-text">Возьмите первый займ под 0%</p>
    </div>
  </div>
));
