import { axios } from 'src/api/axios';

export const getItemsPaymentSign = () => axios.get('/clients/current/payment-methods');
/*
data: [
  0: {
    id: "c0bfb176-d560-4e2f-94d3-7edb6f65ac39"
    id_autopayment: true
    is_default: true
    paymentable_data: {
      bank_name: "TestBankName"
      expire_date: "11/22"
      first_six: "111111"
      id: "c0bfb176-d560-4e2f-94d3-7edb6f65ac39"
      last_four: "1222"
    }
    paymentable_type: "bank_card"
  }
]
*/

export const bindPaymentToAppSign = ({ applicationId, data }) => axios.patch(
  `applications/${applicationId}/payment-method`, data,
);

// export const startBindCardBankSign = () => axios.post('/payment-methods/bank-cards/start-binding-process');
// GET BankCard binding process
export const startBindCardBankSign = () => axios.post('/bank-cards/binding-processes');

export const removeBindCardBankSign = (binkCardId) => axios.delete(`/bank-cards/${binkCardId}`);

export const getBindCardBankSign = (bindingProcessId) => axios.get(`/bank-cards/binding-processes/${bindingProcessId}`);
