import React from 'react';

export const How = React.memo(() => (
  <section className="howSection" id="how">
    <div className="wrapper">
      <h2>
        Как
        это работает
      </h2>
      <div className="items">
        <div className="item">
          <div className="item-num">
            01
            <div className="item-icon">
              <img src="/img/main/how/icon1.svg" alt="" />
            </div>
          </div>
          <div className="item-title">
            Оформите
            {' '}
            заявку
          </div>
          <p className="item-text">
            Потребуется только паспорт
          </p>
        </div>
        <div className="item">
          <div className="item-num">
            02
            <div className="item-icon">
              <img src="/img/main/how/icon2.svg" alt="" />
            </div>
          </div>

          <div className="item-title">
            Дождитесь
            {' '}
            ответа
          </div>

          <p className="item-text">
            Дадим ответ в
            <br />
            течение 1 минуты
          </p>
        </div>
        <div className="item">
          <div className="item-num">
            03
            <div className="item-icon">
              <img src="/img/main/how/icon3.svg" alt="" />
            </div>
          </div>
          <div className="item-title">
            Мгновенно
            {' '}
            получите деньги
          </div>
          <p className="item-text">
            Наличными, на карту
            <br />
            или банковский счёт
          </p>
        </div>
      </div>
      <div className="description">
        <p>
          Оформить займ можно
          {' '}
          <span className="bold">круглосуточно</span>
          ,
          в выходные и праздники. Простое и быстрое оформление не выходя из дома
          всего за 5 минут!
        </p>
      </div>
    </div>
  </section>
));
