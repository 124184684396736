export const isString = (str) => (str && typeof str.valueOf() === 'string');

export const isObject = (value) => {
  const type = typeof value;
  return !!value && (type === 'object' || type === 'function');
};

export const get = (object, path, value) => {
  const pathArray = Array.isArray(path) ? path : path.split('.').filter((key) => key);
  const pathArrayFlat = pathArray.flatMap((part) => (typeof part === 'string' ? part.split('.') : part));
  // eslint-disable-next-line unicorn/no-array-reduce
  const checkValue = pathArrayFlat.reduce((obj, key) => obj && obj[key], object);
  return checkValue === undefined ? value : checkValue;
};

/* Проверяет, является ли ключ прямым свойством объекта.
Ключ может быть путем к значению, разделенному знаком.
has(object, 'a') => true || false */
export const has = (obj, key) => {
  const keyParts = key.split('.');
  return !!obj && (
    keyParts.length > 1
      ? has(obj[key.split('.')[0]], keyParts.slice(1).join('.'))
      : hasOwnProperty.call(obj, key)
  );
};

// Проверяет, является ли значение пустым объектом или коллекцией.
export const isEmpty = (obj) => [Object, Array].includes((obj || {}).constructor)
  && Object.entries((obj || {})).length === 0;

/* Создает объект, состоящий из предиката свойств объекта, который возвращает true для.
 console.log(pickBy({ 'a': 1, 'b': null, 'c': 3, 'd': false, 'e': undefined })) => {a: 1, c: 3} */
export const pickBy = (object) => {
  const obj = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const key in object) {
    if (object[key]) {
      obj[key] = object[key];
    }
  }
  return obj;
};

/* Пересечение массивов
 const arrayA = [1, 2, 3, 4, 5]; const arrayB = [2, 3];
 intersection(arrayA, arrayB); // [2, 3] */
export const intersection = (a, b) => a.filter((value) => b.includes(value));

// random массива
export const random = (items) => items[Math.floor(Math.random() * items.length)];

export const zipObject = (keys, values) => {
  const result = {};
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < keys.length; i++) result[keys[i]] = values[i];
  return result;
};

export const replaceFromPos = (str, search, replace, from) => {
  if (str.length > from) {
    return str.slice(0, from) + str.slice(from).replace(search, replace);
  }
  return str;
};
