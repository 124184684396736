import React from 'react';
import {
  Switch, Route, withRouter, Redirect, // Redirect,
} from 'react-router-dom';
import { PROFILE_NEW_LOAN_CARD_PATH, PROFILE_NEW_LOAN_PATH } from 'src/dict/path';

import { ListChoice } from './children/ListChoice';
import { Cards } from './children/Cards/Cards';

const { PAYMENT } = PROFILE_NEW_LOAN_PATH;
const { CARDS } = PROFILE_NEW_LOAN_CARD_PATH;

export const ToRepayRoutes = withRouter(({ match: { url } }) => {
  const baseUrl = url.slice(-1) === '/' ? url.slice(0, Math.max(0, url.length - 1)) : url;
  return (
    <Switch>
      <Route path={`${baseUrl}/${PAYMENT}/${CARDS}`} component={Cards} />
      <Route exact path={`${baseUrl}/${PAYMENT}`} component={ListChoice} />

      <Redirect exact from={`${baseUrl}`} to={`${baseUrl}/${PAYMENT}`} />
    </Switch>
  );
});
