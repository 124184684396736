import React from 'react';
import { useStore } from 'effector-react';
import { isEmpty } from 'src/lib/lodash';
import { formatISODate } from 'src/lib/date';
import {
  $isStatusProcessing, $dateExpired,
} from 'src/models/Loan/Application';
import {
  goToAgreementsFn,
} from 'src/models/Loan/Agreement';
import {
  closeMenuMobileFn, openMenuMobileFn,
  $openMenuMobile,
} from 'src/models/Helper/MenuMobile';
import { $firstName } from 'src/models/User';
import { Sidebar } from 'src/ui/blocks/Profile/Sidebar/Sidebar';

const WaitLoan = () => {
  const userName = useStore($firstName);
  const dateExpired = useStore($dateExpired);
  const isProcessing = useStore($isStatusProcessing);
  const isOpen = !isEmpty(useStore($openMenuMobile));
  const handlerOpenMenu = isOpen ? closeMenuMobileFn : openMenuMobileFn;

  const textButton = isProcessing ? 'Перейти к заявке' : 'Забрать деньги';
  const textHeader = isProcessing
    ? 'Ваша заявка в процессе обработки' : `${userName}, Вам одобрен займ!`;
  const textSmall = !isProcessing ? `Предложение действует до ${formatISODate(dateExpired)}` : '';
  const styleHeaderBlock = { marginLeft: '25%' };
  const styleHeaderText = { maxWidth: '100%' };
  const styleHeaderMobileDescText = { lineHeight: '30px' };
  return (
    <>
      <div className="profileTop makePayment wait_loan">

        <div className="profileTop-top-mobile">
          <a href="/" className="profileTop-logo">
            <img src="/img/main/logo.png" alt="" />
          </a>

          <div className="profileTop-item-info">
            <p className="profileTop-item-title">
              {textHeader}
            </p>
            <p
              style={styleHeaderMobileDescText}
              className="profileTop-item-description"
            >
              {textSmall}
            </p>
          </div>

          {/* mobile version */}
          <div className="profileTop-button">
            <button
              type="button"
              className="default-btn button-open"
              onClick={goToAgreementsFn}
            >
              {textButton}
            </button>
          </div>

          <div className="profileTop-menuButton" onClick={handlerOpenMenu}>
            <img className="open" src="/img/icons/menu.svg" alt="" />
            <img className="close" src="/img/icons/menuclose.svg" alt="" />
          </div>
        </div>

        <div className="profileTop-top">
          <div className="wrapper">

            <div
              className="profileTop-content profileTop-content-makePayment"
              style={styleHeaderBlock}
            >
              <div className="profileTop-items">

                <div
                  style={styleHeaderText}
                  className="profileTop-item profileTop-item-date"
                >
                  <div className="profileTop-item-info">
                    <p className="profileTop-item-title">
                      {textHeader}
                    </p>
                    <p className="profileTop-item-description">
                      {textSmall}
                    </p>
                  </div>

                </div>
              </div>

              <div className="profileTop-button">
                <button
                  type="button"
                  className="default-btn button-open"
                  onClick={goToAgreementsFn}
                >
                  {textButton}
                </button>
              </div>
            </div>
          </div>
        </div>

        <Sidebar />
      </div>
    </>
  );
};

export { WaitLoan };
