import ym from 'react-yandex-metrika';
import {
  get, isEmpty, isObject,
} from 'src/lib/lodash';
// import { paramsToObject } from 'src/lib/url';
import { statistic } from 'src/dict/config';

// формируем объект и записываем его в storage
export const transformParamsSign = ({ /* info, */ params: objParams, __queryString }) => {
  // await new Promise((r) => setTimeout(r, 3000));
  const { UTM_LIST } = statistic;
  // сюда будем складывать то что сохраним в storage
  // const stat = { ...info };
  const stat = {
    __query_string: __queryString,
  };
  // вначале обрабатываем utm-метки
  // смотрим есть ли вообще GET-параметры
  const queryString = {};
  if (!isEmpty(objParams)) {
    // cмотрим есть ли среди них хоть одна utm-метка
    const isUtm = UTM_LIST.some((utm) => get(objParams, utm, false));
    if (isUtm) {
      UTM_LIST.forEach((utm) => {
        stat[utm] = get(objParams, utm, '');
      });
    }
    Object.keys(objParams).forEach((key) => {
      const val = get(objParams, key, '');
      if (!UTM_LIST.includes(key)) {
        queryString[key] = val;
      }
    });

    if (!isEmpty(queryString)) {
      stat.query_string = { ...queryString };
    }
  }
  // смотрим откуда пришел
  stat.referrer = get(window, 'document.referrer', '');
  // смотрим куда пришел
  stat.landingUri = get(window, 'location.pathname', '');
  // время прихода
  stat.datetime = Date.now();
  stat.language = get(window, 'navigator.language', '');
  stat.userAgent = get(window, 'navigator.userAgent', '');
  stat.platform = get(window, 'navigator.platform', '');
  stat.product = get(window, 'navigator.product', '');

  return stat;
};

export const sendYMGoalSign = (value) => {
  if (isObject(value)) {
    const { goal, params } = value;
    if (!isEmpty(goal)) {
      if (!isEmpty(params) && isObject(params)) {
        return ym('reachGoal', goal, params);
      }
      return ym('reachGoal', goal);
    }
  }
  return ym('reachGoal', value);
};

export const sendYMHitSign = (value) => {
  if (isObject(value)) {
    const { url, params } = value;
    if (!isEmpty(url)) {
      if (!isEmpty(params) && isObject(params)) {
        return ym('hit', url, params);
      }
      return ym('hit', url);
    }
  }
  return ym('hit', value);
};
