import React from 'react';
import { get, isEmpty } from 'src/lib/lodash';
import { getFormatMoney } from 'src/lib/money';
import { STATUS_AGREEMENT } from 'src/dict/fields/loan';
import { getUnitDay } from 'src/lib/date';
import {
  toggleLoanExtendedFn, intentionToRepayFn,
} from 'src/models/Loan/Agreement';
import { Info } from './children/Info';

const {
  EXPIRED, OVERDUE, ACTIVE, AGREE,
} = STATUS_AGREEMENT;

export const Loan = ({
  item, isActiveAgreement, pendingDocs, pendingPayments, itemsPaymentForExLoan,
}) => {
  const {
    id, status, number, term, isOpen,
  } = item;

  const amountFull = get(item, 'debt.amount', 0);
  const activeClassForInfo = isOpen ? 'active' : '';
  const isExpired = [EXPIRED, OVERDUE].includes(status);
  const isActive = [ACTIVE, AGREE].includes(status);
  const statusLoan = isActive ? 'approach' : (isExpired ? 'expired' : 'repaid');

  const isRepaid = !isExpired && !isActive;
  const textLoan = isActive
  // eslint-disable-next-line unicorn/no-nested-ternary
    ? 'Активный займ' : (isExpired ? 'Займ просрочен! Срочно внесите оплату!' : 'Займ погашен');

  const disabledClassButton = isEmpty(isActiveAgreement) ? 'disabled' : '';
  return (
    <div className={`loan block ${statusLoan}`} key={id}>
      <div className="block-notification">
        <p>{textLoan}</p>
      </div>

      <div className="block-info">
        <div className="block-info-items">
          <div className="block-info-item">
            <div className="block-info-item-icon">
              <img src="/img/profile/coinIcon.png" alt="" />
            </div>
            <div className="block-info-item-info">
              <p className="block-info-item-description">Сумма к погашению</p>
              <p className="block-info-item-title">
                {getFormatMoney(amountFull)}
                {' '}
                ₽
              </p>
            </div>
          </div>

          <div className="block-info-item term">
            <div className="block-info-item-icon">
              <img src="/img/profile/dateIcon.png" alt="" />
            </div>
            <div className="block-info-item-info">
              <p className="block-info-item-description">Срок займа</p>
              <p className="block-info-item-title">
                {term}
                {' '}
                {getUnitDay(term)}
              </p>
            </div>
          </div>

        </div>

        {!isRepaid ? (
          <div className="block-info-btns">
            <div className="block-info-btn">
              <button
                type="button"
                disabled={isEmpty(isActiveAgreement)}
                className={`default-btn ${disabledClassButton}`}
                onClick={intentionToRepayFn.prepend(() => id)}
              >
                Погасить
              </button>
            </div>
          </div>
        )
          : (
            <div className="block-info-canceled">
              <p>Займ погашен</p>
            </div>
          )}
      </div>

      <div className={`block-main ${activeClassForInfo}`}>
        <div
          className="block-main-top"
          onClick={toggleLoanExtendedFn.prepend(() => ({ id, isOpen: !isOpen }))}
        >
          <div
            className="block-main-info"
          >
            <h3 className="block-subtitle">Подробная информация о займе</h3>
            <p className="block-description">
              Договор номер:
              {' '}
              {number}
            </p>
          </div>

          <div className="block-main-arrow">
            <div className="icon"><img src="/img/icons/selectarrow.svg" alt="" /></div>
          </div>

        </div>

        {isOpen && (
        <Info
          item={item}
          pendingDocs={pendingDocs}
          pendingPayments={pendingPayments}
          itemsPaymentForExLoan={itemsPaymentForExLoan}
        />
        )}

      </div>
    </div>
  );
};
