import {
  restore, sample, split,
} from 'effector';
import { combineEvents } from 'patronum/combine-events';
import { get, isEmpty } from 'src/lib/lodash';
import { paramsToObject } from 'src/lib/url';
import { storage } from 'src/lib/storage';
import {
  AUTH_TO_PROFILE, PAGES_PATH, REG_PATH,
} from 'src/dict/path';
import { LS, token } from 'src/dict/config';
import { $pathnameUrl } from 'src/models/App';
import { clearRegStorageFn } from 'src/models/Auth';
import {
  pushHistoryFn,
} from 'src/models/Helper/History';
import {
  showLoaderNotTimePageFn,
  hideLoaderNotTimePageFn,
} from 'src/models/Helper/Loader';
import { notifyErrorFn } from 'src//models/Helper/Notification';
import { closeModalFn, openModalFn } from 'src/models/components/Modal';
import { $userId, getCurrentUserOnlyQueryFx } from 'src/models/User';
import { goAgreementPageFn } from 'src/models/Loan/Agreement';
import { storageSetFn } from 'src/models/Helper/Storage';
import {
  EsiaBusy,
  EsiaError,
  EsiaNotConfirmed,
  EsiaSuccess,
} from 'src/ui/components/Modals/Esia';
import {
  getLinkForAuthESIAFn, closeErrorEsiaRegFn,
  $urlAuthESIA,
  getLinkForAuthESIAFx, redirectAuthESIAFx,
  authESIALoginFx, bindESIAFx,
} from './index';

const { ESIA_RETURN_URL } = PAGES_PATH;
const {
  REGISTRATION, PERSONAL,
} = REG_PATH;

/// ///////////////////////////////////////////////////////////
// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
/// ///////////////////////////////////////////////////////////

$urlAuthESIA
  .on(getLinkForAuthESIAFx.doneData, (_, result) => get(result, 'data.url', ''));

// распихиваем полученные данные по соответствующим полям
// authESIALoginFx.doneData, (_, result) => get(result, 'data.url', ''));

/// ///////////////////////////////////////////////////////////
// ===================== БЛОК ОПЕРАТОРОВ =================== //
/// ///////////////////////////////////////////////////////////

sample({
  clock: getLinkForAuthESIAFn,
  target: getLinkForAuthESIAFx,
});

sample({
  clock: getLinkForAuthESIAFx.doneData,
  source: $urlAuthESIA,
  target: [
    redirectAuthESIAFx,
  ],
});

// случай для подключение esia из ЛК
split({
  source: sample({
    clock: combineEvents({
      events: {
        $pathnameUrl,
        $userId,
      },
    }),
    source: [$pathnameUrl, $userId],
    filter: ([pathname, userId]) => {
      const objParams = paramsToObject(get(window, 'location.search', {}));
      const code = get(objParams, 'code', '');
      const error = get(objParams, 'error', '');
      return pathname === ESIA_RETURN_URL && !isEmpty(userId)
          && (!isEmpty(code) || !isEmpty(error));
    },
  }),
  match: {
    cancel: () => {
      const objParams = paramsToObject(get(window, 'location.search', {}));
      return !isEmpty(get(objParams, 'error', ''));
    },
  },
  cases: {
    cancel: [
      goAgreementPageFn,
      hideLoaderNotTimePageFn,
    ],
    __: [
      showLoaderNotTimePageFn,
      bindESIAFx.prepend(() => {
        const objParams = paramsToObject(get(window, 'location.search', {}));
        const code = get(objParams, 'code', '');
        return ({ code });
      }),
    ],
  },
});

sample({
  clock: bindESIAFx.done,
  target: [
    openModalFn.prepend(() => ({
      importantOperation: true,
      className: 'modal-agreement',
      content: EsiaSuccess,
      closeCallback: (() => {
        closeModalFn();
        goAgreementPageFn();
        hideLoaderNotTimePageFn();
        // запрашиваем данные по юзеру чтобы заполнить хранилище юзера данными esia
        getCurrentUserOnlyQueryFx();
      }),
    })),
  ],
});

split({
  clock: bindESIAFx.failData,
  source: restore(bindESIAFx.fail, {}),
  match: {
    isBusy: (error) => (get(error, 'error.response.status') === 409),
    notVerified: (error) => (get(error, 'error.response.status') === 406),
  },
  cases: {
    isBusy: [
      openModalFn.prepend(() => ({
        importantOperation: true,
        className: 'modal-agreement',
        content: EsiaBusy,
        closeCallback: (() => {
          closeModalFn(); goAgreementPageFn(); hideLoaderNotTimePageFn();
        }),
      })),
    ],
    notVerified: [
      openModalFn.prepend(() => ({
        importantOperation: true,
        className: 'modal-agreement',
        content: EsiaNotConfirmed,
        closeCallback: (() => {
          closeModalFn(); goAgreementPageFn(); hideLoaderNotTimePageFn();
        }),
      })),
    ],
    __: [
      openModalFn.prepend(() => ({
        importantOperation: true,
        className: 'modal-agreement',
        content: EsiaError,
        closeCallback: (() => {
          closeModalFn(); goAgreementPageFn(); hideLoaderNotTimePageFn();
        }),
      })),
    ],
  },
});

// случай для подключение esia из внешней части
sample({
  clock: $pathnameUrl,
  filter: (pathname) => {
    const objParams = paramsToObject(get(window, 'location.search', {}));
    const code = get(objParams, 'code', '');
    return pathname === ESIA_RETURN_URL && !isEmpty(code) && isEmpty(storage.get(token.ACCESS));
  },
  fn: () => {
    // получаем code из url
    const objParams = paramsToObject(get(window, 'location.search', {}));
    const code = get(objParams, 'code', '');
    return { code };
  },
  target: [
    showLoaderNotTimePageFn,
    authESIALoginFx,
  ],
});

split({
  clock: authESIALoginFx.failData,
  source: restore(authESIALoginFx.fail, {}),
  match: {
    error406: (error) => (get(error, 'error.response.status') === 406),
  },
  cases: {
    error406: [
      hideLoaderNotTimePageFn,
      openModalFn.prepend(() => ({
        importantOperation: true,
        className: 'modal-agreement',
        content: EsiaNotConfirmed,
        // указываем свое событие на закрытие модального окна
        closeCallback: closeErrorEsiaRegFn,
        // closeCallback: closeModalFn,
      })),
    ],
    __: [
      pushHistoryFn.prepend(() => '/'),
      notifyErrorFn.prepend(() => 'Ошибка получения токена через ЕСИА'),
      hideLoaderNotTimePageFn,
      closeModalFn,
    ],
  },
});

sample({
  clock: closeErrorEsiaRegFn,
  target: [
    pushHistoryFn.prepend(() => '/'),
    closeModalFn,
    hideLoaderNotTimePageFn,
  ],
});

split({
  source: sample(
    restore(authESIALoginFx.doneData, {}),
    authESIALoginFx.doneData,
  ),
  match: {
    toProfile: (data) => !isEmpty(get(data, 'data.is_verified', '')),
  },
  cases: {
    toProfile: [
      pushHistoryFn.prepend(() => `/${AUTH_TO_PROFILE}`),
      clearRegStorageFn,
    ],
    __: [
      // запрашиваем данные по юзеру чтобы заполнить хранилище юзера данными esia
      getCurrentUserOnlyQueryFx,
      // стираем данные шагов если есть
      clearRegStorageFn,
      // срузу указываем 1ую страницу регистрации
      storageSetFn.prepend(() => ({
        [LS.STEP]: PERSONAL,
      })),
      // записываем данные по шагам в LS
      pushHistoryFn.prepend(() => `/${REGISTRATION}/${PERSONAL}`),
    ],
  },
});
