import IMask from 'imask';
import { isEmpty } from 'src/lib/lodash';
import {
  letterAddressPattern,
  letterPattern,
  numberPattern,
  numHousePattern,
  positionPattern,
} from 'src/lib/rules';

export const getValueFromMask = (value, mask) => {
  const val = mask.resolve(`${value}`);
  // return val.trim();
  return val;
};

export const maskPhone = IMask.createMask({
  mask: '+7 (000) 000-00-00',
});

export const maskSnils = IMask.createMask({
  mask: '000-000-000 00',
});

export const maskLetter = IMask.createMask({
  mask: letterPattern,
});

export const maskLetterAddress = IMask.createMask({
  mask: letterAddressPattern,
});

export const maskNumHouses = IMask.createMask({
  mask: numHousePattern,
});

export const maskNumber = IMask.createMask({
  mask: numberPattern,
});

export const maskPosition = IMask.createMask({
  mask: positionPattern,
});

export const maskDateYear = IMask.createMask({
  mask: 'num',
  blocks: {
    num: {
      mask: Date,
      min: new Date(1920, 0, 1),
      max: new Date(),
    },
  },
});

export const maskPassportSeries = IMask.createMask({
  mask: '00 00',
});

export const maskPassportId = IMask.createMask({
  mask: '000 000',
});

export const maskPassportCodeDepartment = IMask.createMask({
  mask: '000-000',
});

export const maskDate = IMask.createMask({
  mask: '00.00.0000',
});

export const hideSeriesPassport = (value) => `${value.slice(0, 2)}**`;

export const hideNumberPassport = (value) => `***${value.slice(-3)}`;

export const hideSnils = (value) => {
  if (isEmpty(value)) return value;
  // eslint-disable-next-line no-useless-concat
  return `${`${value}`.slice(0, 2)}*-` + '***-' + `*${`${value}`.slice(8)}`;
};
