import React, { useEffect } from 'react';
import { storage } from 'src/lib/storage';
import { openPageExperienceFn } from 'src/models/Registration/Experience';
import { YellowBlock, MainBlock } from 'src/ui/components';
import { EsiaButtonReg } from 'src/ui/components/Block/EsiaButtonReg';
import { ExperienceForm } from './children/ExperienceForm';

const KeksBlock = () => (
  <>
    <div className="keks keks5">
      <img src="/img/form/keks-5.png" alt="" />
    </div>
    <span className="keks5-hand"><img src="/img/form/keks-5-hand.png" alt="" /></span>
  </>
);

export const Step4 = () => {
  useEffect(() => {
    openPageExperienceFn();
  }, []);

  const city = storage.get('city');
  return (
    <div className="step step5 active">
      <YellowBlock
        title={`${city} - чудесное место!`}
        text="Расскажите, пожалуйста, о себе поподробнее"
      />
      <MainBlock keksBlock={KeksBlock}>
        <div className="block-main__content">
          <ExperienceForm />
          <EsiaButtonReg />
        </div>
      </MainBlock>
    </div>
  );
};
