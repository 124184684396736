import React from 'react';

export const Timer = ({ timerActive, stopTimer, value }) => {
  const [seconds, setSeconds] = React.useState(value);

  React.useEffect(() => {
    if (seconds > 0 && timerActive) {
      setTimeout(setSeconds, 1000, seconds - 1);
    } else {
      stopTimer();
    }
  }, [seconds, timerActive, value]);

  const val = `${seconds}`.padStart(2, '0');
  return (
    <div className="timer-text startTimer timerBlock">
      Запросить код повторно можно через
      {' '}
      <span className="timer" id="timer" data-time="1">
        {`00:${val}`}
      </span>
    </div>
  );
};
