export const URL_DOCS = 'https://static.kekscredit.ru/public-docs/';

export const LINKS_DOCS = {
  // eslint-disable-next-line max-len
  MKK: `${URL_DOCS}Свидетельство МКК.pdf`,
  // eslint-disable-next-line max-len
  CPO: `${URL_DOCS}%D0%A1%D0%B2%D0%B8%D0%B4%D0%B5%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D1%82%D0%B2%D0%BE%20%D0%A1%D0%A0%D0%9E.pdf`,
  // eslint-disable-next-line max-len
  DISCLOSED: `${URL_DOCS}%D0%A1%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D1%8F%20%D0%BF%D0%BE%D0%B4%D0%BB%D0%B5%D0%B6%D0%B0%D1%89%D0%B8%D0%B5%20%D1%80%D0%B0%D1%81%D0%BA%D1%80%D1%8B%D1%82%D0%B8%D1%8E.pdf`,
  // eslint-disable-next-line max-len
  HOLIDAY: `${URL_DOCS}%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D1%8F_%D0%B4%D0%BB%D1%8F_%D0%B7%D0%B0%D0%B5%D0%BC%D1%89%D0%B8%D0%BA%D0%BE%D0%B2_%D0%B2%D0%BE%D0%B5%D0%BD%D0%BD%D0%BE%D1%81%D0%BB%D1%83%D0%B6%D0%B0%D1%89%D0%B8%D1%85_%D0%B8_%D0%B7%D0%B0%D0%B5%D0%BC%D1%89%D0%B8%D0%BA%D0%BE%D0%B2_%D1%87%D0%BB%D0%B5%D0%BD%D0%BE%D0%B2_%D1%81%D0%B5%D0%BC%D1%8C%D0%B8.pdf`,
  // eslint-disable-next-line max-len
  CBO: `${URL_DOCS}%D0%91%D1%80%D0%BE%D1%88%D1%8E%D1%80%D0%B0%20%D0%A6%D0%91%20%D0%A0%D0%A4%20%D0%BE%20%D0%BA%D1%80%D0%B5%D0%B4%D0%B8%D1%82%D0%BD%D1%8B%D1%85%20%D0%BA%D0%B0%D0%BD%D0%B8%D0%BA%D1%83%D0%BB%D0%B0%D1%85%20%D0%B4%D0%BB%D1%8F%20%D1%83%D1%87%D0%B0%D1%81%D1%82%D0%BD%D0%B8%D0%BA%D0%BE%D0%B2%20%D0%A1%D0%92%D0%9E.pdf`,
  // eslint-disable-next-line max-len
  LOAN_CONDITIONS: `${URL_DOCS}%D0%A3%D1%81%D0%BB%D0%BE%D0%B2%D0%B8%D1%8F%20%D0%BF%D1%80%D0%B5%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F%20%D0%B7%D0%B0%D0%B8%CC%86%D0%BC%D0%BE%D0%B2.pdf`,
  // eslint-disable-next-line max-len
  BASE_STANDARD: `${URL_DOCS}archive/2023/Базовый_стандарт_совершения_микрофинансовой_организацией_операций.pdf`,
  // eslint-disable-next-line max-len
  LIST_PARTICIPANTS: `${URL_DOCS}archive/2023/%D0%A1%D0%BE%D1%81%D1%82%D0%B0%D0%B2%20%D1%83%D1%87%D0%B0%D1%81%D1%82%D0%BD%D0%B8%D0%BA%D0%BE%D0%B2.pdf`,
};
