/* eslint-disable react/prop-types */
import React from 'react';
import { useStore } from 'effector-react';
import { Route, Redirect } from 'react-router-dom';
import { get } from 'src/lib/lodash';

import { $isAuthenticated } from 'src/models/Auth';

import { ErrorBoundary } from 'src/ui/components/ErrorBoundary/ErrorBoundary';
import { DefaultLayout } from 'src/ui/layouts/Default';
import { HomeLayout } from 'src/ui/layouts/Home/Home';
import { ProfileLayout } from 'src/ui/layouts/Profile/Profile';
import { LoaderLayout } from 'src/ui/layouts/Loader';

export const paths = [
  // Профайл
  {
    routes: ['/', undefined],
    component: HomeLayout,
  },
  {
    routes: ['profile'],
    component: ProfileLayout,
  },
  {
    routes: ['esia', 'payment-result'],
    component: LoaderLayout,
  },
  // Следующий...
];

export const getLayout = (pathname) => {
  const list = pathname.split('/').find(Boolean);
  const element = paths.filter((item) => item.routes.includes(list));
  return (element.length > 0) ? element[0].component : DefaultLayout;
};

export const BasicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const Layout = getLayout(get(props, 'location.pathname'));
      return <Layout><ErrorBoundary><Component {...props} /></ErrorBoundary></Layout>;
    }}
  />
);

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useStore($isAuthenticated);

  return (
    <Route
      {...rest}
      render={(props) => {
        const Layout = getLayout(get(props, 'location.pathname'));
        return (isAuthenticated
          ? <Layout><ErrorBoundary><Component {...props} /></ErrorBoundary></Layout>
          : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />);
      }}
    />
  );
};
