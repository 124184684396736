import React from 'react';

export const ChangePhone = React.memo(() => (
  <>
    <div className="block" id="changePhoneBlock">
      <h2 className="block-title">Смена телефона</h2>
      <div className="block-item block-item-phone">
        <p className="block-item-title">Текущий телефон</p>
        <p className="block-item-text">+ 8 800 345 67 89</p>
      </div>
      <div className="block-item">
        <div className="default-input">
          <input type="text" placeholder=" " tabIndex="0" required />
          <label>Новый телефон</label>
        </div>
      </div>
      <div className="block-info">
        <p>Для того, чтобы сменить телефон на новый, на текущий номер будет отправлен код из SMS</p>
      </div>
      <div className="block-info changePhoneActive timer-text timerBlock">
        Отправить код еще раз через
        {' '}
        <span className="timer" id="timer" data-time="1">00:58</span>
      </div>
      <a href="#" className="default-btn border hidden newCodeBtn">Отправить код еще раз</a>
      <div className="block-button">
        <a href="#" className="default-btn" id="changePhone">Сменить</a>
      </div>
      <div className="block-button changePhoneActive">
        <a data-fancybox href="#saveСhanges" className="default-btn">Подтвердить</a>
      </div>
    </div>

  </>
));
