import { combine } from 'effector';
import { isEmpty } from 'src/lib/lodash';
import { isCurrentPath } from 'src/lib/url';
import {
  PROFILE_LOAN_PATH, PROFILE_NEW_LOAN_PATH, PROFILE_PATH,
} from 'src/dict/path';
import { STATUS_AGREEMENT } from 'src/dict/fields/loan';
import { $pathnameUrl } from 'src/models/App';
import {
  loanDomain,
  $isProfileLoanPage,
} from 'src/models/Loan';
import {
  getAgreementsApplicationSign,
  sendCodeSigningApplicationSign,
  signAgreementApplicationSign,
  getAgreementsSign,
  getAgreementSign,
  createIntentToRepaySign,
  getStatusToRepaySign,
  sendToRepaySign,
} from 'src/api/signatures/Agreement';
import { timerSign } from 'src/signatures/Timer';

export const agreementDomain = loanDomain.createDomain('Agreement');

/// ///////////////////////////////////////////////////////////
// ================== СЛОВАРИ / КОНСТАНТЫ ================== //
/// ///////////////////////////////////////////////////////////

const { CARDS } = PROFILE_PATH;
const { NEW_LOAN, LOANS } = PROFILE_LOAN_PATH;
const { PAYMENT } = PROFILE_NEW_LOAN_PATH;
const {
  ACTIVE, AGREE, EXPIRED, OVERDUE,
} = STATUS_AGREEMENT;
/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ СОБЫТИЙ ===================== //
/// ///////////////////////////////////////////////////////////

// перенаправления
export const goAgreementPageFn = agreementDomain.createEvent('goAgreementPageFn');
export const goAgreementPaymentPageFn = agreementDomain.createEvent('goAgreementPaymentPageFn');
export const goAgreementPaymentCardsPageFn = agreementDomain.createEvent('goAgreementPaymentCardsPageFn');

export const goAgreementSuccessPageFn = agreementDomain.createEvent('goAgreementSuccessPageFn');

export const goToAgreementsFn = agreementDomain.createEvent('goToAgreementsFn');

export const getAgreementsApplicationFn = agreementDomain.createEvent('getAgreementsApplicationFn');
export const getAgreementsApplicationTempFn = agreementDomain.createEvent('getAgreementsApplicationTempFn');

export const removeAgreementInStorageFn = agreementDomain.createEvent('removeAgreementInStorageFn');

export const getAgreementsFn = agreementDomain.createEvent('getAgreementsFn');
export const getAgreementFn = agreementDomain.createEvent('getAgreementFn');
export const getAgreementForLoanFn = agreementDomain.createEvent('getAgreementForLoanFn');

export const toggleLoanExtendedFn = agreementDomain.createEvent('toggleLoanExtendedFn');

export const bindCalcAmountToRepayFn = agreementDomain.createEvent('bindCalcAmountToRepayFn');
export const closeCalculatorToRepayFn = agreementDomain.createEvent('closeCalculatorToRepayFn');
export const changeValueCalcAmountToRepayFn = agreementDomain.createEvent('changeValueCalcAmountToRepayFn');

// Событие нажатия кнопки "Применить" из калькулятора погашения
export const changeAmountToRepayFn = agreementDomain.createEvent('changeAmountToRepayFn');

// Событие нажатие кнопки погашения займа (из шапки и из страницы займов)
export const intentionToRepayFn = agreementDomain.createEvent('intentionToRepayFn');

export const sendToRepayFn = agreementDomain.createEvent('sendToRepayFn');

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ХРАНИЛИЦ ==================== //
/// ///////////////////////////////////////////////////////////

export const $repaymentIntentId = loanDomain.createStore('', { name: 'repaymentIntentId' });
export const $repaymentIntentItem = loanDomain.createStore({}, { name: 'repaymentIntentItem' });

// данные одобренного займа
export const $agreementItems = loanDomain.createStore([], { name: 'agreementItems' });
// данные одобренногоодобренного займа
export const $agreementAppItem = loanDomain.createStore({}, { name: 'agreementAppItem' });
// только для вывода информации для страницы займов
// export const $agreementAppItemTemp = loanDomain.createStore({}, { name: 'agreementAppItemTemp' });

// активный / подписанный займ
export const $agreementActiveItem = loanDomain.createStore({}, { name: 'agreementActive' });

export const $agreementId = loanDomain.createStore('', { name: 'agreementId' });
export const $agreementStatus = loanDomain.createStore('', { name: 'agreementStatus' });
export const $agreementStartDate = loanDomain.createStore('', { name: 'agreementStartDate' });
export const $agreementEndDate = loanDomain.createStore('', { name: 'agreementEndDate' });

export const $amountToRepay = loanDomain.createStore('', { name: 'amountToRepay' });

export const $selectorCalcAmountToRepay = loanDomain.createStore('', { name: 'selectorCalcAmountToRepay' });

export const $amountAgreements = loanDomain.createStore('', { name: 'amountAgreements' });

export const $termAgreements = loanDomain.createStore('', { name: 'termAgreements' });

export const $dateAgreements = loanDomain.createStore('', { name: 'dateAgreements' });

export const $amountBodyAgreements = loanDomain.createStore('', { name: 'amountBodyAgreements' });

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ЭФФЕКТОВ ==================== //
/// ///////////////////////////////////////////////////////////

// привязываем карту к заявке
// export const bindCardBankWithApplicationFx = loanDomain.createEffect(bindCardBankWithApplicationSign);

// Получение договора по зайвке
export const getAgreementsApplicationFx = loanDomain.createEffect(getAgreementsApplicationSign);

// Получение договора по зайвке на странице займов (до вывода списка догвооров)
export const getAgreementsApplicationTempFx = loanDomain.createEffect(getAgreementsApplicationSign);

// Получение договоров
export const getAgreementsFx = loanDomain.createEffect(getAgreementsSign);

// пр
export const getAgreementFx = loanDomain.createEffect(getAgreementSign);

// Получение договора на странице займов (подробная информация)
export const getAgreementForExtendedLoanFx = loanDomain.createEffect(getAgreementSign);

// для получения инфы при погашении (нажатии на кнопку погасить займ)
export const getAgreementForToRepayLoanFx = loanDomain.createEffect(getAgreementSign);

export const signAgreementApplicationFx = loanDomain.createEffect(signAgreementApplicationSign);

export const sendCodeSigningApplicationFx = loanDomain.createEffect(sendCodeSigningApplicationSign);

export const createIntentToRepayFx = loanDomain.createEffect(createIntentToRepaySign);
export const getStatusToRepayFx = loanDomain.createEffect(getStatusToRepaySign);
export const getStatusToRepayFinalFx = loanDomain.createEffect(getStatusToRepaySign);

export const sendToRepayFx = loanDomain.createEffect(sendToRepaySign);

export const timerAgreementActiveStatusFx = agreementDomain.createEffect(timerSign);

export const timerToRepayStatusFx = agreementDomain.createEffect(timerSign);
export const timerToRepayStatusFinalFx = agreementDomain.createEffect(timerSign);

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

export const $isActiveAgreement = $agreementStatus.map((status) => [ACTIVE, AGREE, EXPIRED, OVERDUE].includes(status));

// Проверка на страницу займов
export const $isProfileLoansPage = combine($isProfileLoanPage, $pathnameUrl, (isProfileLoanPage, path) => (
  isProfileLoanPage && isCurrentPath(path, LOANS)
));

// Проверка на URL погашения
export const $isProfileToRepayPage = combine($isProfileLoanPage, $pathnameUrl, (isProfileLoanPage, path) => {
  const arrPath = path.split('/');
  return isProfileLoanPage
      && !isEmpty(arrPath[3])
      && ![NEW_LOAN, LOANS].includes(arrPath[3]);
});

export const $isPaymentToRepayPage = combine($isProfileToRepayPage, $pathnameUrl, (isProfileToRepayPage, path) => (
  isProfileToRepayPage && isCurrentPath(path, PAYMENT)
));

export const $isCardsToRepayPage = combine($isProfileToRepayPage, $pathnameUrl, (isProfileToRepayPage, path) => (
  isProfileToRepayPage && isCurrentPath(path, CARDS)
));

// достаем из URL id договора и проверяем сходится ли он с активным | расскомментировать
export const $agreementToRepayId = combine($pathnameUrl, $agreementId, $isProfileToRepayPage,
  (pathnameUrl, agreementId, isProfileToRepayPage) => {
    const arrPath = pathnameUrl.split('/');
    // return (isProfileToRepayPage && !isEmpty(agreementId) && arrPath[3] === agreementId) ? agreementId : '';
    return (isProfileToRepayPage && !isEmpty(arrPath[3])) ? arrPath[3] : '';
  });

export const $agreementsCombineData = combine(
  $amountAgreements, $termAgreements, $dateAgreements, $amountBodyAgreements, (
    amountAgreements, termAgreements, dateAgreements, amountBodyAgreements,
  ) => ({
    amountAgreements, termAgreements, dateAgreements, amountBodyAgreements,
  }),
);

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ЭФФЕКТОВ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

// тут создаются эффекты через attach

/// ///////////////////////////////////////////////////////////
// ====================== ЮНИТЫ ФОРМ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем формы с помощью effector-forms

/// ///////////////////////////////////////////////////////////
//  ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ФОРМ + (ХРАНИЛИЩ, ЭФФЕКТОВ) //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ========================= ФАБРИКИ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем различные фабрики и хранилища с использованием фабрик

/* Статусы договора
waiting_agree - ожидание подписания, оферта
Статусы после подписания:
agree - подписан, не просрочен
repaid - погашен
expired - просрочен
 */
