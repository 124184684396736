export const FEEDBACK_FIELDS = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  // MIDDLE_NAME: 'middle_name',
  EMAIL: 'email',
  PHONE: 'phone',
  SUBJECT: 'subject',
  MESSAGE: 'message',
  TOKEN: 'token',
};
