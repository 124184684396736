import { sample } from 'effector';
import {
  // события
  cookieSetFn, cookieRemoveFn,
  // хранилища,
  // эффекты
  cookieSetFx, cookieRemoveFx,
} from './index';

/// ///////////////////////////////////////////////////////////
// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ===================== БЛОК ОПЕРАТОРОВ =================== //
/// ///////////////////////////////////////////////////////////

// Запись в куки
sample({ clock: cookieSetFn, target: cookieSetFx });

// Удаление из куки
sample({ clock: cookieRemoveFn, target: cookieRemoveFx });
