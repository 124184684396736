import React from 'react';
import { useStore } from 'effector-react';
import { $phoneSite } from 'src/models/App';

const Phone = React.memo(() => {
  const phoneSite = useStore($phoneSite);
  return (
    <div className="header-info">
      <div className="header-info__label">Круглосуточно</div>
      <a href={`tel:${phoneSite};`} className="header-info__tel">{phoneSite}</a>
    </div>
  );
});

export { Phone };
