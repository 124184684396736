import React, { useState, useEffect } from 'react';
import { useForm } from 'effector-forms';
// import { get } from 'src/lib/lodash';
// import { getEventsField } from 'src/lib/form';
import { maskPhone } from 'src/lib/maskPlaceholder';
import { LOGIN_FIELDS } from 'src/dict/fields';
import { PAGES_PATH } from 'src/dict/path';
import {
  getPropsField, getRefField, jumpFocusField, onSubmit,
} from 'src/lib/form';
import { jumpFocusFieldFn, setRefFieldsFn } from 'src/models/Helper/Ref';
import { loginForm } from 'src/models/Auth';
import { ButtonWithLoader as Button, Input } from 'src/ui/components/Form';
import { EsiaButtonLogin } from 'src/ui/components/Block/EsiaButtonLogin';

const { PHONE, PASSWORD } = LOGIN_FIELDS;
const { RECOVERY } = PAGES_PATH;

const handleInput = (form) => (event) => {
  const value = event.target.value.trim().replace(/\D/g, '');
  let val = value;
  if (value[0] !== '7' && value.length === 10) {
    val = `7 ${value}`;
  }
  setTimeout(() => {
    form.setForm({ [PHONE]: val });
  }, 0);
};
export const LoginForm = React.memo(() => {
  const { submit, ...restProps } = useForm(loginForm);
  const [mask, setMask] = useState({});

  useEffect(() => {
    setTimeout(() => {
      setMask(maskPhone);
    }, 1500);
  }, [mask]);

  return (
    <>
      <form onSubmit={onSubmit(submit)} autoComplete="on">
        <h1 className="title">Личный кабинет</h1>
        <div className="block-item ">
          <Input
            onInput={handleInput(loginForm)}
            autoComplete="on"
            label="Телефон"
            type="text"
            inputMode="tel"
            name={PHONE}
            {...mask}
            {...getPropsField({
              fieldName: PHONE, props: restProps,
            })}
            ref={getRefField({
              fieldName: PHONE, callBack: setRefFieldsFn,
            })}
            jumpField={jumpFocusField({
              fieldName: PASSWORD,
              callBack: jumpFocusFieldFn,
              trigger: { name: 'maxLength', data: { value: 18 } },
            })}
            required
          />
        </div>
        <div className="block-item">
          <Input
            label="Пароль"
            type="password"
            name={PASSWORD}
            {...getPropsField({
              fieldName: PASSWORD, props: restProps,
            })}
            ref={getRefField({
              fieldName: PASSWORD, callBack: setRefFieldsFn,
            })}
            required
          />
          {/* <a
            href={`/${RECOVERY}`}
            className="forgotPassword forgotPassword-absolute"
          >
            Забыли пароль?
          </a> */}
        </div>

        <div>
          <a
            href={`/${RECOVERY}`}
            className="forgotPassword"
            // className="forgotPasswordTwo"
          >
            Забыли пароль?
          </a>
        </div>

        <div className="buttons">
          <div className="button">
            <Button
              type="submit"
              className="nextStep"
            >
              Войти
            </Button>
          </div>

          <EsiaButtonLogin />
        </div>
        {/*
        <a href="#" className="register">Регистрация</a>
        */}
      </form>
    </>
  );
});
