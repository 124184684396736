import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from 'src/lib/lodash';

const Progress = require('progressbar.js');

const container = {
  margin: '10px',
  width: '100%',
  height: '8px',
};

export const ProgressBar = React.memo(({ val }) => {
  const id = !isEmpty(val) ? val : uuidv4();
  useEffect(() => {
    const bar = new Progress.Line(`.progress-${id}`, {
      easing: 'easeInOut',
      color: '#61c1d6',
    });

    bar.animate(1); // Number from 0.0 to 1.0
  }, []);

  return (<div className={`progress-${id}`} style={container} />);
});
