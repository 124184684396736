import {
  attach, combine, sample,
} from 'effector';
import { debounce } from 'patronum/debounce';
import { get, isEmpty } from 'src/lib/lodash';
import { isCurrentPath } from 'src/lib/url';
import { storage } from 'src/lib/storage';
import { REG_PATH, PAGES_PATH } from 'src/dict/path';
import { REG_STEP1_FIELDS, REG_STEP2_FIELDS } from 'src/dict/fields';
import {
  appDomain,
  $pathnameUrl, $firstPage,
} from 'src/models/App';
import { $isAuthenticated } from 'src/models/Auth';
import { $user } from 'src/models/User';

export const registrationDomain = appDomain.createDomain('Registration');

/// ///////////////////////////////////////////////////////////
// ================== СЛОВАРИ / КОНСТАНТЫ ================== //
/// ///////////////////////////////////////////////////////////

const { REGISTRATION } = PAGES_PATH;

const {
  PERSONAL, PASSPORT, ADDRESS, EXPERIENCE, VERIFICATION,
  FAILURE, SUCCESS, AGREEMENT, CONFIRM,
} = REG_PATH;

const { PHONE } = REG_STEP1_FIELDS;
const { PLACE_BIRTH } = REG_STEP2_FIELDS;

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ СОБЫТИЙ ===================== //
/// ///////////////////////////////////////////////////////////

// события присваивания селектора для калькулятора
export const getMoneyFn = registrationDomain.createEvent();
export const toRegistrationFn = registrationDomain.createEvent();
export const checkPageRegistrationFn = registrationDomain.createEvent();

export const unLockButtonFn = registrationDomain.createEvent();
export const lockButtonFn = registrationDomain.createEvent();

export const loadDataFromLSFn = registrationDomain.createEvent();

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ХРАНИЛИЦ ==================== //
/// ///////////////////////////////////////////////////////////

export const $lockButton = registrationDomain.createStore(false);

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ЭФФЕКТОВ ==================== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

export const $isRegistrationPage = $pathnameUrl.map(
  (path) => isCurrentPath(path, REGISTRATION),
);

export const $registrationPage = $firstPage.map((path) => {
  const arrPath = path.split('/');
  return arrPath.pop();
});

export const $classNamesMap = $pathnameUrl.map((path) => {
  const arrPath = path.split('/');
  const page = arrPath[arrPath.length - 1];

  let sectionClass = 'section';
  let pageClass = 'formPage';

  switch (page) {
    case VERIFICATION:
      sectionClass = 'section validation';
      pageClass = 'formPage validationPage';
      break;
    case FAILURE:
      pageClass = 'failurePage';
      break;
    case SUCCESS:
      pageClass = 'successfullLoanPage';
      break;
    case AGREEMENT:
    case CONFIRM:
      pageClass = 'agreementPage';
      break;
    default:
  }
  return { pageClass, sectionClass };
});

export const $routesStepsRegistration = combine(
  $isAuthenticated, () => [
    {
      path: PERSONAL, // фио / пол / дата рождения, email, цель займа
      visible: true,
    },
    {
      path: PASSPORT, // паспортные данные
      visible: true,
    },
    {
      path: ADDRESS, // адрес прописки
      visible: true,
    },
    {
      path: EXPERIENCE, // образование, работа и подобные вопросы
      visible: true,
    },
    {
      path: VERIFICATION, // верификация телефона
      visible: true,
    },
    /* {
      path: PAYMENT, // выбор банковской карты
      visible: true,
    },
    {
      path: PROCESSING, // обработка заявки
      visible: true,
    },
    {
      path: FAILURE, // обработка заявки
      visible: true,
    },
    {
      path: LOAN, // обработка заявки
      visible: true,
    },
    {
      path: SUCCESS, // обработка заявки
      visible: true,
    },
    {
      path: AGREEMENT, // обработка заявки
      visible: true,
    },
    {
      path: CONFIRM, // обработка заявки
      visible: true,
    }, */
  ].filter(({ visible }) => visible)
  ,
);

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ЭФФЕКТОВ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

// тут создаются эффекты через attach

/// ///////////////////////////////////////////////////////////
// ====================== ЮНИТЫ ФОРМ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем формы с помощью effector-forms

/// ///////////////////////////////////////////////////////////
//  ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ФОРМ + (ХРАНИЛИЩ, ЭФФЕКТОВ) //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ========================= ФАБРИКИ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем различные фабрики и хранилища с использованием фабрик

export const loadDataToFormOperator = ({ form, key }) => {
// При первом заходе на шаблон (определяется через Gate на самом верхнем уровне layout-a)
  sample({
    clock: sample({
      clock: $registrationPage,
      filter: (registrationPage) => {
        const data = storage.get(key);
        return !isEmpty(registrationPage)
          && registrationPage === key
          && !isEmpty(data);
      },
    }),
    fn: () => storage.get(key),
    target: [
      form.setForm,
      loadDataFromLSFn,
    ],
  });

  sample({
    clock: sample({
      clock: debounce({
        source: loadDataFromLSFn,
        timeout: 10,
      }),
      source: $registrationPage,
      filter: (registrationPage) => (
        !isEmpty(registrationPage) && registrationPage === key),
    }),
    source: [form.$values, form.fields],
    // filter: ([values]) => Object.entries(values).some(([, val]) => !isEmpty(val)),
    fn: ([values, formFields]) => ({ formFields, values }),
    target: [
      attach({
        effect: (_, { formFields, values }) => {
          Object.entries(values).forEach(([field, val]) => {
            if (!isEmpty(val)) {
              formFields[field].validate();
            }
          });
          return '';
        },
      }),
    ],
  });
};

export const setFormFieldsOperator = ({
  fields, form, key, combineValues,
}) => {
  sample({
    clock: [
      debounce({
        source: sample({
          clock: [$pathnameUrl, $user],
          source: $pathnameUrl, // не точка входа сайт
          filter: (path) => path.split('/').pop() === key,
        }),
        timeout: 10,
      }),
    ],
    source: combineValues,
    fn: (data) => {
      // console.log('data', data);
      let dataFromLS = storage.get(key);
      if (isEmpty(dataFromLS)) {
        dataFromLS = {};
      }
      fields.forEach((field) => {
        dataFromLS[field] = get(dataFromLS, field, '');
        if (isEmpty(dataFromLS[field])) {
          dataFromLS[field] = get(data, field, '');
        }
      });
      if (isEmpty(dataFromLS[PLACE_BIRTH])) {
        delete dataFromLS[PLACE_BIRTH];
      }
      if (isEmpty(dataFromLS[PHONE])) {
        delete dataFromLS[PHONE];
      }

      return dataFromLS;
    },
    target: form.setInitialForm,
  });
};
