import React, { useState } from 'react';
import { useUnit } from 'effector-react';
import { useForm } from 'effector-forms';
import { get } from 'src/lib/lodash';
import { getEventsField } from 'src/lib/form';
import { REG_STEP1_FIELDS } from 'src/dict/fields';
// import { getDocFn } from 'src/models/Documents';
import {
  changeAgreementDocsFn,
  $agreementDocs,
  step1Form,
} from 'src/models/Registration/Personal';
import { Checkbox, Button } from 'src/ui/components/Form';
import { Official } from './children/Official';

const { AGREEMENT } = REG_STEP1_FIELDS;

const toggleHandler = (setVisibleOfficial, val) => () => setVisibleOfficial(!val);

export const Agreement = React.memo(({ closeCallback }) => {
  const [isOpenOfficial, setVisibleOfficial] = useState(false);
  const agreementDocs = useUnit($agreementDocs);
  const { ...restProps } = useForm(step1Form);

  return (
    <div className="modal-main">
      <button
        type="button"
        className="close-modal__btn close-modal"
        onClick={closeCallback}
      >
        <img src="/img/modal/close.svg" alt="" />
      </button>
      <form>
        <div className="modal-wrapper">
          <h2 className="modal-title">Соглашение</h2>
          <div className="changeAmountAndTerm-checks">
            {agreementDocs.map(
              ({
                id, label, value, link,
              }) => (
                <div className="modal-checkbox default-checkbox" key={id}>
                  <input
                    type="checkbox"
                    id={`ex${id}`}
                    checked={value}
                    onChange={changeAgreementDocsFn.prepend(
                      () => ({
                        id, value: !value,
                      }),
                    )}
                  />
                  <label htmlFor={`ex${id}`} className="default-checkbox__label">
                    <div className="default-checkbox__block" />
                    <a
                      // onClick={getDocFn.prepend(() => ({ templateId: id, docName: label }))}
                      className="default-checkbox__text"
                      href={link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {label}
                    </a>
                  </label>
                </div>
              ),
            )}
          </div>
          <p className="modal-description nocenter">
            Подтверждаю, что в отношении ООО МКК ”Варенье Кредит” действую
            сознательно, свободно, своей волей и в своих интересах, мои действия не контролируются иными третьими
            лицами, самостоятельно представляю свои интересы, также подтверждаю что я не являюсь
            {' '}
            {/* <a href="#" onClick={toggleHandler(setVisibleOfficial, true)}>
              публичным
              должностным лицом
            </a> */}
            <span className=" default-checkbox__text">
              <button
                onClick={toggleHandler(setVisibleOfficial, isOpenOfficial)}
                type="button"
                className="button-agreement"
              >
                публичным
                должностным лицом
              </button>
            </span>
          </p>
          {isOpenOfficial && <Official />}

          <div className="modal-checkbox default-checkbox green agreeWithEverything">
            <Checkbox
              {...getEventsField(get(restProps.fields, AGREEMENT))}
              name={AGREEMENT}
              id={AGREEMENT}
            />
            <label htmlFor={AGREEMENT} className="default-checkbox__label ">
              <div className="default-checkbox__block" />
              <p className="default-checkbox__text">Согласен со всем</p>
            </label>
          </div>

          <div className="modal-button">
            <Button
              className="close-modal"
              onClick={closeCallback}
            >
              Продолжить
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
});
