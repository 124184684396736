import { combine } from 'effector';
import { createForm } from 'effector-forms';
import { rules } from 'src/lib/rules';
import { LOAN_REPAYMENT_FORM } from 'src/dict/fields';
import { isCurrentPath } from 'src/lib/url';
import { PAGES_PATH } from 'src/dict/path';
import { $pathnameUrl, $pathParams, appDomain } from 'src/models/App';
import {
  createIntentRepaymentSign,
  getStatusRepaymentSign,
  sendRepaymentIframeSign,
} from 'src/api/signatures/Repayment';
import { getStatusToRepaySign, sendToRepaySign } from 'src/api/signatures/Agreement';
import { timerSign } from 'src/signatures/Timer';
import { storage } from '../../lib/storage';
import { isEmpty } from '../../lib/lodash';

export const loanRepaymentDomain = appDomain.createDomain('LoanRepayment');

/// ///////////////////////////////////////////////////////////
// ================== СЛОВАРИ / КОНСТАНТЫ ================== //
/// ///////////////////////////////////////////////////////////

const { LOAN_REPAYMENT, PAYMENT_RESULT } = PAGES_PATH;
const { NUMBER, LAST_NAME } = LOAN_REPAYMENT_FORM;

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ СОБЫТИЙ ===================== //
/// ///////////////////////////////////////////////////////////

export const goLoanRepaymentPageFn = loanRepaymentDomain.createEvent();
export const bindCalcAmountToRepayFn = loanRepaymentDomain.createEvent();
export const closeCalculatorToRepayFn = loanRepaymentDomain.createEvent();
export const changeValueCalcAmountToRepayFn = loanRepaymentDomain.createEvent();

// Событие нажатия кнопки "Применить" из калькулятора погашения
export const changeAmountToRepayFn = loanRepaymentDomain.createEvent();
export const sendToRepayFn = loanRepaymentDomain.createEvent();
export const resetRepaymentFn = loanRepaymentDomain.createEvent();

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ХРАНИЛИЦ ==================== //
/// ///////////////////////////////////////////////////////////
export const $repaymentIntentId = loanRepaymentDomain.createStore('');
export const $repaymentIntentItem = loanRepaymentDomain.createStore({});
export const $amountToRepay = loanRepaymentDomain.createStore('');
export const $selectorCalcAmountToRepay = loanRepaymentDomain.createStore('');
/*
export const $amountAgreements = loanRepaymentDomain.createStore('');
export const $termAgreements = loanRepaymentDomain.createStore('');
export const $dateAgreements = loanRepaymentDomain.createStore('');
export const $amountBodyAgreements = loanRepaymentDomain.createStore('');
*/

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ЭФФЕКТОВ ==================== //
/// ///////////////////////////////////////////////////////////

export const createIntentToRepayFx = loanRepaymentDomain.createEffect(createIntentRepaymentSign);
export const getStatusToRepayFx = loanRepaymentDomain.createEffect(getStatusRepaymentSign);
export const timerToRepayStatusFx = loanRepaymentDomain.createEffect(timerSign);
export const sendRepaymentIframeFx = loanRepaymentDomain.createEffect(sendRepaymentIframeSign);

// /////// /////////////////////////////////////////////////
export const getStatusToRepayFinalFx = loanRepaymentDomain.createEffect(getStatusToRepaySign);
export const sendToRepayFx = loanRepaymentDomain.createEffect(sendToRepaySign);
export const timerToRepayStatusFinalFx = loanRepaymentDomain.createEffect(timerSign);

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////
export const $isLoanRepaymentPage = $pathnameUrl.map((path) => isCurrentPath(path, LOAN_REPAYMENT));
export const $isPaymentResultPage = $pathnameUrl.map((path) => isCurrentPath(path, PAYMENT_RESULT));
export const $isPaymentSuccessPage = combine($isLoanRepaymentPage, $pathParams,
  (isLoanRepaymentPage, pathParams) => {
    const success = pathParams.replace('?', '') === 'success';
    return isLoanRepaymentPage && success;
  });
export const $isPaymentSuccessDataPage = combine($isPaymentSuccessPage,
  (isPaymentSuccessPage) => isPaymentSuccessPage && !isEmpty(storage.get('repayment')));

export const $isPaymentFailPage = combine($isLoanRepaymentPage, $pathParams,
  (isLoanRepaymentPage, pathParams) => {
    const fail = pathParams.replace('?', '') === 'fail';
    return isLoanRepaymentPage && fail;
  });
export const $isPaymentFailDataPage = combine($isPaymentFailPage,
  (isPaymentFailPage) => isPaymentFailPage && !isEmpty(storage.get('repayment')));

// если юзер на этих страницах - очищаем данные из LS
export const $isRepaymentResultDataPage = combine($isPaymentSuccessDataPage, $isPaymentFailDataPage,
  (isPaymentSuccessDataPage, isPaymentFailDataPage) => (
    !isEmpty(isPaymentSuccessDataPage) || !isEmpty(isPaymentFailDataPage)
  ));

export const $isClearDataRepayment = combine($isPaymentResultPage, $isPaymentSuccessPage, $isPaymentFailPage,
  (isPaymentResultPage, isPaymentSuccessPage, isPaymentFailPage) => (
    isEmpty(isPaymentResultPage) && isEmpty(isPaymentSuccessPage) && isEmpty(isPaymentFailPage)
  ));

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ЭФФЕКТОВ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

// тут создаются эффекты через attach

/// ///////////////////////////////////////////////////////////
// ====================== ЮНИТЫ ФОРМ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем формы с помощью effector-forms

export const loanRepaymentDomainForm = createForm({
  fields: {
    [NUMBER]: { rules: [rules.required()], init: '' },
    [LAST_NAME]: {
      rules: [
        rules.required(), rules.letter(), rules.maxLength(50),
      ],
      init: '',
    },
  },
  validateOn: ['blur', 'submit'],
  domain: loanRepaymentDomain,
});

/// ///////////////////////////////////////////////////////////
//  ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ФОРМ + (ХРАНИЛИЩ, ЭФФЕКТОВ) //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ========================= ФАБРИКИ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем различные фабрики и хранилища с использованием фабрик
