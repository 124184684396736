import React from 'react';
import { useUnit } from 'effector-react';
import { getLinkForAuthESIAFn } from 'src/models/Esia';
import { get, isEmpty } from 'src/lib/lodash';
import { $configDict } from 'src/models/Dictionaries';
import { $user } from 'src/models/User';

export const EsiaBloockReg = React.memo(() => {
  const [user, configDict] = useUnit([$user, $configDict]);
  const esiaData = get(user, 'esia_data', {});
  const isAccessEsia = get(configDict, 'settings.esia.enabled', false);
  return (
    <>
      {!isEmpty(isAccessEsia) && isEmpty(esiaData) && (
      <div className="reg">
        <div className="gos">
          <p>
            Авторизация через Госуслуги возможна только при наличии
            подтвержденной учетной записи на сайте www.gosuslugi.ru
          </p>
          <a
            href="#"
            className="default-btn white big"
            onClick={getLinkForAuthESIAFn.prepend(() => 'register')}
          >
            <div>Получите на 5 000₽ больше</div>
            <div className="bottom">
              Оформите заявку через
              <span className="icon">
                <img
                  src="/img/main/gosusluga.png"
                  alt=""
                />
              </span>
            </div>
          </a>
        </div>
      </div>
      )}
    </>
  );
});
