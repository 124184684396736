import React from 'react';
import { NavLink } from 'react-router-dom';

const Logo = React.memo(() => (
  <NavLink to="/" className="logo">
    <img src="/img/main/logo.png" alt="logo" />
  </NavLink>
));

export { Logo };
