import { sample } from 'effector';
import { isEmpty } from 'src/lib/lodash';
import { mountAppFn } from 'src/models/App';
import { openPageExperienceFn } from 'src/models/Registration/Experience';
import { fnState } from './helper';
import {
  // эффекты
  getMessagesDictFx, getPurposesDictFx, getEducationsDictFx, getConfigDictFx,
  getMaritalStatusesDictFx, getWorkScopesDictFx, getEmploymentsDictFx,
  // события
  getMessagesDictFn, getPurposesDictFn, getEducationsDictFn, getConfigDictFn,
  getMaritalStatusesDictFn, getWorkScopesDictFn, getEmploymentsDictFn,
  // хранилища
  $messagesDict, $purposesDict, $configDict,
  $educationsDict, $maritalStatusesDict, $workScopesDict, $employmentsDict,
} from './index';

// укорачиваем однообразный код для on и gurd
const dictItems = [
  { fn: getMessagesDictFn, fx: getMessagesDictFx, store: $messagesDict },
  { fn: getPurposesDictFn, fx: getPurposesDictFx, store: $purposesDict },
  { fn: getEducationsDictFn, fx: getEducationsDictFx, store: $educationsDict },
  { fn: getMaritalStatusesDictFn, fx: getMaritalStatusesDictFx, store: $maritalStatusesDict },
  { fn: getWorkScopesDictFn, fx: getWorkScopesDictFx, store: $workScopesDict },
  { fn: getEmploymentsDictFn, fx: getEmploymentsDictFx, store: $employmentsDict },
  { fn: getConfigDictFn, fx: getConfigDictFx, store: $configDict },
];

/// ///////////////////////////////////////////////////////////
// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
/// ///////////////////////////////////////////////////////////

dictItems.forEach(({ store, fx }) => {
  store.on(fx.doneData, fnState);
});

/// ///////////////////////////////////////////////////////////
// ===================== БЛОК ОПЕРАТОРОВ =================== //
/// ///////////////////////////////////////////////////////////

// При первом заходе на шаблон (определяется через Gate на самом верхнем уровне layout-a)
sample({
  clock: mountAppFn,
  // вызываем событие для эффекта загрузки продуктов
  target: [getMessagesDictFn, getPurposesDictFn, getConfigDictFn],
});

sample({
  clock: openPageExperienceFn,
  target: [
    getEducationsDictFn,
    getMaritalStatusesDictFn,
    getWorkScopesDictFn,
    getEmploymentsDictFn,
  ],
});

dictItems.forEach(({ fn, fx, store }) => {
  sample({
    clock: fn,
    source: store,
    filter: (items) => isEmpty(items),
    target: fx,
  });
});
