import { axios } from 'src/api/axios';

export const createDocsSign = (applicationId) => axios.post(
  `/applications/${applicationId}/documents`,
);

export const getTemplatesDocSign = () => axios.get('/documents/first-step');

export const getTemplateDocSign = ({ templateId }) => axios.get(
  `/documents/templates/${templateId}`, { responseType: 'blob' },
);
