import React from 'react';
import { LINKS_DOCS } from 'src/dict/docs';
import { PAGES_PATH } from 'src/dict/path';
import { CalculatorSidebar } from 'src/ui/components';

import './Documents/Documents.css';

const Regulation = () => (
  <>
    <main className="main legalPage legalLinks">
      <section className="document">
        <div className="wrapper">
          <h1 className="title">Документы</h1>
          <h4 className="description">
            Работаем исключительно по стандартам Банка России и строго в рамках законодательства РФ.
          </h4>
          <div className="content">
            <div className="left">
              <CalculatorSidebar />
            </div>

            <div className="right">
              <div className="topBlock"><img src="/img/other/topbgblock.svg" alt="" /></div>
              <div className="topBlock mobile"><img src="/img/other/topbgblock-mobile.svg" alt="" /></div>

              <div className="block">
                <div className="block-content">
                  {/* <h4 className="block-title">{title}</h4> */}

                  <div className="block-line" />
                  {/* <h4 className="block-title">Юридическая база</h4> */}
                  <div className="block-regulation-docs">

                    <div className="block-doc">
                      <p>
                        Общество с ограниченной ответственностью
                        «Микрокредитная компания «Варенье Кредит» зарегистрировано
                        в Государственном реестре микрофинансовых организаций 16 августа 2021 года.
                      </p>
                    </div>

                    <div className="part-link">
                      Номер свидетельства:
                      {' '}
                      <a
                        target="_blank"
                        href="https://www.cbr.ru/registries/microfinance/?utm_source=w&utm_content=page#a_14199"
                        rel="noreferrer"
                      >
                        №2103140009725 — cbr.ru
                      </a>
                    </div>

                    <a
                      href={LINKS_DOCS.HOLIDAY}
                      className="block-doc"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="icon"><img src="/img/icons/pdf.svg" alt="" /></span>
                      <p>
                        Правила предоставления займов
                        {' '}
                        (152 kb)
                      </p>
                    </a>

                    <a
                      href={`/${PAGES_PATH.LOGIN}`}
                      className="block-doc"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p>
                        Личный кабинет заемщика
                      </p>
                    </a>

                    <div className="part-link">
                      ООО МКК «Варенье Кредит» является членом
                      {' '}
                      <a
                        target="_blank"
                        href="https://npmir.ru/"
                        rel="noreferrer"
                      >
                        СРО «МиР»
                      </a>
                    </div>

                    <a
                      href="https://fssp.gov.ru/"
                      className="block-doc"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p>
                        Федеральная служба судебных приставов
                      </p>
                    </a>

                    <a
                      href="https://finombudsman.ru/"
                      className="block-doc"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p>
                        Официальный сайт финансового уполномоченного
                      </p>
                    </a>
                  </div>
                  <br />
                  <div className="block-line" />

                </div>
              </div>
              <div className="bottomBlock mobile"><img src="/img/other/bottombgblock.svg" alt="" /></div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </>
);

export { Regulation };
