import React from 'react';
import { get, isEmpty } from 'src/lib/lodash';

/* const handlerFocus = (e) => {
  setTimeout(() => {
    // eslint-disable-next-line no-multi-assign
    e.target.selectionStart = e.target.selectionEnd = 2;
  }, 0);
}; */

const handlerChange = ({
  onChange, value, // jumpField, maskPattern,
}) => (e) => {
  if (e.target.value.length > 1) {
    e.target.value = e.target.value.replace(value, '');
  }
  onChange(e);
  // jumpField(e, maskPattern);
};

export const InputOneChar = React.forwardRef(({
  value, onChange, onBlur, // приходят из getPropsField - формы эффектора
  placeholder, name, label, type, tabIndex, typeInput, className,
  mask, maskPlaceholder, maskPattern, jumpField,
  required, errorText, hasError, disabled, ...restProps
}, ref) => {
  const hasFocused = name === get(document, 'activeElement.id', false);

  const nameClasses = [className];
  if (required && (hasError || (!isEmpty(`${value}`.trim()) && !hasFocused))) {
    nameClasses.push(hasError ? 'invalid' : ' valid');
  }
  return (
    <>
      <input
        type="number"
        {...restProps}
        tabIndex={tabIndex}
        required={required}
        value={(isEmpty(value) ? '' : value)}
        onChange={handlerChange({
          onChange, value, // jumpField, maskPattern,
        })}
        // onFocus={handlerFocus}
        onBlur={onBlur}
        disabled={disabled}
        id={name}
        ref={ref}
        className={`default-input code-input ${nameClasses.join(' ')}`}
        inputMode="numeric"
      />
    </>
  );
});

InputOneChar.defaultProps = {
  className: '',
  tabIndex: 0,
  type: 'text',
  name: '',
  label: '',
  typeInput: '',
  placeholder: ' ',
  value: '',
  hasError: false,
  errorText: '',
  onChange: () => '',
  onBlur: () => '',
  jumpField: () => '',
  required: false,
  disabled: false,
  mask: '',
  maskPlaceholder: '',
  maskPattern: '',
};
