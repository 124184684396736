import React from 'react';
import { useUnit } from 'effector-react';
import { useForm } from 'effector-forms';
import { get } from 'src/lib/lodash';
import {
  getEventsField,
  getPropsField, getRefField, jumpFocusField, onSubmit,
} from 'src/lib/form';
import { maskNumber, maskPosition } from 'src/lib/mask';
import { maskWorkPhone } from 'src/lib/maskPlaceholder';
import { REG_STEP4_FIELDS } from 'src/dict/fields';
import {
  setRefFieldsFn, jumpFocusFieldFn, // jumpFocusFn,
} from 'src/models/Helper/Ref';
import { getKeyboardLayoutFn } from 'src/models/Helper/Keyboard';
import { $dictCombineData } from 'src/models/Dictionaries';
import {
  $isNotWorkActivityType,
  step4Form,
} from 'src/models/Registration/Experience';
import {
  Checkbox, Input,
  SelectPosAbsolute as Select,
  ButtonWithLoader as Button,
} from 'src/ui/components/Form';

const {
  EDUCATION, FAMILY_STATUS, ACTIVITY_TYPE, SCOPE_EMPLOYMENT, POSITION,
  PLACE_WORK, PHONE_WORK, INCOME_PER_MONTH, COST_LOANS_PER_MONTH, EXISTENCE_BANKRUPTCY_CASE,
} = REG_STEP4_FIELDS;

export const ExperienceForm = React.memo(() => {
  const {
    submit, eachValid, ...restProps
  } = useForm(step4Form);

  const [{
    workScopesDict, employmentsDict, maritalStatusesDict, educationsDict,
  }, isNotWorkActivityType] = useUnit([$dictCombineData, $isNotWorkActivityType]);

  return (
    <form onSubmit={onSubmit(submit)}>

      <div className="block-item">
        <Select
          label="Образование"
          placeholder=""
          options={educationsDict}
          name={EDUCATION}
          {...getPropsField({
            fieldName: EDUCATION, props: restProps,
          })}
          ref={getRefField({
            fieldName: EDUCATION, callBack: setRefFieldsFn,
          })}
          jumpField={jumpFocusField({
            fieldName: FAMILY_STATUS,
            callBack: jumpFocusFieldFn,
          })}
          required
        />
      </div>

      <div className="block-item default-select">
        <Select
          label="Семейное положение"
          placeholder=""
          options={maritalStatusesDict}
          name={FAMILY_STATUS}
          {...getPropsField({
            fieldName: FAMILY_STATUS, props: restProps,
          })}
          ref={getRefField({
            fieldName: FAMILY_STATUS, callBack: setRefFieldsFn,
          })}
          jumpField={jumpFocusField({
            fieldName: ACTIVITY_TYPE,
            callBack: jumpFocusFieldFn,
          })}
          required
        />
      </div>

      <div className="block-line" />
      <div className="block-item default-select">
        <Select
          label="Тип трудоустройства"
          placeholder=""
          options={employmentsDict}
          name={ACTIVITY_TYPE}
          {...getPropsField({
            fieldName: ACTIVITY_TYPE, props: restProps,
          })}
          ref={getRefField({
            fieldName: ACTIVITY_TYPE, callBack: setRefFieldsFn,
          })}
          jumpField={jumpFocusField({
            fieldName: SCOPE_EMPLOYMENT,
            callBack: jumpFocusFieldFn,
          })}
          required
        />
      </div>

      {!isNotWorkActivityType && (
        <>
          <div className="block-item default-select">
            <Select
              label="Сфера трудоустройства"
              placeholder=""
              options={workScopesDict}
              name={SCOPE_EMPLOYMENT}
              {...getPropsField({
                fieldName: SCOPE_EMPLOYMENT, props: restProps,
              })}
              ref={getRefField({
                fieldName: SCOPE_EMPLOYMENT, callBack: setRefFieldsFn,
              })}
              jumpField={jumpFocusField({
                fieldName: POSITION,
                callBack: jumpFocusFieldFn,
              })}
              required
            />
          </div>

          <div className="block-item">
            <Input
              label="Должность"
              name={POSITION}
              {...getPropsField({
                fieldName: POSITION, props: restProps, mask: maskPosition,
              })}
              ref={getRefField({
                fieldName: POSITION, callBack: setRefFieldsFn,
              })}
              onInput={getKeyboardLayoutFn.prepend((e) => ({
                field: get(restProps, `fields.${POSITION}`),
                value: e.target.value,
              }))}
              required
            />
          </div>

          <div className="block-item">
            <Input
              label="Место работы"
              name={PLACE_WORK}
              {...getPropsField({
                fieldName: PLACE_WORK, props: restProps, // mask: maskPosition,
              })}
              ref={getRefField({
                fieldName: PLACE_WORK, callBack: setRefFieldsFn,
              })}
              onInput={getKeyboardLayoutFn.prepend((e) => ({
                field: get(restProps, `fields.${PLACE_WORK}`),
                value: e.target.value,
              }))}
              required
            />
          </div>

          <div className="block-item">
            <Input
              label="Рабочий телефон"
              type="text"
              inputMode="tel"
              name={PHONE_WORK}
              {...maskWorkPhone}
              {...getPropsField({
                fieldName: PHONE_WORK, props: restProps,
              })}
              ref={getRefField({
                fieldName: PHONE_WORK, callBack: setRefFieldsFn,
              })}
              jumpField={jumpFocusField({
                fieldName: INCOME_PER_MONTH,
                callBack: jumpFocusFieldFn,
                trigger: { name: 'maxLength', data: { value: 18 } },
              })}
              required
            />
          </div>
        </>
      )}

      <div className="block-line" />

      <div className="block-item">
        <Input
          label="Доходы в месяц"
          type="tel"
          name={INCOME_PER_MONTH}
          {...getPropsField({
            fieldName: INCOME_PER_MONTH, props: restProps, mask: maskNumber,
          })}
          ref={getRefField({
            fieldName: INCOME_PER_MONTH, callBack: setRefFieldsFn,
          })}
          required
        />
      </div>

      <div className="block-item">
        <Input
          label="Расходы на кредиты в месяц"
          type="tel"
          name={COST_LOANS_PER_MONTH}
          {...getPropsField({
            fieldName: COST_LOANS_PER_MONTH, props: restProps, mask: maskNumber,
          })}
          ref={getRefField({
            fieldName: COST_LOANS_PER_MONTH, callBack: setRefFieldsFn,
          })}
          required
        />
      </div>

      <div className="block-item default-checkbox">
        <Checkbox
          label="Наличие дела о банкротстве"
          {...getEventsField(get(restProps.fields, EXISTENCE_BANKRUPTCY_CASE))}
          name={EXISTENCE_BANKRUPTCY_CASE}
        />
      </div>

      <div className="block-button block-button__form">
        <Button
          type="submit"
          className="nextStep"
        >
          Далее
        </Button>
      </div>

    </form>
  );
});
