import React from 'react';
import { ButtonWithLoader as Button } from 'src/ui/components/Form';

const styleSubTitle = {
  margin: '30px',
  textAlign: 'left',
};

export const EsiaError = React.memo((props) => {
  const {
    closeCallback,
  } = props;
  return (

    <div className="modal-main">
      <button
        type="button"
        className="close-modal__btn close-modal"
        onClick={closeCallback}
      >
        <img src="/img/modal/close.svg" alt="" />
      </button>
      <h2 className="modal-title">При привязке профиля возникла ошибка!</h2>
      <div className="sub-title">
        <div style={styleSubTitle}>
          При привязке профиля из ГосУслуг произошла ошибка, пожалуйста, попробуйте еще раз.
        </div>

        <div style={styleSubTitle}>
          <div className="button">
            <Button
              type="button"
              className="nextStep"
              onClick={closeCallback}
            >
              Продолжить
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
});
