import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { get, isEmpty } from 'src/lib/lodash';
import { daData } from 'src/dict/config';
import {
  getInputDaDataModelApiFn,
  $objInputDaDataModels,
} from 'src/models/components/InputDaData/Simple';
import { InputDaData } from './shared/InputDaData';

const {
  TIMEOUT_DEBOUNCE, MIN_LENGTH, PATH, LIMIT,
} = daData.EMAIL;

export const InputEmail = React.forwardRef((props, ref) => {
  const { name } = props;

  const dataInputDaDataApi = get(useStore($objInputDaDataModels), name, {});
  useEffect(() => {
    getInputDaDataModelApiFn({
      typeAction: PATH,
      timeoutDebounce: TIMEOUT_DEBOUNCE,
      minLength: MIN_LENGTH,
      limit: LIMIT,
      ...props,
    });
  }, []);

  if (isEmpty(dataInputDaDataApi)) return <div />;

  return (
    <InputDaData
      modelData={{ ...dataInputDaDataApi }}
      propsData={{ ...props, className: 'email' }}
      ref={ref}
    />
  );
});
