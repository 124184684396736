import { createForm } from 'effector-forms';
import { isEmpty } from 'src/lib/lodash';
import { isCurrentPath } from 'src/lib/url';
import { rules } from 'src/lib/rules';
import { LOGIN_FIELDS } from 'src/dict/fields';
import { PAGES_PATH } from 'src/dict/path';
import { storage } from 'src/lib/storage';
import { token } from 'src/dict/config';
import { $pathnameUrl, appDomain } from 'src/models/App';
import {
  authLoginSign,
} from 'src/api/signatures/Auth';

export const authDomain = appDomain.createDomain('Auth');

/// ///////////////////////////////////////////////////////////
// ================== СЛОВАРИ / КОНСТАНТЫ ================== //
/// ///////////////////////////////////////////////////////////

const { LOGIN } = PAGES_PATH;
const { PHONE, PASSWORD } = LOGIN_FIELDS;

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ СОБЫТИЙ ===================== //
/// ///////////////////////////////////////////////////////////

export const goLoginPageFn = authDomain.createEvent();
export const authLoginAfterFn = authDomain.createEvent();

export const logoutFn = authDomain.createEvent();
export const clearAllStorageFn = authDomain.createEvent();
export const clearRegStorageFn = authDomain.createEvent();
export const redirectAfterTokenAdminFn = authDomain.createEvent();

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ХРАНИЛИЦ ==================== //
/// ///////////////////////////////////////////////////////////

export const $tokenData = authDomain.createStore({
  accessToken: storage.get(token.ACCESS),
  refreshToken: storage.get(token.REFRESH),
});

export const $urlAuthESIA = authDomain.createStore('');

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ЭФФЕКТОВ ==================== //
/// ///////////////////////////////////////////////////////////

// авторизация
export const authLoginFx = authDomain.createEffect(authLoginSign);

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

export const $isLoginPage = $pathnameUrl.map((path) => isCurrentPath(path, LOGIN));

export const $isAuthenticated = $tokenData.map(
  ({ accessToken, refreshToken }) => !isEmpty(accessToken) || !isEmpty(refreshToken),
);

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ЭФФЕКТОВ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

// тут создаются эффекты через attach

/// ///////////////////////////////////////////////////////////
// ====================== ЮНИТЫ ФОРМ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем формы с помощью effector-forms

export const loginForm = createForm({
  fields: {
    [PHONE]: { rules: [rules.required(), rules.phone()], init: '' },
    [PASSWORD]: { rules: [rules.required()], init: '' },
  },
  validateOn: ['blur', 'submit'],
  domain: authDomain,
});

/// ///////////////////////////////////////////////////////////
//  ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ФОРМ + (ХРАНИЛИЩ, ЭФФЕКТОВ) //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ========================= ФАБРИКИ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем различные фабрики и хранилища с использованием фабрик
