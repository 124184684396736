import React from 'react';
import { LoginForm } from 'src/ui/components/LoginForm';
// import './UserExists.css';

export const UserExists = React.memo(({ closeCallback }) => (
  <div className="modal-main user-exist">
    <button
      type="button"
      className="close-modal__btn close-modal"
      onClick={closeCallback}
    >
      <img src="/img/modal/close.svg" alt="" />
    </button>
    <h2 className="modal-title">Пользователь с такими данными уже зарегистрирован в системе!</h2>
    <div className="sub-title">

      <div>Для продолжения, пожалуйста, войдите</div>
    </div>

    <LoginForm />
  </div>
));
