import { combine } from 'effector';
import { createForm } from 'effector-forms';
import { pending } from 'patronum/pending';
import { appDomain } from 'src/models/App';
import {
  getCurrentUserSign,
  getAddressDataSign,
  addWorksUserSign,
  getWorksUserSign,
  getItemsIdentitiesDataSign,
  getItemIdentitiesDataSign,
  updateContactsUserSign,
  updateAdditionalInfoUserSign,
  updateAdditionalProfileUserSign,
  changePasswordUserSign,
} from 'src/api/signatures/User';
import { rules } from 'src/lib/rules';
import { REG_STEP1_FIELDS, SECURITY_FIELDS } from 'src/dict/fields';

export const userDomain = appDomain.createDomain('User');

/// ///////////////////////////////////////////////////////////
// ================== СЛОВАРИ / КОНСТАНТЫ ================== //
/// ///////////////////////////////////////////////////////////

const { PASSWORD, PASSWORD_CONFIRM } = SECURITY_FIELDS;
const {
  FIRST_NAME, LAST_NAME, MIDDLE_NAME, YEAR_OF_BIRTH,
  PHONE, GENDER, EMAIL,
} = REG_STEP1_FIELDS;

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ СОБЫТИЙ ===================== //
/// ///////////////////////////////////////////////////////////

export const logoutFn = userDomain.createEvent();

export const resetUserStatesFn = userDomain.createEvent();
export const getCurrentUserFn = userDomain.createEvent();

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ХРАНИЛИЦ ==================== //
/// ///////////////////////////////////////////////////////////

export const $user = userDomain.createStore({});

export const $userId = userDomain.createStore('');

export const $phone = userDomain.createStore('');
export const $lastName = userDomain.createStore('');
export const $firstName = userDomain.createStore('');
export const $middleName = userDomain.createStore('');
// birth_date
export const $yearOfBirth = userDomain.createStore('');
export const $gender = userDomain.createStore('');
export const $email = userDomain.createStore('');
export const $snils = userDomain.createStore('');
// export const $city = userDomain.createStore(storage.get('city'), { name: 'city' });

export const $isVerified = userDomain.createStore(false);
export const $isRepeated = userDomain.createStore(false);
export const $isPasswordSpecified = userDomain.createStore(false);
export const $maritalStatus = userDomain.createStore(false);
export const $education = userDomain.createStore({});
export const $employment = userDomain.createStore({});

export const $passportId = userDomain.createStore('');
export const $addressPermanentId = userDomain.createStore('');
export const $addressActualId = userDomain.createStore('');
export const $workId = userDomain.createStore('');

export const $itemAddressData = userDomain.createStore({});
export const $itemIdentitiesData = userDomain.createStore({});
export const $dataESIA = userDomain.createStore({});

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ЭФФЕКТОВ ==================== //
/// ///////////////////////////////////////////////////////////

// получение данных юзера
export const getCurrentUserFromRegFx = userDomain.createEffect(getCurrentUserSign);
export const getCurrentUserFromAuthFx = userDomain.createEffect(getCurrentUserSign);

export const getCurrentUserFx = userDomain.createEffect(getCurrentUserSign);
export const getCurrentUserOnlyQueryFx = userDomain.createEffect(getCurrentUserSign);
export const getCurrentUserAfterConfirmSuccessFx = userDomain.createEffect(getCurrentUserSign);

// запись / обновление контактов
export const updateContactsUserFx = userDomain.createEffect(updateContactsUserSign);

export const getItemsIdentitiesDataFx = userDomain.createEffect(getItemsIdentitiesDataSign);
export const getItemIdentitiesDataFx = userDomain.createEffect(getItemIdentitiesDataSign);

export const getAddressDataFx = userDomain.createEffect(getAddressDataSign);

export const updatePhoneUserFx = userDomain.createEffect(updateContactsUserSign);
export const updateHomePhoneUserFx = userDomain.createEffect(updateContactsUserSign);
export const updateWorkPhoneUserFx = userDomain.createEffect(updateContactsUserSign);

// эффект записи / обновления контактов непосредствено после регистрации
export const updateContactsUserAfterRegFx = userDomain.createEffect(updateContactsUserSign);

export const addWorksUserFx = userDomain.createEffect(addWorksUserSign);
export const getWorksUserFx = userDomain.createEffect(getWorksUserSign);

// запись / обновление дополнительной информации
export const updateAdditionalInfoUserFx = userDomain.createEffect(updateAdditionalInfoUserSign);
export const updateAdditionalProfileUserFx = userDomain.createEffect(updateAdditionalProfileUserSign);

// эффект записи / обновления дополнительной информации непосредствено после регистрации
export const updateAdditionalInfoUserAfterRegFx = userDomain.createEffect(updateAdditionalInfoUserSign);

export const changePasswordUserFx = userDomain.createEffect(changePasswordUserSign);

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

export const $userCombineData = combine(
  $firstName, $lastName, $middleName, $phone, $yearOfBirth, $email, $gender,
  (firstName, lastName, middleName,
    phone, yearOfBirth, email, gender) => ({
    [FIRST_NAME]: firstName,
    [LAST_NAME]: lastName,
    [MIDDLE_NAME]: middleName,
    [YEAR_OF_BIRTH]: yearOfBirth,
    [PHONE]: phone,
    [GENDER]: gender,
    [EMAIL]: email,
  }),
);

export const $isLoadingUser = pending({
  effects: [getCurrentUserFx],
});

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ЭФФЕКТОВ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

// тут создаются эффекты через attach

/// ///////////////////////////////////////////////////////////
// ====================== ЮНИТЫ ФОРМ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем формы с помощью effector-forms
export const changePasswordForm = createForm({
  fields: {
    [PASSWORD]: { rules: [rules.required()], init: '' },
    [PASSWORD_CONFIRM]: { rules: [rules.required(), rules.confirm()], init: '' },
  },
  validateOn: ['blur', 'submit'],
  domain: userDomain,
});

/// ///////////////////////////////////////////////////////////
//  ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ФОРМ + (ХРАНИЛИЩ, ЭФФЕКТОВ) //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ========================= ФАБРИКИ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем различные фабрики и хранилища с использованием фабрик
