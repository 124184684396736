import {
  sample, attach,
} from 'effector';
import { spread } from 'patronum/spread';
import noUiSlider from 'nouislider';
import { get, isEmpty } from 'src/lib/lodash';
import { findObjectWithMinProp } from 'src/lib/payment';
import { PRODUCT_FIELDS } from 'src/dict/fields';
import { mountAppFn } from 'src/models/App';
import { closeModalFn } from 'src/models/components/Modal';
import { $isProfilePage } from 'src/models/Profile';
import {
  //
  getProductsCalcFn,
  closeCalculatorPageFn,
  backupDataCalcFn, changeBackupDataCalcFn, closeChangeCalcFn, getPersonalProductsCalcFn,
  changeValueCalcAmountFn, changeValueCalcPeriodFn, bindCalcAmountFn, bindCalcPeriodFn,
  //
  $selectorCalcAmount, $selectorCalcPeriod,
  $productsCalc, $periodMin, $periodMax, $periodStep, $period,
  $amountMin, $amountMax, $amountStep, $amount, $rate, $productId,
  $amountBackup, $periodBackup, $amountLimit, $graceRate,
  //
  getProductsForUserCalcFx, getProductsCalcFx, $products, $isChangePeriodInterval,
} from './index';

const {
  PRODUCT_ID, PERIOD_DEFAULT, PERIOD_MIN, PERIOD_MAX, PERIOD_STEP, PRIORITY,
  AMOUNT_DEFAULT, AMOUNT_MIN, AMOUNT_MAX, AMOUNT_STEP, RATE, GRACE_RATE,
} = PRODUCT_FIELDS;

/// ///////////////////////////////////////////////////////////
// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
/// ///////////////////////////////////////////////////////////

$selectorCalcAmount.reset(closeCalculatorPageFn);
$selectorCalcPeriod.reset(closeCalculatorPageFn);

$productsCalc
  .on([
    getProductsCalcFx.doneData,
    getProductsForUserCalcFx.doneData,
  ], (_, result) => get(result, 'data', []));

$amount
  .on(changeValueCalcAmountFn, (_, value) => Number.parseInt(get(value, '0', 0), 10));

$period
  .on(changeValueCalcPeriodFn, (_, value) => Number.parseInt(get(value, '0', 0), 10));

// $amount.watch((store) => console.log('$amount:', store));

/// ///////////////////////////////////////////////////////////
// ===================== БЛОК ОПЕРАТОРОВ =================== //
/// ///////////////////////////////////////////////////////////

// При первом заходе на шаблон (определяется через Gate на самом верхнем уровне layout-a)
sample({
  clock: sample({
    clock: mountAppFn,
    source: $isProfilePage,
    // для ЛК у нас запрашиваются персональные продукты
    filter: (isProfilePage) => isEmpty(isProfilePage),
  }),
  // вызываем событие для эффекта загрузки продуктов
  target: [
    getProductsCalcFn,
  ],
});

sample({
  clock: [getProductsCalcFn, getPersonalProductsCalcFn],
  target: getProductsCalcFx,
});

sample({
  // при изменении хранилища для продуктов
  clock: $productsCalc,
  // производим определение продукта с которым будем работать
  fn: (productsCalc) => {
    const data = productsCalc.reduce((obj, item) => {
      if (isEmpty(obj)) {
        obj[AMOUNT_MIN] = item[AMOUNT_MIN];
        obj[AMOUNT_MAX] = item[AMOUNT_MAX];
        obj[AMOUNT_STEP] = item[AMOUNT_STEP];
        obj[AMOUNT_DEFAULT] = item[AMOUNT_DEFAULT];

        obj[PERIOD_MIN] = item[PERIOD_MIN];
        obj[PERIOD_MAX] = item[PERIOD_MAX];
        obj[PERIOD_STEP] = item[PERIOD_STEP];
        obj[PERIOD_DEFAULT] = item[PERIOD_DEFAULT];

        obj[PRODUCT_ID] = item[PRODUCT_ID];
        obj[RATE] = item[RATE];
        obj[GRACE_RATE] = item[GRACE_RATE];
        obj[PRIORITY] = item[PRIORITY];
      } else {
        if (obj[AMOUNT_MIN] > item[AMOUNT_MIN]) {
          obj[AMOUNT_MIN] = item[AMOUNT_MIN];
        }
        if (obj[AMOUNT_MAX] < item[AMOUNT_MAX]) {
          obj[AMOUNT_MAX] = item[AMOUNT_MAX];
        }

        if (obj[PERIOD_MIN] > item[PERIOD_MIN]) {
          obj[PERIOD_MIN] = item[PERIOD_MIN];
        }
        if (obj[PERIOD_MAX] < item[PERIOD_MAX]) {
          obj[PERIOD_MAX] = item[PERIOD_MAX];
        }

        if (obj[PRIORITY] > item[PRIORITY]) {
          obj[PRIORITY] = item[PRIORITY];
          obj[PERIOD_STEP] = item[PERIOD_STEP];
          obj[AMOUNT_STEP] = item[AMOUNT_STEP];
          obj[PERIOD_DEFAULT] = item[PERIOD_DEFAULT];
          obj[AMOUNT_DEFAULT] = item[AMOUNT_DEFAULT];
          obj[PRODUCT_ID] = item[PRODUCT_ID];
          obj[RATE] = item[RATE];
          obj[GRACE_RATE] = item[GRACE_RATE];
        }
      }
      return obj;
    }, {});

    /* const item = productsCalc.reduce(
      (prev, current) => ((+current[PRIORITY] > +prev[PRIORITY]) ? current : prev),
    );
    item[GRACE_RATE] = (isEmpty(item[GRACE_RATE]) && item[GRACE_RATE] !== 0)
      ? item[RATE] : item[GRACE_RATE]; */
    // console.log('productsCalc:', productsCalc);
    // console.log('data:', data);
    return data;
  },
  // раскидываем полученные данные по точечных хранилищам
  target: [
    spread({
      targets: {
        [AMOUNT_MAX]: $amountLimit,
      },
    }),
    spread({
      targets: {
        [AMOUNT_MAX]: $amountLimit,
        [PRODUCT_ID]: $productId,
        [PERIOD_MIN]: $periodMin,
        [PERIOD_MAX]: $periodMax,
        [PERIOD_STEP]: $periodStep,
        [PERIOD_DEFAULT]: $period,
        [AMOUNT_MIN]: $amountMin,
        [AMOUNT_MAX]: $amountMax,
        [AMOUNT_STEP]: $amountStep,
        [AMOUNT_DEFAULT]: $amount,
        [RATE]: $rate,
        // [RATE_MIN]: $rateMin,
        // [RATE_MAX]: $rateMax,
        [GRACE_RATE]: $graceRate,
      },
    }),
  ],
});

sample({
  clock: $productsCalc,
  target: $products,
});

sample({
  clock: changeValueCalcAmountFn,
  source: [$productsCalc, $amount, $periodMin, $periodMax, $period],
  fn: ([products, amount, periodMin, periodMax, period]) => {
    // Отсеянные продукты
    const items = products.filter((item) => {
      const min = get(item, AMOUNT_MIN, 0);
      const max = get(item, AMOUNT_MAX, 0);
      return amount >= min && amount <= max;
    });
    // Узнаем мin/max
    const data = items.reduce((obj, item) => {
      if (isEmpty(obj)) {
        obj[PERIOD_MIN] = item[PERIOD_MIN];
        obj[PERIOD_MAX] = item[PERIOD_MAX];
        obj[PERIOD_STEP] = item[PERIOD_STEP];
      } else {
        if (obj[PERIOD_MIN] > item[PERIOD_MIN]) {
          obj[PERIOD_MIN] = item[PERIOD_MIN];
        }
        if (obj[PERIOD_MAX] < item[PERIOD_MAX]) {
          obj[PERIOD_MAX] = item[PERIOD_MAX];
        }
        if (obj[PRIORITY] > item[PRIORITY]) {
          obj[PERIOD_STEP] = item[PERIOD_STEP];
        }
      }
      return obj;
    }, {});

    const isChangePeriod = (periodMin !== data[PERIOD_MIN] || periodMax !== data[PERIOD_MAX]);
    let currentPeriod = period;
    if (isChangePeriod) {
      if (period > data[PERIOD_MAX]) {
        currentPeriod = data[PERIOD_MAX];
      }
      if (period < data[PERIOD_MIN]) {
        currentPeriod = data[PERIOD_MIN];
      }
    }

    return ({
      items,
      currentPeriod,
      // [PERIOD_DEFAULT]: data[PERIOD_DEFAULT],
      [PERIOD_MIN]: data[PERIOD_MIN],
      [PERIOD_MAX]: data[PERIOD_MAX],
      [PERIOD_STEP]: data[PERIOD_STEP],
      // флаг для перерендера min / max периода
      isChangePeriod,
    });
  },
  target:
    [
      spread({
        targets: {
          items: $products,
          currentPeriod: $period,
          [PERIOD_MAX]: $periodMax,
          [PERIOD_MIN]: $periodMin,
          isChangePeriod: $isChangePeriodInterval,
        },
      }),
      // перерендер
      attach({
        source: [$selectorCalcPeriod, $isChangePeriodInterval],
        effect: ([selector, isChangePeriod]) => {
          if (isChangePeriod) {
            selector.noUiSlider.destroy();
          }
        },
      }),
    ],
});

sample({
  clock: changeValueCalcPeriodFn,
  source: [$productsCalc, $period, $amount],
  fn: ([products, period, amount]) => products.filter((item) => {
    const amountMin = get(item, AMOUNT_MIN, 0);
    const amountMax = get(item, AMOUNT_MAX, 0);
    const min = get(item, PERIOD_MIN, 0);
    const max = get(item, PERIOD_MAX, 0);
    return period >= min && period <= max
      && amount >= amountMin && amount <= amountMax;
  }),
  target: $products,
});

sample({
  clock: $products,
  source: [$products, $period, $amount],
  fn: ([products, period, amount]) => {
    const items = products.filter((item) => {
      const amountMin = get(item, AMOUNT_MIN, 0);
      const amountMax = get(item, AMOUNT_MAX, 0);
      const min = get(item, PERIOD_MIN, 0);
      const max = get(item, PERIOD_MAX, 0);
      return period >= min && period <= max
        && amount >= amountMin && amount <= amountMax;
    });
    // console.log('products:', products.length, products);
    const item = findObjectWithMinProp(items, PRIORITY);
    return item[PRODUCT_ID];
  },
  target: $productId,
});

// $productId.watch((store) => console.log('$productId:', store));

// записываем в бекап
sample({
  clock: backupDataCalcFn,
  target: spread({
    targets: {
      amount: $amountBackup,
      period: $periodBackup,
    },
  }),
});

// при нажатии на кнопку применить обновляем бекап
sample({
  clock: changeBackupDataCalcFn,
  source: [$amount, $period],
  fn: ([amount, period]) => ({ amount, period }),
  target: [
    spread({
      targets: {
        amount: $amountBackup,
        period: $periodBackup,
      },
    }),
    closeChangeCalcFn,
  ],
});

// при закрытии модального окна и бекапа возвращаем значения в калькулятор
sample({
  clock: closeChangeCalcFn,
  source: [$amountBackup, $periodBackup],
  fn: ([amountBackup, periodBackup]) => ({ amountBackup, periodBackup }),
  target: [
    closeModalFn,
    spread({
      targets: {
        amountBackup: $amount,
        periodBackup: $period,
      },
    }),
  ],
});

sample({
  clock: sample({
    clock: bindCalcAmountFn,
    source: $selectorCalcAmount,
    filter: (selectorCalcAmount) => isEmpty(selectorCalcAmount),
  }),
  source: bindCalcAmountFn,
  target: $selectorCalcAmount,
});

sample({
  clock: sample({
    clock: bindCalcPeriodFn,
    source: $selectorCalcPeriod,
    filter: (selectorCalcPeriod) => isEmpty(selectorCalcPeriod),
  }),
  source: bindCalcPeriodFn,
  target: $selectorCalcPeriod,
});

sample({
  clock: sample({
    clock: [$selectorCalcAmount, $productsCalc],
    source: [$selectorCalcAmount, $productsCalc],
    filter: ([selectorCalcAmount, productsCalc]) => !isEmpty(selectorCalcAmount) && !isEmpty(productsCalc),
  }),
  source: $selectorCalcAmount,
  fn: (data) => data,
  target: attach({
    source: [$amountMin, $amountMax, $amountStep, $amount],
    effect: ([min, max, step, sum], selector) => {
      noUiSlider.create(selector, {
        start: sum,
        range: { min, max },
        step,
        connect: 'lower',
      });
      selector.noUiSlider.on('update', changeValueCalcAmountFn);
    },
  }),
});

sample({
  clock: sample({
    clock: [$selectorCalcPeriod, $productsCalc, changeValueCalcAmountFn],
    source: [$selectorCalcPeriod, $productsCalc],
    filter: ([selectorCalcPeriod, productsCalc]) => !isEmpty(selectorCalcPeriod) && !isEmpty(productsCalc),
  }),
  source: $selectorCalcPeriod,
  target: attach({
    source: [$periodMin, $periodMax, $periodStep, $period],
    effect: ([min, max, step, sum], selector) => {
      if (isEmpty(selector.noUiSlider)) {
        noUiSlider.create(selector, {
          start: sum,
          range: { min, max },
          step,
          connect: 'lower',
        });
        selector.noUiSlider.on('update', changeValueCalcPeriodFn);
      }
    },
  }),
});
