import React, { useRef } from 'react';
import { useStore } from 'effector-react';
import { get, isEmpty } from 'src/lib/lodash';
import { useOutsideAlerter } from 'src/lib/hooks';
import './InputDaData.css';

const handlerBlur = (onBlur) => (e) => {
  onBlur(e);
};

const checkPhoneKey = (onCloseOptionsFn) => (e) => {
  const keyCode = get(e, 'keyCode', '');
  if (!isEmpty(keyCode) && keyCode === 9) {
    onCloseOptionsFn();
  }
};

const handlerChange = (value, onChange, onChangeInput) => (e) => {
  onChange(e);
  onChangeInput(get(e, 'target.value', ''));
};

const handlerClick = (valueOption, onChange, onBlurInputFn, jumpField) => () => {
  onChange(valueOption);
  jumpField();
  onBlurInputFn();
};

export const InputDaData = React.forwardRef(({
  // пришло из модели DaData
  modelData: {
    $selectData, onChangeInputFn, onFocusInputFn, onCloseOptionsFn,
  },
  propsData,
}, ref) => {
  const {
    /* value, required,  */ placeholder, options, isOpen, minLength,
  } = useStore($selectData);
  const {
    className,
    value, label, disabled, name, type, tabIndex, jumpField, required,
    errorText, hasError, onChange, onBlur, // приходят из getPropsField - формы эффектора
    ...restProps
  } = propsData;

  const isMinValue = (minLength <= value.length);
  const isOpenOptions = isMinValue && isOpen && !isEmpty(options) && isEmpty(hasError);

  const hasFocused = name === get(document, 'activeElement.id', false);

  const fieldLabel = `${label}${(required ? '*' : '')}`;
  let validState = '';
  if (required && (hasError || (!isEmpty(`${value}`.trim()) && !hasFocused))) {
    validState = hasError ? 'invalid' : 'valid';
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onCloseOptionsFn);

  const offsetWidth = get(document.getElementById(name), 'parentElement.parentElement.offsetWidth', '');
  const styleOptions = {
    position: 'absolute',
    width: isEmpty(offsetWidth) ? 0 : `${offsetWidth}px`,
  };
  return (
    <>
      <div
        className={`default-input ${className} ${validState} ${isOpenOptions ? ' select open' : ''}`}
      >
        <input
          {...restProps}
          tabIndex={tabIndex}
          type={type}
          placeholder={placeholder}
          required={required}
          value={value}
          onChange={handlerChange(value, onChange, onChangeInputFn)}
          onFocus={onFocusInputFn}
          disabled={disabled}
          id={name}
          ref={ref}
          onBlur={handlerBlur(onBlur)}
          onKeyDown={checkPhoneKey(onCloseOptionsFn)}
        />
        <label>{fieldLabel}</label>
      </div>
      {required && hasError && !isEmpty(errorText) && <p className="invalid-text">{errorText}</p>}

      {isOpenOptions && (
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        <div
          type="options"
          className="default-select container"
          style={styleOptions}
        >
          <div
            className="box"
          >
            <ul ref={wrapperRef}>
              <>
                {options.map(({ label: labelOption, value: valueOption }) => (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                  <li
                    key={valueOption}
                    aria-selected={valueOption === value}
                    role="option"
                    onClick={
                      handlerClick(valueOption, onChange, onCloseOptionsFn, jumpField)
                    }
                  >
                    {labelOption}
                  </li>
                ))}
              </>
            </ul>
          </div>

        </div>
      )}
    </>
  );
});

InputDaData.defaultProps = {
  label: '',
  value: '',
  className: '',
  options: [],
  onChange: () => '',
  onBlur: () => '',
  required: false,
};
