import React from 'react';
import { useUnit } from 'effector-react';
import { getLinkForAuthESIAFn } from 'src/models/Esia';
import { $user } from 'src/models/User';
import { $configDict } from 'src/models/Dictionaries';
import { get, isEmpty } from 'src/lib/lodash';

export const EsiaButtonReg = React.memo((callback = getLinkForAuthESIAFn) => {
  const [user, configDict] = useUnit([$user, $configDict]);
  const esiaData = get(user, 'esia_data', {});
  const isAccessEsia = get(configDict, 'settings.esia.enabled', false);

  const handler = isEmpty(callback) ? getLinkForAuthESIAFn.prepend(() => 'register') : callback;
  return (
    <>
      {!isEmpty(isAccessEsia) && isEmpty(esiaData) && (
      <div className="reg">
        <a
          href="#"
          className="default-btn white big"
          onClick={handler}
        >
          <div>Получите на 5 000₽ больше</div>
          <div className="bottom">
            Оформите заявку через
            <span className="icon">
              <img
                src="/img/main/gosusluga.png"
                alt=""
              />
            </span>
          </div>
        </a>
      </div>
      )}
    </>
  );
});
