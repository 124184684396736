import React from 'react';
import { GetMoneyButton } from 'src/ui/components/Form';

export const Way = React.memo(() => (
  <section className="waySection">
    <div className="wrapper">
      <div className="way-block">
        <div className="content">
          <div className="left">
            <h2>Способы получения</h2>
            <div className="way-btn">
              <GetMoneyButton size="big" ym="GET_MONEY_LANDING_MID_BT" />
            </div>
          </div>
          <div className="right">
            <div className="items">
              <div className="item">
                <div className="item-img">
                  <img src="/img/main/way/visa.png" alt="" />
                </div>
                <p className="item-text">Visa</p>
              </div>
              <div className="item">
                <div className="item-img">
                  <img src="/img/main/way/masterCard.png" alt="" />
                </div>
                <p className="item-text">MasterCard</p>
              </div>
              <div className="item">
                <div className="item-img">
                  <img src="/img/main/way/mir.png" alt="" />
                </div>
                <p className="item-text">Мир</p>
              </div>
              {/* <div
                  className={`item ${wow} fadeIn`}
                  data-wow-duration=".7s"
                  data-wow-delay=".36s"
                >
                  <div className="item-img">
                    <img src="/img/main/way/qiwi.png" alt="" />
                  </div>
                  <p className="item-text">Qiwi</p>
                </div>
                <div
                  className={`item ${wow} fadeIn`}
                  data-wow-duration=".7s"
                  data-wow-delay=".38s"
                >
                  <div className="item-img">
                    <img src="/img/main/way/credit-card.png" alt="" />
                  </div>
                  <p className="item-text">На карту онлайн и без звонков</p>
                </div> */}
              <div className="item">
                <div className="item-img">
                  <img src="/img/main/way/bank.png" alt="" />
                </div>
                <p className="item-text">Банк</p>
              </div>
              <div className="item">
                <div className="item-img">
                  <img src="/img/main/way/cash.png" alt="" />
                </div>
                <p className="item-text">Наличными в пункте выдачи</p>
              </div>

              {Array.from({ length: 4 }, (_, index) => (
                <div className="item" key={index}>
                  <div className="item-img">&nbsp;</div>
                  <p className="item-text">&nbsp;</p>
                </div>
              ))}
              {/* <div
                  className={`item ${wow} fadeIn`}
                  data-wow-duration=".7s"
                  data-wow-delay=".44s"
                >
                  <div className="item-img">
                    <img src="/img/main/way/wallet.png" alt="" />
                  </div>
                  <p className="item-text">На электронный кошелёк</p>
                </div>
                <div
                  className={`item ${wow} fadeIn`}
                  data-wow-duration=".7s"
                  data-wow-delay=".46s"
                >
                  <div className="item-img">
                    <img src="/img/main/way/contact.png" alt="" />
                  </div>
                  <p className="item-text">Contact</p>
                </div>
                <div
                  className={`item ${wow} fadeIn`}
                  data-wow-duration=".7s"
                  data-wow-delay=".48s"
                >
                  <div className="item-img">
                    <img src="/img/main/way/money.png" alt="" />
                  </div>
                  <p className="item-text">ЮMoney</p>
                </div> */}
            </div>
          </div>
          <div className="way-btn__mobile">
            <GetMoneyButton size="big" ym="GET_MONEY_LANDING_MID_BT" />
          </div>
        </div>
      </div>
    </div>
  </section>
));
