import React from 'react';
import { getConfirmSuccessPageFn } from 'src/models/Loan/Application/Success';
import { ButtonWithLoader as Button } from 'src/ui/components/Form';
import { InfoParams } from './children/InfoParams';

export const SuccessLoan = () => (
  <>
    <InfoParams />

    <div className="block-line" />

    <Button
      className="button"
      onClick={getConfirmSuccessPageFn}
    >
      Получить деньги
    </Button>
  </>
);
