import React from 'react';
import { useGate, useUnit } from 'effector-react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { get } from 'src/lib/lodash';
import {
  RouteGate,
  $webpClass,
} from 'src/models/App';
import { $loaderPage } from 'src/models/Helper/Loader';
import { $openMenuMobile } from 'src/models/Helper/MenuMobile';

import { Header } from 'src/ui/blocks/Header/Header';
import { Footer } from 'src/ui/blocks/Footer/Footer';

const DefaultLayout = ({ children }) => {
  useGate(RouteGate, {
    pathname: get(window, 'location.pathname', ''),
    pathParams: get(window, 'location.search', ''),
    history: useHistory(),
  });

  const [openMenuMobile, loader, webpClass] = useUnit([$openMenuMobile, $loaderPage, $webpClass]);

  return (
    <div className={`${loader} ${webpClass}`}>
      <div className="loaded-box" />

      <div className={`otherpage ${openMenuMobile}`}>
        <Header />
        {children}
        {/* <div className="back-top">
          <img src="/img/icons/arrowtop.svg" alt="top" />
        </div> */}
        <Footer />
      </div>
    </div>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export { DefaultLayout };
