import { createForm } from 'effector-forms';
import { pending } from 'patronum/pending';
import { rules } from 'src/lib/rules';
import { isCurrentPath } from 'src/lib/url';
import { PAGES_PATH } from 'src/dict/path';
import { FEEDBACK_FIELDS } from 'src/dict/fields';
import { $pathnameUrl, appDomain } from 'src/models/App';
import {
  sendFeedbackSign,
  initReCaptchaSign,
  getTokenReCaptchaSign,
} from 'src/api/signatures/Feedback';

export const feedbackDomain = appDomain.createDomain('Feedback');

/// ///////////////////////////////////////////////////////////
// ================== СЛОВАРИ / КОНСТАНТЫ ================== //
/// ///////////////////////////////////////////////////////////

const { FEEDBACK } = PAGES_PATH;
const {
  SUBJECT, MESSAGE, EMAIL, PHONE, FIRST_NAME, LAST_NAME, // TOKEN, MIDDLE_NAME,
} = FEEDBACK_FIELDS;

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ СОБЫТИЙ ===================== //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ХРАНИЛИЦ ==================== //
/// ///////////////////////////////////////////////////////////

export const $tokenReCaptcha = feedbackDomain.createStore('', { name: 'tokenReCaptcha' });

export const $isFeedbackPage = $pathnameUrl.map((path) => isCurrentPath(path, FEEDBACK));

/// ///////////////////////////////////////////////////////////
// ===================== ЮНИТЫ ЭФФЕКТОВ ==================== //
/// ///////////////////////////////////////////////////////////

// Обратная связь
export const sendFeedbackFx = feedbackDomain.createEffect(sendFeedbackSign);

export const initReCaptchaFx = feedbackDomain.createEffect(initReCaptchaSign);
export const getTokenReCaptchaFx = feedbackDomain.createEffect(getTokenReCaptchaSign);

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

export const $isLoadingReCaptcha = pending({
  effects: [initReCaptchaFx, getTokenReCaptchaFx],
});

/// ///////////////////////////////////////////////////////////
// ==== ЮНИТЫ ЭФФЕКТОВ ПРИЗВОДНЫЕ от ХРАНИЛИЩ, ЭФФЕКТОВ ==== //
/// ///////////////////////////////////////////////////////////

// тут создаются эффекты через attach

/// ///////////////////////////////////////////////////////////
// ====================== ЮНИТЫ ФОРМ ======================= //
/// ///////////////////////////////////////////////////////////

// форма обратной связи
export const feedbackForm = createForm({
  fields: {
    [EMAIL]: { rules: [rules.required(), rules.email()], init: '' },
    [PHONE]: { rules: [rules.required(), rules.phone()], init: '', validateOn: ['blur'] },
    [LAST_NAME]: {
      rules: [
        rules.required(),
        rules.letter(),
        rules.maxLength(50),
      ],
      init: '',
      validateOn: ['blur'],
    },
    [FIRST_NAME]: {
      rules: [
        rules.required(),
        rules.letter(),
        rules.maxLength(50),
      ],
      init: '',
      validateOn: ['blur'],
    },
    // [MIDDLE_NAME]: { rules: [rules.required()], init: '' },
    [SUBJECT]: {
      rules: [
        rules.required(), rules.maxLength(255),
      ],
      init: '',
      validateOn: ['blur'],
    },
    [MESSAGE]: {
      rules: [
        rules.required(), rules.maxLength(3000),
      ],
      init: '',
      validateOn: ['blur'],
    },
    // [TOKEN]: { rules: [rules.required()], init: '' },
  },
  validateOn: ['submit'],
  domain: feedbackDomain,
});

/// ///////////////////////////////////////////////////////////
//  ЮНИТЫ ХРАНИЛИЩ ПРИЗВОДНЫЕ от ФОРМ + (ХРАНИЛИЩ, ЭФФЕКТОВ) //
/// ///////////////////////////////////////////////////////////

/// ///////////////////////////////////////////////////////////
// ========================= ФАБРИКИ ======================= //
/// ///////////////////////////////////////////////////////////

// тут создаем различные фабрики и хранилища с использованием фабрик
