import React from 'react';
import { useStore } from 'effector-react';
import { useForm } from 'effector-forms';
import {
  getPropsField, getRefField, jumpFocusField, onSubmit,
} from 'src/lib/form';
import { daData } from 'src/dict/config';
import { get } from 'src/lib/lodash';
import { maskLetter } from 'src/lib/mask';
import { maskPhone } from 'src/lib/maskPlaceholder';
import { FEEDBACK_FIELDS } from 'src/dict/fields';
import { $isLoadingReCaptcha, feedbackForm } from 'src/models/Feedback';
import { $isProfilePage } from 'src/models/Profile';
import { getKeyboardLayoutFn } from 'src/models/Helper/Keyboard';
import {
  jumpFocusFieldFn, jumpFocusFn, setRefFieldsFn,
} from 'src/models/Helper/Ref';
import {
  Input, InputName, ButtonWithLoader as Button, InputEmail,
} from 'src/ui/components/Form';
import { Textarea } from './Textarea';

const {
  PHONE, EMAIL, FIRST_NAME, LAST_NAME, SUBJECT, MESSAGE,
} = FEEDBACK_FIELDS;
const { NAME, SURNAME } = daData.NAME.FILTER_PARTS;

export const FeedbackForm = () => {
  const { submit, eachValid, ...restProps } = useForm(feedbackForm);
  const disabled = useStore($isProfilePage);
  const isLoadingReCaptcha = useStore($isLoadingReCaptcha);
  return (
    <>

      <form onSubmit={onSubmit(submit)}>
        <div className="block-item">
          <Input
            label="Телефон"
            type="text"
            inputMode="tel"
            name={PHONE}
            {...maskPhone}
            {...getPropsField({
              fieldName: PHONE, props: restProps,
            })}
            ref={getRefField({
              fieldName: PHONE, callBack: setRefFieldsFn,
            })}
            jumpField={jumpFocusField({
              fieldName: EMAIL,
              callBack: jumpFocusFieldFn,
              trigger: { name: 'maxLength', data: { value: 18 } },
            })}
            disabled={disabled}
            required
          />
        </div>

        <div className="block-item">
          <InputEmail
            label="Email"
            type="text"
            inputMode="email"
            name={EMAIL}
            {...getPropsField({
              fieldName: EMAIL, props: restProps,
            })}
            ref={getRefField({
              fieldName: EMAIL, callBack: setRefFieldsFn,
            })}
            jumpField={jumpFocusField({
              fieldName: LAST_NAME,
              callBack: jumpFocusFn,
            })}
            disabled={disabled}
            required
          />
        </div>

        <div className="block-item">
          <InputName
            filterParts={SURNAME}
            label="Фамилия"
            name={LAST_NAME}
            {...getPropsField({
              fieldName: LAST_NAME, props: restProps, mask: maskLetter,
            })}
            ref={getRefField({
              fieldName: LAST_NAME, callBack: setRefFieldsFn,
            })}
            jumpField={jumpFocusField({
              fieldName: FIRST_NAME, callBack: jumpFocusFieldFn,
            })}
            onInput={getKeyboardLayoutFn.prepend((e) => ({
              field: get(restProps, `fields.${LAST_NAME}`),
              value: e.target.value,
            }))}
            disabled={disabled}
            required
          />
        </div>

        <div className="block-item">
          <InputName
            filterParts={NAME}
            label="Имя"
            name={FIRST_NAME}
            {...getPropsField({
              fieldName: FIRST_NAME, props: restProps, mask: maskLetter,
            })}
            ref={getRefField({
              fieldName: FIRST_NAME, callBack: setRefFieldsFn,
            })}
            jumpField={jumpFocusField({
              fieldName: SUBJECT, callBack: jumpFocusFieldFn,
            })}
            onInput={getKeyboardLayoutFn.prepend((e) => ({
              field: get(restProps, `fields.${FIRST_NAME}`),
              value: e.target.value,
            }))}
            disabled={disabled}
            required
          />
        </div>

        <div className="block-item">
          <Input
            label="Тема"
            name={SUBJECT}
            {...getPropsField({
              fieldName: SUBJECT, props: restProps,
            })}
            ref={getRefField({
              fieldName: SUBJECT, callBack: setRefFieldsFn,
            })}
            required
          />
        </div>

        <div className="block-item">
          <Textarea
            label="Сообщение"
            name={MESSAGE}
            {...getPropsField({
              fieldName: MESSAGE, props: restProps,
            })}
            required
          />
        </div>

        {/* <div className="default-input">
          <textarea placeholder=" " required tabIndex="0" rows="4" />
          <label>Сообщение</label>
        </div> */}

        <div className="buttons">
          <div className="button">
            <Button
              // disabled
              type="submit"
              disabled={isLoadingReCaptcha}
            >
              Отправить
            </Button>
          </div>
        </div>

      </form>
    </>
  );
};
