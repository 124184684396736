import React from 'react';
import { useUnit } from 'effector-react';
import { get, isEmpty } from 'src/lib/lodash';
import { getLinkForAuthESIAFn } from 'src/models/Esia';
import { $configDict } from 'src/models/Dictionaries';

export const EsiaButtonLogin = React.memo(() => {
  const configDict = useUnit($configDict);
  const isAccessEsia = get(configDict, 'settings.esia.enabled', false);

  return (
    <>
      {!isEmpty(isAccessEsia) && (
        <div className="button">
          <a
            href="#"
            className="default-btn border"
            onClick={getLinkForAuthESIAFn.prepend(() => 'login')}
          >
            <span className="icon">
              <img src="img/main/gosusluga-icon.png" alt="" />
            </span>
            Войти с помощью
            «Гос.услуги»
          </a>
        </div>
      )}
    </>
  );
});
