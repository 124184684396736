import {
  sample,
  // attach, restore
} from 'effector';
import { debounce } from 'patronum/debounce';
import { get, isEmpty } from 'src/lib/lodash';
import {
  // события
  setRefFieldsFn, jumpFocusFieldFn, resetRefsFn, clickFieldFn,
  // хранилища,
  $refFields, jumpFocusFn,
  // эффекты
} from './index';

/// ///////////////////////////////////////////////////////////
// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
/// ///////////////////////////////////////////////////////////

$refFields
  .on(setRefFieldsFn,
    (state, { fieldName, field }) => ({ ...state, [fieldName]: field }))
  .on(jumpFocusFieldFn, (state, field) => {
    const currentField = get(state, field, {});
    if (!isEmpty(currentField) && !isEmpty(field)) {
      currentField.focus();
    }
  })
  .on(clickFieldFn, (state, field) => {
    const currentField = get(state, field, {});
    if (!isEmpty(currentField) && !isEmpty(field)) {
      currentField.click();
    }
  })
  .reset(resetRefsFn);

/// ///////////////////////////////////////////////////////////
// ===================== БЛОК ОПЕРАТОРОВ =================== //
/// ///////////////////////////////////////////////////////////

sample({
  clock: debounce({
    source: jumpFocusFn,
    timeout: 100,
  }),
  target: jumpFocusFieldFn,
  /* target: attach({
    source: restore(jumpFocusFn, ''),
    async effect(field) {
      console.log('field:', field);
      if (field) {
        setTimeout(() => {
          jumpFocusFieldFn(field);
        }, 500);
      }
    },
  }), */
});
