import { isEmpty } from './lodash';

function leftPad(str, len, ch) {
  const length = len - str.length + 1;
  return length > 0 ? Array.from({ length }).join(ch) + str : str;
}

export const generateSnils = () => {
  const rnd = Math.floor(Math.random() * 999999999);
  const number = leftPad(`${rnd}`, 9, '0');

  let sum = number
    .split('')
    .map((val, i) => Number.parseInt(val, 10) * (9 - i))
    .reduce((a, b) => a + b);

  if (sum > 101) {
    sum %= 101;
  }

  const checkSum = sum === 100 || sum === 101 ? '00' : leftPad(`${sum}`, 2, '0');
  return number + checkSum;
};

export const validateSnils = (value) => {
  let snils = value.replace(/[\s-]/g, '');
  let result = false;
  if (typeof snils === 'number') {
    snils = snils.toString();
  } else if (typeof snils !== 'string') {
    snils = '';
  }
  if (snils.length === 0) {
    result = false;
  } else if (/\D/.test(snils)) {
    result = false;
  } else if (snils.length !== 11) {
    result = false;
  } else {
    let sum = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 9; i++) {
      sum += Number.parseInt(snils[i], 10) * (9 - i);
    }
    let checkDigit = 0;
    if (sum < 100) {
      checkDigit = sum;
    } else if (sum > 101) {
      checkDigit = Number.parseInt(sum % 101, 10);
      if (checkDigit === 100) {
        checkDigit = 0;
      }
    }
    result = checkDigit === Number.parseInt(snils.slice(-2), 10);
  }
  return result;
};
// 076-098-071 89

export const validateRusNumber = (number, pattern = '') => {
  const regex = isEmpty(pattern) ? /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/ : pattern;
  if (!regex.test(number)) {
    return false;
  }

  const parts = number.split(' ');

  let cityCode = '';
  let phoneNumber = '';
  if (parts.length === 2) {
    cityCode = parts[1].slice(1, 4);
    phoneNumber = parts[1].slice(5).replace(/-/g, '');
  } else if (parts.length === 3) {
    cityCode = parts[1].slice(1, 4);
    phoneNumber = parts[2].replace(/-/g, '');
  } else if (parts.length === 1 && parts[0].length === 11) {
    cityCode = parts[0].slice(1, 4);
    phoneNumber = parts[0].slice(4);
  } else {
    return false;
  }

  if (cityCode === '000' || /^\d{10}$/.test(phoneNumber) && /^(0)\1*$/.test(phoneNumber)) {
    return false;
  }

  return true;
};
