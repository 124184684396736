import React from 'react';
import { ButtonWithLoader as Button } from 'src/ui/components/Form';

export const Contact = React.memo(() => (
  <div className="block-payment disabled">
    <div className="block-payment__top">
      <div className="block-payment__img desktop"><img src="/img/form/contact.png" alt="" /></div>
      <div className="block-payment__img mobile"><img src="/img/form/contact-mobile.png" alt="" /></div>
      <div className="block-payment__info">
        <h2 className="block-payment__title">Наличными через систему Contact</h2>
        <p className="block-payment__text">Время - до 15 мин</p>
      </div>
      <Button className="nextStep block-payment__btn desktop">Выбрать</Button>
    </div>
    <p className="block-payment__text" />
    <Button className="nextStep block-payment__btn mobile">Выбрать</Button>
  </div>
));
