import {
  split, sample,
} from 'effector';
import { get, isEmpty } from 'src/lib/lodash';
// import { getLastPathPath } from 'src/lib/url';
import { storage } from 'src/lib/storage';
import { REG_PATH } from 'src/dict/path';
import { LS } from 'src/dict/config';
import {
  scrollTopFn,
  $pathnameUrl, $firstPage,
} from 'src/models/App';
import { clearAllStorageFn } from 'src/models/Auth';
import { pushHistoryFn } from 'src/models/Helper/History';
import { notifySuccessFn } from 'src/models/Helper/Notification';
import { storageSetFn } from 'src/models/Helper/Storage';
import { showLoaderButtonFn, hideLoaderButtonFn } from 'src/models/Helper/Loader';
import {
  $isVerified,
  getCurrentUserFromRegFx,
} from 'src/models/User';
import { $configDict } from 'src/models/Dictionaries';
import { goAgreementPageFn } from 'src/models/Loan/Agreement';
import {
  toRegistrationFn, checkPageRegistrationFn,
  getMoneyFn, lockButtonFn, unLockButtonFn,
  $lockButton,
} from './index';

const {
  REGISTRATION, PERSONAL, ADDRESS,
  PASSPORT, EXPERIENCE, VERIFICATION,
} = REG_PATH;
const {
  STEP, USER,
} = LS;
// const { FORM_IN_DEVELOPMENT } = config;

/// ///////////////////////////////////////////////////////////
// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
/// ///////////////////////////////////////////////////////////

$lockButton
  .on(unLockButtonFn, () => false)
  // .on(lockButtonFn, () => true);
  .on(lockButtonFn, () => false);

/// ///////////////////////////////////////////////////////////
// ===================== БЛОК ОПЕРАТОРОВ =================== //
/// ///////////////////////////////////////////////////////////

// вначале смотрим не заблокирована ли кнопка
split({
  source: sample($configDict, getMoneyFn),
  match: {
    stop: (configDict) => isEmpty(get(configDict, 'settings.registration.enabled', false)),
  },
  cases: {
    stop: notifySuccessFn.prepend(
      () => 'На данный момент регистрация заявок недоступна. Пожалуйста, попробуйте позже.',
    ),
    __: [
      showLoaderButtonFn,
      toRegistrationFn,
    ],
  },
});

// Если входная точка на сайт находиться на одной из страниц раздела регистрации
sample({
  clock: sample({
    clock: $firstPage,
    source: $pathnameUrl,
    filter: (pathname, firstPage) => {
      const page = get(pathname.split('/'), '1', '');
      return firstPage === pathname && page === REGISTRATION;
    },
  }),
  target: toRegistrationFn,
});

split({
  source: sample([$pathnameUrl, $isVerified], toRegistrationFn),
  match: {
    // если находимся на 1-ой транице регистрации + в LS нет никакого шага + нет верификации (берется из $user)
    personal: ([pathname, isVerified]) => (
      pathname.split('/').pop() === PERSONAL && isEmpty(storage.get(STEP)) && isEmpty(isVerified)
    ),
    // если находимся не на 1-ой транице регистрации + в LS нет никакого шага + нет верификации (берется из $user)
    notPersonal: ([pathname, isVerified]) => (
      pathname.split('/').pop() !== PERSONAL && isEmpty(storage.get(STEP)) && isEmpty(isVerified)
    ),
    // если есть верификация
    toProfile: ([, isVerified]) => !isEmpty(isVerified),
  },
  cases: {
    personal: [
      // скролим на вверх
      scrollTopFn.prepend(
        () => window.scrollTo({ top: 0, behavior: 'smooth' }),
      ),
      // скрываем лаодер
      hideLoaderButtonFn,
    ],
    notPersonal: [
      // перенаправляем на 1-ую страемцу регистрации так как инф-ции о местоположении в LS нет
      pushHistoryFn.prepend(() => `/${REGISTRATION}/${PERSONAL}`),
      // скрываем лаодер
      hideLoaderButtonFn,
    ],
    toProfile: [
      // перенаправляем на стрнаицу займов (дефолтную) в ЛК (то есть авторизация)
      goAgreementPageFn,
      // скрываем лаодер
      hideLoaderButtonFn,
    ],
    // если ни одно условие выше не сработало идем дальше
    __: checkPageRegistrationFn,
  },
});

// дергаем эффект загрузки данных для юзера (клон основного эффекта для загрузки данных юзера)
sample({
  clock: checkPageRegistrationFn,
  source: $pathnameUrl,
  target: getCurrentUserFromRegFx,
});

// и только при  окончании эффекта скрываем Loader в кнопке для этой ветки условия
sample({
  clock: getCurrentUserFromRegFx.finally,
  target: hideLoaderButtonFn,
});

// после завершения запроса (с ошибкой или без) - смотрим условия
split({
  source: getCurrentUserFromRegFx.finally,
  match: {
    /* если данные по юзеру пришли с ошибкой (мы не знаем вообще кто это)
      или id юзера не пришло в отвтете с бекенда (та же петрушка)
      или шаг не сосотносится не с одним из шагов + не верифицирован, то
      выдаем что нужно редиректить нас 1-ую страницу
     */
    personal: (data) => {
      const status = get(data, 'status', '');
      const code = get(data, 'error.response.status', 0);
      const isInvalid = (status === 'fail' && code === 403);
      const userData = get(data, 'result.data', {});
      const isSnils = !isEmpty(get(userData, 'info.snils', ''));
      const isAddress = !isEmpty(get(userData, 'actual_registration_address_id', ''));
      const isExperience = !isEmpty(get(userData, 'info.amount_pay_monthly_credit', ''));
      const { id, is_verified: isVerified } = userData;
      const step = storage.get(STEP);
      const userId = get(storage.get(USER), 'id', '');
      return (isInvalid
        || isEmpty(id)
        || (!Object.values(REG_PATH).includes(step) && !isVerified)
        || (!isEmpty(userId) && !isEmpty(id) && id !== userId && !isVerified))
        || (!isVerified && step === PASSPORT && (
          isSnils || isAddress || isExperience))
        || (!isVerified && step === ADDRESS && (!isSnils
            || isAddress || isExperience))
        || (!isVerified && step === EXPERIENCE && (!isSnils || !isAddress
            || isExperience))
        || (!isVerified && step === VERIFICATION && (!isSnils || !isAddress || !isExperience)
        );
    },
    // если есть id юзера и он верефицирован
    redirectToProfile: (data) => {
      const userData = get(data, 'result.data', {});
      const { id, is_verified: isVerified } = userData;
      // смотрим какой шаг и какие данные подтверждены - отсылаем в ЛК - там модель разберется
      return !isEmpty(id) && !isEmpty(isVerified);
    },
  },
  cases: {
    personal: [
      // очищаем LS
      clearAllStorageFn,
      // вновь тригеррим событие, которое в этот раз нас точно выкинет на 1-ую страницу регистрации так как  LS уже пуст
      toRegistrationFn,
    ],
    redirectToProfile: [
      // редиректим на займ в ЛК (дефолтную стрнацу)
      goAgreementPageFn,
      // скрываем лоадер
      hideLoaderButtonFn,
    ],
    __: [
      // скролим вверх страницы
      scrollTopFn.prepend(
        () => window.scrollTo({ top: 0, behavior: 'smooth' }),
      ),
      // редиреким на страницу которая указана у нас в LS
      pushHistoryFn.prepend(() => `/${REGISTRATION}/${storage.get(STEP)}`),
      // заносим данные юзера в LS
      storageSetFn.prepend((data) => ({
        [USER]: { ...get(data, 'result.data', {}) },
      })),
    ],
  },
});
