import { sample } from 'effector';
import { debounce } from 'patronum/debounce';
import { get, isEmpty } from 'src/lib/lodash';
import { paramsToObject } from 'src/lib/url';
import { statistic, token } from 'src/dict/config';
import { AUTH_TO_PROFILE } from 'src/dict/path';
import {
  mountAppFn,
  $pathnameUrl, $firstPage, // $initApp, $pathParams,
} from 'src/models/App';
import { storageSetFn } from 'src/models/Helper/Storage';
import { cookieSetFn } from 'src/models/Helper/Cookie';
import {
  pushHistoryFn, backFromFirstPageFn,
} from 'src/models/Helper/History';
import { $tokenData, redirectAfterTokenAdminFn } from 'src/models/Auth';
import { declineLandingUrlFn } from 'src/models/Profile';
import {
  $statInfo,
  transformParamsFx, sendYMHitFx,
} from './index';

/// ///////////////////////////////////////////////////////////
// ================== БЛОК ОБРАБОТКИ ХРАНИЛИЩ ============== //
/// ///////////////////////////////////////////////////////////

$statInfo
  .on(transformParamsFx.doneData, (_, result) => result);

/// ///////////////////////////////////////////////////////////
// ===================== БЛОК ОПЕРАТОРОВ =================== //
/// ///////////////////////////////////////////////////////////

// При первом заходе собираем статистику
sample({
  clock: sample({
    clock: mountAppFn,
    source: $statInfo,
    // смотрим пуст ли локалсторадж и пришли ли utm-метки
    filter: (info) => {
      // смотрим есть ли utm-метки
      // смотрим есть ли вообще GET-параметры
      const objParams = paramsToObject(get(window, 'location.search', {}));
      return isEmpty(info) || (!isEmpty(objParams) && !isEmpty(get(objParams, 'utm_source', '')));
    },
  }),
  source: $statInfo,
  fn: (/* statInfo */) => ({
    params: paramsToObject(get(window, 'location.search', {})),
    // info: statInfo,
    __queryString: get(window, 'location.search', ''),
  }),
  // преобразовываем полученные данные через эффект
  target: transformParamsFx,
});

// записываем преобразованные данные в localstorage
sample({
  clock: transformParamsFx.doneData,
  target: storageSetFn.prepend((data) => ({ [statistic.INFO]: data })),
});

// при заходе смотрим есть ли параметр отвечающий за авторизацию
sample({
  clock: sample({
    clock: mountAppFn,
    filter: () => {
      // смотрим есть ли utm-метки
      // смотрим есть ли вообще GET-параметры
      const objParams = paramsToObject(get(window, 'location.search', {}));
      const check = get(objParams, 'save-auth-redirect', '');
      return (!isEmpty(check) && check === 'token'
        && !isEmpty(get(objParams, 'token', ''))
        // && !isEmpty(get(objParams, 'phone', ''))
      );
    },
  }),
  fn: () => {
    const objParams = paramsToObject(get(window, 'location.search', {}));
    return ({
      accessToken: get(objParams, 'token', ''),
    });
  },
  target: [
    $tokenData,
    storageSetFn.prepend(({ accessToken }) => ({
      [token.ACCESS]: accessToken,
    })),
    cookieSetFn.prepend(({ accessToken }) => ({
      [token.COOKIE_API_TOKEN]: accessToken,
    })),
    redirectAfterTokenAdminFn,
  ],
});

sample({
  clock: debounce({
    source: redirectAfterTokenAdminFn,
    timeout: 500,
  }),
  target: pushHistoryFn.prepend(() => `/${AUTH_TO_PROFILE}`),
});

sample({
  clock: $pathnameUrl,
  target: sendYMHitFx,
});

// $initApp.watch((state) => console.log('initApp:', state));
// $history.watch((state) => console.log('$history:', state));

sample({
  clock: backFromFirstPageFn,
  // source: [$initApp, $pathParams],
  source: [$firstPage, $pathnameUrl, $statInfo],
  filter: ([firstPage, pathnameUrl, statInfo]) => {
    // const objParams = paramsToObject(pathParams);
    // const isCheck = !isEmpty(initApp) && (!isEmpty(objParams) && !isEmpty(get(objParams, 'utm_source', '')));
    const isCheck = (
      !isEmpty(get(statInfo, 'utm_source', ''))
      && isEmpty(window.history.state)
      && firstPage === '/'
      && firstPage === pathnameUrl
    );
    return isCheck;
  },
  target: declineLandingUrlFn,
});
