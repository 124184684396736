import React from 'react';
import { useForm } from 'effector-forms';
import {
  getPropsField, onSubmit,
} from 'src/lib/form';
import { SECURITY_FIELDS } from 'src/dict/fields';
import { changePasswordForm } from 'src/models/User';
import { ButtonWithLoader as Button, Input } from 'src/ui/components';

const { PASSWORD, PASSWORD_CONFIRM } = SECURITY_FIELDS;

export const ChangePassword = React.memo(() => {
  const {
    submit, ...restProps
  } = useForm(changePasswordForm);

  return (
    <>
      <div className="block">
        <h2 className="block-title">Смена пароля</h2>
        <form onSubmit={onSubmit(submit)}>
          <div className="block-item">
            <Input
              label="Новый пароль"
              type="password"
              name={PASSWORD}
              {...getPropsField({
                fieldName: PASSWORD, props: restProps,
              })}
              required
            />
          </div>
          <div className="block-item">
            <Input
              label="Подтвердите пароль"
              type="password"
              name={PASSWORD_CONFIRM}
              {...getPropsField({
                fieldName: PASSWORD_CONFIRM, props: restProps,
              })}
              required
            />
          </div>
          <div className="block-button">
            <Button
              type="submit"
              className="nextStep"
            >
              Сменить
            </Button>
          </div>
        </form>
      </div>
    </>
  );
});
